import React from 'react'
import Space from '../../shared/Space';
import {appliableAmount} from './func'

function ProxyApplicationForm({
  course,
  proxyMailAddresses,
  onChangeProxyMailAddressHandler,
  mailAddressValidationMessages,
}) {
  const formRowAmount = () => Math.ceil(appliableAmount(course) / 2)
  const isShowSecondForm = (idx) => appliableAmount(course) !== ((idx + 1) * 2 - 1)
  return (
    <>
      <h2 className="tech_user_course_show_subtitle">{`参加者　（同時に${appliableAmount(course)}人まで申し込みが可能です）`}</h2>
      <div>
        <Space margin="2rem 0">
          {[...Array(formRowAmount())].map((_, idx) => (
            <div className="flex">
              <div className="w-50">
                <Space margin="0.5rem">
                  <span className="form-text">
                    <span className="row-area row-area_liquid">
                      <span className="row-area_liquid_noconnect">
                        <input type="text" name={`proxy_application_address_${idx * 2}`} value={proxyMailAddresses[idx * 2]} onChange={onChangeProxyMailAddressHandler} placeholder='参加者のメールアドレスを入力してください'/>
                        {mailAddressValidationMessages[idx * 2] !== '' && <div className="error-message">{mailAddressValidationMessages[idx * 2]}</div>}
                      </span>
                    </span>
                  </span>
                </Space>
              </div>
              {
                isShowSecondForm(idx) && (
                  <div className="w-50">
                    <Space margin="0.5rem">
                      <span className="form-text">
                        <span className="row-area row-area_liquid">
                          <span className="row-area_liquid_noconnect">
                            <input type="text" name={`proxy_application_address_${idx * 2 + 1}`} value={proxyMailAddresses[idx * 2 + 1]} onChange={onChangeProxyMailAddressHandler} placeholder='参加者のメールアドレスを入力してください'/>
                            {mailAddressValidationMessages[idx * 2 + 1] !== '' && <div className="error-message">{mailAddressValidationMessages[idx * 2 + 1]}</div>}
                          </span>
                        </span>
                      </span>
                    </Space>
                  </div>
                )
              }
            </div>
          ))}
        </Space>
      </div>
    </>
  )
}

export default ProxyApplicationForm