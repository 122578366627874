import React from 'react';
import Card from '../../../../atoms/Card';
import CourseDatePeriod from '../../shared/CourseDatePeriod';
import TrainerCard from '../../shared/TrainerCard';
import Badge from '../../../../atoms/Badge';
import CardSection from '../../../../atoms/CardSection';
import CardSectionArticle from '../../../../atoms/CardSectionArticle';
import Space from '../../../../atoms/Space';
import Breadcrumb from '../../shared/Breadcrumb';
import { StatusActiveBadge, StatusNonActiveBadge } from '../../../Badge';
import Container from '../../../../atoms/Container';

function Index({ applications }) {
  const url = new URL(window.location.href);
  const params = url.searchParams;
  const isCanceledCourses = params.get('is_canceled');

  const currentPageBreadcrumb = () => {
    if (isCanceledCourses == 'true') {
      return { label: 'キャンセル済みのコース', link: '/tech/user/course_applications?is_canceled=true' };
    } else {
      return { label: '申込済みのコース', link: '/tech/user/course_applications?is_canceled=false' };
    }
  };

  const CourseStatusLabel = ({ startDate, finishDate }) => {
    const sDate = new Date(startDate);
    const fDate = new Date(finishDate);
    const today = new Date();
    if (sDate > today) {
      return <StatusNonActiveBadge label="参加期間前" />;
    } else if (sDate < today && today < fDate) {
      return <StatusActiveBadge label="参加期間中" />;
    } else {
      return <StatusNonActiveBadge label="参加期間終了" />;
    }
  };
  return (
    <>
      <div className="d-none d-sm-block">
        <Breadcrumb list={[{ label: 'マイコース' }, currentPageBreadcrumb()]} />
      </div>
      <Card padding="2rem">
        <div className="heading-area">
          <div className="title-area">
            <h1>{isCanceledCourses === 'true' ? 'キャンセル済みのコース' : '申込済みのコース'}</h1>
          </div>
        </div>
        <div className="card_in-card card_section">
          {applications.length === 0 && (
            <Card>
              <CardSection>
                <CardSectionArticle>
                  <p className="c_gray.fw_bold ta_center">申し込んでいるコースがありません</p>
                </CardSectionArticle>
              </CardSection>
            </Card>
          )}
          {applications.map((application) => (
            <div className="card course_application_card">
              <div className="course_application_card_tag_header">
                <CourseStatusLabel
                  startDate={application.course.first_lesson_date}
                  finishDate={application.course.last_lesson_date}
                />
                <Space padding="0 0 0 0.5rem" />
                {application.is_proxy_application &&
                  !application.is_settled_all_applicants &&
                  !application.is_canceled &&
                  application.am_i_applier && (
                    <>
                      <Badge border="1px solid #C53939" color="#C53939">
                        参加者未確定
                      </Badge>
                      <Space padding="0 0 0 0.5rem" />
                    </>
                  )}
                {!application.is_accepted_to_attend && !application.is_canceled && !application.is_course_canceled && (
                  <Badge border="1px solid #C53939" color="#C53939">
                    未入金
                  </Badge>
                )}
                {application.is_course_canceled && (
                  <Badge border="1px solid #C53939" color="#C53939">
                    当社都合によるキャンセル
                  </Badge>
                )}
                {application.am_i_participant &&
                  new Date(application.course.last_lesson_date) < new Date() &&
                  application.result.label !== '保留' &&
                  application.is_accepted_to_attend && (
                    <>
                      {application.result.label === '合格' && (
                        <Badge border="none" color="#00965C" backColor="#C7F2E1">
                          合格
                        </Badge>
                      )}
                      {application.result.label === '不合格' && (
                        <Badge border="none" color="#C53939" backColor="#F2C7C7">
                          不合格
                        </Badge>
                      )}
                    </>
                  )}
                {application.is_attended && (
                  <Badge border="none" color="#00965C" backColor="#C7F2E1">
                    出席済み
                  </Badge>
                )}
              </div>
              <div className="course_application_card_header">
                <div className="course_application_card_header_title">{application.course.name}</div>
              </div>
              <div className={'d-flex justify-content-between'}>
                <TrainerCard course={application.course} />
                <div className="d-flex flex-row-reverse">
                  <div className="user_course_index_link">
                    <span>
                      <a className="button" href={`/tech/user/course_applications/${application.id}`}>
                        <span className="user_course_index_link_text">詳細</span>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className={'d-flex justify-content-between mt-3'}>
                <Container>
                  <span className="material-icons">location_on</span>
                  <span>{application.course.venue?.label}</span>
                </Container>
                <CourseDatePeriod course={application.course} />
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
}

export default Index;
