import React, {useState} from 'react';
import OpenNewTab from './OpenNewTab'
import LoadingOverlay from './LoadingOverlay'

const DownloadButton = (props) => {
  const [loading, setLoading] = useState(false);
  if (loading) {
    return (<LoadingOverlay src={props.gif_path}/>)
  } else {
    return (
      <OpenNewTab setLoadingOverlay={setLoading} {...props}/>
    )
  }
}

export default DownloadButton