import React from 'react';
import CourseLessonTable from "../../CourseLessonTable";

const CourseLessonPreview = ({courseLessons, errorMessage}) => {
  if ((!courseLessons || courseLessons.length === 0) && !errorMessage) {
    return <></>;
  }
  return (
    <>
      <tr className={errorMessage ? 'with_error_message' : ''}>
        <th><label>授業日程</label></th>
      </tr>
      <tr>
        <td><CourseLessonTable courseLessons={courseLessons}/></td>
      </tr>
      {errorMessage ? <tr className={'error_message'}>
        <td><span>{errorMessage}</span></td>
      </tr> : <></>}
    </>
  );
};

export default CourseLessonPreview;
