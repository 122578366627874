import React from 'react';

function DeleteBatu({onClick, className}) {
  return (
    <div onClick={(e) => onClick(e)} className={`rounded_batu ${className}`}>
      <span/>
    </div>
  );
}

export default DeleteBatu;

