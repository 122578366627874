import React from 'react'
import Space from '../../atoms/Space';
import Button from '../../atoms/Button';
import Container from '../../atoms/Container';

export default function PrevEventButton({event, isExistPrevEvent}) {
  return (
    <Space margin="0.2rem">
      {
        isExistPrevEvent ? (
          <Button
            color="#ffffff"
            backColor="#134485"
            width="4rem"
            height="4rem"
            onClick={() => {location.href=`/shifts/edit_page?event_id=${event.id}&prev=true`}}
          >
            <Container width="100%" height="100%"><span>&lt;</span></Container>
          </Button>
        ) : (
          <Button color="#ffffff" backColor="#a9a9a9" width="4rem" height="4rem" disabled>
            <Container width="100%" height="100%"><span>&lt;</span></Container>
          </Button>
        )
      }
    </Space>
  )
}
