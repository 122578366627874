import React from 'react'
import NoContent from '../../../NoContent';
import Span from '../../../../atoms/Span';
import CourseOverviewCard from '../../../CourseOverviewCard';

function CourseList({
  viewCourses,
  order,
}) {
  if (viewCourses.length === 0 ) {
    return(
      <NoContent padding="5rem">
        <Span fontSize="2rem">表示可能なコースはありません</Span>
      </NoContent>
    )
  }else {
    return (
      <div className={"card_in-card card_section mb-4"}>
        { viewCourses.map(
            (course, index) => <CourseOverviewCard course={course} isShowDescriptionLink key={index}/>
          )
        }
      </div>
    )
  }
}

export default CourseList
