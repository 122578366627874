import React from 'react';
import { TocAccountImage } from '../atoms/DummyTocAccountImage';

interface TocAccounts {
  values: Array<TocAccount>
}

interface TocAccount {
  id: bigint
  full_name: string
  full_name_kana: string;
}

const ChildcareFeeIndex = (accounts: TocAccounts) => {
  return (
    <React.StrictMode>
      <>
        <div className="main-side-area_main">
          <div className="card">
            <div className="heading-area">
              <div className="title-area">
                <h1>請求書一覧</h1>
              </div>
            </div>
            <div className="card_in-card card_section">
              {accounts.values && accounts.values.length > 0 && accounts.values.map((toc_account) => {
                return (
                  <>
                    <div className="card">
                      <div
                        className="card_content row-area row-area_xlarge row-area_jc-space-between row-area_ai-start">
                        <div className="col-area">
                          <div className="row-area">
                            <span className="thumbnail-area thumbnail-area_circle">
                              <TocAccountImage accountName={toc_account.full_name}/>
                            </span>
                            <span className="fw_bold">{toc_account.full_name}</span>
                            ({toc_account.full_name_kana})
                          </div>
                        </div>
                        <div className="col-area">
                          <div>
                            <a className="row-area row-area_small link-icon-and-text fs_s"
                               href={`/memori/childcare_fees/index_by_child?toc_account_id=${toc_account.id}`}>
                              <span className="material-icons">zoom_in</span>
                              <span className="link-icon-and-text_text">請求書を見る</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </React.StrictMode>
  )
}

export default ChildcareFeeIndex;
