import React, {useState, useRef, useEffect} from 'react'
import SectionArea from '../atoms/SectionArea';
import Card from '../atoms/Card';
import Space from '../atoms/Space';
import ModalView from '../ModalView';
import ShiftCreateForm from './components/ShiftCreateForm';
import Axios from '../Axios';
import TitleArea from './components/TitleArea';
import ActionArea from './components/ActionArea';
import FacilityShiftTable from './components/FacilityShiftTable';
import DateDecorator from "../DateDecorator";
import { ToastContainer, Slide, toast } from 'react-toastify';
import { shiftValidation } from './shiftValidation';

export default function show({
  event,
  is_exist_next_event,
  is_exist_prev_event,
  is_exist_next_day,
  is_exist_prev_day,
  date,
  shifts,
  facility_options,
  shift_category_options,
  account_category_options,
  posted,
  deleted,
  role_options
}) {
  const [displayedShifts, setDisplayedShifts] = useState(shifts);
  const [postData, setPostData] = useState(false);
  const [deleteData, setDeleteData] = useState(false);
  const [errorMessages, setErrorMessages] = useState({})
  const processing = useRef(false);
  const onCreate = () => {
    processing.current = true;
    var validationMessageObj = shiftValidation(displayedShifts, postData, date);
    if(Object.keys(validationMessageObj).length > 0) {
      setErrorMessages({...validationMessageObj})
      processing.current = false;
      return
    }

    Axios.post("/shifts", {...postData, event_id: event.id, date: date})
      .then(({data}) => {
        window.location.href = `/shifts/edit_page?event_id=${event.id}&date=${new DateDecorator(new Date(date)).param()}&posted=true`;
        processing.current = false;
      }).catch(err => {
      processing.current = false;
    });
  }

  const deleteShift = () => {
    processing.current = true;
    Axios.delete(`/shifts/${deleteData.shiftId}`)
      .then(({data}) => {
        processing.current = false;
        window.location.href = `/shifts/edit_page?event_id=${event.id}&date=${new DateDecorator(new Date(date)).param()}&deleted=true`;
      }).catch(err => {
      processing.current = false;
    });
  }

  const modalOpen = (facility_id, hall_id, spot_id) => {
    setPostData({
      facility_id: facility_id,
      hall_id: hall_id,
      spot_id: spot_id,
      category_id: null,
      toucheez_role_id: null,
      account_category: null,
      started_at: null,
      finished_at: null,
      account: null,
    })
  }

  useEffect(() => {
    if(posted){
      toast.success("正常に作成されました");
    }
    if(deleted){
      toast.success("正常に削除されました");
    }
  },[])

  return (
    <>
      <>
        <SectionArea className="w-100">
          <Card>
            <TitleArea
              event={event}
              isExistPrevEvent={is_exist_prev_event}
              isExistNextEvent={is_exist_next_event}
            />
            <ActionArea
              event={event}
              date={date}
              isExistPrevDay={is_exist_prev_day}
              isExistNextDay={is_exist_next_day}
            />
            <Space padding={"0 1rem 0 1rem"}>
              {displayedShifts.map(facilityData => (
                <FacilityShiftTable
                  facilityData={facilityData}
                  deleteShift={(shiftId) => setDeleteData({shiftId: shiftId})}
                  modalOpen={modalOpen}
                />
              ))}
            </Space>
          </Card>
        </SectionArea>
        {postData && (
          <ModalView
            setModal={() => {setPostData(false); setErrorMessages({})}}
            title={'シフトの作成'}
            confirmMessage="追加"
            link={true}
            handleSubmit={onCreate}
            noAutoClose
          >
            <ShiftCreateForm
              event={event}
              facility_options={facility_options}
              shift_category_options={shift_category_options}
              account_category_options={account_category_options}
              role_options={role_options}
              onChangeData={setPostData}
              postData={postData}
              errorMessages={errorMessages}
            />
          </ModalView>
        )}
        {deleteData && (
          <ModalView
            setModal={() => setDeleteData(false)}
            link
            title={'シフトを削除しますがよろしいですか？'}
            body={''}
            confirmMessage={'削除する'}
            cancelMessage={'キャンセル'}
            handleSubmit={deleteShift}
          />
        )}
      </>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2500}
        transition={Slide}
        position={'top-center'}
      />
    </>
  )
}
