import React from 'react';
import JSelect from 'react-select';

const Select = ({ id, name, value, options, onChange }) => {
  const convertValue = value => options.filter(o => o.value === value);

  return (
    <div style={{ width: '100%' }}>
      <JSelect
        id={id}
        options={options}
        name={name}
        value={convertValue(value)}
        onChange={onChange}
        placeholder="選択してください"
        isClearable={true}
        isSearchable={true}
      />
    </div>
  );
};

export default Select;
