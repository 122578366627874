import Width from "../../../atoms/Width";
import Space from "../../../atoms/Space";
import FlexBox from "../../../atoms/FlexBox";
import Badge from "../../../atoms/Badge";
import Span from "../../../atoms/Span";
import MemoTextField from "./MemoTextField";
import React from "react";

export function MemoIndex({application, setSnack, snack}) {
  console.log("running!!!")
  console.log(application)
  return <Width size="20%">
    <Space margin="1rem 0 1rem 0">
      <FlexBox>
        <FlexBox>
          <Badge><Span fontSize="1.5rem">メモ</Span></Badge>
          <Space margin="0 0 0 0.5rem">
            <Span fontSize="1.5rem">
              {application.memo == null ? 'メモがありません' : application.memo.length > 15 ? application.memo.substring(0, 15) + '...' : application.memo}
            </Span>
          </Space>
        </FlexBox>
      </FlexBox>
    </Space>
    <MemoTextField
      applicationId={application.id}
      memo={application.memo}
      onSuccess={(message) => setSnack({...snack, type: 'success', message: message})}
      onError={(message) => setSnack({...snack, type: 'error', message: message})}
    />
  </Width>;
}