import React from 'react';
import {
  DragDropContext as DndContext,
  Droppable,
  Draggable as DndDraggable,
} from 'react-beautiful-dnd';

const DragDropContext = ({ children, handleDragEnd, targets, id }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    handleDragEnd(
      reorder(targets, result.source.index, result.destination.index)
    );
  };

  return (
    <DndContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div id={id} ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DndContext>
  );
};

export const Draggable = ({ target, index, children }) => {
  return (
    <DndDraggable draggableId={'' + target.id} index={index}>
      {provided => (
        <div
          id={`draggable-${index}`}
          key={target.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {children}
        </div>
      )}
    </DndDraggable>
  );
};

export default DragDropContext;
