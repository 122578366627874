import React, {useState, useRef, useMemo} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';
import DateDecorator from "../DateDecorator";
registerLocale('ja', ja);

import CalendarDatePicker from './components/CalendarDatePicker';
import EventIndex from './components/EventIndex';
import SectionArea from '../atoms/SectionArea';
import TwoWayRow from '../atoms/TwoWayRow';

import Axios from '../Axios';
const index = ({events, date, event_date_list, can_export_csv}) => {
  const [displayEvents, setDisplayEvents] = useState(events)
  const [displayDate, setDisplayDate] = useState(new Date(Date.parse(date)))
  const [eventDateList, setEventDateList] = useState(
    event_date_list.map(d => new Date(d)).filter(d => d!== new Date(date))
  )
  const processing = useRef(false);

  const searchEvents = (date) => {
    if (processing.current == true ) return;
    processing.current = true;
    Axios.get(`shifts/filter_events?date=${new DateDecorator(date).param()}`)
      .then(({data: {events, event_date_list}}) => {
        setDisplayEvents(events);
        setDisplayDate(date);
        setEventDateList(event_date_list.map(d => new Date(d)).filter(d => d!== new Date(date)))
        processing.current = false;
      }).catch(err => {
      processing.current = false;
    });
  }

  return (
    <TwoWayRow>
      <SectionArea className="w-60">
        <EventIndex events={displayEvents} date={displayDate} can_export_csv={can_export_csv}/>
      </SectionArea>
      <SectionArea className="w-40">
        <CalendarDatePicker
          date={displayDate}
          highlightDates={eventDateList}
          onChangeDate={searchEvents}
        />
      </SectionArea>
    </TwoWayRow>
  );
}
export default index
