import React, {useState, useEffect} from 'react';
import {Formik, Form, Field, FieldArray, FastField, useField} from 'formik';
import Axios from '../Axios';
import Alert from '../Alert';
import FormColumn from '../FormColumn';
import FlexBox from '../atoms/FlexBox';
import Width from '../atoms/Width';
import Space from '../atoms/Space';
import Card from '../atoms/Card';
import MathJaxTextView from '../MathJaxTextView';
import QuestionTitleForm from './QuestionTitleForm';
import QuestionChoiceForm from './QuestionChoiceForm';
import { useWatchValue } from '../hooks/WatchValue';

const defaultValues = {
  title: '',
  score: 0,
  tech_exercise_form_type_enum_id: 1,
  choices: Array(1).fill({name: '', is_correct: false}),
}


const QuestionForm = ({exercise, question}) => {
  const [message, setMessage] = useState();
  const [shouldShowOptions, toggleShowOptions] = useState(question ? question.tech_exercise_form_type_enum_id != 1 : false);
  const baseUrl = '/tech/exercises';
  const [changeFlg, setChangeFlg] = useState(false)
  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = "MathJax-script";
    scriptTag.async = true;
    scriptTag.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"
    head.appendChild(scriptTag);
  }, [])

  const debouncedInputText = useWatchValue(changeFlg, 500);
  useEffect(() => {
    if(typeof window?.MathJax !== "undefined"){
      // デフォルトだとレンダー時の文字列しか変換されないので、明示的に変換処理を呼ぶ
      window.MathJax.typesetPromise();
    }
  }, [debouncedInputText]);

  const validate = values => {
    let errors = {}
    if (!values.title) {
      errors.title = '入力してください'
    }
    if (!values.score) {
      errors.score = '入力してください'
    } else if (Number(values.score) > 9999999) {
      errors.score = '7桁以内で入力してください'
    }
    if (shouldShowOptions && values.choices.length == 1 && values.choices.some(c => !c.name.trim())) {
      errors.choices = '入力してください'
    }
    return errors;
  }

  const formValues = () => {
    return question ?
      { ...question, choices: question.choices.length > 0 ? question.choices.map(c => { return {name: c.name, is_correct: c.is_correct} }) : defaultValues.choices } :
      {...defaultValues}
  }
  const handleSubmit = values => {
    if (question) {
      Axios.put(`${baseUrl}/${exercise.id}/exercise_questions/${question.id}`, {question: values})
        .then(({data}) => {
          setMessage({text: `更新しました`, type: 'notice'});
          window.location.href = `${baseUrl}/${exercise.id}`
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    } else {
      Axios.post(`${baseUrl}/${exercise.id}/exercise_questions`, {question: values})
        .then(({data}) => {
          setMessage({text: `登録しました`, type: 'notice'});
          window.location.href = `${baseUrl}/${exercise.id}`
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }
  }

  const handleFormChange = (e, setFieldValue) => {
    toggleShowOptions(e.target.value != "1");
    setFieldValue("tech_exercise_form_type_enum_id", e.target.value);
  }

  return (
    <div className='exercise-question'>
      {message && (
        <Alert
          message={message}
          onClose={() => setMessage('')}
          timeout={3000}
        />
      )}
      <h3>{exercise.name}</h3>
      <div className='section-area'>
        <Formik
          enableReinitialize
          initialValues={formValues()}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({values, errors, setFieldValue}) => (
            <Form onChange={() => setChangeFlg(!changeFlg)}>
              <div className='card'>
                <div className='card_section'>
                  <table className='table_form'>
                    <tbody>
                    <FormColumn label='点数' required>
                      <td className='form-text'>
                        <Field type="number" name="score" id="score" placeholder="例）10"/>
                        <span className="red">{errors.score}</span>
                      </td>
                    </FormColumn>
                    <FormColumn label='問題文' required>
                      <td className='form-text'>
                        <QuestionTitleForm name="title"/>
                        <span className="red">{errors.title}</span>
                      </td>
                    </FormColumn>
                    <FormColumn label='回答方式' required>
                      <td className='form-text'>
                        <Field as='select' id="tech_exercise_form_type_enum_id"
                                name='tech_exercise_form_type_enum_id'
                                onChange={e => handleFormChange(e, setFieldValue)}
                                disabled={exercise.results.length > 0}
                        >
                          <option value='1'>記述式</option>
                          <option value='2'>単一選択式</option>
                          <option value='3'>複数選択式</option>
                        </Field>
                      </td>
                    </FormColumn>
                    {shouldShowOptions &&
                      <FormColumn label='選択肢' required>
                        <td className="form-text">
                          <FieldArray
                            name="choices"
                            render={(arrayHelpers) => {
                              return values.choices.map((c, i) => {
                                return <FormChoice i={i} arrayHelpers={arrayHelpers}
                                                    errors={typeof errors.choices === "string" ? errors.choices : null}
                                                    key={i}
                                                    setChangeFlg={setChangeFlg}/>
                              });
                            }}
                          />
                          <div
                            className="may_select_add_button_frame"
                            onClick={() => {
                              const choices = [...values.choices];
                              choices.push('');
                              setFieldValue('choices', choices);
                              setChangeFlg(!changeFlg)
                            }}
                          >
                            <div className="may_select_add_button">
                              さらに追加するにはここをクリックしてください          
                            </div>
                          </div>
                        </td>
                      </FormColumn>
                    }
                    </tbody>
                  </table>
                </div>
                <FlexBox content="center">
                  <Width size="70%">
                    <FlexBox content="space-between">
                      <a href={`${baseUrl}/${exercise.id}`} className="button button_cancel">入力した内容を破棄</a>
                      <Space margin="0 2rem">&nbsp;</Space>
                      <input type='submit' value={question ? "入力した内容を保存" : "入力した内容で登録"} className="button"/>
                    </FlexBox>
                  </Width>
                </FlexBox>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const FormChoice = ({i, arrayHelpers, errors, setChangeFlg}) => {
  const choiceValidation = (value) => {
    if (!value.trim()) {
      return '入力してください'
    }
    return value.length > 255 ? "２５５文字以下で入力してください" : undefined
  }
  return (
    <div key={`top${i}`} style={{marginBottom: "1rem"}}>
      <FastField
        key={i}
        name={`choices.${i}.name`}
        validate={(value) => !errors ? choiceValidation(value) : null}
      >
        {({field, form, meta}) => (
          <div>
            <div className='flex items-center'>
              <Width size="85%">
                <QuestionChoiceForm name={`choices.${i}.name`}/>
              </Width>
              <Width size="15%">
                <FlexBox>
                  <div className='flex items-center mx-3'>
                    <Field key={`correct${i}`} type="checkbox" name={`choices.${i}.is_correct`}
                          className='correct-checkbox'/>
                    <span className='correct-label'>正解</span>
                  </div>
                  <div className='flex items-center'>
                    {i > 0 &&
                      <button 
                        type="button"
                        id={`remove${i}`}
                        style={{marginLeft: "1rem"}}
                        onClick={() => {arrayHelpers.remove(i); setChangeFlg((prev) => !prev)}
                      }>
                        ×
                      </button>
                    }
                  </div>
                </FlexBox>
              </Width>
            </div>
            <span className={'red'}>{errors || meta.error} </span>
          </div>
        )}
      </FastField>
    </div>
  )
}

export default QuestionForm;
