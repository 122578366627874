import React from 'react';
import CourseLessonTable from "./CourseLessonTable";

const CourseLessons = ({courseLessons, trainers}) => {
  return (
    <>
      <h2>授業日程</h2>
      <div>
        <CourseLessonTable
          courseLessons={courseLessons}
          trainers={trainers}
          isEditable={true}
        />
      </div>
    </>
  );
}
export default CourseLessons;
