import React from 'react';
import Axios from './Axios';

const OpenNewTab = ({ message, icon, path, setLoadingOverlay }) => {
  const LoadingButton = () => onClick(path, setLoadingOverlay)
  return (
    <a className="button" onClick={LoadingButton}>
      <span className="material-icons">{icon}</span>
      <span >{message}</span>
    </a>
  )
}

const onClick = (path, _setLoadingOverlay) => {
  _setLoadingOverlay(true);
  Axios
  .get(path)
  .then(response => {
    window.open(response.data.path);
    _setLoadingOverlay(false);
  })
  .catch(error => {
    console.error(error);
    _setLoadingOverlay(false);
  });
}

export default OpenNewTab
