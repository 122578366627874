import React, {useState} from 'react';
import {Formik, Form, Field, FieldArray, FastField} from 'formik';
import Axios from './Axios';
import Alert from './Alert';
import FormColumn from './FormColumn';

const defaultValues = {
  number: 1,
  title: '',
  question_form_type_enum_id: 14,
  choices: Array(1).fill({ name: '' }),
}

const QuestionForm = ({ questionnaire, question }) => {
  const [message, setMessage] = useState();
  const [shouldShowOptions, toggleShowOptions] = useState(question ? question.question_form_type_enum_id != 14 : false);
  const baseUrl = '/questionnaires';

  const validate = values => {
    let errors = {}
    if (!values.number) {
      errors.number = '入力してください'
    } else if (Number(values.number) > 9999999) {
      errors.number = '7桁以内で入力してください'
    }
    if (!values.title) {
      errors.title = '入力してください'
    } else if (values.title.length > 255) {
      errors.title = '２５５文字以下で入力してください'
    }
    if (shouldShowOptions && values.choices.length == 1 && values.choices.some(c => !c.name.trim())) {
      errors.choices = '入力してください'
    }
    return errors;
  }

  const formValues = () => {
    return question ?
      { ...question, choices: question.choices.length > 0 ? question.choices.map(c => { return { name: c.name } }) : defaultValues.choices } :
      {...defaultValues, number: questionnaire.questions.length + 1}
  }
  const handleSubmit = values => {
    if (question) {
      Axios.put(`${baseUrl}/${questionnaire.id}/questions/${question.id}`, {question: values})
        .then(({data}) => {
          setMessage({text: `更新しました`, type: 'notice'});
          window.location.href = `${baseUrl}/${questionnaire.id}`
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    } else {
      Axios.post(`${baseUrl}/${questionnaire.id}/questions`, {question: values})
        .then(({data}) => {
          setMessage({text: `登録しました`, type: 'notice'});
          window.location.href = `${baseUrl}/${questionnaire.id}`
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }
  }

  const handleFormChange = (e, setFieldValue) => {
    toggleShowOptions(e.target.value != "14");
    setFieldValue("question_form_type_enum_id", e.target.value);
  }

  return (
    <React.StrictMode>
      <div className='question'>
        {message && (
          <Alert
            message={message}
            onClose={() => setMessage('')}
            timeout={3000}
          />
        )}
        <h3>{questionnaire.name}</h3>
        <div className='section-area'>
          <Formik
            enableReinitialize
            initialValues={formValues()}
            validate={validate}
            onSubmit={handleSubmit}
          >
            {({values, errors, setFieldValue}) => (
              <Form>
                <div className='main-side-area'>
                  <div className='main-side-area_main'>
                    <div className='card'>
                      <div className='card_section'>
                        <table className='table_form'>
                          <tbody>
                          <FormColumn label='No.' required>
                            <td className='form-text'>
                              <Field type="number" name="number" id="number" placeholder="例）1"/>
                              <span className="red">{errors.number}</span>
                            </td>
                          </FormColumn>
                          <FormColumn label='質問' required>
                            <td className='form-text'>
                              <Field name="title" id="title" placeholder="質問を記入してください"/>
                              <span className="red">{errors.title}</span>
                            </td>
                          </FormColumn>
                          <FormColumn label='回答方式' required>
                            <td className='form-text'>
                              <Field as='select' id="question_form_type_enum_id"
                                     name='question_form_type_enum_id'
                                     onChange={e => handleFormChange(e, setFieldValue)}
                                // TODO 回答ありの場合disable
                                // disabled={questionnaire.results.length > 0}
                              >
                                <option value='14'>記述式</option>
                                <option value='15'>単一選択式</option>
                                <option value='16'>複数選択式</option>
                              </Field>
                            </td>
                          </FormColumn>
                          {shouldShowOptions &&
                            <FormColumn label='選択肢' required>
                              <td className="form-text">
                                <FieldArray
                                  name="choices"
                                  render={(arrayHelpers) => {
                                    return values.choices.map((c, i) => {
                                      return <FormChoice i={i} arrayHelpers={arrayHelpers}
                                                         errors={typeof errors.choices === "string" ? errors.choices : null}
                                                         key={i}/>
                                    });
                                  }}
                                />
                              </td>
                            </FormColumn>
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='main-side-area_side'>
                    <div className='card sticky_top'>
                      <div className='card_section'>
                        <div className='card_section_article'>
                          <h1>ページの操作</h1>
                        </div>
                      </div>
                      <div className='card_section'>
                        <div className='card_section_article'>
                          <div className='col-area'>
                            <span>
                              <input type='submit' value={question ? "入力した内容を保存" : "入力した内容で登録"} className="button"/>
                            </span>
                            <span>
                              <a href={`${baseUrl}/${questionnaire.id}`} className="button button_cancel">入力した内容を破棄</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.StrictMode>
  );
}

const FormChoice = ({i, arrayHelpers, errors}) => {
  const choiceValidation = (value) => {
    if (!value.trim()) {
      return '入力してください'
    }
    return value.length > 255 ? "２５５文字以下で入力してください" : undefined
  }
  return (
    <React.StrictMode>
      <div key={`top${i}`} style={{marginBottom: "1rem"}}>
        <FastField
          key={i}
          name={`choices.${i}.name`}
          validate={(value) => !errors ? choiceValidation(value) : null}
        >
          {({field, form, meta}) => (
            <div>
              <div className='flex items-center'>
                <Field key={i} name={`choices.${i}.name`} placeholder="入力してください"/>
                <div className='flex items-center'>
                  {i > 0 &&
                    <button type="button" id={`remove${i}`} style={{marginLeft: "1rem"}}
                            onClick={() => arrayHelpers.remove(i)}>
                      ×
                    </button>
                  }
                  <button type="button" id={`add${i}`} style={{marginLeft: "1rem"}}
                          onClick={() => arrayHelpers.insert(i + 1, '')}>
                    +
                  </button>
                </div>
              </div>
              <span className={'red'}>{errors || meta.error} </span>
            </div>
          )}
        </FastField>
      </div>
    </React.StrictMode>
  )
}

export default QuestionForm;
