import Axios from '../../../../Axios';

export const handleSubmit = (courseTemplate, isProcessing, handleProcessing, handleErrorMessage, setSnack) => {
  if(isProcessing) {
    return;
  }
  handleProcessing(true);
  if (courseTemplate.id !== null) {
    Axios.patch(`/tech/course_templates/${courseTemplate.id}`, { tech_course_template: courseTemplate })
      .then(({data}) => {
        handleProcessing(false);
        window.location.href = `/tech/course_templates/${data.id}/edit?snack=コーステンプレートを作成しました`;
      }).catch(err => {
        handleErrorMessage(err.response.data);
        handleProcessing(false);
        setSnack(prev => ({...prev, type: 'error', message: "入力情報が不正です。内容を確認してください"}))
      });
  }else {
    Axios.post(`/tech/course_templates`, { tech_course_template: courseTemplate })
      .then(({data}) => {
        handleProcessing(false);
        window.location.href = `/tech/course_templates/${data.id}/edit?snack=コーステンプレートを更新しました`;
      }).catch(err => {
        handleErrorMessage(err.response.data);
        handleProcessing(false);
        setSnack(prev => ({...prev, type: 'error', message: "入力情報が不正です。内容を確認してください"}))
      });
  }
}

export const handleBack = (isProcessing) => {
  if(isProcessing) {
    return;
  }
  window.location.href = `/tech/course_templates`
}