import React from 'react'
import Card from '../../../../atoms/Card'
import CardSection from '../../../../atoms/CardSection'
import Span from '../../../../atoms/Span'
import CourseVideoFrame from '../../../CourseVideoFrame'

function Show({lesson}) {
  return (
    <Card padding="2rem">
      <CardSection>
        <h2>名前</h2>
        <Span fontSize="14px">{lesson.name}</Span>
      </CardSection>
      <CardSection>
        <h2>詳細</h2>
        <Span fontSize="14px">{lesson.detail}</Span>
      </CardSection>
      <CardSection>
        <h2>授業動画</h2>
        {
          lesson.videos.map((v) => (
            <CourseVideoFrame document={v}/>
          ))
        }
      </CardSection>
      <CardSection>
        <h2>理解度テスト</h2>
        {lesson.exercises.length === 0 && (
          <Span fontSize="14px">理解度テストはありません</Span>
        )}
        {
          lesson.exercises.map((e) => (
            <Span fontSize="14px">{e.name}　</Span>
          ))
        }
      </CardSection>
    </Card>
  )
}

export default Show