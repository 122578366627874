import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function HorizontalLinearStepper({
  steps,
  activeStep,
  isShowPagingLink,
  handleChangeStep,
}) {

  const handleNext = () => {
    if(activeStepIndex() === steps.length - 1) {
      return;
    }
    handleChangeStep(steps[activeStepIndex() + 1]);
  };

  const handleBack = () => {
    if(activeStepIndex() === 0) {
      return;
    }
    handleChangeStep(steps[activeStepIndex() - 1]);
  };

  const activeStepIndex = () => steps.indexOf(activeStep)

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStepIndex()}>
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {isShowPagingLink && (
        <React.Fragment>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 1 }}>
            <Button
              disabled={activeStepIndex() === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {"< Back"}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleNext} disabled={activeStepIndex() === steps.length - 1}>
              {activeStepIndex() === steps.length - 1 ? 'Finish' : 'Next >'}
            </Button>
          </Box>
        </React.Fragment>
      )}

    </Box>
  );
}