import React from 'react'

function Thumbnail({flameClass, imgPath, alt, annotation}) {
  return (
    <div className={flameClass}>
      <img src={imgPath} alt={alt} />
      {annotation && <div className={'thumbnail-area_soldout'}><span>{annotation}</span></div>}
    </div>
  )
}

export default Thumbnail