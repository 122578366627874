import React, {useState, useMemo, useEffect} from 'react';
import Form from '../../../form/Form';
import RadioButton from '../../../form/RadioButton';
import DateTimePeriod from '../../../form/DateTimePeriod';
import TextArea from '../../../form/TextArea';
import IncrementalSelect from '../../../form/IncrementalSelect';
import Axios from '../../../../lib/axios'
import CustomButton from '../../../form/CustomButton';
import DateListPreview from '../../DateListPreview';
import {serialize} from '../../../../shared/query';
import CourseLessonPreview from "./CourseLessonPreview";
import TextField from '../../../form/TextField';
import ScheduleTable from '../../ScheduleTable';
import DateDecorator from "../../../DateDecorator";
import Snackbar from '../../../shared/Snackbar';
import CourseMemoModal from './CourseMemoModal';
import Space from '../../../atoms/Space';
import Checkbox from "@mui/material/Checkbox";

const CourseForm = ({
                      course,
                      venue_options,
                      course_template_options,
                      lesson_interval_options,
                      trainer_options,
                      online_offline_options,
                      course_kind_options,
                      protect_schedule,
                      publish_status_options,
                      brand_selection_options
                    }) => {
  const baseUrl = "/tech/courses"
  const [isProcessing, setIsProcessing] = useState(false)

  const convertTimeToJpnTime = (dateStr) => { // オランダでページを開いたときのことを考慮
    const localTime = new Date
    const localOffset = localTime.getTimezoneOffset()
    const jpnOffset = -540
    const offsetSub = jpnOffset - localOffset
    const dateObj = new Date(Date.parse(dateStr))
    const jpnTime = new Date(dateObj.getTime() - offsetSub * 60000)
    return jpnTime
  }
  const convertJpnTimeToIsoString = (dateStr) => new DateDecorator(convertTimeToJpnTime(new Date(Date.parse(dateStr)))).isoString()

  const [data, setData] = useState({
    lesson_interval: course.lesson_interval,
    dateList: [],
    course: {
      ...course,
      course_brand_selections: course.course_brand_selections.map((brand, _) => { return brand.tech_brand_selection_enum_id.toString(10) }),
      tech_online_offline_enum_id: 27,
      main_trainer_id: course.main_trainer_id || course.copy_trainer_id,
      apply_start_day: course.apply_start_day ? convertJpnTimeToIsoString(course.apply_start_day) : '',
      apply_end_day: course.apply_end_day ? convertJpnTimeToIsoString(course.apply_end_day) : '',
    },
    errors: {},
    freeScheduleTrainers: trainer_options,
    courseKinds: course_kind_options,
    schedule: [],
  });
  const [preview, setPreview] = useState(
    course.course_lessons.map(cl => (
      {
        ...cl,
        start_at: cl.start_at ? convertJpnTimeToIsoString(cl.start_at) : '',
        finish_at: cl.finish_at ? convertJpnTimeToIsoString(cl.finish_at) : '',
      }
    ))
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [tableClass, setTableClass] = useState('')
  const [snack, setSnack] = useState({
    type: 'success',
    message: '',
    autoHideDuration: 2000,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
  });

  const filteredTemplateOptions = useMemo(() => {
    return course_template_options.filter((o) => {
      if (data.course.course_kind_id) {
        return o.tech_course_kind_id === Number(data.course.course_kind_id)
      } else {
        return true
      }
    })
  }, [data])

  const handleChange = (e) => {
    setData({
      ...data,
      course: {...data.course, [e.target.name]: e.target.value},
    });
  }

  const handleCheckboxChange = (e) => {
    if(data.course.course_brand_selections.includes(e.target.value)) {
      const tmp = data.course.course_brand_selections.filter((brand, index) => (brand !== e.target.value));
      setData({
        ...data,
        course: {
          ...data.course,
          course_brand_selections: tmp,
        }
      });
    } else {
      setData({
        ...data,
        course: {
          ...data.course,
          course_brand_selections: [...data.course.course_brand_selections, e.target.value]
        },
      });
    }
  }

  const handleDeleteCourse = (e) => {
    if (protect_schedule) {
      return;
    }
    setIsProcessing(true)
    let confirmed = confirm('コースを削除しますがよろしいですか？');
    if (confirmed) {
      Axios.delete(`${baseUrl}/${course.id}`)
        .then(({data}) => {
          window.location.href = data.path;
        }).catch(err => {
          window.location.href = err.path;
        });
    }
    setIsProcessing(false)
  }

  const handleCopyCourse = (e) => {
    if (!course.id) {
      return;
    }
    window.location.href = `${baseUrl}/${course.id}/copy`; 
  }

  const handlePost = () => {
    setIsProcessing(true)
    const postData = {...data.course, lesson_interval: data.lesson_interval, schedule: preview}
    if (course.id) {
      Axios.put(`${baseUrl}/${course.id}`, {tech_course: postData})
        .then(({data}) => {
          window.location.href = data.path;
        }).catch(err => {
        const errors = {...err.response.data}
        setData({...data, errors})
        setIsProcessing(false)
      });
    } else {
      Axios.post(`${baseUrl}`, {tech_course: postData})
        .then(({data}) => {
          window.location.href = data.path;
        }).catch(err => {
        const errors = {...err.response.data}
        setData({...data, errors})
        setIsProcessing(false)
      });
    }
  }

  const handlePreview = () => {
    if(!course.id) {
      return
    }
    setIsProcessing(true)
    window.open(`${baseUrl}/preview?id=${course.id}`, '_blank');
    setIsProcessing(false)
  }

  const handleTemplateChange = (e) => {
    handleChange(e);
    if (!e.target.value) {
      return;
    }
    setTableClass(tableClass === 'tech_course_updated_animation_1' ? 'tech_course_updated_animation_2' : 'tech_course_updated_animation_1')
    fetchLessons(e.target.value);
  }

  const handleScheduleChange = (datetime, idx, key) => {
    setPreview(
      preview.map((l, i) => {
        if (i === idx) {
          return {...l, [key]: datetime}
        } else {
          return l
        }
      })
    )
  }

  useEffect(() => {
  }, [data.course.tech_course_template_id])

  useEffect(() => {
    setIsFirstLoad(false)
    if (course.copy_trainer_id) {
      fetchLessons(course.tech_course_template_id);
    }
  }, [])

  const fetchLessons = (template_id) => {
    Axios.get(`${baseUrl}/lessons?${serialize({
      tech_course_id: data.course.id,
      tech_course_template_id: template_id,
    })}`)
      .then((res) => {
        setPreview(res.data.lessons);
      }).catch(err => {
        setData({...data, errors: {...data.errors, ...err.response.data}});
      });
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({...snack, message: ""})
  };
  
  return (
    <>
      <Snackbar
        open={snack.message !== ""}
        message={snack.message}
        anchorOrigin={snack.anchorOrigin}
        autoHideDuration={snack.autoHideDuration}
        type={snack.type}
        onClose={handleSnackClose}
      />
      <div id={'course_form'}>
        <div className={"section-area"}>
          <div className={"main-side-area"}>
            <div className={"main-side-area_main"}>
              <div className={"card"}>
                <div className={"card_section"}>
                  <Form withoutSubmit={true}>
                    <TextField
                      label="コース名"
                      required={true}
                      value={data.course.name}
                      nameKey={'name'}
                      placeholder={'（例）データサイエンス 1Day 体験コース'}
                      onChange={handleChange}
                      errorMessage={data.errors?.name}
                    />
                    <IncrementalSelect
                      label={'テンプレート'}
                      required={true}
                      options={filteredTemplateOptions}
                      nameKey={'tech_course_template_id'}
                      selectedValue={data.course.tech_course_template_id ?? ''}
                      onChange={handleTemplateChange}
                      errorMessage={data.course.errors?.tech_course_template_id || data.errors?.tech_course_template_id}
                      disabled={protect_schedule}
                    />
                    <tr className={"row_note caption"}>
                      <td>
                        <a href={"/tech/course_templates"} className={"fw_bold"}>コーステンプレート一覧</a>からテンプレートの登録と確認ができます。
                      </td>
                    </tr>
                    <tr className={data.course.errors?.tech_brand_selection_enum_id && 'with_error_message'}>
                      <th>
                        <label>
                          表示させるサイト選択
                          <span className={"badge_status badge_status_important"}>必須</span>
                        </label>
                      </th>
                    </tr>
                    {
                      brand_selection_options.map((option, index) => (
                          <span key={`course_brand_selection_enum_id-${index}-${index}`}>
                            <label htmlFor={`course_brand_selection_enum_id-${index}`}>
                              <Checkbox
                                value={option.value}
                                id={`course_brand_selection_enum_id-${index}`}
                                name={`course_brand_selections[]`}
                                defaultChecked={
                                  data.course.course_brand_selections.length != 0 ?
                                    (data.course.course_brand_selections.includes(option.value.toString(10)) ? 'checked' : '') :
                                    'checked'
                                }
                                onChange={handleCheckboxChange}
                              />
                              {option.name}
                            </label>
                          </span>
                      ))
                    }
                    <IncrementalSelect
                      label={'メイントレーナー'}
                      required={true}
                      options={data.freeScheduleTrainers}
                      nameKey={'main_trainer_id'}
                      selectedValue={data.course.main_trainer_id ?? ''}
                      onChange={handleChange}
                      errorMessage={data.course.errors?.main_trainer_id || data.errors?.main_trainer_id}
                      disabled={protect_schedule}
                    />
                    <tr className={"row_note caption"}>
                      <td>
                        <a href={"/tech/trainers"} className={"fw_bold"}>トレーナー情報一覧</a>からトレーナーの登録と確認ができます。
                      </td>
                    </tr>
                    <IncrementalSelect
                      label={'開催場所'}
                      required={true}
                      options={venue_options.map((venue) => ({...venue, name: venue.label}))}
                      nameKey={'tech_venue_id'}
                      selectedValue={data.course.tech_venue_id}
                      onChange={handleChange}
                      disabled={protect_schedule}
                      errorMessage={data.course.errors?.tech_venue_id || data.errors?.tech_venue_id}
                    />
                    <TextField
                      label="募集人数"
                      required={true}
                      type={'number'}
                      value={data.course.capacity}
                      nameKey={'capacity'}
                      placeholder={'（例）10'}
                      onChange={(e) => handleChange({target: {name: e.target.name, value: Number(e.target.value)}})}
                      errorMessage={data.course.errors?.capacity || data.errors?.capacity}
                    />
                    <TextArea
                      label="受講者限定情報"
                      required={true}
                      value={data.course.private_description}
                      nameKey={'private_description'}
                      placeholder={'参加確定したユーザーに公開したい情報を入力してください'}
                      onChange={handleChange}
                      errorMessage={data.course.errors?.private_description || data.errors?.private_description}
                    />
                    <DateTimePeriod
                      label={'申込期間'}
                      required={true}
                      fromKey={'apply_start_day'}
                      from={data.course?.apply_start_day || ''}
                      toKey={'apply_end_day'}
                      to={data.course?.apply_end_day || ''}
                      onChange={handleChange}
                      from_error_text={data.course.errors?.apply_start_day || data.errors?.apply_start_day}
                      to_error_text={data.course.errors?.apply_end_day || data.errors?.apply_end_day}
                      disabled={protect_schedule}
                    />
                    <div className="mb-4">
                      <tr>
                        <th>
                          <label>
                            授業スケジュール
                            <span className="badge_status badge_status_important">必須</span>
                          </label>
                        </th>
                      </tr>
                      {preview.length > 0 && (
                        <ScheduleTable
                          lessons={preview}
                          onChange={handleScheduleChange}
                          customClass={tableClass}
                          disabled={protect_schedule}
                          errors={data.course.errors?.schedule || data.errors?.schedule || data.errors?.from}
                        />                        
                      )}
                      {!preview.length > 0 && (
                        <div className="course_lesson_preview_none">
                          テンプレートを選択すると授業スケジュールが表示されます
                        </div>
                      )}
                    </div>
                    <RadioButton
                      label={'公開設定'}
                      required={true}
                      options={publish_status_options}
                      nameKey={'tech_course_status_id'}
                      selectedValue={Number(data.course.tech_course_status_id)}
                      onChange={handleChange}
                      errorMessage={data.course.errors?.tech_course_status_id || data.errors?.tech_course_status_id}
                    />
                    {data.course.tech_course_status_id === 111 &&
                      <TextField
                        label="パスワード（自動生成 変更不可）"
                        value={data.course.password_digest}
                        nameKey={'name'}
                        disabled={true}
                      />
                    }
                    </Form>
                </div>
              </div>
            </div>
            <div className={"main-side-area_side"}>
              <div className={"card"}>
                <div className={"card_section"}>
                  <div className={"card_section_article"}>
                    <h1>ページの操作</h1>
                  </div>
                </div>
                <div className="card_section">
                  <div className="card_section_article">
                    <div className={"col-area"}>
                      {course.id && (
                        <span>
                          <a
                            className={`${course.id ? "button" : "button button_disabled"}`}
                            onClick={handlePreview}
                            disabled={isProcessing}
                          >
                            {isProcessing ? '実行中...' : "プレビュー"}
                          </a>
                        </span>
                      )}
                      {course.id && (
                        <span>
                          <a
                            className={`${course.id ? "button" : "button button_disabled"}`}
                            onClick={handleCopyCourse}
                          >
                            コピー
                          </a>
                        </span>
                      )}
                      <span>
                        <button
                          className={"button"}
                          onClick={() => handlePost()}
                          disabled={isProcessing}
                        >
                          {isProcessing ? '送信中...' : "保存"}
                        </button>
                      </span>
                      {course.id && (
                        <Space margin="0 0 1rem 0">
                          <CourseMemoModal
                            courseId={course.id}
                            memo={course.memo}
                            onSuccess={(message) => setSnack({...snack, type: 'success', message: message})}
                            onError={(message) => setSnack({...snack, type: 'error', message: message})}
                          />
                        </Space>
                      )}
                      <span>
                        <a
                          className={"button button_cancel"}
                          datamethod={"get"}
                          href={"/tech/courses"}
                        >
                          破棄
                        </a>
                      </span>
                      {course.id && (
                        <span>
                          <a
                            className={`${protect_schedule ? "button button_disabled" : "button button_cancel"}`}
                            onClick={handleDeleteCourse}
                          >
                            削除
                          </a>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="course_form_margin"/>
    </>
  )
}

export default CourseForm;
