import React, {useState, useEffect, useMemo} from 'react';
import Select from '../Select';
import Axios from '../Axios';
import Alert from "../Alert";

const QuestionnaireResultIndex = ({questionnaireTypeOptions}) => {
  const [message, setMessage] = useState('');
  const [courses, setCourses] = useState([]);
  const [courseLessons, setCourseLessons] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [selectedQuestionnaireTypeId, setSelectedQuestionnaireTypeId] = useState('');
  const [selectedCourseId, setSelectedCourseId] = useState('');
  const [selectedLessonId, setSelectedLessonId] = useState('');
  const [selectedMeetingId, setSelectedMeetingId] = useState('');

  const isTypeCourse = (questionnaireTypeId) => questionnaireTypeId === 11;
  const isTypeLesson = (questionnaireTypeId) => questionnaireTypeId === 12;
  const isTypeMeeting = (questionnaireTypeId) => questionnaireTypeId === 13;

  const courseOptions = useMemo(() => {
    return courses.map((course) => ({ label: course.name, value: course.id }))
  }, [courses]);

  const selectedCourse = useMemo(() => {
    return courses.find((course) => {
      return course.id === selectedCourseId;
    });
  }, [selectedCourseId]);

  const lessonOptions = useMemo(() => {
    return courseLessons.map((course_lesson) => {
      return { label: course_lesson.lesson.name, value: course_lesson.lesson.id };
    });
  }, [courseLessons]);

  const selectedCourseLesson = useMemo(() => {
    return courseLessons.find((cl) => {
      return cl.lesson.id === selectedLessonId;
    });
  }, [selectedCourseId, selectedLessonId]);

  const selectedLesson = () => {
    return !selectedCourseLesson ? undefined : selectedCourseLesson.lesson
  }

  const meetingOptions = useMemo(() => {
    return meetings.map((meeting) => {
      return { label: meeting.name, value: meeting.id }
    });
  }, [meetings]);

  const selectedMeeting = useMemo(() => {
    return meetings.find((meeting) => {
      return meeting.id === selectedMeetingId;
    });
  }, [selectedMeetingId]);

  const targetQuestionnaire = useMemo(() => {
    if(isTypeCourse(selectedQuestionnaireTypeId)) {
      if(!selectedCourse) return;
      return selectedCourse.course_template.questionnaire;
    } else if (isTypeLesson(selectedQuestionnaireTypeId)) {
      if(!selectedLesson()) return;
      return selectedLesson().questionnaire;
    } else if (isTypeMeeting(selectedQuestionnaireTypeId)) {
      if(!selectedMeeting) return;
      return selectedMeeting.questionnaire;
    }
  }, [selectedQuestionnaireTypeId, selectedCourseId, selectedLessonId, selectedMeetingId]);

  const handleQuestionnaireType = (selected) => {
    if(selected && selected.value === selectedQuestionnaireTypeId) return;
    setSelectedQuestionnaireTypeId(selected ? selected.value : '');
    setSelectedCourseId('');
    setSelectedLessonId('');
    setSelectedMeetingId('');
    setCourses([]);
    setCourseLessons([]);
    setMeetings([]);
    setQuestions([]);
    if(!selected) return;

    if(isTypeCourse(selected.value)) {
      Axios.get(`/tech/courses/courses_has_questionnaire`)
        .then(({data}) => {
          setCourses(data);
        }).catch(err => {
          setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    } else if (isTypeLesson(selected.value)) {
      Axios.get(`/tech/courses/courses_has_lesson_questionnaire`)
        .then(({data}) => {
          setCourses(data);
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });

    } else if (isTypeMeeting(selected.value)) {
      Axios.get(`/tech/meetings/meetings_has_questionnaire`)
        .then(({data}) => {
          setMeetings(data);
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }
  }

  const handleCourse = (selected) => {
    if(selected && selected.value === selectedCourseId) return;
    setSelectedLessonId('');
    setCourseLessons([]);
    setMeetings([]);
    setQuestions([]);
    if(!selected) {
      setSelectedCourseId('');
      return;
    }

    if(isTypeCourse(selectedQuestionnaireTypeId)) {
      Axios.get(`/tech/questionnaire_results/course_questionnaire_questions?course_id=${selected.value}`)
        .then(({data}) => {
          setSelectedCourseId(selected.value)
          setQuestions(data);
        }).catch(err => {
          setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    } else if (isTypeLesson(selectedQuestionnaireTypeId)) {
      Axios.get(`/tech/courses/${selected.value}/lessons_has_questionnaire`)
        .then(({data}) => {
          setSelectedCourseId(selected.value)
          setCourseLessons(data);
        });
    }
  }

  const handleLesson = (selected) => {
    if(selected && selected.value === selectedLessonId) return;
    setQuestions([]);
    if(!selected) {
      setSelectedLessonId('');
      return;
    }

    Axios.get(`/tech/questionnaire_results/lesson_questionnaire_questions?course_id=${selectedCourseId}&lesson_id=${selected.value}`)
      .then(({data}) => {
        setSelectedLessonId(selected.value);
        setQuestions(data);
      }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleMeeting= (selected) => {
    if(selected && selected.value === selectedMeetingId) return;
    setSelectedCourseId('');
    setSelectedLessonId('');
    setCourses([]);
    setCourseLessons([]);
    if(!selected) {
      setSelectedMeetingId('');
      return;
    }
    setSelectedMeetingId(selected.value);
    const meeting = meetings.find((meeting) => {
      return meeting.id === selected.value;
    })
    setQuestions(meeting.questionnaire.questions);
  }

  const countQuestionnaireAnswers = () => {
    if(isTypeCourse(selectedQuestionnaireTypeId)) {
      return selectedCourse.questionnaire_answers.length;
    } else if(isTypeLesson(selectedQuestionnaireTypeId)) {
      return selectedCourseLesson.questionnaire_answers.length;
    } else if(isTypeMeeting(selectedQuestionnaireTypeId)) {
      return selectedMeeting.questionnaire_answer_id ? 1 : 0;
    }
  }

  const removeMessage = () => {
    setMessage('');
  }

  const SelectFormCourseQuestionnaire = () => {
    return (
      <div className="control-area_section_article">
        <div className="control-area_section_article_title">
          <label>コース</label>
        </div>
        <Select
          id="course"
          options={courseOptions}
          name="course"
          value={selectedCourseId}
          onChange={handleCourse}
        />
      </div>
    )
  }

  const SelectedFormLessonQuestionnaire = () => {
    return (
      <>
        <div className="control-area_section_article">
          <div className="control-area_section_article_title">
            <label>コース</label>
          </div>
          <Select
            id="course"
            options={courseOptions}
            name="course"
            value={selectedCourseId}
            onChange={handleCourse}
          />
        </div>
        <div className="control-area_section_article">
          <div className="control-area_section_article_title">
            <label>授業</label>
          </div>
          <Select
            id="lesson"
            options={lessonOptions}
            name="lesson"
            value={selectedLessonId}
            onChange={handleLesson}
          />
        </div>
      </>
    )
  }

  const SelectedForMeetingQuestionnaire = () => {
    return (
      <div className="control-area_section_article">
        <div className="control-area_section_article_title">
          <label>セッション</label>
        </div>
        <Select
          id="meeting"
          options={meetingOptions}
          name="meeting"
          value={selectedMeetingId}
          onChange={handleMeeting}
        />
      </div>
    )
  }

  const SelectForm = () => {
    if(isTypeCourse(selectedQuestionnaireTypeId)) {
      return (<SelectFormCourseQuestionnaire />)
    } else if(isTypeLesson(selectedQuestionnaireTypeId)) {
      return (<SelectedFormLessonQuestionnaire />)
    } else if(isTypeMeeting(selectedQuestionnaireTypeId)) {
      return (<SelectedForMeetingQuestionnaire />)
    }
    return <></>
  }

  return (
    <React.StrictMode>
    <>
      {message && (
        <Alert
          message={message}
          onClose={() => removeMessage(message)}
          timeout={3000}
        />
      )}
      <div className="section-area">
        <div className="card">
          <div className="control-area">
            <div className="control-area_section control-area_section_stretch_wrap">
              <div className="control-area_section_article control-area_section_article_stretch">
                <div className="control-area control-area_stretch">
                  <div className="control-area_section control-area_section_stretch">
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>アンケートタイプ</label>
                      </div>
                      <Select
                        id="questionnaire_type"
                        options={questionnaireTypeOptions}
                        name="questionnaire_type"
                        value={selectedQuestionnaireTypeId}
                        onChange={handleQuestionnaireType}
                      />
                    </div>
                    <SelectForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!targetQuestionnaire && (
        <div className="section-area">
          <table className="table">
            <thead>
            </thead>
            <tbody className="ta_center">
            <tr>
              <td>対象が0件です</td>
            </tr>
            </tbody>
          </table>
        </div>
      )}
      {targetQuestionnaire && (
        <div className="card">
          <div className="section-area">
            <h2>アンケート</h2>
            <table className="table">
              <thead>
              <tr>
                <th>名前</th>
                <th>タイプ</th>
                <th>回答人数</th>
              </tr>
              </thead>
              <tbody className="ta_center">
                <tr>
                  <td className='va_middle'>{targetQuestionnaire.name}</td>
                  <td className='va_middle'>
                    {(() => {
                        return questionnaireTypeOptions.find((type) => type.value === selectedQuestionnaireTypeId).label
                      })()}
                  </td>
                  <td className='va_middle'>{`${countQuestionnaireAnswers()}人`}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="section-area">
            <h2>質問一覧</h2>
            { !questions || questions.length == 0 &&
              <table className="table">
                <thead>
                </thead>
                <tbody className="ta_center">
                <tr>
                  <td>質問が登録されていません</td>
                </tr>
                </tbody>
              </table>
            }
            { questions && questions.length > 0 &&
              <table className="table">
                <thead>
                <tr>
                  <th>No.</th>
                  <th>質問</th>
                  <th>回答形式</th>
                  <th>回答状況</th>
                </tr>
                </thead>
                <tbody className="ta_center">
                {questions.map((question) => {
                  return (
                    <tr key={question.id}>
                      <td className='va_middle'>{question.number}</td>
                      <td className='va_middle'>{question.title}</td>
                      <td className='va_middle'>{question.question_form_type_enum.label}</td>
                      <td className='va_middle'>
                        {(question.question_form_type_enum_id === 14) ?
                          <div className="button-area">
                            <a className="button row-area row-area_small" href={`/tech/questionnaire_results/text_answer_list?course_id=${selectedCourseId}&question_id=${question.id}&lesson_id=${selectedLessonId}&meeting_id=${selectedMeetingId}`}>
                              回答一覧
                            </a>
                          </div>
                          :
                          question.count_target_answer_choices.map((str, index) => {
                            return (<div key={index}>{str}</div>)
                          })
                        }
                      </td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            }
          </div>
        </div>
      )}
    </>
    </React.StrictMode>
  )
}

export default QuestionnaireResultIndex;
