import React from 'react';
import { formatDateTimeSlash } from '../../../../shared/date';

function LessonStartDate({ isExist, startDateStr }) {
  if (isExist) {
    return <span>参加日時：{formatDateTimeSlash(new Date(Date.parse(startDateStr)))}</span>;
  } else {
    return <span>参加日時：未受講</span>;
  }
}

export default LessonStartDate;
