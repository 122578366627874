import React from 'react';
import axios from 'axios';

const AttendanceConditionFacilitySearchForm = (props) => {

  const handleSearch = (e) => {
    console.log('handle facility search');
    const name = e.target.value;
    const facility = props.facilities.find((v) => v.label === name);

    if (facility == undefined) {
      return;
    }

    let condition = props.condition;

    condition.target = 'facility';
    condition.id = facility.value;

    props.getAttendanceConditions(condition);
  }

  const facilityList = () => {
    return (
      <datalist id="facility_list">
        {props.facilities.map(value => (
          <option value={value.label} />
        ))}
      </datalist>
    )
  }

  return (
    <div className="control-area_section_article">
      <div className="form-text">
        <input id="facility" placeholder="施設名" autocomplete="on" list="facility_list" onChange={e => handleSearch(e)} />
        {facilityList()}
      </div>
    </div>
  )
}

export default AttendanceConditionFacilitySearchForm;
