import React from 'react'
import {formatDatePeriod} from '../../../../shared/date';

function CourseDatePeriod({course}) {
  return (
    <div className="row-area row-area_small">
      <span className="material-icons c_gray">date_range</span>
      <span className="fw_bold">
        {formatDatePeriod(
          new Date(Date.parse(course.first_lesson_date)),
          new Date(Date.parse(course.last_lesson_date)),
        )}
      </span>
    </div>
  )
}

export default CourseDatePeriod