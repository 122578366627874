import React from 'react'
import SortIcon from '../../../SortIcon'
import FlexBox from '../../../atoms/FlexBox'

function CourseSortIcon({url}) {  
  let urlObj = new URL(url);
  let params = new URLSearchParams(urlObj.search.slice(1));
  const orderValue = params.get('order_by');
  
  const onClick = (value) => {
    params.set('order_by', value);
    location.href = `${urlObj.pathname}?${params.toString()}`
  }

  return (
    <FlexBox content="end">
      <SortIcon
        menuItems={[
          {
            attributes: {onClick : () => onClick('updated_at_desc'), selected: orderValue === 'updated_at_desc'},
            item : "更新日時が新しい順"
          },
          {
            attributes: {onClick : () => onClick('updated_at_asc'), selected: orderValue === 'updated_at_asc'},
            item : "更新日時が古い順"
          },
          {
            attributes: {onClick : () => onClick('first_lesson_date_desc'), selected: orderValue === 'first_lesson_date_desc'},
            item : "開始日時が新しい順"
          },
          {
            attributes: {onClick : () => onClick('first_lesson_date_asc'), selected: orderValue === 'first_lesson_date_asc'},
            item : "開始日時が古い順"
          }
        ]}
      />
    </FlexBox>
  )
}

export default CourseSortIcon