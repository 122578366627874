import React, {useState} from 'react';
import Axios from "../Axios";
import Alert from "../Alert";
import {escape} from 'lodash';
import {AccountImage} from "../atoms/DummyAccountImage";
import sanitizeHtml from 'sanitize-html';

interface Accounts {
  accounts: Array<Account>
}

class Accounts {
  constructor(accounts: Array<Account>) {
    this.accounts = accounts
  }
}

interface RelationshipEnum {
  label: string
}

interface Classroom {
  name: string
}

interface Account {
  id: bigint
  name: string
  email: string
  tel: string
  account_relationship_enums: Array<RelationshipEnum>
  classrooms: Array<Classroom>
}

interface Error {
  text: string
  type: string
}

const AccountIndex = (accounts: Accounts) => {
  const [message, setMessage] = useState<Error>({text: null, type: null});
  const [searchName, setSearchName] = useState<string>("");
  const [searchRelationship, setSearchRelationship] = useState<bigint>(BigInt(1));
  const [searchAccounts, setSearchAccounts] = useState<Accounts>(accounts);

  const handleDelete = (id: bigint) => {
    Axios.delete(`/memori/sub_accounts/${id}`)
      .then(() => {
        setMessage({text: `削除しました。`, type: 'notice'});
        window.location.reload();
      }).catch(() => {
      setMessage({text: `システムエラーが発生しました。`, type: 'alert'});
      window.location.reload();
    });
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  }

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
    setSearchRelationship(BigInt(event.target.value));
  }

  const handleSearch = () => {
    const nameParam = searchName ? `q%5Bname_cont%5D=${searchName}` : `q%5Bname_cont%5D=`;
    const relationshipParam = searchRelationship ? `account_relationship_enum_id=${searchRelationship}` : 'account_relationship_enum_id=';
    Axios.get(`/memori/sub_accounts.json?${relationshipParam}&${nameParam}`)
      .then((data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setSearchAccounts(new Accounts(data.data));
      }).catch(() => {
      return;
    });
  };

  return (
    <React.StrictMode>
      {message.text != null && (
        <Alert
          message={message}
          onClose={() => setMessage({text: null, type: null})}
          timeout={3000}
        />
      )}
      <div className="main-side-area main-side-area_right-side-bar">
        <div className="main-side-area_main">
          <div className="section-area">
            <div className="card">
              <div className="heading-area">
                <div className="title-area">
                  <h1>従業員管理</h1>
                </div>
                <div className="control-area">
                  <div className="control-area_section">
                    <div className="control-area_section_article">
                      <a className="button" href="/memori/sub_accounts/new">
                        新規登録
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card_in-card card_section">
                {searchAccounts.accounts && searchAccounts.accounts.length > 0 && searchAccounts.accounts.map((account) => {
                  return (
                    <>
                      <div className="card">
                        <div
                          className="card_content row-area row-area_xlarge row-area_jc-space-between row-area_ai-start">
                          <div className="col-area">
                            <div className="label-text">{account.account_relationship_enums[0].label}</div>
                            <div className="row-area">
                              <span className="thumbnail-area thumbnail-area_circle">
                                <AccountImage accountName={account.name}/>
                              </span>
                              <span className="fw_bold">{account.name}</span>
                            </div>
                            <div className="row-area row-area_xlarge">
                              <div>
                                <a className="row-area row-area_small link-icon-and-text"
                                   href={`mailto:${escape(account.email)}`}>
                                  <span className="material-icons">email</span>
                                  <span
                                    className="link-icon-and-text_text">{account.email}</span>
                                </a>
                              </div>
                              <div>
                                <a className="row-area row-area_small link-icon-and-text"
                                   href={`tel:${escape(account.tel)}`}>
                                  <span className="material-icons">call</span>
                                  <span
                                    className="link-icon-and-text_text">{account.tel}</span>
                                </a>
                              </div>
                            </div>
                            <div className="row-area row-area_xlarge">
                              <span className={"badge badge_status badge_border_none badge_status_active"}>担当クラス</span>
                              {account.classrooms.length == 0 &&
                                <span>なし</span>
                              }
                              {account.classrooms.map((classroom, index) => (
                                <span key={index}>{classroom.name}</span>
                              ))}
                            </div>
                          </div>
                          <div className="col-area">
                            <div>
                              <a className="row-area row-area_small link-icon-and-text fs_s"
                                 href={sanitizeHtml(`/memori/sub_accounts/${account.id}/edit`)}>
                                <span className="material-icons">zoom_in</span>
                                <span
                                  className="link-icon-and-text_text">従業員の情報</span>
                              </a>
                            </div>
                            {account.account_relationship_enums[0].label != 'Admin' && (
                              <div>
                                <button onClick={() => handleDelete(account.id)}
                                        className="row-area row-area_small link-icon-and-text fs_s c_gray">
                                  <span className="material-icons">delete</span>
                                  <span
                                    className="link-icon-and-text_text">従業員を削除</span>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="main-side-area_side">
          <div className="section-area">
            <div className="card">
              <div className="card_content">
                <div className="heading-area heading-area_nocard">
                  <div className="title-area">
                    <h1>検索</h1>
                  </div>
                </div>
                <div className="card_content_section">
                  <div className="card_content_section_article">
                    <div className="col-area">
                      <span className="form-area">
                        <label className="row-area row-area_small">
                          <span>権限</span>
                        </label>
                      </span>
                      <span className="form-select">
                        <select onChange={(e) => handleSelect(e)}>
                          <option value={0}>全て</option>
                          <option value={1}>Admin</option>
                          <option value={2}>管理者</option>
                          <option value={3}>一般</option>
                        </select>
                      </span>
                    </div>
                  </div>
                  <div className="card_content_section_article">
                    <div className="col-area">
                      <span className="form-area">
                        <label className="row-area row-area_small">
                          <span>名前</span>
                        </label>
                      </span>
                      <span className="form-text">
                        <input onChange={handleInput} placeholder={"例): 田中 太郎"}/>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card_content_section">
                  <div className="card_content_section_article">
                      <span className="button" onClick={handleSearch}>
                        入力した条件で検索
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  )
}

export default AccountIndex;
