import React, {useState} from 'react'
import FlexBox from '../../../atoms/FlexBox';
import Card from '../../../atoms/Card';
import MathJaxTextView from '../../../MathJaxTextView';
import StretchTextField from '../../../StretchTextField';

function FormTextField({ 
  defaultValue,
  keys,
  errorMessage,
}) {
  const [inputText, setInputText] = useState(defaultValue)
  const [height, setHeight] = useState("10rem");

  const onInputChange = (value, height) => {
    setInputText(value)
    setHeight(height)
  }

  return (
    <>
      <input
        type="hidden"
        name={`${keys[0]}[${keys[1]}]`}
        id={`${keys[0]}_${keys[1]}`}
        value={inputText}
      />
      <FlexBox>
        <Card
          width="50%"
          minHeight="10rem"
          border="none"
          padding="0"
        >
          <StretchTextField
            deffaultValue={defaultValue}
            deffaultHeight={"10rem"}
            placeholder="入力してください"
            onChange={(v, h) => onInputChange(v, h)}
          />
        </Card>
        <Card
          width="50%"
          minHeight="10rem"
          height={height}
          border="3px solid #D7D7D7"
        >
          <MathJaxTextView target={inputText}/>
        </Card>
      </FlexBox>
      <div className="w-100"></div>
      {errorMessage && <span className={'error_message'}>{errorMessage}</span>}
    </>
  )
}

export default FormTextField