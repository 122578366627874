import styled from "styled-components"

const DraggableArea = styled.div`
  background: #a8cff1;
  width: 100%;
  height: 3rem;
  border-bottom: 1px solid rgba(155, 151, 151, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`
export default DraggableArea