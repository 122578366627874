import React from 'react'
import Space from '../../shared/Space';

function SelfApplicationForm({tech_student, onChange, validationMessage}) {
  return (
    <>
      <h2 className="tech_user_course_show_subtitle">参加者</h2>
      <div>
        <Space margin="2rem 0">
          <div className="flex">
            <div className="w-50">
              <span className="form-area">
                <label className="row-area row-area_small">
                  <span>お名前</span>
                  <span className="badge_status badge_status_important">必須</span>
                </label>
              </span>
              <span className="form-text">
                <span className="row-area row-area_liquid">
                  <span className="row-area_liquid_noconnect">
                    <input type="text" name="family_name" value={tech_student.family_name} onChange={onChange}/>
                    {validationMessage?.account?.family_name && <div className="error-message">{`お名前（姓）${validationMessage?.account?.family_name[0]}`}</div>}
                  </span>
                  <span className="row-area_liquid_noconnect">
                    <input type="text" name="given_name" value={tech_student.given_name} onChange={onChange}/>
                    {validationMessage?.account?.given_name && <div className="error-message">{`お名前（名）${validationMessage?.account?.given_name[0]}`}</div>}
                  </span>
                </span>
              </span>
            </div>
            <div className="w-50">
              <Space margin="0 0 0 2rem">
                <span className="form-area">
                  <label className="row-area row-area_small">
                    <span>お名前（ローマ字）</span>
                    <span className="badge_status badge_status_important">必須</span>
                  </label>
                </span>
                <span className="form-text">
                  <span className="row-area row-area_liquid">
                    <span className="row-area_liquid_noconnect">
                      <input type="text" name="family_name_romaji" value={tech_student.family_name_romaji} onChange={onChange}/>
                      {validationMessage?.account?.family_name_romaji && <div className="error-message">{`お名前（姓）ローマ字${validationMessage?.account?.family_name_romaji[0]}`}</div>}
                    </span>
                    <span className="row-area_liquid_noconnect">
                      <input type="text" name="given_name_romaji" value={tech_student.given_name_romaji} onChange={onChange}/>
                      {validationMessage?.account?.given_name_romaji && <div className="error-message">{`お名前（名）ローマ字${validationMessage?.account?.given_name_romaji[0]}`}</div>}
                    </span>
                  </span>
                </span>
              </Space>
            </div>
          </div>
        </Space>
        <Space margin="2rem 0">
          <div className="flex">
            <div className="w-50">
              <span className="form-area">
                <label className="row-area row-area_small">
                  <span>性別</span>
                  <span className="badge_status badge_status_important">必須</span>
                </label>
              </span>
              <span className="form-radio">
                <span>
                  <input type="radio" name="gender_enum_id" value={1} checked={String(tech_student.gender_enum_id) === "1"} onChange={onChange}/>
                  <label>男性</label>
                </span>
                <span>
                  <input type="radio" name="gender_enum_id" value={2} checked={String(tech_student.gender_enum_id) === "2"} onChange={onChange}/>
                  <label>女性</label>
                </span>
                <span>
                  <input type="radio" name="gender_enum_id" value={3} checked={String(tech_student.gender_enum_id) === "3"} onChange={onChange}/>
                  <label>回答しない</label>
                </span>
              </span>
              {validationMessage?.student?.gender_enum_id && <div className="error-message">{`性別${validationMessage?.student?.gender_enum_id[0]}`}</div>}
            </div>
            <div className="w-50">
              <Space margin="0 0 0 2rem">
                <span className="form-area">
                  <label className="row-area row-area_small">
                    <span>年齢</span>
                    <span className="badge_status badge_status_important">必須</span>
                  </label>
                </span>
                <span className="form-text">
                  <span className="row-area row-area_liquid">
                    <span className="row-area_liquid_noconnect">
                      <input type="text" name="age" value={tech_student.age} onChange={onChange}/>
                      {validationMessage?.student?.age && <div className="error-message">{`年齢${validationMessage?.student?.age[0]}`}</div>}
                    </span>
                  </span>
                </span>
              </Space>
            </div>
          </div>
        </Space>
        <Space margin="2rem 0">
          <div className="flex">
            <div className="w-50">
              <span className="form-area">
                <label className="row-area row-area_small">
                  <span>メールアドレス</span>
                  <span className="badge_status badge_status_important">必須</span>
                </label>
              </span>
              <span className="form-text">
                <span className="row-area row-area_liquid">
                  <span className="row-area_liquid_noconnect">
                    <input type="text" name="email" value={tech_student.email} onChange={onChange}></input>
                    {validationMessage?.account?.email && <div className="error-message">{`メールアドレス${validationMessage?.account?.email[0]}`}</div>}
                  </span>
                </span>
              </span>
            </div>
            <div className="w-50">
              <Space margin="0 0 0 2rem">
                <span className="form-area">
                  <label className="row-area row-area_small">
                    <span>会員種別</span>
                    <span className="badge_status badge_status_important">必須</span>
                  </label>
                </span>
                <span className="form-radio">
                  <span>
                    <input 
                      type="radio"
                      name="tech_student_classification_enum_id"
                      value="18"
                      checked={String(tech_student.tech_student_classification_enum_id) === "18"}
                      onChange={onChange}
                    />
                    <label>社会人</label>
                  </span>
                  <span>
                    <input 
                      type="radio"
                      name="tech_student_classification_enum_id"
                      value="17"
                      checked={String(tech_student.tech_student_classification_enum_id) === "17"}
                      onChange={onChange}
                    />
                    <label>学生</label>
                  </span>
                </span>
                {validationMessage?.student?.tech_student_classification_enum_id && <div className="error-message">{`会員種別${validationMessage.student.tech_student_classification_enum_id[0]}`}</div>}
              </Space>
            </div>
          </div>
        </Space>
        <Space margin="2rem 0">
          <div className="flex">
            <div className="w-50">
              <span className="form-area">
                <label className="row-area row-area_small">
                  <span>会社名もしくは学校名</span>
                  <span className="badge_status badge_status_important">必須</span>
                </label>
              </span>
              <span className="form-text">
                <span className="row-area row-area_liquid">
                  <span className="row-area_liquid_noconnect">
                    <input type="text" name="organization_name" value={tech_student.organization_name} onChange={onChange}></input>
                  </span>
                </span>
                {validationMessage?.corporation?.name && <div className="error-message">{`会社名もしくは学校名${validationMessage.corporation.name[0]}`}</div>}
              </span>
            </div>
            <div className="w-50">
              <Space margin="0 0 0 2rem">
                <span className="form-area">
                  <label className="row-area row-area_small">
                    <span>会社名もしくは学校名（カナ）</span>
                    <span className="badge_status badge_status_important">必須</span>
                  </label>
                </span>
                <span className="form-text">
                  <span className="row-area row-area_liquid">
                    <span className="row-area_liquid_noconnect">
                      <input type="text" name="organization_name_kana" value={tech_student.organization_name_kana} onChange={onChange}></input>
                    </span>
                  </span>
                  {validationMessage?.corporation?.name_kana && <div className="error-message">{`会社名もしくは学校名（カナ）${validationMessage.corporation.name_kana[0]}`}</div>}
                </span>
              </Space>
            </div>
          </div>
        </Space>
        <Space margin="2rem 0">
          <div className="flex">
            <div className="w-50">
              <span className="form-area">
                <label className="row-area row-area_small">
                  <span>電話番号</span>
                  <span className="badge_status badge_status_important">必須</span>
                </label>
              </span>
              <span className="form-text">
                <span className="row-area row-area_liquid">
                  <span>
                    <input type="text" name="first_tel" value={tech_student.first_tel} onChange={onChange}></input>
                  </span>
                  <span className="row-area_liquid_connect">
                    <span>-</span>
                  </span>
                  <span>
                    <input type="text" name="second_tel" value={tech_student.second_tel} onChange={onChange}></input>
                  </span>
                  <span className="row-area_liquid_connect">
                    <span>-</span>
                  </span>
                  <span>
                    <input type="text" name="third_tel" value={tech_student.third_tel} onChange={onChange}></input>
                  </span>
                </span>
                {validationMessage?.account?.tel_obj && <div className="error-message">{`${validationMessage.account.tel_obj[0]}`}</div>}
              </span>
            </div>
            <div className="w-50">
              <Space margin="0 0 0 2rem">
                <span className="form-area">
                  <label className="row-area row-area_small">
                    <span>メール等の配信希望</span>
                    <span className="badge_status badge_status_important">必須</span>
                  </label>
                </span>
                <span className="form-radio">
                  <span>
                    <input 
                      type="radio"
                      id="is_send_mail_magazine_true"
                      name="is_send_mail_magazine"
                      value="true"
                      checked={String(tech_student.is_send_mail_magazine) === "true"}
                      onChange={onChange}
                    />
                    <label for="is_send_mail_magazine_true">希望する</label>
                  </span>
                  <span>
                    <input 
                      type="radio"
                      id="is_send_mail_magazine_false"
                      name="is_send_mail_magazine"
                      value="false"
                      checked={String(tech_student.is_send_mail_magazine) === "false"}
                      onChange={onChange}
                    />
                    <label for="is_send_mail_magazine_false">希望しない</label>
                  </span>
                </span>
                {validationMessage?.student?.tech_student_classification_enum_id && <div className="error-message">{`会員種別${validationMessage.student.tech_student_classification_enum_id[0]}`}</div>}
              </Space>
            </div>
          </div>
        </Space>
      </div>
    </>
  )
}

export default SelfApplicationForm