import React from 'react';
import Card from './Card'
import CardSection from './CardSection'
import CardSectionArticle from './CardSectionArticle'
const IndexCard = ({children, ...props}) => (
  <Card {...props}>
    <CardSection>
      <CardSectionArticle>
        {children}
      </CardSectionArticle>
    </CardSection>
  </Card>
);

export default IndexCard;