import React, {useState} from 'react';
import MonthPicker from './MonthPicker';

const MonthPickerWithPrevNextButton = (props) => {
  const setPrexMonth = () => props.handlePrevMonth(targetDate)
  const setNextMonth = () => props.handleNextMonth(targetDate)
  const [targetDate, setTargetDate] = useState(new Date(props.targetDate));
  if (!targetDate) {
    return
  }
  return (
    <div className="control-area">
      <div className="control-area_section">
        <div className="control-area_section_article">
          <button className="button button_action" onClick={setPrexMonth} >
            <span className="material-icons">chevron_left</span>
            <span>前月</span>
          </button>
        </div>
        <div className="control-area_section_article">
          <MonthPicker onClick={setTargetDate} date={targetDate}/>
        </div>
        <div className="control-area_section_article">
          <button className="button button_action" onClick={setNextMonth}>
            <span>翌月</span>
            <span className="material-icons">chevron_right</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MonthPickerWithPrevNextButton
