import React, {useState} from 'react'
import TextField from '../../../form/TextField';
import IncrementalSelect from '../../../form/IncrementalSelect';
import ManySelect from '../../../form/ManySelect';
import FormModalLink from '../lesson/FormModalLink';
import Space from '../../../atoms/Space';
import { useLessons } from '../hooks';

function Form({lessonGroup, errors}) {
  const [name, setName]= useState(lessonGroup.name || '')
  const [lessonIds, setLessonIds]= useState(lessonGroup.form_default_lesson_ids || [''])
  const onIncrementForm = () => {
    const newIds = lessonIds.concat(['']);
    setLessonIds(newIds)
  }
  const {lessons, isLoading: lessonsLoading, lessonMutate} = useLessons()
  return (
    <>
      <Space margin="1rem 0 2rem 0">
        <h1>授業群作成フォーム</h1>
      </Space>
      <table className="table_form">
        <tbody>
          <TextField
            label="授業群名"
            required
            value={name}
            placeholder="授業群名を入力してください"
            nameKey="tech_lesson_group[name]"
            onChange={(e) => setName(e.target.value)}
            errorMessage={errors?.name ? `名前${errors.name}` : ''}
            type="text"
          />
          <ManySelect
            label="授業"
            required
            options={lessonsLoading ? [] : lessons}
            nameKey="tech_lesson_group[form_lesson_ids][]"
            selectedValues={lessonIds}
            onChange={(e, i) => setLessonIds((prev) => prev.map((p, index) => index === i ? e.target.value : p))}
            incrementForm={onIncrementForm}
            errorMessage={errors?.lessons ? `授業${errors.lessons}` : ''}
          />
          <FormModalLink positionX={-100} positionY={-100}/>
        </tbody>
      </table>
    </>
  )
}

export default Form