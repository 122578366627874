import React from "react";

export const AccountImage = ({accountName}: { accountName: string }) => {
  const accountNameMap = new Map<string, string>([
    ['有村　敦史', 'atushi_arimura'],
    ['髙橋　優子', 'yuko_takahashi'],
    ['山下　薫', 'kaoru_yamashita'],
    ['安藤　愛', 'ai_ando'],
    ['上田　圭一', 'keiiti_ueda'],
    ['天野　真由美', 'mayumi_amano'],
    ['櫻井　絵梨花', 'erika_sakurai'],
    ['杉山　駿', 'shun_sugiyama'],
  ]);
  if (accountNameMap.has(accountName)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return <img
      src={require(`../../../assets/images/test_image/memori/account/${accountNameMap.get(accountName)}.jpeg`)}/>
  } else {
    return <img src="https://www.odd-e.jp/ja/images/img_member_01.jpg"/>;
  }
};