import React from 'react';

const StudentTable = ({students, handleScore, isEmptyRow=false}) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>学籍番号</th>
          <th>名前</th>
          <th>点数</th>
        </tr>
      </thead>
      <tbody className="ta_center">
        {students &&
          students.map((s) => (
            <tr key={s.id}>
              <td className="va_middle">{s.student_number}</td>
              <td className="va_middle">{s.name}</td>
              <td>
                <div className="form-text">
                  <input
                    type="number"
                    placeholder="点数を入力"
                    defaultValue={s.score}
                    onChange={(e) => handleScore(e, s.id)}
                  />
                </div>
              </td>
            </tr>
        ))}
        {isEmptyRow && (
          <tr style={ { lineHeight: "44px"} }>
            <td>-</td>
            <td>-</td>
            <td>-</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default StudentTable;