import React, { Component } from 'react';

// 環境変数からPAY.JPの公開鍵を取得
const dataKey = gon.PAYJP_GL_PUBLIC_KEY || process.env.PAYJP_GL_PUBLIC_KEY;

class PayjpCheckout extends Component {
  constructor(props) {
    super(props);
    // 元のwindow.alertをバックアップ
    this.windowAlertBackUp = window.alert;
    // script要素を作成
    this.script = document.createElement('script');
    this.payjpCheckoutRef = null;
    // script要素にPAY.JPのURLを設定
    this.script.setAttribute('src', 'https://checkout.pay.jp/');
    // script要素にクラス名を設定
    this.script.setAttribute('class', this.props.className);
    // script要素に公開鍵を設定
    this.script.setAttribute('data-key', dataKey);
    // 各種オプションを設定
    this.props.dataPayjp && this.script.setAttribute('data-payjp', this.props.dataPayjp);
    this.props.dataPartial && this.script.setAttribute('data-partial', this.props.dataPartial);
    this.props.dataText && this.script.setAttribute('data-text', this.props.dataText);
    this.props.dataSubmitText && this.script.setAttribute('data-submit-text', this.props.dataSubmitText);
    this.props.dataTokenName && this.script.setAttribute('data-token-name', this.props.dataTokenName);
    this.props.dataPreviousToken && this.script.setAttribute('data-previous-token', this.props.dataPreviousToken);
    this.props.dataLang && this.script.setAttribute('data-lang', this.props.dataLang);
    // トークン作成成功時と失敗時のコールバック関数を設定
    this.script.setAttribute('data-on-created', 'reactPayjpCheckoutOnCreated');
    this.script.setAttribute('data-on-failed', 'reactPayjpCheckoutOnFailed');
    this.props.dataNamePlaceholder && this.script.setAttribute('data-name-placeholder', this.props.dataNamePlaceholder);
  }

  // デフォルトのプロパティを設定
  static defaultProps = {
    className: 'payjp-button',
    dataKey: '',
    onCreatedHandler: () => {},
    onFailedHandler: () => {},
  };

  // コンポーネントがマウントされる前に実行
  componentWillMount() {
    // グローバル関数を設定
    window.reactPayjpCheckoutOnCreated = this.onCreated.bind(this);
    window.reactPayjpCheckoutOnFailed = this.onFailed.bind(this);
    window.reactPayjpCheckoutContext = this;
    // window.alertを無効化
    window.alert = () => {};
  }

  // コンポーネントがマウントされた後に実行
  componentDidMount() {
    // script要素をDOMに追加
    this.payjpCheckoutRef && this.payjpCheckoutRef.appendChild(this.script);
  }

  // コンポーネントがアンマウントされる前に実行
  componentWillUnmount() {
    // script要素をDOMから削除
    this.payjpCheckoutRef.removeChild(this.script);
    // グローバル関数をクリア
    window.reactPayjpCheckoutOnCreated = null;
    window.reactPayjpCheckoutOnFailed = null;
    window.reactPayjpCheckoutContext = null;
    // window.alertを元に戻す
    window.alert = () => {};
    window.PayjpCheckout = null;
  }

  // コンポーネントの更新を防止
  shouldComponentUpdate() {
    return false;
  }

  // トークン作成成功時の処理
  onCreated(response) {
    const payload = { token: response.id };
    window.reactPayjpCheckoutContext.props.onCreatedHandler(payload);
  }

  // トークン作成失敗時の処理
  onFailed(statusCode, errorResponse) {
    const payload = { message: errorResponse.message };
    window.reactPayjpCheckoutContext.props.onFailedHandler(payload);
  }

  // レンダリング
  render() {
    return <div ref={(node) => (this.payjpCheckoutRef = node)}></div>;
  }
}

export default PayjpCheckout;
