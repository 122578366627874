import React, { useState, memo } from "react";
import ModalView from "./ModalView";
const ModalButton = memo(({
  message,
  title,
  body,
  icon,
  link,
  linkMethod,
  confirmMessage,
  cancelMessage,
  params,
  submitButtonStyle,
  confirmButtonStyle,
  handleSubmit,
  children,
  noAutoClose
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [submitButtonProcessing, setSubmitButtonProcessing] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  }
  return (
    <React.StrictMode>
    <>
      {
        submitButtonProcessing ? (
          <span className="button button_conversion button_conversion_disable">送信中...</span>
        ) : (
          submitButtonStyle ? (
            <a
              className={submitButtonStyle}
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {icon && <span className="material-icons">{icon}</span>}
              <span>{message}</span>
            </a>
          ) : (
            <a
              className="button"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {icon && <span className="material-icons">{icon}</span>}
              <span>{message}</span>
            </a>
          )
        )
      }
      {modalVisible && (
        <ModalView
          setModal={() => setModalVisible(false)}
          title={title}
          body={body}
          link={link}
          linkMethod={linkMethod}
          confirmMessage={confirmMessage}
          cancelMessage={cancelMessage}
          confirmButtonStyle={confirmButtonStyle}
          params={params}
          handleSubmit={handleSubmit}
          setSubmitting={setSubmitButtonProcessing}
          noAutoClose={noAutoClose}
        >
          {children}
        </ModalView>
      )}
    </>
    </React.StrictMode>
  );
});

export default ModalButton;
