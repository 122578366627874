import React, {useState, useEffect} from 'react'
import FlexBox from '../atoms/FlexBox'
import Space from '../atoms/Space'
import Card from '../atoms/Card'
import VideoThumbnail from '../VideoThumbnail'
import Axios from '../../lib/axios'

function CourseVideoFrame({document}) {
  const [src, setSrc] = useState('')
  useEffect(() => {
    Axios.get(`/tech/documents/${document.id}/get_download_link`)
      .then(({data}) => {
        setSrc(data.path[0])
      })
  })
  return (
    <Space>
      <h3>{document.name}</h3>
      <FlexBox content="center">
        <Card height= "30%" width="64%" maxHeight="300px" maxWidth="540px" border="none" padding="none">
          <VideoThumbnail src={src} width="100%" height="100%"/>
        </Card>
      </FlexBox>
    </Space>
  )
}

export default CourseVideoFrame