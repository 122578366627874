import React, {useState, useMemo} from 'react'
import FileUploader from '../../../FileUploader'
import TextField from '../../../form/TextField';
import TextArea from '../../../form/TextArea';
import ManySelect from '../../../form/ManySelect';
import {
  useCourseKinds,
  useLessonVideos,
  useLesson,
  useExercises
} from '../hooks/index';
import FormModalLink from './FormModalLink';
import FlexBox from '../../../atoms/FlexBox';
import LoadingIcon from '../../../LoadingIcon';
import Space from '../../../atoms/Space';
import Axios from '../../../../lib/axios';
import Width from '../../../atoms/Width';

function LessonForm({id, onClose, onSuccess}) {
  const {lesson, isLoading: lessonLoading, error: lessonError } = useLesson(id);
  const [postData, setPostData] = useState({video_ids: [], exercise_ids: []})
  const [errors, setErrors]= useState({})
  const [processing, setProcessing]= useState(false)

  const { lessonVideoOptions, isLoading: videoOptionLoading } = useLessonVideos();
  const { exercises, isLoading: exercisesLoading } = useExercises();

  useMemo(() => {
    if(lessonLoading) {
      return;
    }
    setPostData({
      ...lesson,
      video_ids: lesson.videos.map(v => v.id),
      exercise_ids: lesson.exercises.map(v => v.id)
    })
  }, [lessonLoading])

  const handleSubmit = () => {
    if(processing) {
      return;
    }
    setProcessing(true)
    if(Number.isInteger(id)) {
      Axios.put(`/tech/lessons/${id}`, {tech_lesson: postData})
        .then(data => {
          if(onSuccess){
            onSuccess();
          }
          onClose()
        })
        .catch(e => {
          setErrors(e.response.data.errors)
          setProcessing(false)
        })
    }else {
      Axios.post(`/tech/lessons/`, {tech_lesson: postData})
        .then(data => {
          if(onSuccess){
            onSuccess();
          }
          onClose()
        })
        .catch(e => {
          setErrors(e.response.data.errors)
          setProcessing(false)
        })
    }
  }

  const onIncrementVideoForm = () => {
    const newIds = postData.video_ids.concat(['']);
    setPostData({...postData, video_ids: newIds})
  }

  const onIncrementExerciseForm = () => {
    const newIds = postData.exercise_ids.concat(['']);
    setPostData({...postData, exercise_ids: newIds})
  }

  if(lessonLoading || videoOptionLoading || exercisesLoading) {
    return <LoadingIcon/>
  }else {
    return (
      <>
        <Space margin="1rem 0 2rem 0">
          <h1>授業作成フォーム</h1>
        </Space>
        <table className="table_form">
          <tbody>
            <TextField
              label="名前"
              required
              value={postData.name}
              placeholder="名前"
              nameKey="tech_lesson[name]"
              onChange={(e) => setPostData({...postData, name: e.target.value})}
              errorMessage={errors?.name ? `名前${errors.name}` : ''}
              type="text"
            />
            <TextArea
              label="詳細"
              required
              value={postData.detail}
              placeholder={"データサイエンス理解のため、線形代数学についての基礎を学びます。"}
              nameKey={"tech_lesson[detail]"}
              errorMessage={errors?.detail ? `詳細${errors.detail}` : ''}
              onChange={(e) => setPostData({...postData, detail: e.target.value})}
            />
            <ManySelect
              label="コース動画"
              required={false}
              options={lessonVideoOptions}
              nameKey="tech_lesson[video_ids][]"
              selectedValues={postData.video_ids}
              onChange={
                (e, i) => {
                  setPostData(
                    {...postData, video_ids: postData.video_ids.map((p, index) => index === i ? e.target.value : p)}
                  )
                }
              }
              // 
              incrementForm={onIncrementVideoForm}
            />
            <ManySelect
              label="理解度テスト"
              required={false}
              options={exercises}
              nameKey="tech_lesson[exercise_ids][]"
              selectedValues={postData.exercise_ids}
              onChange={
                (e, i) => {
                  setPostData(
                    {...postData, exercise_ids: postData.exercise_ids.map((p, index) => index === i ? e.target.value : p)}
                  )
                }
              }
              // 
              incrementForm={onIncrementExerciseForm}
            />
          </tbody>
        </table>
        <>
          <hr />
          <FlexBox content="center">
            <Width size="70%">
              <FlexBox content="space-between">
                <span
                  className="button button_cancel row-area row-area_small"
                  onClick={() => !processing && onClose()}
                >
                  <span>閉じる</span>
                </span>
                <Space margin="0 2rem">&nbsp;</Space>
                <span
                  className={"button row-area row-area_small"}
                  onClick={() => {
                    setProcessing(true);
                    handleSubmit();
                  }}
                  disabled={processing}
                >
                  <span>
                    {processing ? "送信中..." : "送信する"}
                  </span>
                </span>
              </FlexBox>
            </Width>
          </FlexBox>
        </>
      </>
    )
  }
}

export default LessonForm