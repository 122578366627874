import React, {memo} from 'react';

const CustomButton = memo(({ label, buttonLabel, onClick }) => {
  return (
    <>
      <tr>
        <th>
          <label>
            {label}
          </label>
        </th>
      </tr>
      <tr>
        <td className={"form-button"}>
          <div className="button-area">
            <span onClick={() => onClick()}>
              <a className={"button"}>
                {buttonLabel}
              </a>
            </span>
          </div>
        </td>
      </tr>
    </>
  );
});

export default CustomButton;
