import React from 'react'

function NoticeMessage({children, success, error, alert, gray}) {
  return (
    <div className="card_section">
      <div className="mobile-only_m_20_10_0">
        <div className={`notice ${success && 'notice_success'} ${error && 'notice_error'} ${alert && 'notice_alert'} ${gray && 'notice_disable'}`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default NoticeMessage