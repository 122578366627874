import React, {useState} from 'react'
import MathJaxTextView from '../../../MathJaxTextView'
import { textUrlToLink, convertBreakToBr } from '../../../../shared/text'
function FormRadio({ 
  options,
  selectedValue,
  keys,
}) {
  const [data, setData] = useState(selectedValue)
  return (
    <td className={"form-radio d-block"}>
      {
        options.map((option, index) => (
          <div className="mb-3">
            <span key={`${keys[0]}_${keys[1]}_${option.value}`}>
              <input
                type={"radio"}
                value={option.value}
                checked={data === option.value}
                id={`${keys[0]}_${keys[1]}_${option.value}`}
                name={`${keys[0]}[${keys[1]}]`}
                onChange={(e) => setData(Number(e.target.value))}
              />
              <label htmlFor={`${keys[0]}_${keys[1]}_${option.value}`}>
                <div dangerouslySetInnerHTML={{ __html: convertBreakToBr(option.name) }} />
              </label>
            </span>
          </div>
        ))
      }
    </td>
  )
}

export default FormRadio