import React, {useState} from 'react';
import Select from '../Select';
import Axios from '../Axios';
import Alert from "../Alert";

const ExerciseScoreIndex = ({courses, exerciseTypes}) => {
  const [course, setCourse] = useState('');
  const [courseLessons, setCourseLessons] = useState([]);
  const [courseLesson, setCourseLesson] = useState('');
  const [exerciseType, setExerciseType] = useState('');
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [message, setMessage] = useState();


  const handleCourse = (selected) => {
    setCourseLessons([])
    setQuestions([])
    setAnswers([])
    if(!selected) {
      setCourse('')
      return;
    }
    setCourse(selected.value)
    Axios.get(`/tech/courses/${selected.value}/course_lessons`)
      .then(({data}) => {
        const optionCourseLessons = data.map((course_lesson) => {
          return {label: course_lesson.lesson.name, value: course_lesson.id}
        })
        setCourseLessons(optionCourseLessons);
      }).catch(err => {
      console.log(err)
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleCourseLesson = (selected) => {
    setCourseLesson('')
    setQuestions([])
    setAnswers([])
    setCourseLesson(selected.value)
  }

  const handleExerciseType = (selected) => {
    setQuestions([])
    setAnswers([])
    if(!selected) {
      setExerciseType('')
      return;
    }
    setExerciseType(selected.value)
    Axios.get(`/tech/exercise_question_answers/find_questions_by_query?course_lesson_id=${courseLesson}&exercise_type_id=${selected.value}&exercise_form_type_id=${1}`) // 一旦、記述式固定
      .then(({data}) => {
        const optionQuestions = data.map((question) => {
          return {label: question.title, value: question.id}
        })
        setQuestions(optionQuestions);
      }).catch(err => {
      console.log(err)
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleQuestion = (selected) => {
    setAnswers([]);
    if(!selected) {
      setQuestion('')
      return;
    }
    setQuestion(selected.value)
    Axios.get(`/tech/exercise_question_answers/find_answers_by_query?course_lesson_id=${courseLesson}&question_id=${selected.value}`)
      .then(({data}) => {
        const optionAnswers = data.map((answer) => {
          return {
            id: answer.id,
            studentName: answer.result.account.name,
            answer: answer.text_answer,
            isCorrect: answer.is_correct
          }
        })
        setAnswers(optionAnswers)
      }).catch(err => {
      console.log(err)
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleChangeRadio = (e, id) => {
    const newAnswers = answers.map(a => {
      if (a.id == id) {
        return {...a, isCorrect: e.target.value}
      }
      return a
    })

    setAnswers(newAnswers)
  }

  const handleSave = () => {
    const params = answers.map((a) => {
      return {id: a.id, is_correct: a.isCorrect}
    });
    Axios.post(`/tech/exercise_question_answers/update_text_answer_is_corrects`, {course_lesson_id: courseLesson, question_answers: params})
      .then(({data}) => {
        setMessage(data);
      }).catch(err => {
      console.log(err)
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const removeMessage = () => {
    setMessage('')
  }

  return (
    <React.StrictMode>
    <>
      {message && (
        <Alert
          message={message}
          onClose={() => removeMessage(message)}
          timeout={3000}
        />
      )}
      <div className="section-area">
        <div className="card">
          <div className="control-area">
            <div className="control-area_section control-area_section_stretch_wrap">
              <div className="control-area_section_article control-area_section_article_stretch">
                <div className="control-area control-area_stretch">
                  <div className="control-area_section control-area_section_stretch">
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>コース</label>
                      </div>
                      <Select
                        id="course"
                        options={courses}
                        name="course"
                        value={course}
                        onChange={handleCourse}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>授業</label>
                      </div>
                      <Select
                        id="course_lesson"
                        options={courseLessons}
                        name="course_lesson"
                        value={courseLesson}
                        onChange={handleCourseLesson}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>タイプ</label>
                      </div>
                      <Select
                        id="exercise_type"
                        options={exerciseTypes}
                        name="exercise_type"
                        value={exerciseType}
                        onChange={handleExerciseType}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>問題</label>
                      </div>
                      <Select
                        id="question"
                        options={questions}
                        name="question"
                        value={question}
                        onChange={handleQuestion}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {answers.length > 0 && (
        <div className="card">
          <div className="heading-area">
            <div></div>
            <div className="control-area">
              <div className="section-area">
                <div className="control-area">
                  <div className="control-area_section">
                    <div className="control-area_section_article">
                      <div className="button row-area row-area_small">
                      <span className="button" onClick={handleSave}>
                        結果を保存
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-area">
            <table className="table">
              <thead>
              <tr>
                <th>学生</th>
                <th>回答</th>
                <th>正誤</th>
              </tr>
              </thead>
              <tbody className="ta_center">
              {answers.map((answer) => {
                return (
                  <tr key={answer.id}>
                    <td className="va_middle">{answer.studentName}</td>
                    <td className="va_middle">{answer.answer}</td>
                    <td className="form-radio va_middle">
                      <label>正</label>
                      <input type="radio" value={1} checked={answer.isCorrect == 1}
                             onChange={(e) => handleChangeRadio(e, answer.id)}/>
                      <label>誤</label>
                      <input type="radio" value={0} checked={answer.isCorrect == 0}
                             onChange={(e) => handleChangeRadio(e, answer.id)}/>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
    </React.StrictMode>
  )
}

export default ExerciseScoreIndex;
