import React, {useEffect, useState} from 'react';
import {TocAccountImage} from '../atoms/DummyTocAccountImage';
import Axios from "../Axios";
import Alert from "../Alert";
import {Field, Form, Formik} from "formik";
import {AccountImage} from "../atoms/DummyAccountImage";

interface Chat {
  id: number
  comment: string
  created_at: string
  reporter: string
  reporter_type: string
  is_received: boolean
}

interface Chats {
  data: Array<Chat>
  title: string
  token: string
}

interface Error {
  text: string
  type: string
}

interface ChatValue {
  id: number
  report_category: string
  token: string
}

const Show = (values: Chats) => {
  const [message, setMessage] = useState<Error>({text: null, type: null});
  const [chats, setChats] = useState<Array<Chat>>(values.data);
  useEffect(() => {
    const intervalId = setInterval(() => {
      Axios.get(`/chats/thread/${values.token}.json`)
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setChats(response.data);
        }).catch(() => {
        setMessage({text: `システムエラーが発生しました`, type: 'alert'});
      });
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const defaultValues = {
    id: 0,
    report_category: 'confirmed',
    token: values.token,
  }
  const formValues = () => {
    return defaultValues
  }

  function handleSubmit(chat: ChatValue) {
    Axios.post('/chats', {chat: chat})
      .then(() => {
        setMessage({text: `返信しました。`, type: 'notice'});
        window.location.reload();
      }).catch(err => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
      setMessage({text: `返信できませんでした。${err.response.data.message}`, type: 'alert'});
      window.location.reload();
    });

  }

  return (
    <React.StrictMode>
      <>
        {message.text != null && (
          <Alert
            message={message}
            onClose={() => setMessage({text: null, type: null})}
            timeout={3000}
          />
        )}
        <div className="main-side-area_main">
          <div className="card">
            <div className="heading-area">
              <div className="title-area">
                <h1>{values.title}</h1>
              </div>
            </div>
            <div className="card_in-card card_section">
              {chats && chats.length > 0 && chats.map((chat, i) => {
                return (
                  <div className="card" key={i}>
                    <div
                      className="card_content row-area row-area_xlarge row-area_jc-space-between row-area_ai-start">
                      <div className="col-area">
                        <div className="row-area">
                          <span className="thumbnail-area thumbnail-area_circle">
                            {chat.reporter_type == "Account" &&
                              <AccountImage accountName={chat.reporter}/>
                            }
                            {chat.reporter_type == "TocAccount" &&
                              <TocAccountImage accountName={chat.reporter}/>
                            }
                          </span>
                          <span className="fw_bold">{chat.reporter}</span>
                          <span>{chat.created_at}</span>
                          <span>{chat.comment}</span>
                        </div>
                      </div>
                      {chat.reporter_type == "TocAccount" &&
                        <div className="col-area">
                        <Formik
                          enableReinitializec
                          initialValues={formValues()}
                          validate={null}
                          onSubmit={handleSubmit}
                        >
                          {({values, setFieldValue}) => (
                            <Form>
                              <table className="table_form">
                                <tbody>
                                <tr>
                                  <td style={{ padding: 0 }}>
                                    <Field type={"hidden"} name="id" value={chat.id}/>
                                    <Field as="select" name="report_category"
                                           onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                             setFieldValue('report_category', e.target.value);
                                           }}>
                                      <option value={'confirmed'} key={0}>承知しました</option>
                                    </Field>
                                  </td>
                                  <td style={{ padding: 0 }}>
                                    <input type='submit' value="返信" className="button" onClick={()=> values.id = chat.id}/>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </React.StrictMode>
  )
}

export default Show;
