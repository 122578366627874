import jsQR from "jsqr";
import React from 'react';

const video = document.createElement('video');
const canvasElement = document.getElementById('canvas');
const canvas = canvasElement.getContext('2d', { willReadFrequently: true });
const loading = document.getElementById('loading');
const json = [];
const save_items = [];
let item_list_index = 0; // ブラウザ上の商品のindex
let item_index = 0;      // スキャンした商品のindex
let count = 0;           // 商品の個数
let sub_total = 0;
let total = 0;
let merchandise_id = ""
let name = ""
let tenant = ""
let price = 0
let expiry_date = null;

window.onload = function(){
  let items = localStorage.getItem('items')
  let isProceedPayment = localStorage.getItem('is_proceed_payment')

  let jsonObj = JSON.parse(items)

  generateLoadingModal()

  if(jsonObj !== null && isProceedPayment === 'true') {
    localStorage.setItem('is_proceed_payment', 'false')
    jsonObj.forEach((elem, index) => {
      generateCard(elem)

      count++
      sub_total += elem.price
      total += elem.price;
      document.getElementById('count').textContent = count + "点";
      document.getElementById('sub_total').textContent = "￥" + sub_total.toLocaleString();
      document.getElementById('total').textContent = "￥" + total.toLocaleString();

      save_items.push({
        item_id: item_index,
        id: elem.id,
        name: elem.name,
        tenant: elem.tenant,
        price: elem.price
      });
      item_list_index++;
      item_index++;

      localStorage.setItem("items",JSON.stringify(save_items));

    });
  } else {
    generateNoScanCard()
  }
}

navigator.mediaDevices.getUserMedia({video: {facingMode: 'environment'}})
  .then((stream) => {
    video.srcObject = stream;
    video.setAttribute('playsinline', true);
    video.play();
    requestAnimationFrame(setInterval(tick, 500));
  });


function generateCard(item) {
  let card = document.createElement('div');
  card.setAttribute("class", "card merchandise")
  let row = document.createElement('div');
  row.setAttribute("class", "row-area row-area_xlarge row-area_ai-start row-area_jc-space-between")
  let colx = document.createElement('div');
  colx.setAttribute("class", "col-area col-area_xlarge")
  let merchandiseName = document.createElement('div');
  merchandiseName.setAttribute("class", "merchandise-name")
  merchandiseName.textContent = item.name + ", " + item.tenant;

  var deleteButton = document.createElement('div')
  deleteButton.setAttribute("class", "delete-button")
  deleteButton.setAttribute("id", "item_" + item_index)
  deleteButton.addEventListener("click", {delete_item_id: item_index, handleEvent: delete_merchandise})

  var button = document.createElement('button')
  button.setAttribute("class", "row-area row-area_small c_gray fw_bold")
  var materialIcon = document.createElement('span')
  materialIcon.setAttribute("class", "material-icons")
  materialIcon.textContent = "delete"

  var scanDelete = document.createElement('span')
  scanDelete.setAttribute("class", "link-icon-and-text_text")
  scanDelete.textContent = "商品を削除"

  var col3 = document.createElement('div')
  col3.setAttribute("class", "merchandise-price")
  col3.textContent = "￥" + item.price.toLocaleString()

  // 初回スキャン
  if (item_list_index == 0) {
    // 商品情報が存在しない場合は、「スキャン済み商品はありません」のcardを削除する
    let merchandise = document.getElementsByClassName('card merchandise')[0]
    if (merchandise != undefined) {
      document.getElementsByClassName('card_in-card')[0].removeChild(merchandise);
    }
  }

  document.getElementsByClassName('card_in-card merchandise')[0].appendChild(card);
  document.getElementsByClassName('card merchandise')[item_list_index].appendChild(row);
  document.getElementsByClassName('row-area row-area_xlarge row-area_ai-start row-area_jc-space-between')[item_list_index].appendChild(colx);
  document.getElementsByClassName('col-area')[item_list_index].appendChild(merchandiseName);
  document.getElementsByClassName('col-area col-area_xlarge')[item_list_index].appendChild(deleteButton);
  document.getElementsByClassName('delete-button')[item_list_index].appendChild(button);
  document.getElementsByClassName('row-area row-area_small c_gray fw_bold')[item_list_index].appendChild(materialIcon);
  document.getElementsByClassName('row-area row-area_small c_gray fw_bold')[item_list_index].appendChild(scanDelete);
  document.getElementsByClassName('row-area row-area_xlarge row-area_ai-start row-area_jc-space-between')[item_list_index].appendChild(col3);
}

function generateNoScanCard(){
  localStorage.removeItem('items');

  // 商品の枠
  var card = document.createElement('div')
  card.setAttribute("class", "card merchandise")
  // 商品情報の内容が表示される部分
  var row = document.createElement('div')
  row.setAttribute("class", "row-area row-area_xlarge row-area_ai-start row-area_jc-space-between")
  // 商品情報（左部分）
  var colx = document.createElement('div')
  colx.setAttribute("class", "col-area col-area_xlarge")
  var merchandiseName = document.createElement('div')
  merchandiseName.setAttribute("class", "merchandise-name")
  merchandiseName.innerText = "スキャン済み商品はありません"

  document.getElementsByClassName('card_in-card merchandise')[0].appendChild(card);
  document.getElementsByClassName('card merchandise')[0].appendChild(row);
  document.getElementsByClassName('row-area row-area_xlarge row-area_ai-start row-area_jc-space-between')[0].appendChild(colx);
  document.getElementsByClassName('col-area')[0].appendChild(merchandiseName);
}

function tick() {
  loading.innerText = 'ロード中...';

  let code;
  if (video.readyState === video.HAVE_ENOUGH_DATA) {
    loading.hidden = true;
    canvasElement.hidden = true;
    canvasElement.height = video.videoHeight;
    canvasElement.width = video.videoWidth;

    canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
    let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);

    // QRコード読み取り時
    code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert',
        });

    if (code) {
      var today = new Date()
      var expiry = null

      let loadingModal = document.getElementById('loading_modal')
      loadingModal.style.display = 'block'

      // todo: parseが失敗すると例外が発生することがあるため、例外処理を追加する必要あり。
      json[item_list_index] = JSON.parse(`${code.data}`);

      merchandise_id = json[item_list_index].id
      name = json[item_list_index].name
      tenant = json[item_list_index].tenant
      price = json[item_list_index].price

      if (json[item_list_index].expiry_date != undefined) {
        expiry_date = json[item_list_index].expiry_date
        expiry = new Date(expiry_date)
      } else {
        expiry_date = null
      }

      if (expiry == null || today <= expiry) {
        generateCard(json[item_list_index])

        count++;
        sub_total += price;
        total += price;
        document.getElementById('count').textContent = count + "点";
        document.getElementById('sub_total').textContent = "￥" + sub_total.toLocaleString();
        document.getElementById('total').textContent = "￥" + total.toLocaleString();

        save_items.push({
          item_id: item_index,
          id: json[item_list_index].id,
          name: json[item_list_index].name,
          tenant: json[item_list_index].tenant,
          price: json[item_list_index].price
        });

        localStorage.setItem("items", JSON.stringify(save_items));

        item_list_index++;
        item_index++;

      } else {
        generateExpiryModal()
        let expiryModal = document.getElementById('expiry_modal')
        expiryModal.style.display = 'block'

      }

      loadingModal.style.display = 'none'
    }
  }
}

function delete_merchandise(e){
  let ids = save_items.map(el => el.item_id);
  let delete_item = save_items.splice(ids.indexOf(this.delete_item_id), 1)
  localStorage.setItem("items",JSON.stringify(save_items));

  count --;
  sub_total -= delete_item[0].price;
  total -= delete_item[0].price;
  item_list_index --;

  document.getElementById('count').textContent = count + "点";
  document.getElementById('sub_total').textContent = "￥" + sub_total.toLocaleString();
  document.getElementById('total').textContent = "￥" + total.toLocaleString();
  let delete_element = document.getElementById('item_' + this.delete_item_id)
  document.getElementsByClassName('card_in-card')[0].removeChild(delete_element.parentElement.parentElement.parentElement);

  if (save_items.length == 0) {
    generateNoScanCard();
  }
}

function generateLoadingModal() {
  let modal = document.createElement('div')
  modal.setAttribute('id', 'loading_modal')
  modal.setAttribute('class', 'loading_modal')
  let modalContent = document.createElement('div')
  modalContent.setAttribute('class', 'loading_modal_content')
  let modalString = document.createElement('div')
  modalString.setAttribute('class', 'loading_modal_document')
  modalString.textContent = 'Loading・・・'

  document.body.appendChild(modal)
  modal.appendChild(modalContent)
  modalContent.appendChild(modalString)
}

function generateExpiryModal() {
  let modal = document.createElement('div')
  modal.setAttribute('id', 'expiry_modal')
  modal.setAttribute('class', 'expiry-modal')

  let modalContent = document.createElement('div')
  modalContent.setAttribute('class', 'expiry-modal-content')

  let modalString = document.createElement('div')
  modalString.setAttribute('class', 'expiry-modal-title')
  modalString.textContent = '賞味期限が切れています。ご確認ください。'

  let modalCloseButtonArea = document.createElement('div')
  modalCloseButtonArea.setAttribute('class', 'expiry-close-button-area')

  let modalCloseButton = document.createElement('a')
  modalCloseButton.setAttribute('id', 'expiry_close_button')
  modalCloseButton.setAttribute('class', 'expiry-close-button')
  modalCloseButton.setAttribute('href', '#')

  let modalCloseIcon = document.createElement('span')
  modalCloseIcon.setAttribute('class', 'material-icons')
  modalCloseIcon.textContent = 'cancel'

  let modalCloseString = document.createElement('span')
  modalCloseString.textContent = '閉じる'

  document.body.appendChild(modal)
  modal.appendChild(modalContent)
  modalContent.appendChild(modalString)
  modalContent.appendChild(modalCloseButtonArea)
  modalCloseButtonArea.appendChild(modalCloseButton)
  modalCloseButton.appendChild(modalCloseIcon)
  modalCloseButton.appendChild(modalCloseString)

  const expiry_modal_close_btn = document.getElementById('expiry_close_button')
  expiry_modal_close_btn.addEventListener('click', function(e) {
    e.preventDefault();
    const modal = document.getElementById('expiry_modal')
    modal.style.display = 'none';
  })

  addEventListener('click', expiryOutsideClose);
  function expiryOutsideClose(e) {
    let modal = document.getElementById('expiry_modal')
    if (e.target == modal) {
      modal.style.display = 'none';
    }
  }

}


export default function QrCoder(){ return <div></div> };