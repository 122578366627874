import React, {useCallback, useState, useEffect} from 'react'
import Axios from '../../../lib/axios';
import LoadingOverlay from '../../LoadingOverlay'

const ImageUpload = ({
                       label,
                       name,
                       deffalt,
                       valid_extensions,
                       hidePreview,
                       placeholder,
                       maxFileSize = 10,
                       description
                     }) => {
  const [file, setFile] = useState(null);
  const [fileId, setFileId] = useState(deffalt);
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const image = require('../../../../assets/images/cover_default_image.png');

  useEffect(() => {
    if ((typeof deffalt !== "undefined") && (deffalt !== null) && (deffalt !== "")) {
      Axios.get("/upload_files/" + deffalt).then(({data}) => {
        setFile(data.files);
        setFileId(data.upload_file_id);
      }).catch((err) => {
        setErrMessage(err.response.data.message);
      });
    }
  }, []);

  const onChangeHandler = useCallback((e) => {
    console.log(e)
    // ファイル選択がキャンセルされた時は undefined
    const file = e.target.files;
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append('files[]', file[i]);
      if (file[i].size > (maxFileSize * 1024 * 1024)) {
        alert(maxFileSize + "MB以下のファイルを選択してください");
        e.target.files.value = '';
        return;
      }
    }

    const uploadUri = '/upload_files';
    setLoading(true);

    Axios.post(uploadUri, formData).then(({data}) => {
      // アップロード成功次の処理
      setFile(data.files);
      setFileId(data.upload_file_id);
      setLoading(false);
    }).catch((err) => {
      setErrMessage(err.response.data.message);
      // アップロード失敗時の処理
      setLoading(false);
    });
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    setFileId("")
    setFile(null)
  }

  const uploadFormDivStyle = {
    border: '3px solid',
    width: '230px',
    padding: '6px',
    position: 'relative',
    marginBlockEnd: '3px',
    textAlign: 'center',
    background: 'black',
    color: 'white',
    borderRadius: '5px',
    marginTop: '-10px'
  }

  const uploadFormStyle = {
    height: '100%',
    left: '0',
    opacity: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
    cursor: 'pointer'
  }

  const coverImage = {
    height: '150px',
    width: '250px',
    marginBlock: '10px',
  }

  if (loading) {
    return (<th><LoadingOverlay/></th>)
  } else {
    return (
      <React.StrictMode>
        <>
          <tr>
            <th>
              <label>
                {label}
              </label>
            </th>
          </tr>
          <tr>
            <td>
              <input type="hidden" name={name} value={fileId}/>
              {!hidePreview && <div className={"row mb-2"}>
                <div className={"col-11"}>
                  <div>
                    { fileId && file && <img src={file[0].link} style={coverImage} alt={"coverImage"}/> }
                    { !fileId && <img src={image} style={coverImage} alt={"coverImage"}/>}
                  </div>
                  <div className={"form-row mb-1"}>
                  </div>
                </div>
              </div>}
              <div id='file_upload_area' style={uploadFormDivStyle}>
                {placeholder || 'ファイルを選択してください'}
                <input type="file" id='file_selection' onChange={onChangeHandler} accept={valid_extensions}
                       style={uploadFormStyle}/>
                {(errMessage !== "") && <span style={{color: "red"}}>{errMessage}</span>}
              </div>
              <div>
                {description}
              </div>
              {file &&
              <div onClick={handleDelete}>
                <span style={{cursor: 'pointer'}}>x削除</span>
              </div>
              }
            </td>
          </tr>
        </>
      </React.StrictMode>
    )
  }
}
export default ImageUpload
