import React, {useState} from 'react'
import ModalButton from '../../../ModalButton'
import Axios from '../../../../lib/axios'
import StretchTextField from '../../../StretchTextField'
function CourseMemoModal({
  courseId,
  memo,
  onSuccess,
  onError,
  from
}) {
  const [viewedMemo, setViewedMemo] = useState(memo)

  const handleSubmit = () => {
    Axios.patch(`/tech/courses/${courseId}/update_memo?from=${from}`, {tech_course: {memo: viewedMemo}})
      .then((res) => {
        if (onSuccess) {
          onSuccess('メモを正常に更新しました');
        } else {
          console.log("onSuccess is not defined")
          window.location.reload();
        }
      })
      .catch(err => {
        console.log(err)
        onError(`[${err.response.status}] ${err.response.statusText}`)
      })
  }

  return (
    <ModalButton
      message="メモの編集"
      title=""
      confirmMessage="保存"
      cancelMessage="キャンセル"
      link={true}
      params={''}
      handleSubmit={handleSubmit}
      key={`course-memo-update-modal-button-${courseId}`}
    >
      <div className="form-text mt-3">
        <StretchTextField
          deffaultValue={viewedMemo}
          deffaultHeight="25rem"
          placeholder="5000文字以内で入力してください"
          onChange={(v, h) => setViewedMemo(v)}
        />
      </div>
    </ModalButton>
  )
}

export default CourseMemoModal