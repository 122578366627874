import React from 'react'
import NoContentCard from '../../../atoms/NoContentCard'

function NoContent({children, ...props}) {
  return (
    <NoContentCard {...props}>
      {children}
    </NoContentCard>
  )
}

export default NoContent