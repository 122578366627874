import React, {useState} from 'react'
import SectionArea from '../../../../atoms/SectionArea'
import Card from '../../../../atoms/Card'
import CardSection from '../../../../atoms/CardSection'
import CardSectionArticle from '../../../../atoms/CardSectionArticle'
import DatePeriodForm from '../../../DatePeriodForm'
import FlexBox from '../../../../atoms/FlexBox'
import Width from '../../../../atoms/Width'
import Space from '../../../../atoms/Space'
import Span from '../../../../atoms/Span'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker';
import DatePickerForm from '../../../../DatePickerForm'
import Axios from '../../../../../lib/axios'
import Snackbar from '../../../../shared/Snackbar'

function Index({course, courseLessons, videoAccessTerms, accountId}) {
  const [accessibleTerms, setAccessibleTerms] = useState(videoAccessTerms.map(t => ({from: new Date(Date.parse(t.from)), to: new Date(Date.parse(t.to))})));
  const [snack, setSnack] = useState({
    type: 'success',
    message: '',
    autoHideDuration: 2000,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
  });
  const onChange = (selected, idx, key) => {
    setAccessibleTerms((prev) => prev.map((p, i) => i === idx ? {...p, [key]: selected} : p))
  }
  
  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({...snack, message: ""})
  };
  
  const onSubmitHandler = () => {
    Axios.post(
      `/tech/video_access_tickets/update_all?course_id=${course.id}&account_id=${accountId}`,
      {tech_course_id: course.id, account_id: accountId, terms: accessibleTerms}
    )
    .then((res) => {
      setSnack({...snack, message: '更新しました'})
    })
    .catch((err) => {
      setSnack({...snack, message: '予期せぬエラーが発生しました', type: 'error'})
    })
  }

  return (
    <>
      <Snackbar
        open={snack.message !== ""}
        message={snack.message}
        anchorOrigin={snack.anchorOrigin}
        autoHideDuration={snack.autoHideDuration}
        type={snack.type}
        onClose={handleSnackClose}
      />
      <SectionArea>
        <FlexBox content="space-between">
          <Span fontSize="1.5rem" fontWeight="bold">[{course.name}]の授業動画へのアクセス権限付与状況</Span>
          <Width size="20%">
            <Space margin="1rem">
              <span className="button" onClick={onSubmitHandler}>更新する</span>
            </Space>
          </Width>
        </FlexBox>
        <Card>
          <CardSection className="mt-5">
            <CardSectionArticle>
              <h2>各授業動画へのアクセス可能期間設定</h2>
              <div>
                <table className="table">
                  <thead>
                    <th className="va_middle">
                      <div className="col-area col-area_small">
                        <span>授業名</span>
                      </div>
                    </th>
                    <th className="va_middle">
                      <div className="col-area col-area_small">
                        <span>日程</span>
                      </div>
                    </th>
                    <th className="va_middle">
                      <div className="col-area col-area_small">
                        <span>開始時刻</span>
                      </div>
                    </th>
                    <th className="va_middle">
                      <div className="col-area col-area_small">
                        <span>終了時刻</span>
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    {courseLessons.map((cl, idx) => (
                      <tr>
                        <td className="ta_center va_middle">
                          <span>{cl.lesson.name}</span>
                        </td>
                        <td className="ta_center va_middle">
                          <span>{cl.display_lesson_period}</span>
                        </td>
                        <td className="ta_center va_middle">
                          <DatePickerForm
                            selected={accessibleTerms[idx].from}
                            onChange={(selected) => onChange(selected, idx, 'from')}
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeFormat="HH:mm"
                            id={`course_lesson_${cl.id}_from`}
                            showTimeSelect
                            style={{ margin: 0 }}
                          />
                        </td>
                        <td className="ta_center">
                          <DatePickerForm
                            selected={accessibleTerms[idx].to}
                            onChange={(selected) => onChange(selected, idx, 'to')}
                            dateFormat="yyyy-MM-dd HH:mm"
                            timeFormat="HH:mm"
                            id={`course_lesson_${cl.id}_to`}
                            showTimeSelect
                            style={{ margin: 0 }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardSectionArticle>
          </CardSection>
        </Card>
      </SectionArea>
    </>
  )
}

export default Index