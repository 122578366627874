import React, {useEffect, useState, useRef, useMemo} from 'react'
import { convertRemToPx } from '../shared/text';

function StretchTextField({
  deffaultHeight,
  placeholder,
  deffaultValue,
  className,
  onChange,
}) {
  const [inputText, setInputText] = useState(deffaultValue)
  const [ height, setHeight ] = useState(0);
  const textAreaRef = useRef(null);
  const [updateHeightFlg, setUpdateHeightFlg] = useState(false)
  useEffect(() => {
    if (textAreaRef.current && height !== currentHeight()) {
    	setUpdateHeightFlg(true); // テキストエリアの高さを初期値に戻す
    }
  }, [inputText]);

  useEffect(() => {
    // 高さが初期値の場合にscrollHeightを高さに設定する
    if (updateHeightFlg && textAreaRef.current) {
      setUpdateHeightFlg(false)
      setHeight(currentHeight());
    }
    if (height && textAreaRef.current) {
      onChange(inputText, height)
    }
  }, [height, inputText, textAreaRef.current]);

  const currentHeight = () => {
    if (textAreaRef.current) {
      return `${Math.max(textAreaRef.current.scrollHeight, convertRemToPx(deffaultHeight))}px`
    } else {
      return deffaultHeight
    }
  }

  return (
    <textarea
      ref={ textAreaRef }
      placeholder={"入力してください"}
      type={"text"}
      value={inputText}
      onChange={(e) => setInputText(e.target.value)}
      className={className}
      style={{height: height !== 'undefined' ? `${ height }` : 'auto'}}
    />
  )
}

export default StretchTextField