import React from 'react';
import { Formik } from 'formik';

const defaultValues = {
  facility_name: '',
  started_at: '',
  finished_at: '',
  role_name: '',
};

const validate = values => {
  const errors = {};
  if (!values.facility_name) {
    errors.facility_name = '入力してください';
  }
  if (!values.started_at) {
    errors.started_at = '入力してください';
  }
  if (!values.finished_at) {
    errors.finished_at = '入力してください';
  }
  if (!values.role_name) {
    errors.role_name = '入力してください';
  }
  return errors;
};

const AttendanceConditionForm = ({ initialValues = defaultValues, onSubmit, selectValues }) => {
  const editFacilityList = () => {
    return (
      <datalist id="edit_facility_list">
        {selectValues.facilities.map(value => (
          <option value={value.label} />
        ))}
      </datalist>
    )
  }

  const editRoleList = () => {
    return (
      <datalist id="edit_role_list">
        {selectValues.roles.map(value => (
          <option value={value.label} />
        ))}
      </datalist>
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, errors, handleChange }) => (
        <form onSubmit={handleSubmit}>
          <div className="card_article">
            <table className="table_form">
              <tr>
                <th className="required-cell">
                  <div className="row-area">
                    <label>施設</label>
                    <span className="badge_status badge_status_important">必須</span>
                  </div>
                </th>
                <td className="form-text required-cell">
                  <input
                    id="facility_name"
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={values.facility_name}
                    name="facility_name"
                    autocomplete="on"
                    list="edit_facility_list"
                  />
                  {editFacilityList()}
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div className="error-message">{errors.facility_name}</div>
                </td>
              </tr>
              <tr>
                <th className="required-cell">
                  <div className="row-area">
                    <label>出勤時間</label>
                    <span className="badge_status badge_status_important">必須</span>
                  </div>
                </th>
                <td className="form-text required-cell">
                  <input
                    id="started_at"
                    type="time"
                    className="form-control"
                    onChange={handleChange}
                    value={values.started_at}
                    name="started_at"
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div className="error-message">{errors.started_at}</div>
                </td>
              </tr>
              <tr>
                <th className="required-cell">
                  <div className="row-area">
                    <label>退勤時間</label>
                    <span className="badge_status badge_status_important">必須</span>
                  </div>
                </th>
                <td className="form-text required-cell">
                  <input
                    id="finished_at"
                    type="time"
                    className="form-control"
                    onChange={handleChange}
                    value={values.finished_at}
                    name="finished_at"
                  />
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div className="error-message">{errors.finished_at}</div>
                </td>
              </tr>
              <tr>
                <th className="required-cell">
                  <div className="row-area">
                    <label>役割</label>
                    <span className="badge badge_important">必須</span>
                  </div>
                </th>
                <td className="form-text required-cell">
                  <input
                    id="role_name"
                    type="text"
                    className="form-control"
                    onChange={handleChange}
                    value={values.role_name}
                    name="role_name"
                    autocomplete="on"
                    list="edit_role_list"
                  />
                  {editRoleList()}
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div className="error-message">{errors.role_name}</div>
                </td>
              </tr>
            </table>
          </div>
          <div className="card_article">
            <div className="button-area">
              <button id="save_button" type="submit" className="button">入力した内容で保存</button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AttendanceConditionForm;
