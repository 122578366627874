import React, {useMemo} from 'react'
import Td from './Td';
import Space from '../../atoms/Space';
import Badge from '../../atoms/Badge';
import FlexBox from '../../atoms/FlexBox';
import Width from '../../atoms/Width';
import Container from '../../atoms/Container';
import Link from '../../atoms/Link';
import DateDecorator from '../../DateDecorator';

export default function SpotView({
  facilityData,
  hallData,
  spotData,
  deleteShift,
  modalOpen
}) {
  const roleLabel = (shift) => {
    if (shift.role_label === 'スタッフ') {
      return shift.toucheez_role_label || '未選択'
    }else {
      return shift.role_label
    }
  }

  return (
    <Td backColor="#ffffff">
      {spotData.shifts.map(shift => {
        if (shift.status_label === '稼働') { // 通常シフト
          return (
            <Space margin="1rem" key={shift.id}>
              <Badge
                backColor="#b0c4de"
                color="#191970"
                border="none"
              >
                <Space padding="0.2rem">
                  <FlexBox>
                    <Width size="30%">{`${new DateDecorator(new Date(shift.started_at)).currentTimeLabel()} - ${new DateDecorator(new Date(shift.finished_at)).currentTimeLabel()}`}</Width>
                    <Width size="30%">{`${roleLabel(shift)}`}</Width>
                    <Width size="30%">{`${shift.account_name}`}</Width>
                    <Width size="10%">
                      <Container>
                        <Link onClick={() => deleteShift(shift.id)}>
                          &times;
                        </Link>
                      </Container>
                    </Width>
                  </FlexBox>
                </Space>
              </Badge>
            </Space>
          )
        } else if (shift.status_label === '待機') { // 待機シフト
          return (
            <Space margin="1rem" key={shift.id}>
              <Badge
                backColor="#b0c4de"
                color="#191970"
                border="none"
              >
                <Space padding="0.2rem">
                  <FlexBox>
                    <Width size="30%">待機</Width>
                    <Width size="30%">{`${roleLabel(shift)}`}</Width>
                    <Width size="30%">{`${shift.account_name}`}</Width>
                    <Width size="10%">
                      <Container>
                        <Link onClick={() => deleteShift(shift.id)}>
                          &times;
                        </Link>
                      </Container>
                    </Width>
                  </FlexBox>
                </Space>
              </Badge>
            </Space>
          )
        } else {
          return (
            <Space margin="1rem" key={shift.id}>
              <Badge
                backColor="#ffe4e1"
                color="#db7093"
                border="none"
              >
                <Space padding="0.2rem">
                  <FlexBox>
                    <Width size="30%">欠員</Width>
                    <Width size="30%">{`${roleLabel(shift)}`}</Width>
                    <Width size="30%">{`${shift.account_name}`}</Width>
                    <Width size="10%">
                      <Container>
                        <Link
                          onClick={() => deleteShift(shift.id)}
                          color="#db7093"
                          hoverColor="#c71585"
                        >
                          &times;
                        </Link>
                      </Container>
                    </Width>
                  </FlexBox>
                </Space>
              </Badge>
            </Space>
          )
        }
      })}
      <Space margin="1rem">
        <Badge
          backColor="#ffffff"
          color="#191970"
          border="none"
          cursor="pointer"
          hoverBackColor="#e6e6fa"
          onClick={() => modalOpen(facilityData.facility_id, hallData.hall_id, spotData.spot_id)}
        >
          <FlexBox>
            <span className="material-icons">add_circle</span>
            <Container>
              <Space margin="0 0 0 0.4rem"><span>従業員を追加</span></Space>
            </Container>
          </FlexBox>
        </Badge>
      </Space>
    </Td>
  )
}
