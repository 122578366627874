import React, {useState, useRef } from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import Axios from '../../Axios';
import Alert from '../../Alert';
import FormColumn from '../../FormColumn';
import SectionArea from '../../atoms/SectionArea';

const EventTalkSettionForm = ({event}) => {
  const [message, setMessage] = useState();
  const baseUrl = `/baseq/admin/events/${event.id}/talk_sessions`
  const paramsKey = 'baseq_event'
  const processing = useRef(false);

  const validate = (value) => {
    if (!value.trim()) {
      return '入力してください'
    }
    if (value.length > 10) {
      return "１０文字以下で入力してください"
    }
    return undefined
  }

  const handleSubmit = values => {
    if (processing.current === true) return;
    if (event.id) {
      processing.current = true;
      Axios.put(baseUrl, {[paramsKey]: values})
        .then(({data}) => {
          setMessage({text: `更新しました`, type: 'notice'});
          window.location.href = baseUrl
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err.response.data.message}`, type: 'alert'});
        processing.current = false;
      });
    }
  }

  const circleStyle = {
    display: "inline-block",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "20px",
    color: "#fff",
    marginRight: "5px",
  };

  return (
    <div className='values_questionnaire_form'>
      {message && (
        <Alert
          message={message}
          onClose={() => setMessage('')}
          timeout={3000}
        />
      )}
      <SectionArea>
        <Formik
          enableReinitialize
          initialValues={event}
          onSubmit={handleSubmit}
        >
          {({values, errors, setFieldValue}) => (
            <Form>
              <div className='main-side-area'>
                <div className='main-side-area_main'>
                  <div className='card'>
                    <div className='card_section'>
                      <table className='table_form'>
                        <tbody>
                          <FieldArray
                            name="talk_sessions"
                            render={(arrayHelpers) => {
                              return (
                                <>
                                  {
                                    values.talk_sessions.map((c, i) => {
                                      return (
                                        <div key={i}>
                                          <FormColumn label='ルーム名' required>
                                            <td className="form-text">
                                              <Field
                                                key={i}
                                                name={`talk_sessions.${i}.name`}
                                                placeholder="例）ロビー"
                                                validate={(value) => validate(value)}
                                              />
                                              <p className={'red'}>
                                                {(errors.talk_sessions && errors.talk_sessions[i]) ? errors.talk_sessions[i].name : ""}
                                              </p>
                                            </td>
                                          </FormColumn>
                                          <tr>
                                            <th></th>
                                            <td>
                                              {i > 0 && !event.is_undeletable_talk_sessions &&
                                                <button
                                                  type="button"
                                                  id={`remove${i}`}
                                                  style={{marginLeft: "1rem"}}
                                                  onClick={() => arrayHelpers.remove(i)}
                                                >
                                                  <span style={{...circleStyle, background: "crimson"}}>×</span>
                                                  <span style={{color: "crimson"}}>ルームを削除</span>
                                                </button>
                                              }
                                              {event.is_undeletable_talk_sessions &&
                                                <span className='nondelatable-question'>※ ロビーのため編集のみ可</span>}
                                            </td>
                                          </tr>
                                          <tr style={{borderTop: "1px solid lightgray"}}>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </div>
                                      );
                                    })
                                  }
                                  {!event.is_undeletable_talk_sessions && (
                                    <tr>
                                      <td colSpan={2} style={{textAlign: "center"}}>
                                        <button
                                          type="button"
                                          style={{marginLeft: "1rem"}}
                                          onClick={() => arrayHelpers.push({name: ''})}
                                        >
                                          <span style={{...circleStyle, background: "black", marginTop: "5px"}}>+
                                          </span>ルームを追加
                                        </button>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )
                            }}
                          />
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='main-side-area_side'>
                  <div className='card sticky_top'>
                    <div className='card_section'>
                      <div className='card_section_article'>
                        <h1>ページの操作</h1>
                      </div>
                    </div>
                    <div className='card_section'>
                      <div className='card_section_article'>
                        <div className='col-area'>
                          <span>
                            <input
                              type='submit'
                              value={event.id ? "入力した内容を保存" : "入力した内容で登録"}
                              className="button"
                            />
                          </span>
                          <span>
                            <a href={'/baseq/admin/events/'} className="button button_cancel">入力した内容を破棄</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
            )}
        </Formik>
      </SectionArea>
    </div>
  )
}
export default EventTalkSettionForm;