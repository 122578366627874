import React, {useState} from 'react'
import Card from '../../../atoms/Card'
import FlexBox from '../../../atoms/FlexBox'
import Span from '../../../atoms/Span'
import Space from '../../../atoms/Space'

export default function MultiFreeWordSelect({values, onChange}) {
  const [inputValue, setInputValue] = useState('')
  const onKeydown = (key) => {
    switch (key) {
      case "Enter":
        const newValues = values.slice();
        newValues.push(inputValue)
        onChange(newValues);
        setInputValue('');
        break;
      default:
        break;
    }
  }

  return (
    <Card width="100%" height="4rem" borderRadius="5px" padding="0.5rem">
      <FlexBox>
        {values.map((v, idx) => (
          <div key={`multi-word-selected-${idx}`}>
            <FlexBox>
              <Card backColor="rgb(230, 230, 230)" padding="0.5rem" borderRadius="5px">
                <FlexBox>
                  <Span color="#333" fontSize="1.2rem">{v}</Span>
                  <Space margin="0 0 0 0.4rem" onClick={() => onChange(values.filter((_v) => _v !== v))}>
                    <Span color="#333" fontSize="1.2rem" className="mail-batsu"/>
                  </Space>
                </FlexBox>
              </Card>
              {(idx !== values.length - 1 ) && <Space padding="0 0.5rem 0 0 "/>}
            </FlexBox>
          </div>
        ))}
        <input
          className="mail-form w-100"
          onKeyDown={(e) => onKeydown(e.key)}
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          placeholder="入力後にEnterで確定"
        />
      </FlexBox>
    </Card>
  )
}