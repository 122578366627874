import React, {useState, useEffect, useMemo} from 'react'
import Card from '../../../atoms/Card'
import ReactSelect from '../../../ReactSelect'
import Axios from '../../../../lib/axios'
import FlexBox from '../../../atoms/FlexBox'
import DatePicker from 'react-datepicker'
import Space from '../../../atoms/Space'
import DateDecorator from '../../../DateDecorator'
import NoContent from '../../user/shared/NoContent'
import ChartA from './ChartA'
import ExerciseResultData from './ExerciseResultsData'

function IndexPage({courseOptions, exerciseOptions}) {
  const [exOptions, setExOptions] = useState(exerciseOptions)
  const [results, setResults] = useState([])
  const [selectedCourseOption, setSelectedCourseOption] = useState(null)
  const [selectedExerciseOption, setSelectedExerciseOption] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const resultData = useMemo(() => (new ExerciseResultData(results)), [results])

  const fetchExerciseOptions = (selectedOption) => {
    if (selectedOption?.value) {
      Axios.get(`/tech/exercises/options?tech_course_id=${selectedOption.value}`)
        .then((ret) => {
          setExOptions(
            ret.data.map(d => ({value: d.id, label: d.name}))
          )
          setSelectedExerciseOption(null)
          setSelectedCourseOption(selectedOption)
        })
    } else {
      setExOptions([])
    }
  }

  const fetchResults = () => {
    Axios.get(
      `/tech/exercise_results/search?tech_course_id=${selectedCourseOption.value}&tech_exercise_id=${selectedExerciseOption.value}&from=${from}&to=${to}`
    )
      .then((res) => {
        setResults(res.data)
      })
  }

  useEffect(() => {
    if(
      selectedCourseOption === null ||
      selectedExerciseOption === null ||
      !Object.keys(selectedCourseOption).length ||
      !Object.keys(selectedExerciseOption).length
    ){
      return
    }
    fetchResults()
  }, [selectedCourseOption, selectedExerciseOption, from, to])

  return (
    <div>
      <h1>理解度テスト結果</h1>
      <Space margin="1rem"/>
      <Card>
        <div className="control-area">
          <div className="control-area_section control-area_section_stretch_wrap">
            <div className="control-area_section_article control-area_section_article_stretch">
              <div className="control-area control-area_stretch">
                <div className="control-area_section control-area_section_stretch">
                  <div className="control-area_section_article">
                    <div className="control-area_section_article_title">
                      <label>コース</label>
                    </div>
                    <div className="form-select form-small">
                      <ReactSelect
                        value={selectedCourseOption?.value || null}
                        options={courseOptions}
                        placeholder="選択してください"
                        isMulti={false}
                        onChange={fetchExerciseOptions}
                      />
                    </div>
                  </div>
                </div>
                <div className="control-area_section control-area_section_stretch">
                  <div className="control-area_section_article">
                    <div className="control-area_section_article_title">
                      <label>理解度テスト</label>
                    </div>
                    <div className="form-select form-small">
                      <ReactSelect
                        value={selectedExerciseOption?.value || null}
                        options={exOptions}
                        placeholder={selectedCourseOption === {} ? "先にコースを選択してください" : "選択してください"}
                        isMulti={false}
                        onChange={setSelectedExerciseOption}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="control-area_section_article control-area_section_article_stretch">
              <div className="control-area control-area_stretch">
                <div className="control-area_section control-area_section_stretch">
                  <div className="control-area_section_article">
                    <div className="control-area_section_article_title">
                      <label>集計期間</label>
                    </div>
                    <div className="form-select form-small d-flex">
                      <div className="w-50 pr-5">
                        <DatePicker
                          selected={!!from ? new Date(from) : ''}
                          onChange={(selected, e) => {
                            setFrom(selected ? new DateDecorator(selected).isoString() : '')
                          }}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeFormat="HH:mm"
                          showTimeSelect
                          style={{ width: "100%", height: "100%" }}
                          wrapperClassName="w-100 h-100"
                          className="w-100 h-100"
                        />
                      </div>
                      <FlexBox>
                        〜
                      </FlexBox>
                      <div className="w-50 pl-5">
                        <DatePicker
                          selected={!!to ? new Date(to) : ''}
                          onChange={(selected, e) => {
                            setTo(selected ? new DateDecorator(selected).isoString() : '')
                          }}
                          dateFormat="yyyy-MM-dd HH:mm"
                          timeFormat="HH:mm"
                          showTimeSelect
                          style={{ width: "100%", height: "100%" }}
                          wrapperClassName="w-100 h-100"
                          className="w-100 h-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Card>
      <Space margin="3rem"/>
      <Card>
        <div className="card_in-card card_section">
          {results.length === 0 && (
            <NoContent padding="3rem">
              <div>
                <p className="c_gray.fw_bold ta_center mb-5">表示する理解度テストがありません</p>
                <p className="c_gray.fw_bold ta_center">検索を行うことで理解度テストの実施状況を確認できます</p>
              </div>
            </NoContent>
          )}
          {results.length > 0 && (
            <>
              <Card height="30vh">
                <ChartA data={resultData}/>
              </Card>
              <table className="table">
                <thead>
                  <tr>
                    <th>参加者名</th>
                    <th>会社名もしくは学校名</th>
                    <th>実施日時</th>
                    <th>スコア</th>
                    <th>回答時間</th>
                  </tr>
                </thead>
                <tbody>
                  {results.map(result => (
                    <tr>
                      <td className="va_middle">
                        <FlexBox content="center">{result.account.name}</FlexBox>
                      </td>
                      <td className="va_middle">
                        <FlexBox content="center">{result.account.tech_student.tech_corporation.name}</FlexBox>
                      </td>
                      <td className="va_middle">
                        <FlexBox content="center">{new DateDecorator(new Date(result.created_at)).dateTimeSlashLabel()}</FlexBox>
                      </td>
                      <td className="va_middle">
                        <FlexBox content="center">{result.score}</FlexBox>
                      </td>
                      <td className="va_middle">
                        <FlexBox content="center">{result.answer_time}</FlexBox>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Card>
    </div>
  )
}

export default IndexPage