
import React, {memo} from 'react';
import FlexBox from '../../atoms/FlexBox';
import Span from '../../atoms/Span';
import Space from '../../atoms/Space';
import Container from '../../atoms/Container';
import Width from '../../atoms/Width';
import ReactSelect from 'react-select'

const AutoCompleteSelect = memo(({
  label,
  required,
  options,
  nameKey,
  selectedValue,
  onChange,
  errorMessage,
  disabled,
  placeholder='入力してください',
  customStyles,
  noOptionsMessage
}) => (
  <Space padding="1rem">
    <Space margin="0 0 1rem 0">
      <FlexBox>
        <Container>
          <Span fontSize={"12px"} fontWeight={"bold"}>{label}</Span>
        </Container>
        <Space margin="0 0 0 1rem">
          {required && <span className={"badge_status badge_status_important"}>必須</span>}
        </Space>
      </FlexBox>
    </Space>
    <Width>
      <ReactSelect
        options={options}
        value={selectedValue}
        placeholder={placeholder}
        onChange={(e) => onChange(e.value)}
        errorMessage={errorMessage}
        isDisabled={disabled}
        styles={customStyles}
        noOptionsMessage={() => noOptionsMessage}
      />
    </Width>
    {
      errorMessage && (
        <tr className={'error_message'}>
          <span>{errorMessage}</span>
        </tr>
      )
    }
  </Space>
));

export default AutoCompleteSelect;
