import React from "react";
import ModalButton from "./ModalButton";

const HeadingAreaModalButton = ({
  buttonLabel,
  cancelLabel,
  confirmLabel,
  modalTitle,
  modalMessage,
  link,
  params
}) => {
  const modalBody = () => {
    return <div>{modalMessage}</div>;
  };

  return (
    <React.StrictMode>
      <>
        <span>
          <ModalButton
            message={buttonLabel}
            title={modalTitle || "注意事項"}
            confirmMessage={confirmLabel}
            cancelMessage={cancelLabel}
            link={link}
            params={params}
          >
            {modalBody()}
          </ModalButton>
        </span>
      </>
    </React.StrictMode>
  );
};

export default HeadingAreaModalButton;
