import React from 'react'
import movie_02 from '../../movie_02.jpg'

function LessonVideoIndex({lessonId, lessonVideos, courseIcon}) {
  const onClickHandler = (documentId) => {
    window.open(`video?document_id=${documentId}`, "subwin",'width=500,height=500' );
  }
  return (
    <div>
      {lessonVideos.map((v) => (
        <div className="tech_user_course_lesson_video_row" onClick={() => onClickHandler(v.id)}>
          <div className="tech_user_course_lesson_video_row_content">
            <div className="tech_user_course_lesson_video_row_img_div">
              <img src={movie_02} className="tech_user_course_lesson_video_row_img"/>
            </div>
            <div className="tech_user_course_lesson_video_row_title">
              <span>{v.name}</span>
            </div>
          </div>
          <div>
            <span class="material-icons">open_in_new</span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default LessonVideoIndex