import React from 'react'
import Badge from './Badge';

function LeftRecruitingAmountBadge({course}) {
  return (
    <Badge className='badge_status badge_status_caption'>
      {course.left_recruiting_amount_label}
    </Badge>
  )
}

export default LeftRecruitingAmountBadge