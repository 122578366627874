
import React, {memo} from 'react';
import FlexBox from '../../atoms/FlexBox';
import Span from '../../atoms/Span';
import Space from '../../atoms/Space';
import Container from '../../atoms/Container';
import Width from '../../atoms/Width';

const Select = memo(({ label, required, options, nameKey, selectedValue, onChange, errorMessage, disabled, placeholder='選択してください' }) => {
  return (
    <Space padding="1rem">
      <Space margin="0 0 1rem 0">
        <FlexBox>
          <Container>
            <Span fontSize={"12px"} fontWeight={"bold"}>{label}</Span>
          </Container>
          <Space margin="0 0 0 1rem">
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </Space>
        </FlexBox>
      </Space>
      <Width>
        <select name={nameKey} id={`${nameKey}-id`} onChange={onChange} value={selectedValue || ''} disabled={disabled}>
          <option value={''}>{placeholder}</option>
          {
            options.map((option, index) => (
              <option value={option.id} key={`${nameKey}-key-${index}`}>{option.name}</option>
            ))
          }
        </select>
      </Width>
      {
        errorMessage &&
        (
          <tr className={'error_message'}>
            <span>{errorMessage}</span>
          </tr>
        )
      }
    </Space>
  );
});

export default Select;
