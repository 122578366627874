import React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import FlexBox from './atoms/FlexBox';

export default function SortIcon({
  menuItems
}) {
  const [open, setOpen] = React.useState(false);  //吹き出しの表示判定
  const anchorRef = React.useRef(null);           //吹き出し表示位置
  
  //吹き出しを閉じるときの処理
  const handleClose = () => {
    setOpen(false);
  };
  
  //並び順ボタンをクリックしたときの処理
  const clickButton = () => {
    setOpen(!open);
  };

  return (
    <>
      {/* 並び順ボタン */}
      <Button
        ref={anchorRef}
        onClick={clickButton}
      >
        <span className="material-icons">sort</span>並び順
      </Button>

      {/* 吹き出し */}
      <Popper 
        open={open}
        anchorEl={anchorRef.current} 
        transition>
        {({ TransitionProps}) => (
          // 表示時のアニメーションを設定
          <Grow
            {...TransitionProps}
            style={{transformOrigin:'top'}}
          >
            {/*吹き出し表示したときの枠を作るため  */}
            <Paper>
              {/* クリックイベントが他の要素で発生したかを判定 */}
              <ClickAwayListener onClickAway={handleClose}>
                {/* 表示するメニュー */}
                <MenuList>
                  {(menuItems).map((item, idx) => 
                    <MenuItem {...item.attributes} key={`menu-item-${idx}`}>{item.item}</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}