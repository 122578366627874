import React, {useState} from 'react';
import Select from '../../Select';
import Axios from '../../Axios';
import SubjectScore from "./SubjectScore";
import DownloadButton from "../../DownloadButton";
import RetestGradeTable from "./RetestGradeTable";

const GradeIndex = ({ years }) => {
  const [year, setYear] = useState('');
  const [discipline, setDiscipline] = useState('');
  const [disciplines, setDisciplines] = useState([]);
  const [courseTerms, setCourseTerms] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [students, setStudents] = useState([]);
  const [fields, setFields] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [averageScores, setAverageScores] = useState([]);
  const [highestScores, setHighestScores] = useState([]);
  const [lowestScores, setLowestScores] = useState([]);
  const [failureCounts, setFailureCount] = useState([]);
  const [selectedRetest, setSelectedRetest] = useState('');
  const [retests, setRetests] = useState([]);

  const handleYear = (selected) => {
    setDiscipline('');
    setDisciplines([]);
    setSelectedSchoolYear('');
    setCourseTerms([]);
    setSubjects({});
    setStudents({});
    setYear(selected.value);
    Axios.get(`/evide/school_disciplines/find_by_year?year=${selected.value}`).then(({ data }) => {
      setDisciplines(data);
    }).catch(err => {
      console.log(err)
    });
  }

  const handleDiscipline = (selected) => {
    setDiscipline(selected.value);
    setSelectedSchoolYear('');
    setCourseTerms([]);
    setSubjects({});
    setStudents({});
    Axios.get(`/evide/school_disciplines/${selected.value}/options`)
      .then(({data}) => {
        let optionCourseTerms = [];
        for (let i = 1; i <= Number(data.course_term); i++) {
          optionCourseTerms.push({ label: `${i}年次`, value: i });
        }
        setCourseTerms(optionCourseTerms);
      }).catch(err => {
        console.log(err);
      });
  }

  const handleCourseTerm = (selected) => {
    setSelectedSchoolYear(selected.value);
    setFields({});
    setSubjects({});
    setStudents({});
    setAverageScores([]);
    Axios.get(`/evide/grade/grades/find_by_query?year=${year}&discipline_id=${discipline}&school_year=${selected.value}`)
      .then(({data})=>{
        console.log('data', data);
        setFields(data.fields);
        setSubjects(data.subjects);
        setStudents(data.students);
        setAverageScores(data.average_scores);
        setHighestScores(data.highest_scores);
        setLowestScores(data.lowest_scores);
        setFailureCount(data.failure_count);
        // ハリボテ
        setRetests([{ value: null, label: '選択してください' }, { value: 1, label: '再試験' }]);
      }).catch(err => {
        console.log(err);
      });
  }

  const handleRetest = (selected) => {
    setSelectedRetest(selected.value);
  }

  const isPassed = score => {
    if (score == null || isNaN(score)) { return true }
    return score >= 60;
  }

  const convertScore = grade => {
    if (grade.evaluation && grade.score == null) {
      return '未入力'
    }
    return grade.score
  }

  return (
    <React.StrictMode>
    <div className='grade'>
      <div className="section-area">
        <div className="card">
          <div className="control-area">
            <div className="control-area_section control-area_section_stretch_wrap">
              <div className="control-area_section_article control-area_section_article_stretch">
                <div className="control-area control-area_stretch">
                  <div className="control-area_section control-area_section_stretch">
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>年度</label>
                      </div>
                      <Select
                        id="year"
                        options={years}
                        name="year"
                        value={year}
                        onChange={handleYear}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>学科</label>
                      </div>
                      <Select
                        id="discipline"
                        options={disciplines}
                        name="discipline"
                        value={discipline}
                        onChange={handleDiscipline}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>年次</label>
                      </div>
                      <Select
                        id="course_term"
                        options={courseTerms}
                        name="course_term"
                        value={selectedSchoolYear}
                        onChange={handleCourseTerm}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>再試験</label>
                      </div>
                      <Select
                        id="test"
                        options={retests}
                        name="retest"
                        value={selectedRetest}
                        onChange={handleRetest}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        {selectedRetest && (
          <RetestGradeTable />
        )}
        {!selectedRetest && students.length > 0 &&
          <>
            <div className="heading-area">
              <div/>
              <div>
                <DownloadButton message='PDF一括ダウンロード' icon='cloud_download' path='grades/download'/>
              </div>
            </div>
          <div className="section-area">
            <table className="table">
              <thead>
                <tr>
                  <th/>
                  <th/>
                  <th>分野</th>
                  {fields.map((field) => {
                    return <th key={field.id} colSpan={field.number_of_subjects * 2}>{field.name}</th>
                  })}
                  <th colSpan={5}/>
                </tr>
                <tr>
                  <th/>
                  <th/>
                  <th>科目</th>
                  {subjects.map((subject) => {
                    return (
                      <th key={subject.id} colSpan='2'>
                        {subject.name}
                        {subject.need_additional_test && (
                          <button className='button button_small'>再試験を登録</button>
                        )}
                      </th>
                    )
                  })}
                  <th colSpan={5}/>
                </tr>
                <tr>
                  <th/>
                  <th>学籍番号</th>
                  <th>規定時間数</th>
                  {subjects.map((subject) => {
                    return <th key={subject.id} colSpan='2'>{subject.semester_hours}</th>
                  })}
                  <th colSpan={5}/>
                </tr>
                <tr>
                  <th>PDF出力</th>
                  <th/>
                  <th/>
                  {subjects.map((subject) => {
                    return (
                      <React.Fragment key={subject.id} >
                        <th>点数</th>
                        <th>判定</th>
                      </React.Fragment>
                    )
                  })}
                  <th>未履修科目</th>
                  <th>素点総得点</th>
                  <th>総試験数</th>
                  <th>再試験数</th>
                  <th>クラス席次</th>
                </tr>
              </thead>
              <tbody className="ta_center">
                {students &&
                  students.map((s) => (
                    <tr key={s.id}>
                      <td className="">
                        <input type="checkbox" style={{display:"inline-block"}} />
                      </td>
                      <td className="va_middle">{s.student_number}</td>
                      <td className="va_middle">{s.name}</td>
                      {s.subject_grade.map((grade, index) => {
                        return (
                          <React.Fragment key={Number(String(s.id) + String(index))} >
                            {/*<td className="va_middle" className={isPassed(grade.score)? '': 'failure' }>{convertScore(grade)}</td>*/}
                            <td className="va_middle">{convertScore(grade)}</td>
                            <td className="va_middle">{grade.evaluation}</td>
                          </React.Fragment>
                        )
                      })}
                      <td className="va_middle">{s.untaken_subjects}</td>
                      <td className="va_middle">{s.total_score}</td>
                      <td className="va_middle">{s.total_test_counts}</td>
                      <td className="va_middle">{s.total_retest_counts}</td>
                      <td className="va_middle">{s.ranking}</td>
                    </tr>
                  ))}
                  {averageScores.length > 0 &&
                    <SubjectScore key='average' id='average' label='クラス平均' scores={averageScores} /> }
                  {highestScores.length > 0 &&
                    <SubjectScore key='highest' id='highest' label='クラス最高' scores={highestScores} /> }
                  {lowestScores.length > 0 &&
                    <SubjectScore key='lowest' id='lowest' label='クラス最低' scores={lowestScores} /> }
                  {failureCounts.length > 0 &&
                    <SubjectScore key='failure' id='failure' label='60点未満者' scores={failureCounts} /> }
              </tbody>
            </table>
          </div>
          </>
        }
      </div>
    </div>
    </React.StrictMode>
  );
}

export default GradeIndex;
