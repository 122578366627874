import React, { useState, useEffect, useContext } from 'react';
import DialogBox from 'react-modeless';
import axios from 'axios';
import AttendanceConditionForm from './AttendanceConditionForm';

const AttendanceConditionDialog = (props) => {
  const update = values => {
    const facility = props.facilities.find((v) => v.label === values.facility_name);
    const role = props.roles.find((v) => v.label === values.role_name);

    return axios
      .put(`/api/v1/toucheez/attendance_conditions/${props.recordID}`, {
        toucheez_attendance_condition: {
          toucheez_facility_id: facility.value,
          toucheez_role_id: role.value,
          started_at: props.attendanceCondition.attend_date + ' ' + values.started_at,
          finished_at: props.attendanceCondition.attend_date + ' ' + values.finished_at,
        }
      })
      .then(() => {
        props.handleClose();
        props.getAttendanceConditions();
      })
      .catch(error => {
        showAlert({ message: '予期せぬエラー', confirmText: '閉じる' });
    });
  };

  return (
    <DialogBox
      isOpen={props.isOpen}
      containerClassName={'dialog'}
      clickBackdropToClose={false}>

      <div className="content">
        <button onClick={props.handleClose} className="dialog_close material-icons">close</button>
        <h2 className="dialog_h2">勤務を修正</h2>
        <AttendanceConditionForm initialValues={props.attendanceCondition} onSubmit={update} selectValues={props} />
      </div>

    </DialogBox>
  )
};

export default AttendanceConditionDialog;
