import { Formik } from 'formik';
import React, { useState, useEffect, useContext } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

import axios from 'axios';

const defaultValues = {
  value: '',
};

const AttendanceConditionMemo = ({initialValues = defaultValues, onSubmit, setMemo, memo, condition, getAttendanceConditions}) => {
  return (
    <div>
      <div className="card_section">
        <h2>メモ</h2>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, values, errors, handleChange }) => (
            <form onSubmit={handleSubmit}>
              <div className="card_article">
                <textarea
                  id="value"
                  className="form-text form-text_small"
                  onChange={handleChange}
                  value={values.value}
                  name="value"
                />
              </div>
              <div className="card_article">
                <div className="button-area">
                  <button id="save_button" type="submit" className="button">
                    メモを保存
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AttendanceConditionMemo;
