import React from 'react';
import {TocAccountImage} from "../atoms/DummyTocAccountImage";

interface InputChildFees {
  childcare_fees: Array<ChildcareFee>
  toc_account: TocAccount
}

interface ChildcareFee {
  id: bigint
  name: string
  amount: number;
  formatted_month: string;
}

interface TocAccount {
  id: bigint
  full_name: string
  full_name_kana: string;
  classroom_name: string;
}

const ChildcareFeeIndex = (data: InputChildFees) => {
  return (
    <React.StrictMode>
      <>
        <div className="main-side-area_main">
          <div className="card">
            <div className="heading-area">
              <div className="title-area">
                <div className={"d-flex"}>
                  <span className="thumbnail-area thumbnail-area_circle">
                    <TocAccountImage accountName={data.toc_account.full_name}/>
                  </span>
                  <div className={'d-block'}>
                    <span>
                      {data.toc_account.classroom_name}
                    </span>
                    <div className={"d-flex"}>
                      <h1>{data.toc_account.full_name}</h1>
                      ({data.toc_account.full_name_kana})
                    </div>
                    <div className={"badge_status badge_status_important"}>
                      <a className="row-area row-area_small link-icon-and-text fs_s" href={`/memori/childcare_fees/new?toc_account_id=${data.toc_account.id}`}>
                        <span className="link-icon-and-text_text">請求書を登録</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card_in-card card_section">
              {data.childcare_fees && data.childcare_fees.length > 0 && data.childcare_fees.map((childcare_fee) => {
                return (
                  <>
                    <div className="card">
                      <div
                        className="card_content row-area row-area_xlarge row-area_jc-space-between row-area_ai-start">
                        <div className="col-area">
                          <div className="row-area">
                            <span>{childcare_fee.formatted_month}</span>
                          </div>
                          <div className="row-area">
                            <span className="fw_bold">{childcare_fee.amount}円</span>
                            ({childcare_fee.name})
                          </div>
                        </div>
                        <div className="col-area">
                          <div>
                            <a className="row-area row-area_small link-icon-and-text fs_s"
                               href={`/memori/childcare_fees/${childcare_fee.id}/edit`}>
                              <span className="material-icons">zoom_in</span>
                              <span className="link-icon-and-text_text">請求書を編集</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </>
    </React.StrictMode>
  )
}

export default ChildcareFeeIndex;
