import Axios from '../../lib/axios';

export const uploadFile = (files, onSuccess, onError) => {
  const formData = new FormData();

  for (let i=0; i<files.length; i++) {
    formData.append('files[]', files[i]);
    if (files[i].size > (20 * 1024 * 1024)) {
      onError("ファイルサイズが上限（20MB以上）を超えています");
      return;
    }
  }

  Axios.post('/upload_files', formData).then(({data}) => {
    onSuccess(data);
  }).catch((err) => {
    onError(err.response.data.message);
  });

}
