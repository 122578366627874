import React from 'react';
export default function Breadcrumb({list}) {
  return(
    <ol className="breadcrumb mb-2">
      {
        list.map((l, idx) => (
          <li className="breadcrumb-item" key={`breadcrumb-${idx}`}>
            {
              l.link ? (
                <a href={l.link}>{l.label}</a>
              ) : (
                <li className="breadcrumb-item">
                  {l.label}
                </li>
              )
            }
          </li>
        ))
      }
    </ol>
  )
} 