export default class CellMeta {
  STATIC_COLUMN = 4
  HEADER_ROW = 2
  DATE_ROW = 0;
  MEETING_ROW = 1

  constructor(hot_table, row, col){
    this.hot_table = hot_table;
    this.row = row
    this.col = col
  }

  update(type, value){
    if(type === 'className') {
      value = "htCenter htMiddle " + value;
    }
    this.hot_table.setCellMeta(this.row, this.col, type, value);
  }
  isDate() {
    return this.DATE_ROW === this.row;
  }

  getWeekdayColor() {
    const date = this.hot_table.getData()[this.DATE_ROW][this.col];
    if (!date) return "";
    if (date.includes("土")) return "saturday";
    if (date.includes("日")) return "sunday";
    return "";
  }

  isMeeting() {
    return this.MEETING_ROW === this.row && this.col >= this.STATIC_COLUMN
  }
  
  isStaticColumn() {
    return this.STATIC_COLUMN > this.col;
  }

  isDynamic() {
    return this.col >= this.STATIC_COLUMN && this.row >= this.HEADER_ROW
  }

  isShift() {
    return this.isDynamic() && this.row % 2 === 0
  }

  isAttendanceCondition() {
    return this.isDynamic() && this.row % 2 !== 0
  }

}