import React, {useState} from 'react';
import Select from '../../Select';
import Axios from '../../Axios';
import Alert from '../../Alert';
import StudentTable from './StudentTable';
// import { CSSTransitionGroup } from "react-transition-group";

const SubjectGradeIndex = ({ disciplines }) => {
  const [discipline, setDiscipline] = useState('');
  const [courseTerms, setCourseTerms] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState('');
  const [lectures, setLectures] = useState([]);
  const [selectedLecture, setSelectedLecture] = useState('');
  const [students, setStudents] = useState([]);
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState('');
  const [message, setMessage] = useState();

  const handleDiscipline = (selected) => {
    setDiscipline(selected.value);
    setCourseTerms([]);
    setLectures([]);
    setTests([]);
    setStudents([]);
    Axios.get(`/evide/school_disciplines/${selected.value}/options`)
      .then(({data}) => {
        let optionCourseTerms = [];
        for (let i = 1; i <= Number(data.course_term); i++) {
          optionCourseTerms.push({ label: `${i}年次`, value: i });
        }
        setCourseTerms(optionCourseTerms);
      }).catch(err => {
        setMessage({text:`システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
  }

  const handleCourseTerm = (selected) => {
    setSelectedSchoolYear(selected.value);
    setLectures([]);
    setTests([]);
    setStudents([]);
    Axios.get(`/evide/grade/curriculums/find_by_query?discipline_id=${discipline}&school_year=${selected.value}`)
      .then(({data}) => {
        if (!data) {
          setMessage({ text:'授業が登録されていません', type: 'alert' });
          return;
        }
        setLectures( data.lectures.map(l => {
          return { label: `${l.field.name} - ${l.subject.name} - ${l.lecturer.name}`, value: l.id }
        }));
      }).catch(err => {
        setMessage({ text:`システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
  }

  const handleLecture = (selected) => {
    setSelectedLecture(selected.value);
    setTests([]);
    setStudents([]);
    // Axios共通に入れても良い？
    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Axios.get(`/evide/grade/lectures/${selected.value}/tests`)
      .then(({data}) => {
        if (!data || data.length === 0) {
          setMessage({ text:'テストが登録されていません', type: 'alert' });
          return;
        }
        setTests(data.map(t=>{
          return { label: t.name, value: t.id }
        }));
      })
  }

  const handleTest = (selected) => {
    setSelectedTest(selected.value);
    setStudents([]);
    Axios.get(`/evide/grade/tests/${selected.value}`)
      .then(({data}) => {
        setStudents(data.find_students);
      }).catch(err => {
        setMessage({text:`システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
  }

  const handleScore = (e, id) => {
    const newStudents = students.map(s => {
      if (s.id === id) { return { ...s, score: e.target.value } }
      return s
    })
    setStudents(newStudents);
  }

  const handleSave = () => {
    Axios.post(`/evide/grade/tests/${selectedTest}/update_scores`, { students: students.map(s => { return { id: s.id, score: s.score }}) })
    .then(({data}) => {
      setMessage(data);
    }).catch(err => {
      setMessage({text:`システムエラーが発生しました。原因：${err}`, type: 'alert'});
    })
  }

  const removeMessage = () => {
    setMessage('')
  }

  return(
    <React.StrictMode>
    <div>
      {message && (
        // TODO: use animation??
        // <CSSTransitionGroup
        //   transitionName="toast_content"
        //   transitionEnter={false}
        //   transitionLeaveTimeout={300}
        // >
        // {
        <Alert
          message={message}
          onClose={() => removeMessage(message)}
          timeout={3000}
        />
        // }
        // </CSSTransitionGroup>
      )}
      <div className="section-area">
        <div className="card">
          <div className="control-area">
            <div className="control-area_section control-area_section_stretch_wrap">
              <div className="control-area_section_article control-area_section_article_stretch">
                <div className="control-area control-area_stretch">
                  <div className="control-area_section control-area_section_stretch">
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>学科</label>
                      </div>
                      <Select
                        id="discipline"
                        options={disciplines}
                        name="discipline"
                        value={discipline}
                        onChange={handleDiscipline}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>年次</label>
                      </div>
                      <Select
                        id="course_term"
                        options={courseTerms}
                        name="course_term"
                        value={selectedSchoolYear}
                        onChange={handleCourseTerm}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>授業</label>
                      </div>
                      <Select
                        id="lecture"
                        options={lectures}
                        name="lecture"
                        value={selectedLecture}
                        onChange={handleLecture}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>テスト</label>
                      </div>
                      <Select
                        id="test"
                        options={tests}
                        name="test"
                        value={selectedTest}
                        onChange={handleTest}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="heading-area">
          <div/>
          <div className="control-area">
            <div className="section-area">
              <div className="control-area">
                <div className="control-area_section">
                  <div className="control-area_section_article">
                    <div className="button row-area row-area_small">
                      <span className="button" onClick={handleSave}>
                        結果を保存
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-area">
          <div className="multi_tables">
            <StudentTable
              students={students.slice(0, Math.ceil(students.length / 2))}
              handleScore={handleScore}
            />
            <StudentTable
              students={students.slice(
                Math.ceil(students.length / 2),
                students.length
              )}
              handleScore={handleScore}
              isEmptyRow={Math.ceil(students.length / 2) > students.length / 2}
            />
          </div>
        </div>
      </div>
    </div>
    </React.StrictMode>
  );
}

export default SubjectGradeIndex;
