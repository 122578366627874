import React, {useState, useRef, useEffect} from 'react';
import Select from 'react-select';

const ReactSelect = ({ name, value, options, placeholder, isMulti, onChange={} }) => {
  const exerciseSelectRef = useRef(null)
  const selectedOption = value => {
    if(Array.isArray(value)) {
      return options.filter(o => value.includes(o.value) || value.includes(o.value.toString()))
    }else if(value) {
      return options.filter(o => o.value === value || o.value === Number(value))[0]
    }else {
      return null
    }
  };
  const [selectedValue, setSelectedValue] = useState(selectedOption(value))

  const onChangeHandler = (v) => {
    setSelectedValue(v);
    if (typeof onChange === 'function') {
      onChange(v);
    }
  }

  useEffect(() => {
    if(exerciseSelectRef.current && value === null) {
      exerciseSelectRef.current.clearValue()
    }
  }, [value, options])

  return (
    <Select
      options={options}
      value={selectedValue}
      onChange={onChangeHandler}
      name={name}
      placeholder={placeholder}
      isClearable
      isMulti={isMulti}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: '#EBEBEB',
          border: 'none'
        }),
      }}
      ref={exerciseSelectRef}
    />
  );
};

export default ReactSelect