import React from 'react';
import {formatDateSlash} from '../../../../shared/date'
import RowArea from '../../../atoms/RowArea'
import IndexCard from '../../../atoms/IndexCard'


const UsersList = ({loopKey, users, renderButtons, handleChecked, selectedApplicants}) => {
  return (
    <>
      {users.map((u, idx) => (
        <IndexCard key={`${loopKey}-${idx}`} data-cy='申込者カード'>
          <RowArea customClass={"row-area_xlarge event-user-card"}>
            <div className='event-user-info-area'>
              <div className='event-user-checkbox'>
                <input
                  type="checkbox"
                  name="select_all_event_user_check"
                  id="select_all_event_user_check"
                  checked={selectedApplicants[loopKey].includes(u.id)}
                  onChange={handleChecked(u.id, loopKey)}
                />
              </div>
              <ul className='event-user-info'>
                <li className='application-info'>
                  <span>{`No.${u.id}`}</span>
                  <span>{formatDateSlash(u.created_at)}</span>
                </li>
                <li>
                  <span className="name">{u.user.name}</span>
                  <span className="organization">{u.user.organization}</span>
                </li>
              </ul>
            </div>
            {renderButtons(u)}
          </RowArea>
        </IndexCard>
      ))}
    </>
  );
}

export default UsersList;