import React, {useState, useEffect} from 'react'
import Space from '../../atoms/Space';
import Button from '../../atoms/Button';
import Container from '../../atoms/Container';
import FlexBox from '../../atoms/FlexBox';
import Width from '../../atoms/Width';
import Select from './Select';
import RadioButton from './RadioButton';
import DatePeriodForm from './DatePeriodForm';
import Axios from '../../Axios';
import TwoWayRow from '../../atoms/TwoWayRow';
import AutoCompleteSelect from './AutoCompleteSelect';

export default function ShiftCreateForm({
  event,
  facilityId,
  hallId,
  spotId,
  facility_options,
  shift_category_options,
  account_category_options,
  role_options,
  onChangeData,
  postData,
  errorMessages,
}) {
  const [fetchErrorMessage, setFetchErrorMessage] = useState('')
  const [hallOptions, setHallOptions] = useState([]);
  const [spotOptions, setSpotOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);

  useEffect(() => {
    fetchHallOptions(postData.facility_id);
    fetchSpotOptions(postData.hall_id);
    fetchAccountOptions('account');
  }, [])

  const fetchHallOptions = (_facilityId) => {
    if(_facilityId) {
      Axios.get(`/shifts/hall_options?facility_id=${_facilityId}`)
        .then(({data}) => {
          setHallOptions(data);
        }).catch(err => {
          setFetchErrorMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
        });
    }else {
      setHallOptions([]);
      setSpotOptions([]);
      onChangeData({...postData, facility_id: '', hall_id: '', spot_id: ''})
    }
  }

  const onChangeFacilitySelect = (e) => {
    onChangeData({...postData, facility_id: e.target.value, hall_id: '', spot_id: ''})
    fetchHallOptions(e.target.value);
  }

  const fetchSpotOptions = (_hallId) => {
    if(_hallId) {
      Axios.get(`/shifts/spot_options?hall_id=${_hallId}`)
      .then(({data}) => {
        setSpotOptions(data);
      }).catch(err => {
        setFetchErrorMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }else {
      setSpotOptions([]);
    }
  }

  const onChangeHallSelect = (e) => {
    onChangeData({...postData, hall_id: e.target.value, spot_id: ''})
    fetchSpotOptions(e.target.value);
  }

  const fetchAccountOptions = (category) => {
    Axios.get(`/shifts/account_options?account_category=${category}`)
      .then(({data}) => {
        setAccountOptions(data.map(d => { return {...d, value: d.id, label: d.name}}));
      }).catch(err => {
        setFetchErrorMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const onChangeAccountCategory = (e) => {
    onChangeData({...postData, account_category: e.target.value})
    fetchAccountOptions(e.target.value)
  }

  return (
    <Width>
      <Space margin="0.2rem">
        <Space margin="0.2rem 1rem">
          <tr className={'error_message'}>
            <td>
              <span>{fetchErrorMessage}</span>
            </td>
          </tr>
        </Space>
        <Select
          label="会場"
          required
          options={facility_options}
          nameKey="facility"
          selectedValue={postData.facility_id}
          placeholder={'会場を選択してください'}
          onChange={onChangeFacilitySelect}
          errorMessage={errorMessages?.facility_id}
        />
        <TwoWayRow>
          <Width size="50%">
            <Select
              label="建物"
              required
              options={hallOptions}
              nameKey="hall"
              selectedValue={postData.hall_id}
              placeholder={'建物を選択してください'}
              onChange={onChangeHallSelect}
              errorMessage={errorMessages?.hall_id}
            />
          </Width>
          <Width size="50%">
            <Select
              label= "試験室"
              required
              options={spotOptions}
              nameKey="spot"
              selectedValue={postData.spot_id}
              placeholder={'試験室を選択してください'}
              onChange={(e) => onChangeData({...postData, spot_id: e.target.value})}
              errorMessage={errorMessages?.spot_id}
            />
          </Width>
        </TwoWayRow>
        <RadioButton
          label="シフトのカテゴリ"
          required
          options={shift_category_options}
          selectedValue={postData.category_id}
          nameKey="category"
          onChange={(e) => onChangeData({...postData, category_id: Number(e.target.value)})}
          errorMessage={errorMessages?.category_id}
        />
        {
          postData.category_id === 94 && (
            <DatePeriodForm
              label="時間"
              fromKey="started_at"
              toKey="finished_at"
              from={postData.started_at}
              to={postData.finished_at}
              onChange={(e) => onChangeData({...postData, [e.target.name]: e.target.value})}
              fromErrorMessage={errorMessages?.started_at}
              toErrorMessage={errorMessages?.finished_at}
              required
            />
          )
        }
        <RadioButton
          label="従業員のカテゴリ"
          required
          options={account_category_options}
          selectedValue={postData.account_category}
          nameKey="account_category"
          onChange={onChangeAccountCategory}
          errorMessage={errorMessages?.account_category}
        />
        {
          postData.account_category === 'toc_account' && (
            <RadioButton
              label="勤務カテゴリ"
              required
              options={role_options.map(r => {return {...r, label: r.name}})}
              selectedValue={postData.toucheez_role_id}
              nameKey="toucheez_role_id"
              onChange={(e) => onChangeData({...postData, [e.target.name]: Number(e.target.value)})}
              errorMessage={errorMessages?.toucheez_role_id}
            />
          )
        }
        <AutoCompleteSelect
          required
          label="従業員"
          options={accountOptions}
          selectedValue={accountOptions.filter(ao => ao.value === postData.account)}
          placeholder={'従業員を選択してください'}
          onChange={(id) => onChangeData({...postData, account: id})}
          errorMessage={errorMessages?.account}
          noOptionsMessage="選択できる従業員もしくはスタッフが存在しません"
          customStyles={{
            control: (provided, state) => ({
              ...provided,
              borderColor: "#2E46AF",
              borderStyle: "solid",
              borderWidth: "1px",
            }),
            placeholder: (provided, state) => ({...provided, color: "black"}),
          }}
        />
      </Space>
    </Width>
  )
}
