import React from "react";
import styled from "styled-components"
import DateDecorator from "../../DateDecorator";

const DayArea = styled.div`
  margin: 3px;
`

const Span = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #134485;
`

const CustomDayContent = (day, date, highlightDates) => {
  return (<DayArea><Span>{date.getDate()}</Span></DayArea>)
}

export default CustomDayContent