import { useLocation } from 'react-router-dom';

export function useQuery(key) {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  return query.get(key);
}

export function serialize(obj) {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}
