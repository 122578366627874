import React, { memo } from 'react';

const LoadingIcon = memo(() => {
  return (
    <div className="loading">
      <div id="loading_anime">
        <div className="loading_anime_group" id="loading_anime_01"></div>
        <div className="loading_anime_group" id="loading_anime_02"></div>
        <div className="loading_anime_group" id="loading_anime_03"></div>
        <div className="loading_anime_group" id="loading_anime_04"></div>
        <div className="loading_anime_group" id="loading_anime_05"></div>
        <div className="loading_anime_group" id="loading_anime_06"></div>
        <div className="loading_anime_group" id="loading_anime_07"></div>
        <div className="loading_anime_group" id="loading_anime_08"></div>
      </div>
      <div className="loading_text">LOADING...</div>
    </div>
  );
});

export default LoadingIcon