import React, {memo} from 'react';

const Form = memo(({children, buttonLabel, cancelLink, onClick, isProcessing, withoutSubmit}) => {
  return (
    <form acceptCharset="UTF-8">
      <div className="card_section">
        <table className="table_form">
          <tbody>
          {children}
          </tbody>
        </table>
      </div>
      {!withoutSubmit &&
      <div className="card_section">
        <div className="card_article">
          <div className="button-area button-area_fit">
            <span>
              <a
                className={"button button_cancel"}
                datamethod={"get"}
                href={cancelLink}
              >
                キャンセル
              </a>
            </span>
            <span>
              <button
                className={"button"}
                onClick={() => onClick()}
                disabled={isProcessing}
              >
                {isProcessing ? '送信中...' : (buttonLabel || '保存')}
              </button>
            </span>
          </div>
        </div>
      </div>
      }
    </form>
  );
});

export default Form;
