import React from 'react';
import Space from '../atoms/Space';

const TextField = ({ label, required, value, placeholder, nameKey, onChange, errorMessage, type, step, disabled }) => {
  return (
    <>
      <tr className={errorMessage && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      {errorMessage && <Space margin="1rem 0 0 0 "/>}
      <tr>
        <td className={"form-text"}>
          <input
            placeholder={placeholder}
            type={type || "text"}
            step={step || "1"}
            name={nameKey}
            id={`form_${nameKey}`}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
          {errorMessage && (
            <div>
              <span className={'error_message'}>{errorMessage}</span>
            </div>
          )}
        </td>
      </tr>
    </>
  );
}

export default TextField;
