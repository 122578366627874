import React, {memo, useState} from 'react';
import Space from '../atoms/Space';
import Select from 'react-select';

const IncrementalSelect = memo(({
  label,
  required,
  options,
  nameKey,
  selectedValue,
  onChange,
  errorMessage,
  disabled,
  placeholder='選択してください'
}) => {
  const convertPropToReactselectFormat = (op) => {
    return op.map((o) => ({value: o.id, label: o.name}))
  }
  const selectedOption = value => {
    if(value) {
      return (convertPropToReactselectFormat(options).filter(o => o.value === value)[0])
    }else {
      return null
    }
  };
  const [selected, setSelected] = useState(selectedOption(selectedValue))
  const handleChange = (obj) => {
    onChange({target: {value: obj?.value, name: nameKey}});
    setSelected(obj)
  }
  return (
    <>
      <tr className={errorMessage && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      {errorMessage && <Space margin="1rem 0 0 0 "/>}
      <tr>
        <td className={"form-select"}>
          <Select
            options={convertPropToReactselectFormat(options)}
            value={selected}
            onChange={(obj) => handleChange(obj)}
            name={nameKey}
            id={`${nameKey}-id`}
            isDisabled={disabled}
            placeholder={placeholder}
            isClearable
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: '#EBEBEB',
                border: 'none',
                height: '54px',
              }),
            }}
          />
          { errorMessage && <span className='error_message'>{errorMessage}</span>}
        </td>
      </tr>
    </>
  );
});

export default IncrementalSelect;
