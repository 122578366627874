import React from 'react'
import DragDropContext, {Draggable} from '../../../../DragDropContext'
import FlexBox from '../../../../atoms/FlexBox'
import Width from '../../../../atoms/Width'
import Container from '../../../../atoms/Container'
import Axios from '../../../../../lib/axios'
import Span from '../../../../atoms/Span'
import Space from '../../../../atoms/Space'

function ChoicesIndex({exerciseId, choices, handleChangeChoices}) {

  const handleChangeOrder = (_choices) => {
    const copy = [...choices]
    handleChangeChoices(_choices[0].tech_exercise_question_id, _choices)
    Axios.put(
      `/tech/exercises/${exerciseId}/exercise_questions/${choices[0].tech_exercise_question_id}/update_choice_order`,
      {choices: _choices}
    )
    .catch((err) => {
      handleChangeChoices(_choices[0].tech_exercise_question_id, copy)
    })
  }

  const answerCheck = (isCollect) => {
    if (isCollect) {
      return (
        <FlexBox borderRadius="50%" backColor="#dcdcdc">
          <Space padding="5px 3px 3px 3px">
            <Span
              className="material-icons"
              color="green"
              fontWeight="bold"
              fontSize="3rem"
            >
              done
            </Span>
          </Space>
        </FlexBox>
      )
    }else {
      <span></span>
    }
  }

  return (
    <>
      <FlexBox width="100%" content="center" borderBottom="1px solid">
        <Container width="90%" margin="1rem">選択肢</Container>
        <Container width="10%" margin="1rem">正誤</Container>
      </FlexBox>
      <DragDropContext
        handleDragEnd={handleChangeOrder}
        targets={choices}
        id="answer_list"
      >
      {choices.map((c, idx) => (
        <Draggable target={c} index={idx} key={c.id}>
          <FlexBox width="100%" content="center">
            <Container width="90%" margin="1rem">{c.name}</Container>
            <Container width="10%" margin="1rem">{answerCheck(c.is_correct)}</Container>
          </FlexBox>
        </Draggable>
      ))}
      </DragDropContext>
    </>
  )
}

export default ChoicesIndex