import React, {useState, useEffect} from 'react'
import ModalButton from '../../../ModalButton'
import Width from '../../../atoms/Width'
import Space from '../../../atoms/Space'
import FlexBox from '../../../atoms/FlexBox'
import Select from '../../../Select'
import Span from '../../../atoms/Span'
import Card from '../../../atoms/Card'
import MultiFreeWordSelect from './MultiFreeWordSelect'
import Axios from '../../../../lib/axios'
import { convertBreakToBr } from '../../../../shared/text'
import Button from '../../../atoms/Button'
import Container from '../../../atoms/Container'
import Snackbar from '../../../shared/Snackbar'
import Checkbox from '@mui/material/Checkbox';

function Index({
  title,
  courseOptions,
  paymentStatusOptions,
  courseId,
  paymentStatusId,
  isIgnoreUserMailSetting,
  params,
  _subject,
  _text
}) {
  const [targetUser, setTargetUser] = useState(courseId === 0 ? '登録済み全ユーザー' : 'コース申込あるいは参加履歴のあるユーザー')
  const [selectedCourseId, setSelectedCourseId] = useState(Number(courseId))
  const [selectedTargetId, setSelectedTargetId] = useState('全ユーザー')
  const [selectedLessonStatusId, setSelectedLessonStatusId] = useState(paymentStatusId)
  const [isIgnoreUserSetting, setIsIgnoreUserSetting] = useState(isIgnoreUserMailSetting)
  const [ccAddresses, setCcAddresses] = useState([]);
  const [subject, setSubject] = useState(_subject)
  const [text, setText] = useState(_text)
  const [step, setStep] = useState('form');
  const [errorMessage, setErrorMessage] = useState({})
  const [snack, setSnack] = useState({
    type: 'success',
    message: '',
    autoHideDuration: 2000,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
  });

  const [processing, setProcessing] = useState(false)

  const mailTargetOption = [
    {label: '全ユーザー', value: '全ユーザー'},
    {label: '申込者のみ', value: '申込者のみ'},
    {label: '参加者のみ', value: '参加者のみ'}
  ]
  useEffect(() => {
    setSnack({...snack, message: params.snack || ''})
  }, [])

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({...snack, message: ""})
  };

  const _courseOptions = [{label: '全コース', value: 0}].concat(courseOptions.map((co) => ({label: co.name, value: co.id})))
  const _paymentStatusOptions = [].concat(paymentStatusOptions.map((po) => ({label: po[1], value: po[0]})))

  const handleSubmit = () => {
    if(processing) {
      console.log('dadada')
      return
    }
    if (step === 'form') {
      if(formValidation()) {
        setErrorMessage({})
        setStep('confirm')
      } else {
        setErrorMessage({ base: 'フォームを正しく入力してください' })
      }
    }else {
      setProcessing(true)
      Axios.post(
        '/tech/students/send_mail',
        {
          target_user: targetUser,
          tech_course_id: selectedCourseId,
          mail_target: selectedTargetId,
          payment_status: selectedLessonStatusId,
          is_ignore_user_mail_setting: isIgnoreUserSetting,
          cc_addresses: ccAddresses,
          subject: subject,
          text: text
        })
        .then((data) => {
          const urlObj = new URL(window.location.href);
          urlObj.search = '';
          location.href = `${urlObj.toString()}?snack=メールを送信しました`
        })
        .catch((error) => {
          setErrorMessage(error.response.data.messages)
          setStep('form')
          setProcessing(false)
        })
    }
  }

  const formValidation = () => {
    if (targetUser == 'コース申込あるいは参加履歴のあるユーザー') {
      if(selectedCourseId === '') {
        return false
      }
      if(selectedLessonStatusId === '') {
        return false
      }
    }
    if (subject === '') {
      return false
    }
    if (text === '') {
      return false
    }

    return true
  }

  const handleChangeCourseSelect = (v) => {
    if(v === null) {
      setSelectedCourseId('')
    } else {
      setSelectedCourseId(v.value)
    }
  }

  const handleChangepaymentStatusSelect = (v) => {
    if(v === null) {
      setSelectedLessonStatusId('')
    } else {
      setSelectedLessonStatusId(v.value)
    }
  }
  
  const handleChangeTargetStatusSelect = (v) => {
    if(v === null) {
      setSelectedTargetId('')
    } else {
      setSelectedTargetId(v.value)
    }
  }

  return (
    <>
      <Snackbar
        open={snack.message !== ""}
        message={snack.message}
        anchorOrigin={snack.anchorOrigin}
        autoHideDuration={snack.autoHideDuration}
        type={snack.type}
        onClose={handleSnackClose}
      />
      <ModalButton
        message={title}
        title="メール送信フォーム"
        confirmMessage={step === 'form' ? "確認する" : "送信する"}
        cancelMessage="閉じる"
        params={""}
        handleSubmit={handleSubmit}
        link
        noAutoClose={true}
      >
        {step === 'form' && (
          <Width>
            {errorMessage.base && (
              <Space margin="0 0 1rem 0">
                <Width size="100%">
                  <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.base}</Span>
                </Width>
              </Space>
            )}
            <Space margin="0 0 1rem 0">
              <FlexBox>
                <Width size="15%">
                  <Span>対象ユーザー</Span>
                </Width>
                <Width size="85%">
                  <Select
                    options={[{label: '登録済み全ユーザー', value: '登録済み全ユーザー'}, {label: 'コース申込あるいは参加履歴のあるユーザー', value: 'コース申込あるいは参加履歴のあるユーザー'}]}
                    value={targetUser}
                    onChange={(selectedOption) => setTargetUser(selectedOption.value)}
                  />
                </Width>
              </FlexBox>
              {errorMessage.tech_course_id && (
                <Space margin="0">
                  <Width size="100%">
                    <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.tech_course_id}</Span>
                  </Width>
                </Space>
              )}
            </Space>
            {targetUser == 'コース申込あるいは参加履歴のあるユーザー' && (
              <>
                <Space margin="0 0 1rem 0">
                  <FlexBox>
                    <Width size="15%">
                      <Span>コース</Span>
                    </Width>
                    <Width size="85%">
                      <Select
                        options={_courseOptions}
                        value={selectedCourseId}
                        onChange={handleChangeCourseSelect}
                      />
                    </Width>
                  </FlexBox>
                  {errorMessage.tech_course_id && (
                    <Space margin="0">
                      <Width size="100%">
                        <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.tech_course_id}</Span>
                      </Width>
                    </Space>
                  )}
                </Space>
                <Space margin="0 0 1rem 0">
                  <FlexBox content="space-between">
                    <Width size="50%">
                      <FlexBox>
                        <Width size="30%">
                          <Span>ユーザー区分</Span>
                        </Width>
                        <Width size="70%">
                          <Select
                            options={mailTargetOption}
                            value={selectedTargetId}
                            onChange={handleChangeTargetStatusSelect}
                          />
                        </Width>
                      </FlexBox>
                      {errorMessage.mail_target && (
                        <Space margin="0">
                          <Width size="100%">
                            <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.mail_target}</Span>
                          </Width>
                        </Space>
                      )}
                    </Width>
                    <Width size="50%">
                      <FlexBox>
                        <Width size="30%">
                          <Span>入金ステータス</Span>
                        </Width>
                        <Width size="70%">
                          <Select
                            options={_paymentStatusOptions}
                            value={selectedLessonStatusId}
                            onChange={handleChangepaymentStatusSelect}
                          />
                        </Width>
                      </FlexBox>
                      {errorMessage.tech_lesson_status && (
                        <Space margin="0">
                          <Width size="100%">
                            <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.tech_lesson_status}</Span>
                          </Width>
                        </Space>
                      )}
                    </Width>
                  </FlexBox>
                </Space>
              </>
            )}
            <Space margin="0 0 1rem 0">
              <FlexBox>
                <Width size="100%">
                  <FlexBox content="end">
                    <Checkbox
                      checked={isIgnoreUserSetting}
                      onChange={(e) => {console.log(e); setIsIgnoreUserSetting(prev => !prev);}}
                    />
                    <Span fontSize="13px" color="red" fontWeight="bold">ユーザーのメール送信設定を無視して対象全員に送信する</Span>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox>
                <Width size="15%">
                  <Span>送信元アドレス</Span>
                </Width>
                <Width size="85%">
                  <FlexBox>
                    <Space>news@gainsline.com</Space>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox content="space-between">
                <Width size="15%">
                  <Span>Cc</Span>
                </Width>
                <Width size="85%">
                <MultiFreeWordSelect values={ccAddresses} onChange={setCcAddresses}/>
                </Width>
              </FlexBox>
              {errorMessage.cc_addresses && (
                <Space margin="0">
                  <Width size="100%">
                    <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.cc_addresses}</Span>
                  </Width>
                </Space>
              )}
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox content="space-between">
                <Width size="15%">
                  <Span>件名</Span>
                </Width>
                <Width size="85%">
                  <Card width="100%" height="4rem" borderRadius="5px" padding="0.6rem">
                    <FlexBox>
                      <input
                        className="mail-form w-100 mail-form-subject h-100"
                        onChange={(e) => setSubject(e.target.value)}
                        value={subject}
                      />
                    </FlexBox>
                  </Card>
                  {errorMessage.subject && (
                    <Space margin="0">
                      <Width size="100%">
                        <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.subject}</Span>
                      </Width>
                    </Space>
                  )}
                </Width>
              </FlexBox>
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox content="space-between">
                <Width size="15%">
                  <Span>本文</Span>
                </Width>
                <Width size="85%">
                  <Card width="100%" borderRadius="5px" padding="0.2rem">
                    <textarea
                      className="mail-form w-100 mail-form-text"
                      onChange={(e) => setText(e.target.value)}
                      rows="10"
                      value={text}
                    />
                  </Card>
                  {errorMessage.text && (
                    <Space margin="0">
                      <Width size="100%">
                        <Span color="red" fontWeight="bold" fontSize="14px">{errorMessage.text}</Span>
                      </Width>
                    </Space>
                  )}
                </Width>
              </FlexBox>
            </Space>
          </Width>
        )}
        {step === 'confirm' && (
          <Width>
            <Space margin="0 0 1rem 0">
              <FlexBox>
                <Width size="15%">
                  <Span>対象ユーザー</Span>
                </Width>
                <Width size="70%">
                  <FlexBox>
                    <Space>{targetUser}</Space>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            {targetUser === 'コース申込あるいは参加履歴のあるユーザー' && (
              <>
              <Space margin="0 0 1rem 0">
                <Width size="50%">
                  <FlexBox>
                    <Width size="30%">
                      <Span>コース</Span>
                    </Width>
                    <Width size="70%">
                      <FlexBox>
                        <Space>{_courseOptions.filter((co) => co.value === selectedCourseId)[0].label}</Space>
                      </FlexBox>
                    </Width>
                  </FlexBox>
                </Width>
              </Space>
              <Space margin="0 0 1rem 0">
                <FlexBox content="space-between">
                  <Width size="50%">
                    <FlexBox>
                      <Width size="30%">
                        <Span>ユーザー区分</Span>
                      </Width>
                      <Width size="70%">
                        <FlexBox>
                          <Space>{mailTargetOption.filter((mto) => mto.value === selectedTargetId)[0].label}</Space>
                        </FlexBox>
                      </Width>
                    </FlexBox>
                  </Width>
                  <Width size="50%">
                    <FlexBox>
                      <Width size="30%">
                        <Span>入金ステータス</Span>
                      </Width>
                      <Width size="70%">
                        <FlexBox>
                          <Space>{_paymentStatusOptions.filter((po) => po.value === selectedLessonStatusId)[0].label}</Space>
                        </FlexBox>
                      </Width>
                    </FlexBox>
                  </Width>
                </FlexBox>
              </Space>
              </>

            )}
            <Space margin="0 0 1rem 0">
              <FlexBox>
                <Width size="15%">
                  <Span>送信元アドレス</Span>
                </Width>
                <Width size="85%">
                  <FlexBox>
                    <Space>news@gainsline.com</Space>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox content="space-between">
                <Width size="15%">
                  <Span>Cc</Span>
                </Width>
                <Width size="85%">
                  <FlexBox>
                    <Space>{ccAddresses.join(', ')}</Space>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox content="space-between">
                <Width size="15%">
                  <Span>件名</Span>
                </Width>
                <Width size="85%">
                  <FlexBox>
                    <Space>{subject}</Space>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            <Space margin="0 0 1rem 0">
              <FlexBox content="space-between">
                <Width size="15%">
                  <Span>本文</Span>
                </Width>
                <Width size="85%">
                  <FlexBox border="1px solid gray">
                    <Space margin="1rem">
                      <div dangerouslySetInnerHTML={{__html: convertBreakToBr(text)}} className="text-left"></div>
                    </Space>
                  </FlexBox>
                </Width>
              </FlexBox>
            </Space>
            <Space margin="4rem 0 1rem 0">
              <FlexBox>
                <Width size="20%"/>
                  <Width size="60%">
                    <FlexBox>
                      <Button
                        height="4rem"
                        backColor="white"
                        color="rgb(85, 85, 85)"
                        border="1px solid"
                        hoverBackColor="rgb(245, 245, 245)"
                        onClick={() => setStep('form')}
                      >
                        <Container height="100%">編集画面に戻る</Container>
                      </Button>
                    </FlexBox>
                  </Width>
                <Width size="20%"/>
              </FlexBox>
            </Space>
          </Width>
        )}
      </ModalButton>
    </>

  )
}

export default Index