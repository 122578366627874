import React from 'react'
import Card from '../../atoms/Card';
import Span from '../../atoms/Span';
import Space from '../../atoms/Space';
import Container from '../../atoms/Container';
import Link from '../../atoms/Link';
import DateDecorator from "../../DateDecorator";

export default function EventIndex({events, date, can_export_csv}) {
  return (
    <Card>
      <Space margin={"0 0 1.5rem 0"}>
        <Span
          fontSize="20px"
          fontWeight="bold"
        >{date && new DateDecorator(date).fullLabel()}</Span>
      </Space>
      {
        events.length === 0 && (
          <>
            <Container>
              <Span fontSize="14px" fontWeight="bold">この日付には予定している案件はありません</Span>
            </Container>
          </>
        )
      }
      {events.map((event) => (
        <article className="card" key={event.id}>
          <Container content="space-between">
            <Space margin={"0 0 1.5rem 0"}>
              <Span fontSize="16px" fontWeight="bold">{event.event_name}</Span>
            </Space>
            <Container content="space-between">
              {can_export_csv && (
                <Space margin={"0 1rem 0 0"}>
                  <Link onClick={() => {location.href=`/shifts/${event.id}/csv_download`}}>
                    <span className="material-icons">file_download</span>
                    <Span fontSize="12px" fontWeight="bold">CSVを出力</Span>
                  </Link>
                </Space>
              )}
              <Space margin={"0 1rem 0 0"}>
                <Link onClick={() => {location.href=`/shifts/edit_page?event_id=${event.id}&date=${new DateDecorator(date).param()}`}}>
                  <span className="material-icons">zoom_in</span>
                  <Span fontSize="12px" fontWeight="bold">内容を確認</Span>
                </Link>
              </Space>
            </Container>
          </Container>
          <Space margin={"1rem 0 0 0"}>
            <span className="badge">会場</span>
            <Span fontSize="14px">{event.toucheez_facilities.map((facility) => facility.name).join(', ')}</Span>
          </Space>
        </article>
      ))}
    </Card>
  )
}
