import Card from "./atoms/Card";
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker';
import React, {useRef} from 'react'

function DatePickerForm(props) {
  const pickerRef = useRef();
  const onClickHandler= () => {
    pickerRef.current.setOpen(true); 
  }

  return (
    <Card onClick={onClickHandler} cursor="pointer">
      <DatePicker {...props} ref={pickerRef}/>
    </Card>
  )
}


export default DatePickerForm