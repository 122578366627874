import React from 'react'
import Space from '../../shared/Space';
import {appliableAmount} from './func'

function SeatApplicationForm({
  course,
  proxyMailAddresses,
  seatReservation,
  onChangeSeatReservation,
  seatReservationValidationMessage,
}) {
  return (
    <>
      <h2 className="tech_user_course_show_subtitle">{`席数　（${appliableAmount(course)}席まで申し込みが可能です）`}</h2>
      <div>
        <Space margin="2rem 0">
          <div className="flex">
            <Space margin="0.5rem">
              <span className="form-text">
                <span className="row-area row-area_liquid">
                  <span className="row-area_liquid_noconnect">
                    <input type="text" value={seatReservation} onChange={onChangeSeatReservation} placeholder='予約する枠数を入力してください'/>
                    {seatReservationValidationMessage !== '' && <div className="error-message">{seatReservationValidationMessage}</div>}
                  </span>
                </span>
              </span>
            </Space>
          </div>
        </Space>
      </div>
    </>
  )
}

export default SeatApplicationForm