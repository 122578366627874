import styled from "styled-components"

const NoContentCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding || "2rem"};
  margin: ${props => props.margin || "1rem"};
  border-width: medium;
  border-color: #c4bebe;
  border-style: dotted;
  min-height: 10rem;
  text-align: center;
  color: #a19b9b;
  font-weight: bold;
`
export default NoContentCard