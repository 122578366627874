import React, {useState} from 'react';
import Form from '../form/Form';
import TextField from "../form/TextField";
import TextArea from "../form/TextArea";
import DateTime from "../form/DateTime";
import Axios from "../../lib/axios";
import Select from "../form/Select";
import SubmitButtonWithModal from "../form/SubmitButtonWithModal";
import moment from 'moment';

const TocNotificationMessageForm = ({years, types, options, message}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});
  const [targetOptions, setTargetOptions] = useState(options);
  const [params, setParams] = useState({
    ...message,
    published_at: message.id ? message.published_at : moment()
  });

  const setValue = (e) => {
    setParams({...params, [e.target.name]: e.target.value})
  }

  const setDateTime = (date) => {
    setParams({...params, published_at: date})
  }

  const changeTargetType = (e) => {
    setValue(e);
    fetchTargetOptions(e.target.value, params.year);
  }

  const changeYear = (e) => {
    setValue(e);
    fetchTargetOptions(params.type, e.target.value);
  }

  const fetchTargetOptions = (type, year) => {
    if (!year || !type || type === 'Evide::EntranceMessage') {
      setTargetOptions(null);
      return;
    }

    Axios.get(`/toc_notification_messages/find_target_options?type=${type}&year=${year}`)
      .then(({data}) => {
        setTargetOptions(data);
      });
  }

  const handleSubmit = () => {
    setIsProcessing(true)
    if (message.id) {
      Axios.put(`/toc_notification_messages/${message.id}`, {toc_notification_message: params})
        .then(({data}) => {
          window.location.href = data.path;
        }).catch(err => {
        setParams({...params});
        setErrors({...err.response.data});
        setIsProcessing(false);
      });
    } else {
      Axios.post(`/toc_notification_messages`, {toc_notification_message: params})
        .then(({data}) => {
          window.location.href = data.path;
        }).catch(err => {
        setParams({...params});
        setErrors({...err.response.data});
        setIsProcessing(false);
      });
    }
  }

  return (
    <React.StrictMode>
      <div className={'section-area'}>
        <div className={'main-side-area'}>
          <div className={'main-side-area_main'}>
            <div className={'card toc_notification_messages'}>
              <Form cancelLink={"/toc_notification_messages"} onClick={handleSubmit} buttonLabel={message.id ? '保存' : '登録'}
                    isProcessing={isProcessing} withoutSubmit={true}>
                <Select
                  label={'対象の種類'}
                  required={true}
                  options={types}
                  nameKey={'type'}
                  selectedValue={params.type}
                  placeholder={'サービスご利用者全員'}
                  onChange={changeTargetType}
                />
                {
                  params.type &&
                  <Select
                    label={'年度'}
                    required={true}
                    options={years}
                    nameKey={'year'}
                    selectedValue={params.year}
                    onChange={changeYear}
                    errorMessage={errors?.year}
                  />
                }
                {
                  targetOptions &&
                  <Select
                    label={'対象者'}
                    required={true}
                    options={targetOptions}
                    nameKey={'target_id'}
                    selectedValue={params.target_id}
                    onChange={setValue}
                    errorMessage={errors?.target_id}
                  />
                }
                <TextField
                  label="タイトル"
                  required={true}
                  value={params.title}
                  nameKey={'title'}
                  placeholder={'例）お知らせ'}
                  onChange={setValue}
                  errorMessage={errors?.title}
                />
                <TextArea
                  label="本文"
                  required={true}
                  value={params.message}
                  nameKey={'message'}
                  placeholder={'例）新しいイベントを公開しました'}
                  onChange={setValue}
                  errorMessage={errors?.message}
                />
                <DateTime
                  label="公開日時"
                  required={true}
                  nameKey={'published_at'}
                  value={params.published_at}
                  onChange={setDateTime}
                  errorMessage={errors?.published_at}
                  editablePastDate={false}
                />
              </Form>
            </div>
          </div>
          <div className={'main-side-area_side'}>
            <div className={'card sticky_top toc_notification_messages'}>
              <SubmitButtonWithModal
                submitLabel={message.id ? '入力した内容を保存' :'入力した内容で登録'}
                cancelLabel={"入力した内容を破棄"}
                confirmLabel={message.id ? '理解して保存' : '理解して登録'}
                cancelPath={"/toc_notification_messages"}
                modalMessage={"公開日時が現在時刻を過ぎている場合にはすぐに対象アカウントに通知され、編集・削除することができません。"}
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  )
}
export default TocNotificationMessageForm;
