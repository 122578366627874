import React from 'react'
import Card from '../../../../atoms/Card'
import CardSection from '../../../../atoms/CardSection'
import CardSectionArticle from '../../../../atoms/CardSectionArticle'
import DateDecorator from '../../../../DateDecorator'
import NoContent from '../../shared/NoContent'
import Select from 'react-select'
import { useLocation } from "react-router-dom";

function Index({results, target, userOptions}) {
  const handleChange = (selected) => {
    location.href = `/tech/user/exercise_results?target=${selected.value}`
  }

  return (
    <Card padding="2rem">
      <div className="heading-area">
        <div className="title-area">
          <h1>理解度テストの実施状況</h1>
        </div>
        <div>
          <Select
            options={userOptions}
            value={userOptions.filter(o => o.value === Number(target))[0]}
            onChange={handleChange}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                minWidth: '200px'
              }),
            }}
          />
        </div>
      </div>
      <div className="card_in-card card_section">
        {results.length === 0 && (
          <NoContent padding="3rem">
            <p className="c_gray.fw_bold ta_center">理解度テストが存在するコースに申し込んでいる場合、実施履歴が表示されます</p>
          </NoContent>
        )}
        {results.map(result => (
          <div className="card tech_user_exercise_result_card">
            <div className="tech_user_exercise_result_card_header">
              <div className="tech_user_exercise_result_card_header_title">
                <a href={`/tech/user/course_lessons/${result.link.id}`}>{result.link.label}</a>
              </div>
            </div>
            <div className="row-area row-area_xlarge mb-3">
              <div className="row-area row-area_xlarge">
                <span className="badge">コース名・授業名</span>
                <span>{result.course_name}</span>
              </div>
            </div>
            <div className="row-area row-area_xlarge mb-3">
              <div className="row-area row-area_xlarge">
                <span className="badge">回答期限</span>
                <span>{new DateDecorator(new Date(result.limit)).dateTimeSlashLabel()}</span>
              </div>
              <div className="row-area row-area_xlarge">
                <span className="badge">回答日時</span>
                <span>
                  {result.submited_at < new Date(1900, 1, 1).toISOString() ? '未実施' : new DateDecorator(new Date(result.submited_at)).dateTimeSlashLabel()}
                </span>
              </div>
              <div className="row-area row-area_xlarge">
                <span className="badge">点数</span>
                <span>{result.score}</span>
              </div>
              <div className="row-area row-area_xlarge">
                <span className="badge">実施回数</span>
                <span>{result.time}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}

export default Index