import React, {memo} from 'react';
import Space from '../atoms/Space';

const Select = memo(({
  label,
  required,
  options,
  nameKey,
  selectedValue,
  onChange,
  errorMessage,
  disabled,
  placeholder='選択してください'
}) => {
  return (
    <>
      <tr className={errorMessage && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      {errorMessage && <Space margin="1rem 0 0 0 "/>}
      <tr>
        <td className={"form-select"}>
          <select name={nameKey} id={`${nameKey}-id`} onChange={onChange} value={selectedValue || ''} disabled={disabled}>
            <option value={''}>{placeholder}</option>
            {
              options.map((option, index) => (
                <option value={option.id} key={`${nameKey}-key-${index}`}>{option.name}</option>
              ))
            }
          </select>
          { errorMessage && <span className='error_message'>{errorMessage}</span>}
        </td>
      </tr>
    </>
  );
});

export default Select;
