import React, {memo, useState} from 'react';
import Space from '../atoms/Space';

const Select = memo(({ 
  label,
  required,
  options,
  nameKey,
  selectedValues,
  onChange,
  errorMessage,
  disabled,
  placeholder='選択してください',
  incrementForm
}) => {
  return (
    <>
      <tr className={errorMessage && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      {errorMessage && <Space margin="1rem 0 0 0 "/>}
      <tr>
        <td className={"form-select"}>
          {
            [...Array(selectedValues.length)].map((_, i) => (
              <>
                {i > 0 && <Space margin="1rem 0 0 0 "/>}
                <select name={nameKey} id={`${nameKey}-id`} onChange={(e) => onChange(e, i)} value={selectedValues && selectedValues[i] || ''} disabled={disabled}>
                  <option value={''}>{placeholder}</option>
                  {
                    options.map((option, index) => (
                      <option value={option.id} key={`${nameKey}-key-${index}`}>{option.name}</option>
                    ))
                  }
                </select>
              </>
            ))
          }
          <div className="may_select_add_button_frame" onClick={incrementForm}>
            <div className="may_select_add_button">
              さらに追加するにはここをクリックしてください          
            </div>
          </div>
          { errorMessage && <span className='error_message'>{errorMessage}</span>}
        </td>
      </tr>
    </>
  );
});

export default Select;
