import React, {useRef, useEffect, useState} from "react";
import jspreadsheet from "jspreadsheet-ce";
import DateDecorator from "./DateDecorator";
import HttpClient from "./HttpClient";
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';
import html2canvas from "html2canvas";
registerLocale('ja', ja);

const ShiftExcel = ({propData, account_names, meeting_names, propMemo, isToucheez = false, isProduction = false}) => {
  const STATIC_COLUMN_NUM = 4
  const emptyUpdateOption = ['']

  const d = new Date();
  const initialDate = new Date(d.getFullYear(), d.getMonth() + 1, 1)
  const initialData = propData.slice(1)
  const jRef = useRef(null);
  const [colHeader, setColHeader] = useState(propData[0])
  const dateRef = useRef(new DateDecorator(initialDate))
  const [displayDate, setDisplayDate] = useState(new DateDecorator(initialDate).param())
  const [shiftMemo, setShiftMemo] = useState(propMemo || '')

  const dropdownFilter = function (instance, cell, x, y, source) {
    if (!isToucheez) {
      return emptyUpdateOption.concat(account_names)
    }
    if (parseInt(x) >= STATIC_COLUMN_NUM && parseInt(y) === 0) {
      return emptyUpdateOption.concat(meeting_names)
    }
    return emptyUpdateOption.concat(account_names)
  }

  async function handleUpdateCell(obj, cell, val) {
    const _headers = jRef.current.jspreadsheet.getHeaders().split(',')
    const _data = jRef.current.jspreadsheet.getData()

    const response = await new HttpClient().post('/shift_sheets', {
      date: dateRef.current.param(),
      data: [_headers].concat(_data)
    });
    console.log('response', response)
    if (response.error) {
      alert(response.error);
    }
  }

  const setHeaderWeekDayColor = () => {
    const tr = jRef.current.jspreadsheet.table.querySelector('thead > tr');
    [...Array(tr.children.length)].forEach(function(e, index) {
      tr.children[index].style.color = getWeekdayColor(tr.children[index].innerHTML)
    })
  }

  const modifyColumnNumberByMonthDays = () => {
    const thisMonthDays = dateRef.current.lastDateOfMonth
    const _headers = jRef.current.jspreadsheet.getHeaders().split(',')
    const _lastDays = _headers.length - STATIC_COLUMN_NUM // 変更する前の月の日数（先月とは限らない）

    if (_lastDays > thisMonthDays) {
      [...Array(_lastDays - thisMonthDays)].forEach(function(a, index) {
        let _num = _headers.length - index - 1
        jRef.current.jspreadsheet.deleteColumn(_num)
      })
    }
    if (_lastDays < thisMonthDays) {
      [...Array(thisMonthDays - _lastDays)].forEach(function(a, index) {
        var prop = [{title: '', width: 100, type:'dropdown', source: isToucheez ? account_names.concat(meeting_names) : account_names, filter: dropdownFilter, autocomplete: true, readOnly: false}];
        jRef.current.jspreadsheet.insertColumn(0, undefined, false, prop)
      })
    }
  }

  function load(passDate) {
    fetch('/shift_sheets/load?date=' + passDate.param())
      .then(res => {
        return res.json();
      })
      .then(res => {
        modifyColumnNumberByMonthDays()
        jRef.current.jspreadsheet.setData(res.data.slice(1))
        // header表示更新
        res.data[0].forEach(function (day, index) {
          if (index > 3) {
            jRef.current.jspreadsheet.setHeader(index, day)
          }
        });
        setHeaderWeekDayColor()
        setShiftMemo(res.memo)
      })
  }

  // 日数分
  let days = propData[0].length - STATIC_COLUMN_NUM // 日数

  // 勤務シフト 始業時間 終業時間 運営予定
  const staticColumns = [
    {
      readOnly: true,
      type: 'text',
      width: 200,
      source: undefined
    },
    {
      readOnly: true,
      type: 'text',
      width: 100,
      source: undefined
    },
    {
      readOnly: true,
      type: 'text',
      width: 100,
      source: undefined
    },
    {
      readOnly: true,
      type: 'text',
      width: 100,
      source: undefined
    },
  ]

  function getWeekdayColor(date) {
    // console.log('date', date)
    if (!date) return "";
    if (date.includes("土")) return "blue";
    if (date.includes("日")) return "red";
    return "";
  }

  let ops = [];
  [...Array(days)].forEach(function (a, index) {
    ops.push({
      type: 'dropdown',
      source: isToucheez ? account_names.concat(meeting_names) : account_names, // FIXME: アカウントの名前と定例の名前両方入れないといけない
      filter: dropdownFilter,
      autocomplete:true,
      readOnly: false,
      width: 100,
      title: propData[0][index + STATIC_COLUMN_NUM]
    })
  })


  const defaultOptions = {
    data: initialData,
    colHeaders: colHeader,
    tableOverflow: true,
    columns: staticColumns.concat(ops),
    freezeColumns: 1,
    columnSorting: false,
    contextMenu: false,
    includeHeadersOnDownload: true,
    onload: function () {
      setHeaderWeekDayColor()
    },
    onchange: handleUpdateCell,
    updateTable: function (el, cell, x, y, source, value, id) {
      el.style.width = '100%'
      el.querySelector('.jexcel_content').style.width = '100%'
      if (x < STATIC_COLUMN_NUM) {
        cell.style.color = 'black'
      }
      // 実績はreadonly
      if (!meeting_names) {
        if (y > 0 && y % 2 !== 0) {
          cell.classList.add('readonly')
          cell.classList.remove('jexcel_dropdown')
        }
      } else {
        if (y > 1 && y % 2 === 0) {
          cell.classList.add('readonly')
          cell.classList.remove('jexcel_dropdown')
        }
      }
      // 過去の日付はreadonly（月単位になっている）
      if (dateRef.current.isPast()) {
        cell.classList.add('readonly')
        cell.classList.remove('jexcel_dropdown')
      }
    }
  }

  useEffect(() => {
    if (!jRef.current.jspreadsheet) {
      jspreadsheet(jRef.current, defaultOptions);
      jRef.current.jspreadsheet.hideIndex();
    }
  }, []);

  function onPreview() {
    // jRef.current.jspreadsheet.download()
    html2canvas(document.querySelector(".jexcel_container"), {
      scrollX: 0,
      scrollY: -window.scrollY
    }).then(canvas => {
      const link = document.createElement('a');
      link.download = 'シフト.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  }

  function onRefresh(passDate) {
    dateRef.current = passDate
    setDisplayDate(passDate)
    load(passDate)
  }

  function onBlurMemo(e) {
    new HttpClient().post('/shift_sheets/create_memo', {memo: e.target.value, date: `${dateRef.current.param()}`});
  }

  // only e2e
  function onTestSave() {
    const _headers = jRef.current.jspreadsheet.getHeaders().split(',')
    const _data = jRef.current.jspreadsheet.getData()
    new HttpClient().post('/shift_sheets', {date: `${new DateDecorator(new Date()).param()}`, data: [_headers].concat(_data)});
  }

  // only e2e
  function onTestLoad() {
    fetch('/shift_sheets/testload?date=' + dateRef.current.param())
      .then(res=> { return res.json(); })
      .then(res => {
        jRef.current.jspreadsheet.setData(res.data.slice(1, 6))
      });
  }

  return (
    <div>
      <div className="attendance-record-heading-area">
        <div className="title-area">
          <div className="title-area_pagination">
            <div className="pagination_calendar">
              <button className="button button_action w-75" onClick={() => onRefresh(dateRef.current.makePrev())}>
                <span className="material-icons">chevron_left</span>
                <span>前月</span>
              </button>
              <div className="datepicker pagination_calendar_select">
                <span className="form-text datetime">
                  <DatePicker
                    selected={dateRef.current.date}
                    onChange={(selected) => onRefresh(new DateDecorator(selected))}
                    dateFormat="yyyy-MM"
                    id='month-select'
                    showMonthYearPicker
                    locale="ja"
                  />
                </span>
              </div>
              <button className="button button_action w-75" onClick={() => onRefresh(dateRef.current.makeNext())}>
                <span>翌月</span>
                <span className="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
        {!isProduction &&
          <div className="control-area">
            <div className="control-area_section">
              <div className="control-area_section_article">
                <span className="button button_action" onClick={() => onTestSave()}>
                  <span className="material-icons">save</span>
                  <span>先月のシフトを保存 for Test</span>
                </span>
              </div>
              <div className="control-area_section_article">
                <span className="button button_action" onClick={() => onTestLoad()}>
                  <span className="material-icons">refresh</span>
                  <span>シフトを更新 for Test</span>
                </span>
              </div>
            </div>
          </div>
        }
        <div className="control-area">
          <div className="control-area_section">
            <div className="control-area_section_article">
            <span className="button button_action" onClick={() => onPreview()}>
              <span className="material-icons">save_alt</span>
              <span>印刷プレビュー</span>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        {
          isToucheez &&
            <div className="card_section">
              <h2>メモ</h2>
              <textarea id="memo" className="form-text form-text_small" value={shiftMemo}
                        onChange={(e) => setShiftMemo(e.target.value)} onBlur={(e) => onBlurMemo(e)}/>
            </div>
        }
        <div className="card_section">
          <h2>シフト</h2>
          <div ref={jRef}/>
          <br/>
        </div>
      </div>
    </div>
  );
}

export default ShiftExcel
