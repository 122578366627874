import React, {useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import MonthPicker from '../MonthPicker'
import Axios from '../Axios';

const DownloadDailySales = ({message, icon}) => {
  const [downloadDate, setDownloadDate] = useState(new Date());
  return (
    <React.StrictMode>
    <div className="control-area" style={{ float: 'right' }}>
      <div className="control-area_section">
        <div className="control-area_section_article">
          <MonthPicker onClick={setDownloadDate}/>
        </div>
        <div className="control-area_section_article" onClick={() => getDailySalesDetailsData(downloadDate)}>
          <div className="download_btn button row-area row-area_small" id="download_daily_details_data">
            <span className="material-icons">{icon}</span>
            <span >{message[0]}</span>
          </div>
        </div>
        <div className="control-area_section_article" onClick={() => getDailySalesData(downloadDate)}>
          <div className="download_btn button row-area row-area_small" id="download_daily_data">
            <span className="material-icons">{icon}</span>
            <span >{message[1]}</span>
          </div>
        </div>
      </div>
    </div>
    </React.StrictMode>
  )
}

const getDailySalesDetailsData = (downloadDate) => {
  Axios
  .post('/dandon/client/analyses/daily_result_details_download', {search_month: downloadDate})
  .then(response => {
    let window_b;
    window_b = window.open(response.data.path);
  })
  .catch(error => {
    console.error(error);
  });
}

const getDailySalesData = (downloadDate) => {
  Axios
  .post('/dandon/client/analyses/daily_result_download', {search_month: downloadDate})
  .then(response => {
    console.log(response.data.path)
    let window_b;
    window_b = window.open(response.data.path);
  })
  .catch(error => {
    console.error(error);
  });
}
export default DownloadDailySales
