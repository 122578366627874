import React, {useState, useEffect} from 'react';
import Select from '../Select';
import Axios from '../Axios';
import Alert from "../Alert";

const ExerciseConfirmationIndex = ({courses}) => {
  const [students, setStudents] = useState([])
  const [courseLessons, setCourseLessons] = useState([]);
  const [courseLessonOptions, setCourseLessonOptions] = useState([]);
  const [exerciseTypeOptions, setExerciseTypeOptions] = useState([])
  const [lesson, setLesson] = useState('')
  const [exerciseResults, setExerciseResults] = useState([])
  const [courseId, setCourseId] = useState('');
  const [courseLessonId, setCourseLessonId] = useState('');
  const [exerciseId, setExerciseId] = useState('');
  const [exerciseTypeId, setExerciseTypeId] = useState('');
  const [message, setMessage] = useState();

  // コース変更されたら授業の選択肢をセットする
  useEffect(() => {
    if (!courseLessons) return

    const optionCourseLessons = courseLessons.map((course_lesson) => {
      return {label: course_lesson.lesson.name, value: course_lesson.id}
    });
    setCourseLessonOptions(optionCourseLessons);
  }, [courseLessons]);

  // 授業が変更されたら対象の授業をセットし、授業に紐づく課題タイプ選択肢をセットする
  useEffect(() => {
    if (!courseLessons || !courseLessons.length) return
    let lesson = '';
    if(courseLessonId) {
      lesson = courseLessons.find((courseLesson) => courseLesson.id === courseLessonId).lesson;
    }
    setLesson(lesson);

    let options = []
    if (lesson.small_test) options.push({label: '小テスト', value: lesson.small_test.tech_exercise_type_enum_id})
    if (lesson.homework) options.push({label: '宿題', value: lesson.homework.tech_exercise_type_enum_id})
    setExerciseTypeOptions(options)
  }, [courseLessonId]);

  const handleCourse = (selected) => {
    if(!selected) {
      setCourseId('')
      return;
    }
    if (selected.value !== courseId) {
      setCourseLessons([])
      setCourseLessonId('')
      setExerciseTypeOptions([])
      setExerciseTypeId('')
      setExerciseResults([])
      setCourseId(selected.value)
    }

    Axios.get(`/tech/courses/${selected.value}/paid_course_applications`)
      .then(({data}) => {
        setStudents(data.map((course_application) => {
          return course_application.account
        }));
      }).catch(err => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });

    Axios.get(`/tech/courses/${selected.value}/course_lessons`)
      .then(({data}) => {
        setCourseLessons(data);
      }).catch(err => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleCourseLesson = (selected) => {
    if(!selected) {
      setCourseLessonId('')
      return;
    }
    if (selected.value !== courseLessonId) {
      setExerciseTypeId('')
      setExerciseTypeOptions([])
      setExerciseResults([])
      setCourseLessonId(selected.value);
    }
  }

  const handleExerciseType = (selected) => {
    setExerciseResults([])
    if(!selected) {
      setExerciseTypeId('')
      return;
    }
    setExerciseTypeId(selected.value);

    const targetExerciseId = (selected.value === lesson.small_test?.tech_exercise_type_enum_id)
      ? lesson.small_test_id
      : lesson.homework_id;
    setExerciseId(targetExerciseId);
    if (!targetExerciseId) return;

    getExerciseResults(targetExerciseId, selected.value);
  }

  const getExerciseResults = (targetExerciseId, targetExerciseTypeId) => {
    Axios.get(`/tech/exercises/${targetExerciseId}/exercise_results/exercise_results_by_exercise_type?tech_course_lesson_id=${courseLessonId}&tech_exercise_type_enum_id=${targetExerciseTypeId}`)
      .then(({data}) => {
        setExerciseResults(data);
      }).catch(err => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleFix = () => {
    const isOk = window.confirm('採点を確定してよろしいですか？');
    if (!isOk) return;

    const params = exerciseResults.map((result) => {
      return result.id
    });
    Axios.post(`/tech/exercises/${exerciseId}/exercise_results/update_results_to_fixed`, {tech_exercise_result_ids: params})
      .then(({data}) => {
        // 課題結果再取得
        getExerciseResults(exerciseId, exerciseTypeId);
        setMessage(data);
      }).catch(err => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const getResultByStudentId = (studentId) => {
    const result = exerciseResults.find((result) => {
      return result.account_id === studentId
    })
    return result && result
  }

  const isSubmittedExercise = (studentId) => {
    const result = getResultByStudentId(studentId)
    return result !== undefined
  }

  const isSubmittedAllExercise = () => {
    return exerciseResults.length === students.length
  }

  const getScore = ((studentId) => {
    const result = getResultByStudentId(studentId)
    return result && result.score
  })

  const removeMessage = () => {
    setMessage('')
  }

  return(
    <React.StrictMode>
    <>
      {message && (
        <Alert
          message={message}
          onClose={removeMessage}
          timeout={3000}
        />
      )}
      <div className="section-area">
        <div className="card">
          <div className="control-area">
            <div className="control-area_section control-area_section_stretch_wrap">
              <div className="control-area_section_article control-area_section_article_stretch">
                <div className="control-area control-area_stretch">
                  <div className="control-area_section control-area_section_stretch">
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>コース</label>
                      </div>
                      <Select
                        id="course"
                        options={courses}
                        name="course"
                        value={courseId}
                        onChange={handleCourse}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>授業</label>
                      </div>
                      <Select
                        id="course_lesson"
                        options={courseLessonOptions}
                        name="course_lesson"
                        value={courseLessonId}
                        onChange={handleCourseLesson}
                      />
                    </div>
                    <div className="control-area_section_article">
                      <div className="control-area_section_article_title">
                        <label>タイプ</label>
                      </div>
                      <Select
                        id="exercise_type"
                        options={exerciseTypeOptions}
                        name="exercise_type"
                        value={exerciseTypeId}
                        onChange={handleExerciseType}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exerciseTypeId && students.length === 0 && (
        <div className="section-area">
          <table className="table">
            <thead>
            </thead>
            <tbody className="ta_center">
            <tr>
              <td>対象が0件です</td>
            </tr>
            </tbody>
          </table>
        </div>
      )}
      {exerciseTypeId && students.length > 0 && (
        <div className="card">
          <div className="heading-area">
            <div/>
            <div className="control-area">
              <div className="section-area">
                <div className="control-area">
                  <div className="control-area_section">
                    <div className="control-area_section_article">
                      <button className="button" onClick={handleFix} disabled={!isSubmittedAllExercise()}>
                        採点を確定
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-area">
            <table className="table">
              <thead>
              <tr>
                <th>学生</th>
                <th>提出状況</th>
                <th>点数</th>
                <th>採点確定状況</th>
                <th/>
              </tr>
              </thead>
              <tbody className="ta_center">
              {students.map((student) => {
                return (
                  <tr key={student.id}>
                    <td className='va_middle'>{student.name}</td>
                    <td className='va_middle'>{isSubmittedExercise(student.id) ? '提出済' : '未提出'}</td>
                    <td className='va_middle'>{getScore(student.id)}</td>
                    <td className='va_middle'>{getResultByStudentId(student.id)?.fixed ? '確定済' : '未確定'}</td>
                    <td className='va_middle'>{getResultByStudentId(student.id) && (
                      <div className='button-area'>
                        <a
                          href={`/tech/exercises/${exerciseId}/exercise_results/${getResultByStudentId(student.id).id}`}
                          className="button row-area row-area_small">
                          確認
                        </a>
                      </div>)
                    }</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
    </React.StrictMode>
  )
}

export default ExerciseConfirmationIndex;
