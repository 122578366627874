import axios from 'axios';

const csrfToken = document.querySelector('meta[name=csrf-token]') == null ?
  '' : document.querySelector('meta[name=csrf-token]').content;

const Axios = axios.create({
  headers: {'X-CSRF-Token': csrfToken},
});

export default Axios;
