import styled, { css } from "styled-components"

const Button = styled.div`
  width: ${props => props.width || "100%"};
  height: ${props => props.height || "100%"};
  background-color: ${props => props.backColor || "blue"};
  color: ${props => props.color || "ffffff"};
  border-radius 5px;
  border: ${props => props.border || "none"};
  ${props => !props.disabled && css`
    &:hover {
      cursor: pointer;
      color: ${props => props.hoverColor || "ffffff"};
      background-color: ${props => props.hoverBackColor || "#163A67"} !important;
    }
  `}
`
export default Button