import React, {useState, useEffect} from 'react';
import Select from '../../Select';
import Axios from '../../Axios';
import DownloadButton from "../../DownloadButton";

const RetestGradeTable = ({departments}) => {
  const [students, setStudents] = useState([]);
  const [fields, setFields] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const STATIC_DATA = {
    "fields": [
      {
        "id": 1,
        "name": "一般教養",
        "number_of_subjects": 3
      },
      {
        "id": 2,
        "name": "専門基礎分野",
        "number_of_subjects": 1
      }
    ],
    "subjects": [
      {
        "id": 1,
        "name": " 心理学",
        "semester_hours": 20,
        "need_additional_test": true
      },
      {
        "id": 2,
        "name": "コミュニケーション論",
        "semester_hours": 20,
        "need_additional_test": true
      },
      {
        "id": 3,
        "name": "社会学",
        "semester_hours": 20
      },
      {
        "id": 4,
        "name": "看護基礎論",
        "semester_hours": 30
      }
    ],
    "students": [
      {
        "id": 1,
        "student_number": "1",
        "name": "山田花子",
        "subject_grade": [
          {
            "score": "",
            "evaluation": ""
          },
          {
            "score": "",
            "evaluation": ""
          },
          {
            "score": 55,
            "evaluation": "D"
          },
          {
            "score": "",
            "evaluation": ""
          }
        ]
      },
      {
        "id": 2,
        "student_number": "2",
        "name": "山田太郎",
        "subject_grade": [
          {
            "score": 59,
            "evaluation": "D"
          },
          {
            "score": "",
            "evaluation": ""
          },
          {
            "score": "",
            "evaluation": ""
          },
          {
            "score": 89,
            "evaluation": "C"
          }
        ]
      }
    ]
  }

  useEffect(() => {
    setFields(STATIC_DATA.fields);
    setSubjects(STATIC_DATA.subjects);
    setStudents(STATIC_DATA.students);
  }, [])

  const isPassed = score => {
    if (!score) {
      return true
    }
    return score >= 60;
  }

  return (
    <>
      <div className="heading-area">
        <div/>
        <div>
          <DownloadButton message='PDF一括ダウンロード' icon='cloud_download' path='grades/download'/>
        </div>
      </div>
      <div className="section-area">
        <table className="table">
          <thead>
          <tr>
            <th/>
            <th/>
            <th>分野</th>
            {fields.map((field) => {
              return <th key={field.id} colSpan={field.number_of_subjects * 2}>{field.name}</th>
            })}
          </tr>
          <tr>
            <th/>
            <th/>
            <th>科目</th>
            {subjects.map((subject) => {
              return (
                <th key={subject.id} colSpan='2'>
                  {subject.name}
                  {/*{subject.need_additional_test && (*/}
                  {/*  <button className='button button_small'>再試験を登録</button>*/}
                  {/*)}*/}
                </th>
              )
            })}
          </tr>
          <tr>
            <th/>
            <th>学籍番号</th>
            <th>規定時間数</th>
            {subjects.map((subject) => {
              return <th key={subject.id} colSpan='2'>{subject.semester_hours}</th>
            })}
          </tr>
          <tr>
            <th>PDF出力</th>
            <th/>
            <th/>
            {subjects.map((subject) => {
              return (
                <React.Fragment key={subject.id}>
                  <th>点数</th>
                  <th>判定</th>
                </React.Fragment>
              )
            })}
          </tr>
          </thead>
          <tbody className="ta_center">
          {students &&
          students.map((s) => (
            <tr key={s.id}>
              <td className="">
                <input type="checkbox" style={{display: "inline-block"}}/>
              </td>
              <td className="va_middle">{s.student_number}</td>
              <td className="va_middle">{s.name}</td>
              {s.subject_grade.map((grade, index) => {
                return (
                  <React.Fragment key={Number(String(s.id) + String(index))}>
                    {/*<td className="va_middle" className={isPassed(grade.score) ? '' : 'failure'}>{grade.score}</td>*/}
                    <td className="va_middle">{grade.score}</td>
                    <td className="va_middle">{grade.evaluation}</td>
                  </React.Fragment>
                )
              })}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default RetestGradeTable;
