import React from 'react'
import ApplierInformation from './ApplierInformation';

function SelfApplicationDescription({course, tech_student}) {
  return (
    <>
      <ApplierInformation course={course} tech_student={tech_student}/>
    </>
  )
}

export default SelfApplicationDescription