import React from 'react'
import Badge from './Badge';

function RecruitingStatusBadge({course}) {
  const RecruitingBadgeStatusClass = (course) => {
    if (course.recruiting_status === '募集停止' || course.recruiting_status === '募集終了' || course.recruiting_status === '未公開' || course.recruiting_status === '終了') {
      return 'badge_status badge_status_nonactive';
    }else if (course.recruiting_status === '空席待ち') {
      return 'badge_status badge_status_pending';
    }else if (course.recruiting_status === '優先申込受付中') {
      return 'badge_status badge_status_important';
    }else {
      return 'badge_status badge_status_active';
    }
  }
  return (
    <Badge className={RecruitingBadgeStatusClass(course)}>
      {course.recruiting_status}
    </Badge>
  )
}

export default RecruitingStatusBadge