import React, {useState} from 'react';

const WideTextField = ({ name, value, placeholder }) => {
  const [selectedValue, setSelectedValue] = useState(value)

  return (
    <input 
      type="text"
      name={name}
      value={selectedValue}
      onChange={(e) => setSelectedValue(e.target.value)}
      style={{
        height: "48px",
        border: "1px",
      }}
      placeholder={placeholder}
    />
  );
};

export default WideTextField