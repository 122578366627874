export default class ExerciseResultData {
  constructor(data) {
    this.data = data;
  }

  allAccountIds(){
    const accountIds = this.data.map(d => d.account.id)
    return accountIds.filter((x, i, self) => self.indexOf(x) === i) // 重複するIDを削除
  }

  highScoreHistogram(){
    const ret = []
    // アカウントごとにハイスコアをグループ化
    this.allAccountIds()
      .map(id => {
        const accountData = this.data.filter(d => d.account.id === id)
        const highScore = Math.max(...accountData.map((d) => d.score))
        ret.push({
          accountId: id,
          accountName: accountData[0].account.name,
          highScore: highScore, //最高得点
          trialTime: accountData.map(a => a.score).indexOf(highScore) + 1, // 最高得点を取得した最初の試行回
        })
      })
    return ret
  }



  labels(){
    if (this.data.length === 0) {
      return []
    }
    const max = this.data[0].exercise.total_score
    let labels = [...Array(Math.floor(max / 10) + 1)].map((_, i) => i * 10)
    if (max !== labels[labels.length - 1]) {
      labels.push(max)
    }
    return labels
  }

  dataset(){
    if (this.data.length === 0) {
      return []
    }
    const max = this.data[0].exercise.total_score
    
    return this.labels().map((l, idx) => {
      if(l === max) {
        return this.highScoreHistogram().filter(d => d.highScore === max).length
      }else {
        return this.highScoreHistogram().filter(d => l - 10 < d.highScore && d.highScore <= l).length
      }
    })
  }

  tooltipLabels(labelText){
    if (this.data.length === 0) {
      return []
    }
    const max = this.data[0].exercise.total_score
    const targetScore = Number(labelText)
    return (
      targetScore === max ? 
        this.highScoreHistogram().filter(d => d.highScore === max).map(a => `${a.accountName} (${a.trialTime})`) :
        this.highScoreHistogram().filter(d => targetScore - 10 < d.highScore && d.highScore <= targetScore).map(a => `${a.accountName} (${a.trialTime})`)
    )
  }
}