import styled from "styled-components"

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: ${props => props.minWidth || "0"};
  min-height: ${props => props.minHeight || "0"};
  word-wrap: break-word;
  background-color: ${props => props.backColor || "#fff"};
  background-clip: border-box;
  padding: ${props => props.padding || "1rem"};
  margin: ${props => props.margin || "0"};
  border: ${props => props.border || "1px solid #D7D7D7"};
  border-radius: ${props => props.borderRadius || "0"};
  max-height: ${props => props.maxHeight || "100%"};
  max-width: ${props => props.maxWidth || "100%"};
  height: ${props => props.height || "auto"};
  width: ${props => props.width || "auto"};
  cursor: ${props => props.cursor || "deffault"};
`
export default Card