import React, {useEffect} from 'react'
import { useWatchValue } from './hooks/WatchValue';

export default function MathJaxTextView ({target}) {
  const debouncedInputText = useWatchValue(target, 500);
  useEffect(() => {
    if(typeof window?.MathJax !== "undefined"){
      // デフォルトだとレンダー時の文字列しか変換されないので、明示的に変換処理を呼ぶ
      window.MathJax.typesetPromise();
    }
  }, [debouncedInputText]);

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = "MathJax-script";
    scriptTag.async = true;
    scriptTag.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"
    head.appendChild(scriptTag);
  }, [])

  return (
    <>
      {target.split("\n").map((item, idx) => <div key={Math.random().toString(32).substring(2)}>{item}<br/></div>)}
    </>
  )
}