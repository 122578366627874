import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: ${props => props.content || "center"};
  margin: ${props => props.margin || 0};
  padding: ${props => props.padding || 0};
  align-items: center;
  text-align: center;
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
`
export default Container