import React, { useState } from 'react';
import Card from '../../../../atoms/Card';
import Width from '../../../../atoms/Width';
import Space from '../../../../atoms/Space';
import Span from '../../../../atoms/Span';
import FlexBox from '../../../../atoms/FlexBox';
import Badge from '../../../../atoms/Badge';
import TextField from '../../../../form/TextField';
import Axios from '../../../../../lib/axios';

function ApplicantInformationCard({ applicant }) {
  const [errMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState({ email: applicant.email });
  const [processing, setProcessing] = useState(false);

  const submitHandler = () => {
    if (processing) {
      return;
    }
    setProcessing(true);
    Axios.put(`/tech/user/course_applications/${applicant.application_id}`, { ...userData })
      .then((res) => {
        const urlObj = new URL(window.location.href);
        urlObj.search = '';
        location.href = `${urlObj.toString()}?snack=${res.data.message}`;
      })
      .catch((e) => {
        setErrorMessage(e.response.data.error_message);
        setProcessing(false);
      });
  };

  return (
    <Card padding="2rem">
      <Space margin="0 0 2rem 0">
        <FlexBox>
          <Space margin="0 1rem 0 0">
            <Span fontSize="16px">参加者</Span>
          </Space>
          {applicant.email !== '' &&
            (applicant.participant_status == '参加済' ? (
              <Badge border="none" color="#429992" backColor="#a4f4a5">
                <Span fontSize="12px">{applicant.participant_status}</Span>
              </Badge>
            ) : (
              <Badge border="none" color="#C53939" backColor="#FFE5E5">
                <FlexBox content="center" align="center">
                  <Span fontSize="12px">{applicant.participant_status}</Span>
                </FlexBox>
              </Badge>
            ))}
        </FlexBox>
      </Space>

      <Space margin="0 0 1rem 0">
        <FlexBox>
          <Span fontSize="12px">メールアドレス</Span>
        </FlexBox>
        <FlexBox>
          <Width>
            <div className={'form-text'}>
              <input
                placeholder={'参加者のメールアドレスを入力してください'}
                type="text"
                value={userData.email}
                onChange={(e) => setUserData((prev) => ({ ...prev, email: e.target.value }))}
              />
              {errMessage && <span className={'error_message'}>{errMessage}</span>}
            </div>
          </Width>
        </FlexBox>
      </Space>
      {applicant.name !== '' && (
        <Space margin="0 0 1rem 0">
          <FlexBox>
            <Span fontSize="12px">氏名</Span>
          </FlexBox>
          <FlexBox>
            <Space margin="0 0 0 2rem">
              <Span fontSize="14px">{applicant.is_initialized_account ? applicant.name : '未設定'}</Span>
            </Space>
          </FlexBox>
        </Space>
      )}
      {processing ? (
        <Space margin="2rem">
          <FlexBox content="center" align="center">
            <Width size="40%">
              <span className="button button_conversion button_conversion_disable">送信中...</span>
            </Width>
          </FlexBox>
        </Space>
      ) : (
        <Space margin="2rem">
          <FlexBox content="center" align="center">
            <Width size="40%">
              <a className="button" onClick={submitHandler}>
                <span>参加者を変更</span>
              </a>
            </Width>
          </FlexBox>
        </Space>
      )}
    </Card>
  );
}

export default ApplicantInformationCard;
