export const randomColorSet = (n) => {
  const ret = []
  for(let i=0; i<n; i++) {
    // 色はHue（色相）を変化させる。
    // Saturation（彩度）とLightness（輝度）は固定。
    const h = i*10 * (360 / 100);
    const s = 0.8;
    const l = 0.6;

    const h360 = h % 360; // hueは360度までに収める。
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const hp = h360 / 60;
    const x = c * (1 - Math.abs((hp % 2) - 1));

    let r1;
    let g1;
    let b1;
  
    if(hp >= 0 && hp <= 1) {
      r1 = c;
      g1 = x;
      b1 = 0;
    } else if(hp >= 1 && hp <= 2) {
      r1 = x;
      g1 = c;
      b1 = 0;
    } else if(hp >= 2 && hp <= 3) {
      r1 = 0;
      g1 = c;
      b1 = x;
    } else if(hp >= 3 && hp <= 4) {
      r1 = 0;
      g1 = x;
      b1 = c;
    } else if (hp >= 4 && hp <= 5) {
      r1 = x;
      g1 = 0;
      b1 = c;
    } else if (hp >= 5 && hp < 6) {
      r1 = c;
      g1 = 0;
      b1 = x;
    } else {
      r1 = 0;
      g1 = 0;
      b1 = 0;
    }

    const m = l - 0.5 * c;
    const r = r1 + m;
    const g = g1 + m;
    const b = b1 + m;

    // 上の計算で0.1から1.0までのRGB値が得られるので、255倍する。
    const r255 = Math.floor(r * 255);
    const g255 = Math.floor(g * 255);
    const b255 = Math.floor(b * 255);

    ret.push(`rgb(${r255},${g255},${b255})`)
  }
  return ret
}