import React, {useState} from 'react'
import {useField} from 'formik';
import StretchTextField from '../StretchTextField';
import Card from '../atoms/Card';
import MathJaxTextView from '../MathJaxTextView';
import FlexBox from '../atoms/FlexBox';
import Space from '../atoms/Space';

function QuestionChoiceForm({name}) {
  const [field, meta, helpers] = useField(name);
  const [height, setHeight] = useState("500px");
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <FlexBox>
      <Card
        width="50%"
        minHeight="10px"
        border="none"
        padding="0"
      >
        <Space margin="0 1rem 0 0">
        <StretchTextField
          deffaultValue={value}
          deffaultHeight="1rem"
          placeholder="入力してください"
          onChange={(value, height) => {setValue(value); setHeight(height)}}
        />
        </Space>
      </Card>

      <Card 
        width="50%"
        height={`${height}px`}
        minHeight="10px"
        border="1px solid #D7D7D7"
      >
        <MathJaxTextView target={value}/>
      </Card>
    </FlexBox>
  )
}

export default QuestionChoiceForm