import React, {useState} from 'react';
import NavigationTab from './atoms/NatigationTab'
import SectionArea from '../../atoms/SectionArea'
import ColArea from '../../atoms/ColArea'
import IndexCard from '../../atoms/IndexCard'
import UsersList from './atoms/UsersList'
import Axios from "../../Axios";
import Alert from '../../Alert';
import EventCard from "./atoms/EventCard";
import ModalView from "../../ModalView";

const ProfileButton = ({ user, event }) => (
  <ColArea>
    <span className="row-area">
      <span>
        <a className="button" href={`/baseq/admin/users/${user.baseq_user_id}?event_id=${event.id}`}>プロフィール</a>
      </span>
    </span>
  </ColArea>
);

const ButtonsForApplied = () => (
  <ColArea>
    <span className="row-area">
      <span>
        <a className="button" href="#">プロフィール</a>
      </span>
      <span>
        <a className="button" href="#">参加確定</a>
      </span>
      <span>
        <a className="button button_delete" href="#">
          <div className="round_btn"></div>
          参加却下
        </a>
      </span>
    </span>
  </ColArea>
);

const UsersListArea = ({loopKey, users, renderButtons, handleChecked, selectedApplicants, handleAllChecked, allSelected}) => (
  <SectionArea data-cy='申込者リスト'>
    { users.length === 0 && <div className='center'>対象は0件です</div>}
    { users.length > 0 &&
      <>
        <IndexCard>
          <ColArea customClass="col-area_xlarge">
            <div className='heading-area'>
              <div className='title-area'>
                <div>
                    <span className='select_all_checkbox'>
                      <input type='checkbox' checked={allSelected[loopKey]} onChange={handleAllChecked(loopKey)}/>
                    </span>
                  <span className='bold'>申込者を一括選択</span>
                </div>
              </div>
              {/*<div className='control-area'>*/}
              {/*  <div className='control-area_section'>*/}
              {/*    <div className='control-area_section_article'>*/}
              {/*      <div className='button row-area row-area_small'>*/}
              {/*        <span className='material-icons'>refresh</span>*/}
              {/*        <span>メールの送信</span>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </ColArea>
        </IndexCard>
        <UsersList
          loopKey={loopKey}
          users={users}
          renderButtons={renderButtons}
          handleChecked={handleChecked}
          selectedApplicants={selectedApplicants}
        />
      </>
      }
  </SectionArea>
)

const tabLabels = {
  applied: "未確定の申込者",
  confirmed: "確定済の申込者",
  canceled: "キャンセル済の申込者",
  rejected: "参加却下の申込者"
}

const EventUserIndex = ({event, applied_users, confirmed_users, canceled_users, rejected_users}) => {
  const [message, setMessage] = useState();
  const [activeTab, setActiveTab] = useState('applied');
  const [isOpenConfirmationModal, toggleOpenConfirmationModal] = useState(false);
  const [isOpenRejectionModal, toggleOpenRejectionModal] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState({
    applied: [],
    confirmed: [],
    canceled: [],
    rejected: [],
  });

  const [allSelected, setAllSelected] = useState({
    applied: false,
    confirmed: false,
    canceled: false,
    rejected: false,
  })

  const handleAllChecked = (loopKey) => {
    return (e) => {
      let applicants = [];
      switch (activeTab) {
        case 'applied':
          applicants = e.target.checked ? applied_users.map((ap) => ap.id) : [];
          break;
        case 'confirmed':
          applicants = e.target.checked ? confirmed_users.map((cu) => cu.id) : [];
          break;
        case 'canceled':
          applicants = e.target.checked ? canceled_users.map((cu) => cu.id) : [];
          break;
        case 'rejected':
          applicants = e.target.checked ? rejected_users.map((ru) => ru.id) : [];
          break;
      }
      setSelectedApplicants({...selectedApplicants, [loopKey]: applicants});
      setAllSelected({...allSelected, [loopKey]: e.target.checked});
    }
  }

  const handleChecked = (id, loopKey) => {
    return () => {
      const applicants = selectedApplicants[loopKey];
      if (applicants.includes(id)) {
        setSelectedApplicants({...selectedApplicants, [loopKey]: applicants.filter((selectedId) => selectedId !== id)})
      } else {
        applicants.push(id);
        setSelectedApplicants({...selectedApplicants, [loopKey]: applicants});
      }
    }
  }

  const handleConfirmation = () => {
    Axios.put(`/baseq/admin/events/${event.id}/event_users/confirm`, {ids: selectedApplicants[activeTab]})
      .then(({data}) => {
        window.location.href = data.path;
      }).catch(err => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const handleRejection = () => {
    Axios.put(`/baseq/admin/events/${event.id}/event_users/reject`, {ids: selectedApplicants[activeTab]})
      .then(({data}) => {
        window.location.href = data.path;
      }).catch(err => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const toggleConfirmationModal = () => toggleOpenConfirmationModal(true)
  const toggleRejectionModal = () => toggleOpenRejectionModal(true)

  return (
    <React.StrictMode>
      <div className='event_users'>
        {message && (
          <Alert
            message={message}
            onClose={() => setMessage('')}
            timeout={3000}
          />
        )}
        <SectionArea>
          <EventCard event={event}/>
        </SectionArea>
        <NavigationTab
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabLabels={tabLabels}
          tabContents={[
            <UsersListArea
              loopKey="applied"
              users={applied_users}
              renderButtons={(user) => <ProfileButton user={user} event={event}/>}
              selectedApplicants={selectedApplicants}
              handleChecked={handleChecked}
              handleAllChecked={handleAllChecked}
              allSelected={allSelected}
            />,
            <UsersListArea
              loopKey="confirmed"
              users={confirmed_users}
              renderButtons={(user) => <ProfileButton user={user} event={event}/>}
              selectedApplicants={selectedApplicants}
              handleChecked={handleChecked}
              handleAllChecked={handleAllChecked}
              allSelected={allSelected}
            />,
            <UsersListArea
              loopKey="canceled"
              users={canceled_users}
              renderButtons={(user) => <ProfileButton user={user} event={event}/>}
              selectedApplicants={selectedApplicants}
              handleChecked={handleChecked}
              handleAllChecked={handleAllChecked}
              allSelected={allSelected}
            />,
            <UsersListArea
              loopKey="rejected"
              users={rejected_users}
              renderButtons={(user) => <ProfileButton user={user} event={event}/>}
              selectedApplicants={selectedApplicants}
              handleChecked={handleChecked}
              handleAllChecked={handleAllChecked}
              allSelected={allSelected}
            />,
          ]}
        />
        {
          selectedApplicants[activeTab].length > 0 &&
          <div className='submit-button-area' data-cy='一括操作エリア'>
            <span className='item bold'>{`${selectedApplicants[activeTab].length}件選択`}</span>
            {/*<span className='item button'>メールの送信</span>*/}
            {activeTab === 'applied' &&
            <span className='item button' onClick={toggleConfirmationModal}>参加確定</span>
            }
            {activeTab !== 'rejected' && activeTab !== "canceled" &&
            <span className='item button button_delete' onClick={toggleRejectionModal}>
                <div className='round_btn'></div>
                <span>参加却下</span>
              </span>
            }
          </div>
        }
        {isOpenConfirmationModal && (
          <ModalView
            setModal={() => {
              toggleOpenConfirmationModal(false);
            }}
            link={'link'}
            title={'参加を確定します。よろしいですか？'}
            body={'参加を確定した場合、申込者にイベント参加の確定メールを送信します。'}
            confirmMessage={'確定する'}
            cancelMessage={'キャンセル'}
            handleSubmit={handleConfirmation}
          />
        )}
        {isOpenRejectionModal && (
          <ModalView
            setModal={() => {
              toggleOpenRejectionModal(false);
            }}
            link={'link'}
            title={'参加を却下します。よろしいですか？'}
            confirmMessage={'却下する'}
            cancelMessage={'キャンセル'}
            handleSubmit={handleRejection}
            isWarning={true}
          />
        )}
      </div>
    </React.StrictMode>
  );
}

export default EventUserIndex;