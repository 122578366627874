import React, {useEffect, memo} from 'react';
import DatePicker from 'react-datepicker';
import DateDecorator from '../DateDecorator';

const DateTimePeriod = memo(({
  label,
  required,
  fromKey,
  toKey,
  from,
  to,
  onChange,
  from_error_text,
  to_error_text,
  disabled
}) => {
  return (
    <>
      <tr className={(from_error_text || to_error_text) && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      <tr>
        <td className={"form-text"}>
          <div className={`row-area row-area_liquid`}>
            <span className={from_error_text && "error_border"}>
              <DatePicker
                selected={!!from ? new Date(from) : ''}
                onChange={(selected, e) => {
                  onChange(selected ? {target: {name: fromKey, value: new DateDecorator(selected).isoString()}} : '', e)
                }}
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
                disabled={disabled}
              />
            </span>
            <span className={"row-area_liquid_connect"}>
              〜
            </span>
            <span className={to_error_text && "error_border"}>
              <DatePicker
                selected={!!to ? new Date(to) : ''}
                onChange={(selected, e) => {
                  onChange(selected ? {target: {name: toKey, value: new DateDecorator(selected).isoString()}} : '', e)
                }}
                dateFormat="yyyy-MM-dd HH:mm"
                timeFormat="HH:mm"
                showTimeSelect
                disabled={disabled}
              />
            </span>
          </div>
        </td>
      </tr>
      {
        (from_error_text || to_error_text) &&
        (
          <tr className={'error_message'}>
            <td>
              <span>{from_error_text}</span>
              <br/>
              <span>{to_error_text}</span>
            </td>
          </tr>
        )
      }
    </>
  );
});

export default DateTimePeriod;
