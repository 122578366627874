import React, {useState} from 'react'
import SelfApplicationDescription from './SelfApplicationDescription';
import ProxyApplicationDescription from './ProxyApplicationDescription';
import SeatApplicationDescription from './SeatApplicationDescription';

function ApplicationDescription({course, tech_student, formType, postData, seatReservation}) {

  return (
    <>
      {formType === '参加者本人が申し込む' && <SelfApplicationDescription course={course} tech_student={tech_student}/>}
      {formType === '本人の代理で申し込む' && <ProxyApplicationDescription course={course} tech_student={tech_student} postData={postData}/>}
      {formType === '席のみ申し込む' && <SeatApplicationDescription course={course} tech_student={tech_student} postData={postData}/>}
    </>
  )
}

export default ApplicationDescription