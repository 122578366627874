import React, {useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {Formik} from 'formik';
import Axios from "./Axios";
import {formatDateSlash, isSameDate} from '../shared/date';
import Alert from "./Alert";

const MessagesBoard = ({room, sender, initialMessages, postable}) => {
  const [messages, setMessages] = useState(initialMessages);
  const [message, setMessage] = useState();
  const isFirstRender = useRef(true)
  const scrollBottomRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      Axios.get(`/rooms/${room.id}/messages/latest`)
        .then(({data}) => {
          setMessages(data.messages);
        }).catch((err) => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!(messages?.length)) return

    if (isFirstRender.current) {
      isFirstRender.current = false
      scrollLatestMessage();
    }

    Axios.patch(`/rooms/${room.id}/messages/update_last_read_at`, {
      last_read_at: messages[messages.length - 1].created_at
    }).then(() => {
    }).catch((err) => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    })
  }, [messages])

  const handleSend = (values, {resetForm}) => {
    if (!values.body.trim()) {
      return;
    }
    Axios.post(`/rooms/${room.id}/messages`, values)
      .then(({data}) => {
        setMessages(data.messages);
        resetForm();
        scrollLatestMessage();
      }).catch((err) => {
      setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
    });
  }

  const scrollLatestMessage = () => {
    if (scrollBottomRef && scrollBottomRef.current) {
      scrollBottomRef.current.scrollIntoView()
    }
  }

  const setOwner = messageSender => {
    if (sender === messageSender) {
      return 'self';
    }
    if (room.is_tech_inquiry && room.accounts.find(a => a.id === sender).tech_sales && room.accounts.find(a => a.id === messageSender).tech_sales) {
      return 'self'
    }
    return 'other'
  };

  const setName = (messageSender, messageSenderName) => {
    const account = room.accounts.find(a => a.id === messageSender);
    if (room.is_tech_inquiry && account.tech_sales) {
      return `Tech会運営事務局（${account.name}）`
    }
    if (room.is_tech_notification && account && account.tech_trainer) { // accountがない場合はシステムからの自動メッセージ
      return account.name
    }

    if (room.is_tech_notification && !account ) { // accountがない場合はシステムからの自動メッセージ
      return messageSenderName;
    }
    return ''
  }

  const shouldShowDate = (message, index) => {
    if (index === 0) {
      return true;
    }
    return !isSameDate(messages[index - 1].created_at, message.created_at);
  };

  return (
    <React.StrictMode>
    <div className="message-board">
      {message && (
        <Alert
          message={message}
          onClose={() => setMessage('')}
          timeout={5000}
        />
      )}
      <div className="card_content">
        <div className="card_content_section">
          <div className="card_content_section_article">
            <div className="card_in-card">
              <div className="card message-area">
                <div className="card_content">
                  {messages.map((message, index) => (
                    <div key={message.id} className="card_content_section">
                      <div className="card_content_section_article">
                        {shouldShowDate(message, index) && (
                          <div className="message-area_date">{formatDateSlash(message.created_at)}</div>
                        )}
                        <Message
                          senderName={setName(message.sender_id, message.sender_account_name)}
                          body={message.body}
                          time={message.created_at}
                          owner={setOwner(message.sender_id)}
                        />
                      </div>
                    </div>
                  ))}
                  {!messages || messages.length === 0 && <div className='ta_center'>メッセージはありません</div>}
                  <div ref={scrollBottomRef}/>
                </div>
              </div>
            </div>
          </div>
          <MessageInput onSubmit={handleSend} postable={postable}/>
        </div>
      </div>
    </div>
    </React.StrictMode>
  );
}

const Message = ({senderName, owner, body, time}) => {
  return (
    <div>
      <div className={`message-area_name message-area_name_${owner}`}>{senderName}</div>
      <div className={`message-area_item message-area_item_${owner}`}>
        <div dangerouslySetInnerHTML={{__html: body}} className="message-area_item_content"/>
        <div className="message-area_item_time">{moment(time).format('HH:mm')}</div>
      </div>
    </div>
  );
};

const MessageInput = ({onSubmit, postable}) => {
  if (postable === true) {
    return (
      <React.StrictMode>
      <div className="card_content_section_article">
        <div className="mobile-only_m_20_10">
          <Formik onSubmit={onSubmit} initialValues={{body: ''}}>
            {({handleSubmit, values, handleChange}) => (
              <form onSubmit={handleSubmit} className="col-area">
                <div className="form-text">
                  <textarea
                    name="body"
                    value={values.body}
                    onChange={handleChange}
                    placeholder="ここにメッセージを入力"
                    style={{height: '10rem'}}
                  />
                </div>
                <div className="button-area">
                  <button className="button button_nostretch" type="submit">
                    メッセージを送信
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      </React.StrictMode>
    );
  } else {
    return <div className='ta_center'>※ お知らせのため、読み取り専用です</div>;
  }
};

export default MessagesBoard;
