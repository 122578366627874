import React, {useState, useEffect} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'

function StudentAttendanceCalendar({ data }) {
  // const data =
  // [
  //   {
  //     title: '健康管理論',
  //     start: '2020-12-01 10:40',
  //     end: '2020-12-01 12:10'
  //   },
  //   {
  //     title: 'コミュニケーション論',
  //     start: '2020-12-02 09:00',
  //     end: '2020-12-02 10:30'
  //   },
  //   {
  //     title: '心理学',
  //     start: '2020-12-01 09:00',
  //     end: '2020-12-01 10:30'
  //   }
  // ]
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(data);
  }, []);

  function handleEventClick() {
    history.replaceState('','','/evide/grade/attendance_records/1/edit');
    window.location.reload();
  }

  function renderEventContent(eventInfo) {
    const startHour = eventInfo.event.start.getHours();
    const startMinute = eventInfo.event.start.getMinutes().toString().padStart(2, '0');
    const endHour = eventInfo.event.end.getHours();
    const endMinute = eventInfo.event.end.getMinutes().toString().padStart(2, '0');
    const startTime = `${startHour}:${startMinute}`
    const endTime = `${endHour}:${endMinute}`
    return (
      <div>
        <span>{eventInfo.event.title}</span>
        <p>{startTime} - {endTime}</p>
      </div>
    )
  }

  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      initialView="dayGridMonth"
      locale={"ja"}
      buttonText={
        {today: '本日'}
      }
      eventContent={renderEventContent}
      events={events}
      eventClick={handleEventClick}
    />
  )
}

export default StudentAttendanceCalendar;
