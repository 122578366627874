import React, { useRef, useState, useEffect } from 'react';
import { formatDateTimeSlash } from '../shared/date.ts';
import Axios from './Axios';

const UserMenuToast = ({ label, menus, toastClass }) => {
  const toastRef = useRef();
  const [show, setShow] = useState(false);
  const [parentNode, setParentNode] = useState(null);
  useEffect(() => {
    // リンクはコンポーネント外で生成されているので、その要素についてはjqueryで取得する
    document.addEventListener('click', documentClickHandler);
    setParentNode($('.user_menu_div').get()[0]);
    return () => {
      document.removeEventListener('click', documentClickHandler);
    };
  });

  const documentClickHandler = (e) => {
    if (toastRef.current && !toastRef.current.contains(e.target) && !parentNode.contains(e.target)) {
      if (show) {
        setShow(false);
      }
    } else {
      setShow(!show);
    }
  };

  return (
    <React.StrictMode>
      <div className="user_menu_div">
        <span className="d-none d-sm-inline">{label}</span>
        <span className="d-sm-none material-icons">settings</span>
      </div>
      <div className={`toast-container position-absolute ${toastClass}`}>
        <div
          className={`${show ? 'show' : ''} user-menu`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          ref={toastRef}
        >
          <div className="user-menu-body">
            {menus.map((m, idx) => (
              <div className="row justify-content-center h-100 user-menu-link" key={idx}>
                <div className="user_menu_child">
                  <a href={m.link} data-method={m.method} rel="nofollow">
                    {m.label}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default UserMenuToast;
