import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';

const New = () => {
  const [scanResult, setScanResult] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [account, setAccount] = useState('');
  const [course, setCourse] = useState('');
  const [corporation, setCorporation] = useState('');
  useEffect(() => {
    if (message.text) {
      const timer = setTimeout(() => {
        setMessage({ type: '', text: '' });
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleError = (err) => {
    console.error(err);
    setMessage({ type: 'error', text: '予期しないエラーが発生しました。再度読み込ませてください。' });
  };

  const handleScan = (result) => {
    if (!!result) {
      let parseResult;
      try {
        const decodedResult = atob(result);
        parseResult = JSON.parse(decodedResult);
      } catch (e) {
        console.log(e);
        setMessage({ type: 'error', text: '不正なQRコードです' });
        return;
      }
      if (
        !parseResult['account_id'] ||
        !parseResult['tech_course_lesson_id'] ||
        !(parseResult['type'] === 'tech_course_ticket')
      ) {
        setMessage({ type: 'error', text: '不正なQRコードです' });
        return;
      }

      fetch('/tech/attendances', {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tech_attendance: {
            tech_course_lesson_id: parseResult['tech_course_lesson_id'],
            attendance_attributes: {
              account_id: parseResult['account_id'],
            },
          },
        }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((error) => {
              throw new Error(error.message);
            });
          }
          return response.json();
        })
        .then((data) => {
          setAccount(data.account);
          setCourse(data.course);
          setCorporation(data.corporation);
          if (data.message) {
            setMessage({ type: 'warning', text: data.message });
          } else {
            setMessage({ type: 'success', text: '受付に成功しました' });
          }
        })
        .catch((error) => {
          setMessage({ type: 'error', text: error.message });
          console.error('Error:', error);
        });

      setScanResult(JSON.stringify(parseResult, null, 2));
    }
  };

  return (
    <div className={'qr-code'}>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <QrReader
          delay={1000}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
          facingMode="environment"
        />
        {message.text && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor:
                message.type === 'success' ? '#008000' : message.type === 'warning' ? '#FFA500' : '#f30606',
              color: 'white',
              borderRadius: '50%',
              padding: '20px',
              textAlign: 'center',
              width: '300px',
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '2rem',
            }}
          >
            {message.type === 'error' ? (
              <>
                受付に失敗しました
                <br />
                {message.text}
              </>
            ) : (
              <>{message.text}</>
            )}
          </div>
        )}

        <div style={{ fontSize: '2rem', textAlign: 'center', marginTop: '20px' }}>
          QRコードが枠内に収まるように
          <br />
          カメラの位置を調整してください
        </div>
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: '0',
          fontSize: '2rem',
          width: '100%',
          backgroundColor: 'white',
        }}
      >
        <p>参加者情報</p>
        <div style={{ backgroundColor: '#f0f0f0' }}>
          <p>会社名: {corporation}</p>
          <p>氏名: {account}</p>
          <p>コース名: {course}</p>
        </div>
      </div>
    </div>
  );
};

export default New;
