import {Moment} from "moment";

const moment = require('moment');

export const DateFormat = {
  BASE: 'YYYYMMDD',
  HYPHEN: 'YYYY-MM-DD',
  SLASH: 'YYYY/MM/DD',
  SLASH_WITH_WEEKDAY_AND_TIME: 'YYYY/MM/DD(ddd) HH:mm',
  KANJI: 'YYYY年MM月DD日',
  YEAR_MONTH_KANJI: 'YYYY年MM月'
}

export type DateFormat = typeof DateFormat[keyof typeof DateFormat];

// TODO 名前が分かりにくいため廃止予定
export function longDate(date: Date): string {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY年MM月DD日');
}

// TODO 名前が分かりにくいため廃止予定
export function longDateWithTime(date: Date): string {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY年MM月DD日HH:mm');
}
export function formatDateTimeSlash(date: Date): string {
  if (!date) {
    return '-';
  }
  return moment(date).format('YYYY/MM/DD HH:mm') + timeZoneLabel();
}

export function formatDate(date: Date): string {
  return moment(date).format('YYYY年MM月DD日');
}

export function formatDateSlash(date: Date): string {
  return moment(date).format('YYYY/MM/DD');
}

export function formatTimeSlash(date: Date): string {
  return `${moment(date).format('HH:mm')}`
}

export function formatDateHyphen(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

export function formatTimePeriod(from: Date, to: Date): string {
  return `${moment(from).format('HH:mm')} 〜 ${moment(to).format('HH:mm')}`
}

export function formatDatePeriod(from: Date, to: Date): string {
  return `${moment(from).format('YYYY年MM月DD日')}${timeZoneLabel()} 〜 ${moment(to).format('YYYY年MM月DD日')}${timeZoneLabel()}`
}

export function formatDatePeriodBySlash(from: Date, to: Date): string {
  return `${moment(from).format('YYYY/MM/DD')} 〜 ${moment(to).format('YYYY/MM/DD')}`
}

export function formatDateTimePeriodBySlash(from: Date, to: Date): string {
  return `${moment(from).format('YYYY/MM/DD')} ${moment(from).format('HH:mm')}${timeZoneLabel()} 〜 ${moment(to).format('YYYY/MM/DD')} ${moment(to).format('HH:mm')}${timeZoneLabel()}`
}

export function convertDateWithFormat(date: Date, format: string, locale= 'ja'): string {
  if(!format) return formatDateSlash(date)
  const originalLocale = moment.locale()
  moment.locale(locale)
  const formatDate = `${moment(date).format(format)}`
  moment.locale(originalLocale) // 元のlocaleに戻す
  return formatDate
}

export function formatDatePeriodWithFormat(from: Date, to: Date, format: string, locale = 'ja'): string {
  if(!format) return formatDatePeriod(from, to)
  return `${convertDateWithFormat(from, format, locale)} 〜 ${convertDateWithFormat(to, format, locale)}`
}

export function startOfDay(date: Date): Moment {
  return moment(date).startOf('days');
}

export function isSameDate(date: Date, comparedDate: Date): boolean {
  return startOfDay(date).diff(startOfDay(comparedDate)) === 0;
}


export function countdownText(today: Date, date: Date): string {
  const diff = startOfDay(date).diff(startOfDay(today), 'days');
  if (diff > 0) {
    return `あと${diff}日`;
  }
  return '本日';
}

export function elapsedDaysLabel(today: Date, targetDate: Date): string {
  const secondsDiff = moment(today).diff(moment(targetDate), "seconds");
  if (secondsDiff < TimeThreshold.IN_SOME_SECONDS) return '数秒前'
  if (secondsDiff < TimeThreshold.AROUND_ONE_MINUTE) return '約1分前'

  const minutesDiff = moment(today).diff(moment(targetDate), "minutes");
  if (minutesDiff < TimeThreshold.IN_MINUTES) return `${minutesDiff}分前`;

  const hoursDiff = moment(today).diff(moment(targetDate), "hours");
  if (hoursDiff < TimeThreshold.IN_HOURS) return `${hoursDiff}時間前`;

  const daysDiff = moment(today).diff(moment(targetDate), "days");
  if (daysDiff < TimeThreshold.IN_DAYS) return `${daysDiff}日前`;

  const weeksDiff = moment(today).diff(moment(targetDate), "weeks");
  if (weeksDiff <= TimeThreshold.IN_WEEKS) return `${weeksDiff}週間前`;

  const monthsDiff = moment(today).diff(moment(targetDate), "months");
  if (monthsDiff < TimeThreshold.IN_MONTHS) return `${monthsDiff}ヶ月前`;

  return `${(moment(today).diff(moment(targetDate), "years"))}年前`;
}

export function timeZoneLabel(): string {
  var str = new Date().toString();
  const tzStr = str.substring(str.indexOf("(")+1,str.indexOf(")"))
  if (tzStr === '日本標準時') { // 端末時間設定が日本でブラウザ言語設定が日本語
    return ''
  } else {
    const isEnglish = !tzStr.split('').some(char => char.charCodeAt(0) > 255)
    if (isEnglish) {
      return `(${tzStr.match(/[A-Z]/g).join('')})`
    } else {
      return `(${tzStr})` // 中国語等
    }
  }
}

const TimeThreshold: Readonly<Record<string, number>> = {
  IN_SOME_SECONDS: 10,
  AROUND_ONE_MINUTE: 120,
  IN_MINUTES: 60,
  IN_HOURS: 24,
  IN_DAYS: 7,
  IN_WEEKS: 4,
  IN_MONTHS: 12,
};
