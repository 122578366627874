import React, {useState} from 'react'
import TextField from './form/TextField';
import Space from './atoms/Space';
import TextArea from './form/TextArea';

function Form({faq, category_id, errors}) {
  const [question, setQuestion]= useState(faq.question || '')
  const [answer, setAnswer]= useState(faq.answer || '')
  const [faq_category_id, setFaqCategory_id]= useState(faq.faq_category_id || category_id)
  return (
    <>
      <table className="table_form">
        <tbody>
          <TextField
            label="質問"
            required
            value={question}
            placeholder="例）会員登録をしたいのですがどうすればいいですか？"
            nameKey="faq[question]"
            onChange={(e) => setQuestion(e.target.value)}
            errorMessage={errors?.question ? `質問${errors.question}` : ''}
            type="text"
          />
          <TextArea
            label="回答"
            required
            value={answer}
            placeholder={'例）画面右上の新規会員登録から作成することができます'}
            nameKey="faq[answer]"
            onChange={(e) => setAnswer(e.target.value)}
            errorMessage={errors?.answer ? `回答${errors.answer}` : ''}
          />
          <input type="hidden" name="faq[faq_category_id]" value={faq_category_id}/>
        </tbody>
      </table>
    </>
  )
}

export default Form