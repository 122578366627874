import React, {useState} from 'react'
import DragDropContext, {Draggable} from './DragDropContext'
import FlexBox from './atoms/FlexBox'
import SectionArea from './atoms/SectionArea'
import Card from './atoms/Card'
import Axios from '../lib/axios'
import Span from './atoms/Span'
import Space from './atoms/Space'
function FaqCategoryIndex({categories}) {
  const [viewCategories, setViewCategories] = useState(categories)

  const handleChangeOrder = (_categories) => {
    const copy = [...viewCategories]
    setViewCategories(_categories.map((c, idx) => ({...c, number: idx + 1})))
    Axios.put(`/tech/faq_categories/update_order`, { categories: _categories })
      .catch((err) => {
        setViewCategories(copy)
      })
  }

  const handleDelete = async (id) => {
    const isConfirmed = await confirm('削除するとカテゴリに紐づくFAQも一緒に削除されます。\n本当に削除してよろしいですか？',)
    if (!isConfirmed) {
      return
    }
    Axios.delete(`/tech/faq_categories/${id}`)
      .then((res) => {
        location.href = `/tech/faq_categories/`
      })
  }

  return (
    <SectionArea>
      <DragDropContext
        handleDragEnd={handleChangeOrder}
        targets={viewCategories}
      >
        {
          viewCategories.map((c, index) => (
            <Draggable target={c} index={index} key={c.id}>
              <Card>
                <FlexBox content="space-between">
                  <Space margin="0 0 0 2rem">
                    <Span fontWeight="bold" fontSize="14px">{c.name}</Span>
                  </Space>
                  <Space margin="0 2rem 0 0">
                    <div className="row-area mobile-only_button-area">
                      <span className="button" onClick={() => {location.href = `/tech/faqs?q[faq_category_id_eq]=${c.id}`}}>確認</span>
                      <span className="button" onClick={() => {location.href = `/tech/faq_categories/${c.id}/edit`}}>編集</span>
                      <span className="button button_delete" onClick={() => handleDelete(c.id)}>削除</span>
                    </div>
                  </Space>
                </FlexBox>
              </Card>
            </Draggable>
          ))
        }
      </DragDropContext>

    </SectionArea>
  )
}

export default FaqCategoryIndex