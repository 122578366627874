import React, {useState, useRef} from 'react';
import {Formik, Form, Field, FieldArray} from 'formik';
import Axios from '../../Axios';
import Alert from '../../Alert';
import FormColumn from '../../FormColumn';

const defaultValues = {
  name: '',
  is_published: false,
  questions: Array(1).fill({name: '', label: ''}),
}

const validate = values => {
  let errors = {}
  if (!values.name) {
    errors.name = '入力してください'
  } else if (values.name.length > 255) {
    errors.name = '２５５文字以下で入力してください'
  }
  return errors;
}

const QuestionForm = ({questionnaire, questions}) => {
  const [message, setMessage] = useState();
  const baseUrl = '/baseq/admin/master_values_questionnaires'
  const paramsKey = 'baseq_master_values_questionnaire'

  const publishOptions = [{name: false, label: "非公開"}, {name: true, label: "公開"}]

  const formValues = () => {
    return questionnaire.id ?
      {
        ...questionnaire,
        questions: questions.length > 0 ? questions : defaultValues.questions
      } :
      {...defaultValues}
  }
  const processing = useRef(false);

  const handleSubmit = values => {
    if (processing.current === true) return;
    if (questionnaire.id) {
      processing.current = true;
      Axios.put(`${baseUrl}/${questionnaire.id}`, {[paramsKey]: values})
        .then(({data}) => {
          setMessage({text: `更新しました`, type: 'notice'});
          window.location.href = baseUrl
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    } else {
      processing.current = true;
      Axios.post(baseUrl, {[paramsKey]: values})
        .then(({data}) => {
          setMessage({text: `登録しました`, type: 'notice'});
          window.location.href = baseUrl
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }
  }

  const questionNameValidation = (value) => {
    if (!value.trim()) {
      return '質問内容を入力してください'
    }
    if (value.length > 255) {
      return "質問内容を２５５文字以下で入力してください"
    }
    return undefined
  }
  const questionLabelValidation = (value) => {
    if (!value.trim()) {
      return '質問カテゴリを入力してください'
    }
    if (value.length > 255) {
      return "質問カテゴリを２５５文字以下で入力してください"
    }
    return undefined
  }

  const circleStyle = {
    display: "inline-block",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    textAlign: "center",
    lineHeight: "20px",
    color: "#fff",
    marginRight: "5px",
  };
  return (
    <React.StrictMode>
      <div className='values_questionnaire_form'>
        {message && (
          <Alert
            message={message}
            onClose={() => setMessage('')}
            timeout={3000}
          />
        )}
        <h3>{questionnaire.name}</h3>
        <div className='section-area'>
          <Formik
            enableReinitialize
            initialValues={formValues()}
            validate={validate}
            onSubmit={handleSubmit}
          >
            {({values, errors, setFieldValue}) => (
              <Form>
                <div className='main-side-area'>
                  <div className='main-side-area_main'>
                    <div className='card'>
                      <div className='card_section'>
                        <table className='table_form'>
                          <tbody>
                          <FormColumn label='価値観のタイトル' required>
                            <td className='form-text'>
                              <Field name="name" id="baseq_master_values_questionnaire_name"
                                     placeholder="例）大切にしている価値観"/>
                              <span className="red">{errors.name}</span>
                            </td>
                          </FormColumn>
                          <FormColumn label='公開設定' required>
                            <td className='form-radio'>
                              <div className="row-area">
                                {publishOptions.map((option) => {
                                  return (
                                    <span key={option.name}>
                                        <Field
                                          type={"radio"}
                                          name={`is_published`}
                                          id={`is_published_${option.name}`}
                                          value={option.name}
                                          checked={values.is_published === option.name}
                                          onChange={() => setFieldValue("is_published", option.name)}
                                        />
                                      <label>{option.label}</label>
                                    </span>
                                  )
                                })}
                              </div>
                            </td>
                          </FormColumn>
                          <tr>
                            <td colSpan={2}>
                              <h2>質問</h2>
                            </td>
                          </tr>
                          <FieldArray
                            name="questions"
                            render={(arrayHelpers) => {
                              return (
                                <>
                                  {
                                    values.questions.map((c, i) => {
                                      return (
                                        <>
                                          <FormColumn label='質問カテゴリ' required>
                                            <td className="form-text">
                                              <Field
                                                key={i}
                                                name={`questions.${i}.label`}
                                                placeholder="入力してください"
                                                validate={(value) => questionLabelValidation(value)}
                                              />
                                              <p className={'red'}>
                                                {(errors.questions && errors.questions[i]) ? errors.questions[i].label : ""}
                                              </p>
                                            </td>
                                          </FormColumn>
                                          <FormColumn label='質問内容' required>
                                            <td className="form-text question_form">
                                              <Field
                                                key={i}
                                                name={`questions.${i}.name`}
                                                placeholder="入力してください"
                                                validate={(value) => questionNameValidation(value)}
                                              />
                                              <p
                                                className={'red'}>{(errors.questions && errors.questions[i]) ? errors.questions[i].name : ""}
                                              </p>
                                            </td>
                                          </FormColumn>
                                          <tr>
                                            <th></th>
                                            <td>
                                              {i > 0 && !questionnaire.is_undeletable_answers &&
                                              <button
                                                type="button"
                                                id={`remove${i}`}
                                                style={{marginLeft: "1rem"}}
                                                onClick={() => arrayHelpers.remove(i)}
                                              >
                                                <span style={{...circleStyle, background: "crimson"}}>×</span>
                                                <span style={{color: "crimson"}}>質問を削除</span>
                                              </button>
                                              }
                                              {questionnaire.is_undeletable_answers &&
                                              <span className='nondelatable-question'>※ 回答済みのため編集のみ可</span>}
                                            </td>
                                          </tr>
                                          <tr style={{borderTop: "1px solid lightgray"}}>
                                            <td></td>
                                            <td></td>
                                          </tr>
                                        </>
                                      );
                                    })
                                  }
                                  {!questionnaire.is_undeletable_answers && (
                                    <tr>
                                      <td colSpan={2} style={{textAlign: "center"}}>
                                        <button
                                            type="button"
                                            style={{marginLeft: "1rem"}}
                                            onClick={() => arrayHelpers.push({name: '', label: ''})}
                                        >
                                        <span style={{...circleStyle, background: "black", marginTop: "5px"}}>+
                                        </span>質問を追加
                                        </button>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )
                            }}
                          />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='main-side-area_side'>
                    <div className='card sticky_top'>
                      <div className='card_section'>
                        <div className='card_section_article'>
                          <h1>ページの操作</h1>
                        </div>
                      </div>
                      <div className='card_section'>
                        <div className='card_section_article'>
                          <div className='col-area'>
                          <span>
                            <input
                              type='submit'
                              value={questionnaire.id ? "入力した内容を保存" : "入力した内容で登録"}
                              className="button"
                            />
                          </span>
                            <span>
                            <a href={baseUrl} className="button button_cancel">入力した内容を破棄</a>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </React.StrictMode>
  );
}

export default QuestionForm;
