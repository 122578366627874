import React, {useRef, useState} from 'react';
import Alert from "../Alert";
import {Field, Form, Formik, FormikErrors, FormikValues} from "formik";
import DatePicker from "react-datepicker";
import Axios from "../Axios";
import {values} from "lodash";

const defaultValues = {
  id: 0,
  name: '',
  amount: 0,
  month: (new Date().getFullYear()).toString() + "/" + (new Date().getMonth() + 1).toString(),
  toc_account_id: 0
}

interface ChildCareFee {
  id: number
  name: string,
  amount: number,
  month: string,
  toc_account_id: number
}

interface Error {
  text: string
  type: string
}

type FormErrorType = {
  name?: string;
  month?: string;
};


const validate = (values: ChildCareFee): FormErrorType => {
  const errors: FormikErrors<FormikValues> = {}
  if (values.name === "") {
    errors.name = '内容を入力してください'
  }
  if (values.amount.toString().length == 0) {
    errors.amount = '金額を入力してください'
  } else if (values.amount < 0) {
    errors.amount = '金額は0以上で入力してください'
  } else if (values.amount.toString().length > 7) {
    errors.amount = '金額は7桁以下で入力してください'
  }

  if (values.month === "") {
    errors.month = '入力してください'
  }
  return errors;
}


const ChildcareFeeForm = (childCareFee: ChildCareFee) => {
  const processing = useRef(false);
  const [message, setMessage] = useState<Error>({text: null, type: null});
  const [selectDate, setSelectDate] = useState<Date>(childCareFee.month ? new Date(childCareFee.month) : new Date());


  function createChildCareFee(values: ChildCareFee) {
    values.toc_account_id = childCareFee.toc_account_id
    Axios.post('/memori/childcare_fees', {memori_childcare_fee: values})
      .then(() => {
        setMessage({text: `請求書を作成しました。`, type: 'notice'});
        window.location.href = `/memori/childcare_fees/index_by_child?toc_account_id=${childCareFee.toc_account_id}`
      }).catch(() => {
      setMessage({text: `システムエラーが発生しました。`, type: 'alert'});
      window.location.reload();
    });
  }

  function updateChildCareFee(values: ChildCareFee) {
    values.toc_account_id = childCareFee.toc_account_id
    Axios.put(`/memori/childcare_fees/${childCareFee.id}`, {memori_childcare_fee: values})
      .then(() => {
        setMessage({text: `願書を更新しました。`, type: 'notice'});
        window.location.href = `/memori/childcare_fees/index_by_child?toc_account_id=${childCareFee.toc_account_id}`
      }).catch(() => {
      setMessage({text: `システムエラーが発生しました。`, type: 'alert'});
      window.location.reload();
    });
  }

  const handleSubmit = (values: ChildCareFee) => {
    if (processing.current === true) return;
    processing.current = true;
    if (childCareFee.id === null) {
      createChildCareFee(values);
    } else {
      updateChildCareFee(values);
    }
  }

  const initValue = () => {
    return childCareFee.id ? {...childCareFee} : {...defaultValues}
  }

  function isSubmitActive(errors: FormikErrors<FormikValues>, values: FormikValues) {
    return (values.name !== "") && (Object.entries(errors).length === 0)
  }

  return (
    <React.StrictMode>
      <>
        {message.text != null && (
          <Alert
            message={message}
            onClose={() => setMessage({text: null, type: null})}
            timeout={3000}
          />
        )}
        <Formik
          enableReinitializec
          initialValues={initValue()}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({errors, setFieldValue}) => (
            <Form>
              <div className="card">
                <div className='card_section'>
                  <table className="table_form">
                    <tbody>
                    <tr>
                      <td className='form-text'>
                        <label>
                          請求月
                          <span className="badge_status badge_status_important">必須</span>
                        </label>
                        <DatePicker
                          name={"month"}
                          selected={selectDate}
                          onChange={(date) => {
                            setFieldValue('month', (date.getFullYear()).toString() + "/" + (date.getMonth() + 1).toString(),)
                            // setFieldValue('month', date.toString())
                            setSelectDate(date)
                          }}
                          dateFormat="yyyy/MM"
                          showMonthYearPicker
                        />
                        <span className="red">{errors.month}</span>
                        <label>
                          内容
                          <span className="badge_status badge_status_important">必須</span>
                        </label>
                        <Field type="string" name="name" placeholder="例）給食費"/>
                        <span className="red">{errors.name}</span>
                        <label>
                          金額
                          <span className="badge_status badge_status_important">必須</span>
                        </label>
                        <Field type="number" name="amount" min={0} max={9999999} placeholder="例）20,000"/>
                        <span className="red">{errors.amount}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className='card_section_article'>
                    <div className='col-area'>
                      <span>
                        <input type='submit' value="入力した内容で登録" className="button" disabled={!isSubmitActive(errors, values)}/>
                      </span>
                      <span>
                        <a href={`/memori/childcare_fees/index_by_child?toc_account_id=${childCareFee.toc_account_id}`}
                           className="button button_cancel">入力した内容を破棄</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </React.StrictMode>
  )
}

export default ChildcareFeeForm;
