import React, {useRef, useState} from 'react';
import Select from '../../Select';
import Axios from '../../Axios';
import Alert from '../../Alert';
import StudentTable from '../exam/StudentTable';
import moment from 'moment';

const SubjectScoreIndex = ({examinations, grades}) => {
    const [examination, setExamination] = useState('');
    const [tests, setTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [selectedSubject, setSelectedSubject] = useState('');
    const [isNumericEvaluation, setIsNumericEvaluation] = useState(true);
    const [students, setStudents] = useState([]);
    const [message, setMessage] = useState();
    const processing = useRef(false);

    const handleExamination = (selected) => {
        setExamination(selected.value);
        setTests([]);
        setSubjects([]);
        setStudents([]);
        Axios.get(`/evide/exam/examinations/${selected.value}/tests/options`)
            .then(({data}) => {
                if (!data) {
                    setMessage({text: '試験が登録されていません', type: 'alert'});
                    return;
                }
                setTests(data.map(t => {
                    return {label: `${t.name}`, value: t.id, resultDay: t.result_day}
                }));
            }).catch(err => {
            setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
        });
    }

    const handleTest = (selected) => {
        setSelectedTest([selected.value, selected.resultDay])
        setSubjects([]);
        setStudents([]);
        Axios.get(`/evide/exam/examinations/${examination}/tests/${selected.value}/subjects/options`)
            .then(({data}) => {
                if (!data || data.length === 0) {
                    setMessage({text: '科目が登録されていません', type: 'alert'});
                    return;
                }
                setSubjects(data.map(s => {
                    return {label: `${s.name}`, value: s.id, isNumericEvaluation: s.isNumericEvaluation}
                }));
            }).catch(err => {
            setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
        });
    }

    const handleSubject = (selected) => {
        setSelectedSubject(selected.value);
        setIsNumericEvaluation(selected.isNumericEvaluation);
        setStudents([]);
        // Axios共通に入れても良い？
        Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        Axios.get(`/evide/exam/subjects/${selected.value}/subject_scores/students`)
            .then(({data}) => {
                if (!data || data.length === 0) {
                    setMessage({text: '受験生が登録されていません', type: 'alert'});
                    return;
                }
                setStudents(data.map(s => {
                    return {
                        id: s.id,
                        label: s.name,
                        value: s.id,
                        examineeNumber: s.examineeNumber,
                        score: s.score,
                        grade: s.grade,
                        evaluationComment: s.evaluationComment
                    }
                }));
            })
    }

    const handleScore = (e, id) => {
        const newStudents = students.map(s => {
            if (s.id === id) {
                return {...s, score: e.target.value}
            }
            return s
        })
        setStudents(newStudents);
    }

    const handleGrade = (e, id) => {
        const newStudents = students.map(s => {
            if (s.id === id) {
                return {...s, grade: e.target.value}
            }
            return s
        })
        setStudents(newStudents);
    }

    const handleEvaluationComment = (e, id) => {
        const newStudents = students.map(s => {
            if (s.id === id) {
                return {...s, evaluationComment: e.target.value}
            }
            return s
        })
        setStudents(newStudents);
    }

    const handleSave = () => {
        if (processing.current === true ) return;
        processing.current = true
        Axios.put(`/evide/exam/subject_scores/update_scores`, {
            students: students.map(s => {
                return {
                    id: s.id,
                    score: s.score,
                    grade: s.grade,
                    evaluationComment: s.evaluationComment
                }
            })
        })
            .then(({data}) => {
                setMessage({text: `更新しました`, type: 'notice'});
                processing.current = false
            }).catch(err => {
            setMessage({text: `点数は9999以下で入力してください`, type: 'alert'});
            processing.current = false
        })
    }

    const removeMessage = () => {
        setMessage('')
    }

    return (
      <React.StrictMode>
        <>
            {message && (
                <Alert
                    message={message}
                    onClose={() => removeMessage(message)}
                    timeout={3000}
                />
            )}
            <div className="section-area">
                <div className="card">
                    <div className="control-area">
                        <div className="control-area_section control-area_section_stretch_wrap">
                            <div className="control-area_section_article control-area_section_article_stretch">
                                <div className="control-area control-area_stretch">
                                    <div className="control-area_section control-area_section_stretch">
                                        <div className="control-area_section_article">
                                            <div className="control-area_section_article_title">
                                                <label>入試</label>
                                            </div>
                                            <Select
                                                id="examination"
                                                options={examinations}
                                                name="examination"
                                                value={examination}
                                                onChange={handleExamination}
                                            />
                                        </div>
                                        <div className="control-area_section_article">
                                            <div className="control-area_section_article_title">
                                                <label>試験</label>
                                            </div>
                                            <Select
                                                id="test"
                                                options={tests}
                                                name="test"
                                                value={selectedTest[0]}
                                                onChange={handleTest}
                                            />
                                        </div>
                                        <div className="control-area_section_article">
                                            <div className="control-area_section_article_title">
                                                <label>科目</label>
                                            </div>
                                            <Select
                                                id="subject"
                                                options={subjects}
                                                name="subject"
                                                value={selectedSubject}
                                                onChange={handleSubject}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                {students.length > 0 &&
                <div className="heading-area">
                    <div></div>
                    <div className="control-area">
                        <div className="section-area">
                            <div className="control-area">
                                <div className="control-area_section">
                                    <div className="control-area_section_article">
                                        {(() => {
                                            if (new Date(selectedTest[1]) - new Date() > 0) {
                                                return (
                                                    <div className="button row-area row-area_small">
                                                            <span className="button" onClick={handleSave}>
                                                                採点を更新する
                                                            </span>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <span>
                                                            公開日時を過ぎたので更新ができません
                                                        </span>
                                                )
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="section-area">
                    {(() => {
                        if (isNumericEvaluation) {
                            return (
                                <div className="multi_tables">
                                    <StudentTable
                                        isNumericEvaluate={isNumericEvaluation}
                                        students={students.slice(0, Math.ceil(students.length / 3))}
                                        grades={[]}
                                        handleScore={handleScore}
                                        handleGrade={handleGrade}
                                        handleEvaluationComment={handleEvaluationComment}
                                    />
                                    <StudentTable
                                        isNumericEvaluate={isNumericEvaluation}
                                        students={students.slice(
                                            Math.ceil(students.length / 3),
                                            Math.ceil(students.length * 2 / 3)
                                        )}
                                        grades={[]}
                                        handleScore={handleScore}
                                        handleGrade={handleGrade}
                                        handleEvaluationComment={handleEvaluationComment}
                                        isEmptyRow={students.length % 3 === 1}
                                    />
                                    <StudentTable
                                        isNumericEvaluate={isNumericEvaluation}
                                        students={students.slice(
                                            Math.ceil(students.length * 2 / 3),
                                            students.length
                                        )}
                                        grades={[]}
                                        handleScore={handleScore}
                                        handleGrade={handleGrade}
                                        handleEvaluationComment={handleEvaluationComment}
                                        isEmptyRow={students.length % 3 !== 0}
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div className="multi_tables">
                                    <StudentTable
                                        isNumericEvaluate={isNumericEvaluation}
                                        students={students.slice(0, Math.ceil(students.length / 2))}
                                        grades={grades}
                                        handleScore={handleScore}
                                        handleGrade={handleGrade}
                                        handleEvaluationComment={handleEvaluationComment}
                                    />
                                    <StudentTable
                                        isNumericEvaluate={isNumericEvaluation}
                                        students={students.slice(
                                            Math.ceil(students.length / 2),
                                            students.length
                                        )}
                                        grades={grades}
                                        handleScore={handleScore}
                                        handleGrade={handleGrade}
                                        handleEvaluationComment={handleEvaluationComment}
                                        isEmptyRow={Math.ceil(students.length / 2) > students.length / 2}
                                    />
                                </div>
                            )
                        }
                    })()}
                </div>
            </div>
        </>
      </React.StrictMode>
    );
};

export default SubjectScoreIndex;
