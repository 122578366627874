import React, {memo} from 'react';

const FormColumn = memo(({label, required, children}) => {
  return (
    <tr>
      <th>
        <label>
          {label}
          {required && <span className="badge_status badge_status_important">必須</span> }
        </label>
      </th>
      {children}
    </tr>
  )
})
export default FormColumn;
