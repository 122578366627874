import React, {useState, useEffect} from 'react'
import FileUploader from '../../../FileUploader'
import TextField from '../../../form/TextField';
import IncrementalSelect from '../../../form/IncrementalSelect';

function DocumentForm({document, options, errors}) {
  const [name, setName]= useState(document.name || '')
  const [documentTypeEnumId, setDocumentTypeEnumId]= useState(document.tech_document_type_enum_id || '')
  const [acceptExtensions, setAcceptExtensions]= useState()
  const [uploadFileId, setUploadFileId]= useState(document.upload_file_id)

  const validExtensions = (documentTypeEnumId) => {
    if (documentTypeEnumId == 1 || documentTypeEnumId == 2) {
      return "application/pdf";
    }else if (documentTypeEnumId == 3) {
      return "image/*";
    }else if (documentTypeEnumId == 4 || documentTypeEnumId == 5) {
      return "video/mp4,video/ogg,video/webm";
    } else {
      return "application/pdf";
    }
  }

  const maxFileSize = () => {
    return 200;
  }

  useEffect(() => {
    setAcceptExtensions(validExtensions(documentTypeEnumId))
    setUploadFileId((prev) => `reset${prev}`)
  }, [documentTypeEnumId])

  return (
    <table className="table_form">
      <tbody>
        <TextField
          label="名前"
          required
          value={name}
          placeholder="名前"
          nameKey="tech_document[name]"
          onChange={(e) => setName(e.target.value)}
          errorMessage={errors?.name ? "名前を入力してください" : ''}
          type="text"
        />
        <IncrementalSelect
          label="タイプ"
          required
          options={options}
          nameKey="tech_document[tech_document_type_enum_id]"
          selectedValue={documentTypeEnumId}
          onChange={(e) => setDocumentTypeEnumId(e.target.value)}
          errorMessage={errors?.tech_document_type_enum_id ? "タイプを選択してください" : ''}
        />
        {
          documentTypeEnumId && (
            <FileUploader
              deffalt={uploadFileId}
              valid_extensions={acceptExtensions}
              hidePreview={false}
              maxFileSize={maxFileSize()}
              errorMessage={errors?.upload_file || errors?.upload_file_id ? `ファイル${errors?.upload_file || errors?.upload_file_id}` : ""}
              name="tech_document[upload_file_id]"
            />
          )
        }

      </tbody>
    </table>
  )
}

export default DocumentForm