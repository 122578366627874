import React from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import { HotTable } from "@handsontable/react";
import SyncLoader from "react-spinners/SyncLoader";
import html2canvas from 'html2canvas';
import DateDecorator from "../DateDecorator";
import CellMeta from "./CellMeta";
import HttpClient from "../HttpClient";

const loaderCss = "display: block; margin: 0 auto; border-color: red;";

export default class Shift extends React.Component {

  cellsSetting = (account_names, meeting_names, date, edited_cells) => {
    const _account_names = account_names;
    const _meeting_names = meeting_names;
    const _date = date;
    const _edited_cells = edited_cells;
    return function(row, col, props) {
      let cellProperties = {};
      const cell = new CellMeta(this.instance, row, col);
      if (cell.isDate()) {
        cellProperties.readOnly = true;
        cell.update("className", `header-row ${cell.getWeekdayColor()}`);
      }
      if (cell.isMeeting()) {
        if (_date.isPast()) {
          cellProperties.readOnly = true;
        } else {
          cell.update("type", "dropdown");
          cell.update("source", _meeting_names);
        }
      }
      if (cell.isStaticColumn()) {
        cellProperties.readOnly = true;
        cell.update("className", "static-column");
      }
      if (cell.isShift()) {
        if (_date.isPast()) {
          cellProperties.readOnly = true;
        } else {
          cell.update("type", "autocomplete");
          cell.update("strict", true);
          cell.update("source", _account_names);
        }
      }
      if (cell.isAttendanceCondition()) {
        cellProperties.readOnly = true;
      }
      if (_edited_cells.some(edited_cell => edited_cell.row == row && edited_cell.col == col)) {
        cell.update("className", "edited-cell");
      }
      return cellProperties;
    }
  }

  customBorders() {
    return [
      {
        range: { from: { row: CellMeta.HEADER_ROW, col: 0 }, to: { row: CellMeta.HEADER_ROW, col: 40, } },
        top: { width: 3, color: 'gray' },
      }
    ];
  }

  mergeCells() {
    const mergeCells = [];
    [2,4,6,8,10,12,14,16,18,20,22].forEach(row => {
      [...Array(CellMeta.STATIC_COLUMN - 1)].forEach((_, col) => {
        mergeCells.push({row: row, col: col, rowspan: 2, colspan: 1})
      });
    })
    return mergeCells.flat();
  }

  constructor(props) {
    super(props);
    this.hotTableComponent = React.createRef();
    const d = new Date();
    const initialDate = new Date(d.getFullYear(), d.getMonth() + 1, 1)
    this.state = {
       attend_date: new DateDecorator(initialDate),
       loading: false,
    }
    this.state.settings = {
      // mergeCells: this.mergeCells(), // TODO: やれるのがベターだが、横スクロール、ダウンロード画像が崩れる
      // cells: this.cellsSetting(this.props.account_names, this.props.meeting_names, this.state.date, []), //初期表示時にオプション当てるか。すぐロードされるの、性能観点で一旦外してみる
      data: [this.state.date.days.map(d => "")], //初期表示に1行目だけいれておく。いれないと表示が崩れる
      height: 600,
      rowHeights: 70,
      className: "htCenter htMiddle",
      stretchH: "all",
      fixedColumnsLeft: 1,
      fixedRowsTop: 2,
      customBorders: this.customBorders(),
      viewportColumnRenderingOffset: 40, // 日数 + 固定カラム分。適正値不明
      viewportRowRenderingOffset: 70,
      afterChange: (change, source) => {
        if(source === 'loadData') { return; }
        this.onUpdateCell();
      }
    }
  }

  componentDidMount() { this.load(this.state.date.param()); }

  load(date) {
    fetch('/toucheez/shift_sheets/load?date=' + date)
    .then(res=> { return res.json(); })
    .then(res => {
      document.querySelector("#memo").value = res.memo; // TODO: onChangeによるcomponent再描画が遅いので、一旦このやり方を採用
      this.setState({loading: false }, () => {
        this.hotTableComponent.current.hotInstance.loadData(res.data);
        this.hotTableComponent.current.hotInstance.updateSettings({cells: this.cellsSetting(this.props.account_names, this.props.meeting_names, this.state.date, res.edited_cells)});
      })
    });
  }

  onTestLoad(){
    fetch('/toucheez/shift_sheets/testload?date=' + this.state.date.param())
    .then(res=> { return res.json(); })
    .then(res => {
      this.setState({loading: false}, () => {
        this.hotTableComponent.current.hotInstance.loadData(res.data);
        this.hotTableComponent.current.hotInstance.setDataAtCell(0, 0, 'update by test');

      })
    });
  }

  onBlurMemo(e) {
    new HttpClient().post('/toucheez/shift_sheets/create_memo', {memo: e.target.value, attend_date: `${this.state.date.param()}`});
  }

  onPreview() {
    html2canvas(document.querySelector("#hot-table table"), {
      scrollX: 0,
      scrollY: -window.scrollY
    }).then(canvas => {
      const link = document.createElement('a');
      link.download = 'シフト.png';
      link.href = canvas.toDataURL();
      link.click();
    });
  }

  async onUpdateCell() {
    const response = await new HttpClient().post('/toucheez/shift_sheets', {attend_date: `${this.state.date.param()}`, data: this.hotTableComponent.current.hotInstance.getData()});
    if (response.error) { alert(response.error); }
  }

  onTestSave() {
    new HttpClient().post('/toucheez/shift_sheets', {attend_date: `${new DateDecorator(new Date()).param()}`, data: this.hotTableComponent.current.hotInstance.getData()});
  }

  onRefresh(date) {
    this.setState({ date: date, loading: false }, () => {
      this.load(date.param());
    });
  }

  render() {
    return (
      <div id="hot-table">
        {this.props.isMobile || <div>
          <div>
            <div className="attendance-record-heading-area">
              <div className="title-area">
                <div className="title-area_pagination">
                  <h1>シフト</h1>
                  <div className="pagination_calendar">
                    <button className="button button_action w-75" onClick={() => this.onRefresh(this.state.date.makePrev())}>
                      <span className="material-icons">chevron_left</span>
                      <span>前月</span>
                    </button>
                    <div className="datepicker pagination_calendar_select">
                      <span className="form-text datetime">
                        <DatePicker
                          selected={this.state.date.date}
                          onChange={(selected) => this.onRefresh(new DateDecorator(selected))}
                          dateFormat="yyyy-MM"
                          id='month-select'
                          showMonthYearPicker
                        />
                      </span>
                    </div>
                    <button className="button button_action w-75" onClick={() => this.onRefresh(this.state.date.makeNext())}>
                      <span>翌月</span>
                      <span className="material-icons">chevron_right</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="control-area">
                <div className="control-area_section">
                  <div className="control-area_section_article">
                    <span className="button button_action" onClick={() => this.onPreview()}>
                      <span className="material-icons">save_alt</span>
                      <span>印刷プレビュー</span>
                    </span>
                  </div>
                  {this.props.isProduction ||
                    <div className="control-area_section_article">
                      <span className="button button_action" onClick={() => this.onTestSave()}>
                        <span className="material-icons">save</span>
                        <span>先月のシフトを保存 for Test</span>
                      </span>
                    </div>
                  }
                  {this.props.isProduction ||
                    <div className="control-area_section_article">
                      <span className="button button_action" onClick={() => this.onTestLoad()}>
                        <span className="material-icons">refresh</span>
                        <span>シフトを更新 for Test</span>
                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <SyncLoader size={10} css={loaderCss} color={"#123abc"} loading={this.state.loading} />
          <div className="card">
            <div className="card_section">
              <h2>メモ</h2>
              <textarea id="memo" className="form-text form-text_small" onBlur={(e) => this.onBlurMemo(e)} />
            </div>
            <div className="card_section">
              <h2>シフト</h2>
              <HotTable ref={this.hotTableComponent} settings={this.state.settings} />
            </div>
          </div>
        </div>
        }
        {this.props.isMobile && <div>
          <div>
            <section>
              <div className="mobile_heading-area">
                <div className="mobile_title-area mobile_title-area_result">
                  <h1>シフト</h1>
                </div>
              </div>
            </section>
            <section>
              <div className="mobile_row-area">
                <button className="mobile_button mobile_button_action" onClick={() => this.onRefresh(this.state.date.makePrev())}>
                  <span className="material-icons">chevron_left</span>
                  <span>前月</span>
                </button>
                <div className="mobile_pagination_calendar_date-select">
                  <YearSelect date={this.state.date}  onChange={(e) => this.onRefresh(this.state.date.makeByYear(e.target.value))} />
                  <MonthSelect date={this.state.date} onChange={(e) => this.onRefresh(this.state.date.makeByMonth(e.target.value))} />
                </div>
                <button className="mobile_button mobile_button_action" onClick={() => this.onRefresh(this.state.date.makeNext())}>
                  <span>翌月</span>
                  <span className="material-icons">chevron_right</span>
                </button>
              </div>
            </section>
            <div className="mobile_card-area mobile_card-area_large">
              <section className="mobile_card">
                <div className="mobile_card_section">
                  <div className="mobile_card_article">
                    <div className="mobile_button-area mobile_button-area_fit">
                      <span className="mobile_button mobile_button_action" onClick={() => this.onPreview()}>
                        <span className="material-icons">save_alt</span>
                        <span>シフトをダウンロード</span>
                      </span>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <SyncLoader size={10} css={loaderCss} color={"#123abc"} loading={this.state.loading} />
          <section className="mobile_card">
            <div className="mobile_card_section">
              <h2>メモ</h2>
              <textarea id="memo" className="mobile_form-text mobile_form-text_small" />
            </div>
            <div className="mobile_card_section">
              <h2>シフト</h2>
              <HotTable ref={this.hotTableComponent} settings={this.state.settings} />
            </div>
          </section>
        </div>
        }
      </div>
    );
  }
}
