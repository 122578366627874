import React from 'react';
import {formatDateSlash} from '../../shared/date'

const DateListPreview = ({label, dateList}) => {
  if (!dateList) {
    return <></>
  } else {
    return (
      <>
        <tr>
          <th>
            <label>
              {label}
            </label>
          </th>
        </tr>
        <tr>
          <td className={"date-list-preview"}>
            <div>
              <table className={"table"}>
                <thead className={'center'}>
                <tr>
                  <th>回数</th>
                  <th>日付</th>
                </tr>
                </thead>
                <tbody className={'center'}>
                {dateList.map((date, i) => (
                  <tr key={`${date}-${i}`}>
                    <td>{`第${i + 1}回`}</td>
                    <td>{formatDateSlash(date)}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </>
    )
  }
}

export default DateListPreview;
