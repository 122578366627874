import styled, { css } from "styled-components"

const Link = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  ${props => !props.disabled && css`
    color: ${props => props.color ? `${props.color} !important` : "#134485 !important"};
    &:hover {
      cursor: pointer;
      color:  ${props => props.hoverColor ? `${props.hoverColor} !important` : "#0000EE !important"};
    }
  `}
  ${props => props.disabled && css`
    color: #333333 !important;
  `}
`
export default Link