import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Snack from '@mui/material/Snackbar';
import Span from '../atoms/Span';
import FlexBox from '../atoms/FlexBox';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Snackbar({type, message, autoHideDuration, anchorOrigin, onClose}) {
  return (
    <Snack
      open={message !== ""}
      autoHideDuration={autoHideDuration}
      message={message}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
    >
      <FlexBox>
        <Alert onClose={onClose} severity={type} sx={{ width: '100%', minWidth: 300 }}>
          <Span>{message}</Span>
        </Alert>
      </FlexBox>
    </Snack>
  )
}

export default Snackbar