import React, {useState, useRef, useEffect} from 'react'
import LessonForm from './Form'
import DraggableModal from '../../../DraggableModal';
import useWindowSize from '../../../hooks/useWindowSize';
import Space from '../../../atoms/Space';

function FormModal({
  id,
  callBack,
  label
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const btnRef = useRef(null);
  const [width, height] = useWindowSize();
  const [position, setPosition] = useState({
    width: 0,
    height: 0,
    defaultX: 0,
    defaultY: 0,
  })

  useEffect(() => {
    const refInfo = btnRef.current.getBoundingClientRect()
    setPosition({
      ...position,
      width: Math.min(width * 0.7, 800),
      height: Math.min(height * 0.7, 800),
      defaultX: (refInfo.x - width / 5) * -1, // 画面サイズに対して左から20%の位置
      defaultY: (refInfo.y - height / 10) * -1,// 画面サイズに対して上から10%の位置
    })
  }, [width, height]);

  const onSuccess = () => {
    location.reload()
  }

  return (
    <>
      <a className="button" onClick={() => { setModalVisible(true) }} ref={btnRef}>
        <span>{label}</span>
      </a>
      {modalVisible && (
        <DraggableModal
          onClose={() => setModalVisible(false)}
          zIndex={110}
          {...position}
        >
          <>
            <Space margin="3rem"/>
            <LessonForm
              id={id}
              onClose={() => setModalVisible(false)}
              onSuccess={onSuccess}
            />
            <Space margin="1rem"/>
          </>
        </DraggableModal>
      )}
    </>
  )
}

export default FormModal