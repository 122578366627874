import React, {useState} from 'react'
import MathJaxTextView from '../../../MathJaxTextView'
import { textUrlToLink, convertBreakToBr } from '../../../../shared/text'
import FlexBox from '../../../atoms/FlexBox'
import Width from '../../../atoms/Width'

function FormCheckbox({
  options,
  selectedValues,
  keys,
}) {
  const [data, setData] = useState([])
  const changeData = (e) => {
    if(data.includes(Number(e.target.value))){
      setData(data.filter(d => d !== Number(e.target.value)))
    } else {
      setData([...data, Number(e.target.value)])
    }
  } 
  return (
    <td className={"form-checkbox d-block"}>
      {
        options.map((option, index) => (
          <div className="mb-3">
            <span key={`${keys[0]}_${keys[1]}_${option.value}`}>
              <FlexBox>
                <span className="mr-3">
                  <input
                    type={"checkbox"}
                    value={option.value}
                    checked={data.includes(option.value)}
                    id={`${keys[0]}_${keys[1]}_${option.value}`}
                    name={`${keys[0]}[${keys[1]}][]`}
                    onChange={changeData}
                    style={{margin: '0 6px 0 0'}}
                  />
                </span>
                <div dangerouslySetInnerHTML={{ __html: convertBreakToBr(option.name) }} className="exercise_choice_span d-block" />
              </FlexBox>
            </span>
          </div>
        ))
      }
    </td>
  )
}

export default FormCheckbox