import React from 'react'

function MainSideAreaSide({children}) {
  return (
    <div className="main-side-area_side">
      {children}
    </div>
  )
}

export default MainSideAreaSide