import React, {useState} from 'react';
import Axios from "../Axios";
import Alert from "../Alert";
import sanitizeHtml from 'sanitize-html';

interface EventsByMonth {
  [month: string]: Array<Event>
}

interface Event {
  id: bigint
  name: string
  formatted_date: string
  comment: string
  is_published: boolean
  enable_to_destory: boolean
  enable_to_edit: boolean
}

interface Error {
  text: string
  type: string
}

const ScheduleIndex = (data: EventsByMonth) => {
  const [message, setMessage] = useState<Error>({text: null, type: null});

  const initialPublished = () => {
    for (const month in data) {
      const hasNotPublishedEvent = data[month].some((event) => {
        return event.is_published === false;
      });
      if (hasNotPublishedEvent) {
        return true
      }
    }
    return false
  }
  const [hasNotPublishedEvent, setHasNotPublishedEvent] = useState<boolean>(initialPublished);
  const [searchName, setSearchName] = useState<string>("");
  const [events, setEvents] = useState<EventsByMonth>(data);

  const handleSearch = () => {
    const nameParam = searchName ? `q%5Bname_cont%5D=${searchName}` : `q%5Bname_cont%5D=`;
    Axios.get(`/schedules.json?${nameParam}`)
      .then((data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setEvents(data.data);
      }).catch(() => {
      return;
    });
  };


  const handleDelete = (id: bigint) => {
    Axios.delete(`/schedules/${id}`)
      .then(() => {
        setMessage({text: `削除しました。`, type: 'notice'});
        window.location.reload();
      }).catch(() => {
      setMessage({text: `システムエラーが発生しました。`, type: 'alert'});
      window.location.reload();
    });
  };

  const handleUpdate = () => {
    setHasNotPublishedEvent(false)
    Axios.put(`/schedules/publish`)
      .then(() => {
        setMessage({text: `公開しました。`, type: 'notice'});
        window.location.reload();
      }).catch(() => {
      setMessage({text: `システムエラーが発生しました。`, type: 'alert'});
      window.location.reload();
    });
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  }


  function eventDeleteButton(event: Event) {
    return <button onClick={() => handleDelete(event.id)}
                   className="row-area row-area_small link-icon-and-text fs_s c_gray">
      <span className="material-icons">delete</span>
      <span className="link-icon-and-text_text">予定を削除</span>
    </button>;
  }

  function eventEditLink(event: Event) {
    return <a className="row-area row-area_small link-icon-and-text fs_s"
              href={sanitizeHtml(`/schedules/${event.id}/edit`)}>
      <span className="material-icons">edit</span>
      <span className="link-icon-and-text_text">予定を変更</span>
    </a>;
  }

  return (
    <React.StrictMode>
      <>
        {message.text != null && (
          <Alert
            message={message}
            onClose={() => setMessage({text: null, type: null})}
            timeout={3000}
          />
        )}
        <div className="main-side-area main-side-area_right-side-bar">
          <div className="main-side-area_main">
            <div className={"section-area"}>
              <div className="card">
                {hasNotPublishedEvent && (
                  <>
                    <div style={{textAlign: "right"}}>
                      <button onClick={() => {
                        const publishCheck = window.confirm("本当に公開しますか");
                        if (publishCheck === true) {
                          handleUpdate();
                        }
                      }}>
                        <div className="button">公開</div>
                      </button>
                    </div>
                    <div style={{textAlign: "right"}}>
                      <span className={"red fw_bold"}>下書きで保存されているイベントがあります</span>
                    </div>
                  </>
                )}
                {Object.keys(events).map((month, index) => {
                  return (
                    <dl className="accordion-new" key={index.toString()}>
                      <dt>
                        {month}
                      </dt>
                      {data[month].map((event, index) => {
                        return (
                          <dd className="accordion-new_content-open" key={index.toString()}>
                            <div className="card_in-card card_section">
                              <div className="card_section_article">
                                <div className="card">
                                  <div
                                    className="card_content row-area row-area_xlarge row-area_jc-space-between">
                                    <div className="row-area">
                                  <span className="col-area">
                                    <span className="row-area row-area_small c_gray">
                                      <span>
                                        {event.formatted_date}
                                      </span>
                                    </span>
                                    <span>
                                      {event.is_published == false && (
                                        <span className={"badge_status badge_status_important"}>非公開</span>
                                      )}
                                      <span className="fs_l fw_bold">
                                       {event.name}
                                      </span>
                                    </span>
                                  </span>
                                    </div>
                                    <div
                                      className="col-area col-area_small ai_flex-end">
                                      {event.enable_to_edit && eventEditLink(event)}
                                      {event.enable_to_destory && eventDeleteButton(event)}
                                      <span
                                        className="c_gray">{event.comment}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </dd>
                        )
                      })}
                    </dl>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="main-side-area_side">
            <div className="section-area">
              <div className="card">
                <div className="card_content">
                  <div className="heading-area heading-area_nocard">
                    <div className="title-area">
                      <h1>検索</h1>
                    </div>
                  </div>
                  <div className="card_content_section">
                    <div className="card_content_section_article">
                      <div className="col-area">
                      <span className="form-area">
                        <label className="row-area row-area_small">
                          <span>名前</span>
                        </label>
                      </span>
                        <span className="form-text">
                        <input id={'name_cont'} onChange={handleInput} placeholder={"例): 田中 太郎"}/>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="card_content_section">
                    <div className="card_content_section_article">
                      <span className="button" onClick={handleSearch}>
                        入力した条件で検索
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    </React.StrictMode>
  )
}
export default ScheduleIndex;
