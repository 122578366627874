import React from "react";
import ModalButton from "../ModalButton";

const SubmitButtonWithModal = ({
  submitLabel,
  cancelLabel,
  confirmLabel,
  cancelPath,
  formId,
  modalTitle,
  modalMessage,
  onSubmit
}) => {
  const handleSubmit = () => {
    if (onSubmit){
      onSubmit();
    } else {
      let form = document.getElementById(formId);
      form.submit();
    }
  };

  const modalBody = () => {
    return <div>{modalMessage}</div>;
  };

  return (
    <React.StrictMode>
      <div className="card_section">
        <div className="card_section_article">
          <h1>ページの操作</h1>
        </div>
      </div>
      <div className="card_section">
        <div className="card_section_article">
          <div className="col-area">
            <span>
              <ModalButton
                message={submitLabel}
                title={modalTitle || "注意事項"}
                link={true}
                confirmMessage={confirmLabel}
                cancelMessage={cancelLabel}
                params={""}
                handleSubmit={handleSubmit}
              >
                {modalBody()}
              </ModalButton>
            </span>
            <span>
              <a className="button button_cancel" href={cancelPath}>
                {cancelLabel || "キャンセル"}
              </a>
            </span>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default SubmitButtonWithModal;
