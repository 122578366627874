import React, {useEffect, memo} from 'react';

const DatePeriodForm = memo(({ label, required, fromKey, toKey, from, to, onChange, from_error_text, to_error_text, disabled }) => {
  useEffect(() => {
    $(`input[name='${fromKey}']`).bootstrapMaterialDatePicker({
      weekStart: 0,
      lang: "ja",
      time: false,
      format: "YYYY-MM-DD",
    }).on('change', function (e, date) {
      onChange(e)
    });

    $(`input[name='${toKey}']`).bootstrapMaterialDatePicker({
      weekStart: 0,
      lang: "ja",
      time: false,
      format: "YYYY-MM-DD",
    }).on('change', function (e, date) {
      onChange(e)
    });
  })

  return (
    <>
      <div className={`row-area row-area_liquid`}>
        <span className={from_error_text && "error_border"}>
          <input
            type={"text"}
            id={`id-${fromKey}`}
            name={fromKey}
            defaultValue={from}
            placeholder={"カレンダーより選択してください"}
            disabled={disabled}
          />
        </span>
        <span className={"row-area_liquid_connect"}>
          〜
        </span>
        <span className={to_error_text && "error_border"}>
          <input
            type={"text"}
            id={`id-${toKey}`}
            name={toKey}
            defaultValue={to}
            placeholder={"カレンダーより選択してください"}
            disabled={disabled}
          />
        </span>
      </div>
      {
        (from_error_text || to_error_text) &&
        (
          <tr className={'error_message'}>
            <td>
              <span>{from_error_text}</span>
              <br/>
              <span>{to_error_text}</span>
            </td>
          </tr>
        )
      }
    </>
  );
});

export default DatePeriodForm;
