import React from 'react'
import ReactTable from 'react-table-6'

const Index = ({subject_scores}) => {
  const columns = [
    {
      Header: '受験番号',
      accessor: 'examinee_number',
    },
    {
      Header: '氏名',
      accessor: 'full_name',
    },
    {
      Header: '得点',
      accessor: 'score',
    },
    {
      Header: '偏差値',
      accessor: 'deviation',
    },
    {
      Header: '順位',
      accessor: 'rank',
    },
  ];

  const data = subject_scores.map((score, index) => {
    score.ordered_details.forEach((detail, idx) => {
      score[`question_${idx + 1}`] = detail.answer
      if(index === 0) {
        columns.push({ Header: `問${idx + 1}`, accessor: `question_${idx + 1}`})
      }
    })
    return score;
  });

  columns.push({ Header: '読み込み時刻', accessor: 'loaded_at'});

  return (
    <ReactTable
      data={data}
      columns={columns}
      showPaginationTop
      defaultPageSize={10}
      showPaginationBottom={false}
      sortable={false}
    />
  )
}

export default Index;
