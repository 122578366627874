import React, {useState, useEffect,memo} from 'react';
import {formatDateSlash, formatTimePeriod} from '../../shared/date'
import ModalButton from '../../components/ModalButton';

const CourseLessonTable = memo(({courseLessons, trainers = [], isEditable = false}) => {
  const [lessons, setLessons] = useState([]);

  useEffect(() => {
    setLessons(courseLessons.map(cl => {
      return {...cl, new_account_id: cl?.account?.id}
    }))
  }, [courseLessons])

  const changeTrainer = (e, courseLessonId) => {
    setLessons(lessons.map(l => {
      if (l.id !== courseLessonId || l.account.id === e.target.value) {
        return l;
      }
      return {...l, new_account_id: e.target.value};
    }));
  }

  const lessonNameLinkOrLabel = (courseLesson) => {
    if (isEditable) {
      return <a href={`/tech/course_lessons/${courseLesson.id}`} >{courseLesson.lesson.name}</a>
    }
    return courseLesson.lesson.name
  }

  const trainerSelectOrLabel = (courseLesson, i) => {
    if (isEditable) {
      return (
        <TrainerOptions
          trainers={trainers}
          index={i}
          trainer={courseLesson.account}
          onChange={e => changeTrainer(e, courseLesson.id)}
        />
      );
    }
    return <span>{courseLesson.account.name}</span>
  }

  return (
    <table className={"table"}>
      <thead className={'center'}>
      <tr>
        <th>回数</th>
        <th>授業名</th>
        <th>トレーナー名</th>
        <th>日付</th>
        <th>時間</th>
        {isEditable && <th></th>}
      </tr>
      </thead>
      <tbody className={'center'} key={'preview'}>
      {lessons.map((courseLesson, i) => (
        <tr key={i}>
          <td>{`第${i + 1}回`}</td>
          <td>{lessonNameLinkOrLabel(courseLesson)}</td>
          <td>{trainerSelectOrLabel(courseLesson, i)}</td>
          <td>{formatDateSlash(courseLesson.start_at)}</td>
          <td>{formatTimePeriod(courseLesson.start_at, courseLesson.finish_at)}</td>
          {isEditable &&
          <td>
            <div className={'button-area'}>
              <span>
              <ModalButton
                message='トレーナー変更'
                title='変更確認'
                body={`${courseLesson.lesson.name}のトレーナーを変更しますか。変更後は、メールおよびチャットでトレーナー変更の通知がされます。`}
                confirmMessage='変更'
                link={`/tech/course_lessons/${courseLesson.id}/change_trainer`}
                params={{tech_course_lesson: {account_id: courseLesson.new_account_id}}}
                submitButtonStyle="button row-area row-area_small"
              />
              </span>
              <span>
                <a
                href={`/tech/course_lessons/${courseLesson.id}/edit_lesson_progress`}
                className={'button row-area row-area-small'}
                >進捗報告</a>
              </span>
            </div>
          </td>
          }
        </tr>
      ))}
      </tbody>
    </table>
  );
})

const TrainerOptions = memo(({trainers, index, trainer, onChange}) => {
  return (
    <select name='trainers' key={index} onChange={onChange}>
      {trainers.map((t) => <option key={index + t.account.id} value={t.account.id}
                                   selected={t.account.id === trainer.id}>{t.account.name}</option>)}
    </select>
  );
})


export default CourseLessonTable;
