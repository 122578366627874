import React from 'react';

const Icon = ({ name, className = '', ...props }) => {
  return (
    <span {...props} className={`material-icons ${className}`}>
      {name}
    </span>
  );
};

export default Icon;
