import React from 'react';
import SectionArea from '../../../atoms/SectionArea'
import IndexCard from '../../../atoms/IndexCard';

const NavigationTab = ({tabLabels, tabContents, setActiveTab, activeTab}) => {
  return (
    <SectionArea>
      <IndexCard>
        <div className='tabs_content ui-tabs ui-corner-all ui-widget ui-widget-content'>
          <ul className='tabs_nav ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header'>
            {
              Object.keys(tabLabels).map((tabType, idx) => (
                <li 
                  className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${activeTab === tabType? "ui-tabs-active ui-state-active" : ""}`}
                  key={`navigation-tab-idx-${idx}`}
                >
                  <a
                    className={`ui-tabs-anchor ${activeTab === tabType? "" : "non-active"}`}
                    onClick={() => setActiveTab(tabType)}
                  >
                    {tabLabels[tabType]}
                  </a>
                </li>
              ))
            }
          </ul>
          <div className="ui-tabs-panel">
            {tabContents[Object.keys(tabLabels).indexOf(activeTab)]}
          </div>
        </div>
      </IndexCard>
    </SectionArea>
  );
}

export default NavigationTab;