import React, {useRef, useState} from 'react';
import {Formik, Field, Form} from "formik";
import Axios from "../Axios";
import DatePicker, {} from 'react-datepicker';
import Alert from "../Alert";

interface EventValue {
  name: string
  comment: string
  attend_date: string
  facility_id: number
}

interface InputValues {
  event: InputEventValue
  facilities: Array<Facility>
}

interface InputEventValue {
  id: number
  name: string
  comment: string
  attend_date: string
  facility_id: number
}

const defaultValues = {
  name: '',
  comment: '',
  attend_date: new Date().toLocaleString(),
  facility_id: 0
}

interface Error {
  text: string
  type: string
}

interface Facility {
  id: number
  name: string
}

class FormErrors {
  attend_date: string
  name: string
  comment: string
  facility_id: string
  other: string;
}

const ScheduleForm = (values: InputValues) => {
  const [event] = useState(values.event);
  const [facilities] = useState(values.facilities);
  const processing = useRef(false);
  const [message, setMessage] = useState<Error>({text: null, type: null});
  const [formErrors, setFormErrors] = useState<FormErrors>(new FormErrors);
  const formValues = () => {
    if (event.id == null) {
      return defaultValues
    } else {
      defaultValues.name = event.name
      defaultValues.comment = event.comment
      defaultValues.attend_date = new Date(event.attend_date).toLocaleString()
      defaultValues.facility_id = event.facility_id
      return defaultValues
    }
  }

  const validate = (values: EventValue): FormErrors => {
    const errors: FormErrors = new FormErrors()
    if (values.attend_date == null) {
      errors.attend_date = '入力してください'
    }
    if (values.name === "") {
      errors.name = '入力してください'
    }
    if (values.name.length > 30) {
      errors.name = '30文字以内で入力してください'
    }
    if (values.comment.length > 255) {
      errors.comment = '255文字以内で入力してください'
    }
    setFormErrors(errors)
    return errors;
  }


  function createEventProcess(values: EventValue) {
    processing.current = true;
    Axios.post('/schedules', {schedule: values})
      .then(() => {
          setMessage({text: `行事予定を作成しました。`, type: 'notice'});
          window.location.href = '/schedules'
        }
      ).catch(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const error = err.response.data as FormErrors
        setFormErrors(error)
        console.log(formErrors)
      }
    );
    processing.current = false;
  }

  function updateEventProcess(values: EventValue) {
    processing.current = true;
    Axios.put(`/schedules/${event.id}`, {schedule: values})
      .then(() => {
        setMessage({text: `行事予定を更新しました。`, type: 'notice'});
        window.location.href = '/schedules'
      }).catch(err => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions
      setMessage({text: `${err.response.data.message}`, type: 'alert'});
    });
    processing.current = false;
  }

  const handleSubmit = (values: EventValue) => {
    if (processing.current === true) return;
    if (event.id == null) {
      createEventProcess(values);
    } else {
      updateEventProcess(values);
    }
  }
  return (
    <React.StrictMode>
      <>
        {message.text != null && (
          <Alert
            message={message}
            onClose={() => setMessage({text: null, type: null})}
            timeout={3000}
          />
        )}
        <Formik
          enableReinitializec
          initialValues={formValues()}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({values, setFieldValue}) => (
            <Form>
              <div className="card">
                <div className='card_section'>
                  <table className="table_form">
                    <tbody>
                    <tr>
                      <td className='form-text'>
                        <label>
                          <span className="badge_status badge_status_important">必須</span>
                          園
                        </label>
                        <Field as="select" name="facility_id" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          setFieldValue('facility_id', e.target.value);
                        }}>
                          <option value={null} key={0}>選択して下さい</option>
                          {facilities.map((facility: Facility, index: number) => (
                            <option value={facility.id} key={index}>{facility.name}</option>
                          ))}
                        </Field>
                        <span className="red">{formErrors.facility_id}</span>
                        <label>
                          <span className="badge_status badge_status_important">必須</span>
                          日付
                        </label>
                        <div className={'form-select'}>
                          <DatePicker
                            id={"schedule_attend_date"}
                            locale="ja"
                            selected={new Date(values.attend_date)}
                            onChange={(date: Date) => {
                              setFieldValue('attend_date', date);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </div>
                        <span className="red">{formErrors.attend_date}</span>
                        <label>
                          <span className="badge_status badge_status_important">必須</span>
                          名前
                        </label>
                        <Field id={"schedule_name"} type="string" name="name" placeholder="例）七夕まつり"/>
                        <span className="red">{formErrors.name}</span>
                        <label>
                          備考
                        </label>
                        <Field id={"schedule_comment"} type="string" name="comment" placeholder="例）雨天決行"/>
                        <span className="red">{formErrors.comment}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div className='card_section_article'>
                    <div className='col-area'>
                      <span className="red">{formErrors.other}</span>
                      <span>
                        <input type='submit' value="入力した内容で登録" className="button"/>
                      </span>
                      <span>
                        <a href={"/schedules"} className="button button_cancel">入力した内容を破棄</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </React.StrictMode>
  )
}

export default ScheduleForm;

