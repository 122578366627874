import React, {useState} from 'react'
import DragDropContext, {Draggable} from './DragDropContext'
import FlexBox from './atoms/FlexBox'
import SectionArea from './atoms/SectionArea'
import Card from './atoms/Card'
import Axios from '../lib/axios'
import Span from './atoms/Span'
import Space from './atoms/Space'
import { convertBreakToBr, textUrlToLink } from '../shared/text'

function FaqIndex({faqs, category_id}) {
  const [viewFaqs, setViewFaqs] = useState(faqs)

  const handleChangeOrder = (_faqs) => {
    const copy = [...viewFaqs]
    setViewFaqs(_faqs.map((f, idx) => ({...f, number: idx + 1})))
    Axios.put(`/tech/faqs/update_order`, { faqs: _faqs })
      .catch((err) => {
        setViewFaqs(copy)
      })
  }

  const handleDelete = async (id) => {
    const isConfirmed = await confirm('本当に削除してよろしいですか？',)
    if (!isConfirmed) {
      return
    }
    Axios.delete(`/tech/faqs/${id}`)
      .then((res) => {
        location.href = `/tech/faqs?q[faq_category_id_eq]=${category_id}`
      })
  }

  return (
    <SectionArea>
      <DragDropContext
        handleDragEnd={handleChangeOrder}
        targets={viewFaqs}
      >
        {
          viewFaqs.map((f, index) => (
            <Draggable target={f} index={index} key={f.id}>
              <Card>
                <FlexBox content="space-between">
                  <Space margin="0 0 0 2rem">
                    <FlexBox>
                      <Space margin="0 1rem 0 0">
                        <div className="bg-info rounded rounded-4">
                          <Space margin="1rem" padding="1rem">
                            <Span fontWeight="bold" fontSize="20px" color="white">Q</Span>
                          </Space>
                        </div>
                      </Space>
                      <Span fontWeight="bold" fontSize="14px">
                        <div dangerouslySetInnerHTML={{__html: textUrlToLink(convertBreakToBr(f.question))}}></div>
                      </Span>
                    </FlexBox>
                    <FlexBox>
                      <Space margin="0 1rem 0 0">
                        <div className="bg-danger rounded rounded-4">
                          <Space margin="1rem" padding="1rem">
                            <Span fontWeight="bold" fontSize="20px" color="white">A</Span>
                          </Space>
                        </div>
                      </Space>
                      <Span fontWeight="bold" fontSize="14px">
                        <div dangerouslySetInnerHTML={{__html: textUrlToLink(convertBreakToBr(f.answer))}}></div>
                      </Span>
                    </FlexBox>
                  </Space>
                  <Space margin="0 2rem 0 0">
                    <div className="row-area mobile-only_button-area">
                      <span className="button" onClick={() => {location.href = `/tech/faqs/${f.id}/edit?category_id=${f.faq_category_id}`}}>編集</span>
                      <span className="button button_delete" onClick={() => handleDelete(f.id)}>削除</span>
                    </div>
                  </Space>
                </FlexBox>
              </Card>
            </Draggable>
          ))
        }
      </DragDropContext>

    </SectionArea>
  )
}

export default FaqIndex