import DateDecorator from "../DateDecorator";

export const shiftValidation = (reservedShifts, postData, date) => {
  const validationMessageObj = {};

  if(postData.category_id === null) {
    validationMessageObj.category_id = 'シフトのカテゴリは必須です'
  }

  if(postData.account_category === null) {
    validationMessageObj.account_category = '従業員のカテゴリは必須です'
  }

  if(postData.account_category === 'toc_account' && postData.toucheez_role_id === null) {
    validationMessageObj.toucheez_role_id = 'スタッフのシフトの場合には勤務カテゴリの選択は必須です'
  }

  if(postData.started_at > postData.finished_at) {
    validationMessageObj.started_at = '開始時間は終了時間よりも後に設定してください'
  }

  if(postData.category_id === 94 && postData.started_at === null) {
    validationMessageObj.started_at = '稼働のシフトを作成する場合は開始時間は必須です'
  }

  if(postData.category_id === 94 && postData.finished_at === null) {
    validationMessageObj.finished_at = '稼働のシフトを作成する場合は終了時間は必須です'
  }

  if(postData.facility_id === "" || postData.facility_id === null) {
    validationMessageObj.facility_id = '会場選択は必須です'
  }

  if(postData.hall_id === "" || postData.hall_id === null) {
    validationMessageObj.hall_id = '建物選択は必須です'
  }

  if(postData.spot_id === "" || postData.spot_id === null) {
    validationMessageObj.spot_id = '試験室選択は必須です'
  }

  if(postData.account === "" || postData.account === null) {
    validationMessageObj.account = '従業員選択は必須です'
  }

  var targetShift = {};
  reservedShifts.map(s => {
    s.halls.map(h => {
      h.spots.map(sp => {
        sp.shifts.map(shift => {
          if (
            (postData.account_category === 'account' && shift.account_category === 'account' && postData.account === shift.account_id) || 
            (postData.account_category === 'toc_account' && shift.account_category === 'toc_account' && postData.account === shift.toc_account_id)
          ) {

            if (postData.category_id === 95) { // 待機
              validationMessageObj.category_id = '既に対象のアカウントはシフト登録済みです'
            }
  
            if (isOverLappingShift(shift, postData, date) || shift.status_label === '待機') {
              validationMessageObj.started_at = '既に対象のアカウントは指定の時間でシフト登録済みです'
              validationMessageObj.finished_at = null
            }
          }
        })
      })
    })
  })

  return validationMessageObj;
}

const isOverLappingShift = (reservedShift, postDataShift, date) => {
  const reservedShiftStart = new Date(reservedShift.started_at)
  const reservedShiftEnd = new Date(reservedShift.finished_at)
  const postDataShiftStart = new Date(`${new DateDecorator(new Date(date)).param()} ${postDataShift.started_at}`)
  const postDataShiftEnd = new Date(`${new DateDecorator(new Date(date)).param()} ${postDataShift.finished_at}`)

  return reservedShiftStart <= postDataShiftEnd && reservedShiftEnd >= postDataShiftStart;
} 