import React from 'react';

class Alert extends React.Component {
  componentDidMount() {
    this.timer = setTimeout(this.props.onClose, this.props.timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  alertClass(type) {
    let classes = {
      alert: "toast_content_alert",
      notice: "toast_content_notice",
    };
    return classes[type] || classes.notice;
  }

  render() {
    const message = this.props.message;
    // TODO: how to deal with dismiss component
    const alertClassName = `toast_content ${this.alertClass(message.type)}`;
    return (
      <div className="toast_wrap">
        <span className={alertClassName}>{message.text}</span>
      </div>
    );
  }
}

export default Alert;
