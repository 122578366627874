export const textUrlToLink = (str) => {
  var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
  var regexp_makeLink = function(all, url, h, href) {
    return `<a href='h${href}' target='_blank' rel='noopener noreferrer' class=''><span>h${href}</span><span class='material-icons awesome_text_material_icon'>open_in_new</span></a>`;
  }
  return str.replace(regexp_url, regexp_makeLink);
}

export const convertBreakToBr = (str) => {
  return str.replace(/(\r\n|\n|\r)/gm, '<br>');
}

export const convertRemToPx = (fontSizeText) => {
  let rem = 0;
  let fontSize = 0;
  if(fontSizeText.substring(fontSizeText.length - 3) == 'rem') {
    rem = Number(fontSizeText.replace("rem", ""));
    fontSize = getComputedStyle(document.documentElement).fontSize;
    return rem * parseFloat(fontSize);
  } else if (fontSizeText.substring(fontSizeText.length - 2) == 'px'){
    fontSizeText.replace("px", "")
    return Number(fontSizeText.replace("px", ""));
  } else {
    return fontSizeText
  }
}

export const isDateFormatString = (string) => {
  return (new Date(string) !== "Invalid Date") && !isNaN(new Date(string));
}