import React from 'react';
import IndexCard from "../../../atoms/IndexCard";

const EventCard = ({event}) => {
  return (
    <IndexCard>
      <div className='row-area row-area_xlarge main-side-area'>
        <div className='col-area'>
          <div className='row-area row-area_small'>
            <span className='badge_number badge_applied'>
              申込人数 {event.number_of_applied_or_confirmed}
            </span>
            <span className='badge_number badge_number_of_applicants'>
              残り席数 {event.number_of_left_seat}
            </span>
          </div>
          <h1><a href={`/baseq/admin/events/${event.id}`}>{event.name}</a></h1>
          <div className='row-area row-area_xlarge'>
            <span className='badge'>開催期間</span>
            <span className='period'>{event.event_period}</span>
          </div>
        </div>
        <div className='col-area'>
          <div className='row-area row-area_xlarge'>
            <span className='row-area'>
              <span>
                <a href='#' className='button'>開催確定</a>
              </span>
              <a className='button button_delete'>
                <div className='round_btn'></div>
                <span>開催中止</span>
              </a>
            </span>
          </div>
        </div>
      </div>
    </IndexCard>
  );
}

export default EventCard
