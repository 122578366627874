import React, {useCallback, useState, useEffect} from 'react'
import LoadingOverlay from './LoadingOverlay'
import Icon from './Icon';
import {ResizedImage} from './ResizedImage';
import { uploadFile } from '../shared/api/uploadFile';
import { resizeFile } from "../shared/resizeFile";

const ImageUploadButton = ({
                              deffaultLink,
                              valid_extensions,
                              placeholder,
                              className,
                              buttonClassName,
                              onChange
}) => {
  const [originalfile, setOriginalFile] = useState('');
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeHandler = useCallback((e) => {
    // ファイル選択がキャンセルされた時は undefined
    setLoading(true);
    setOriginalFile(e.target.files[0]);
    resizeFile(e.target.files[0], 600, 800, (resized) => {
      uploadFile(
          [resized],
        (data) => {
          onChange(data.upload_file_id);
          setLoading(false);
        }, (message) => {
          setErrMessage(message);
          setLoading(false);
        }
      )
    }, "file")
  }, []);

  const handleDelete = (e) => {
    e.preventDefault();
    setOriginalFile('');
    onChange('');
  }

  const uploadFormStyle = {
    height: '100%',
    left: '0',
    opacity: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
    cursor : 'pointer',
    color: '#006464',
    border: '1px solid #006464',
    display: 'none',
    alignItems: 'center',
  }

  if (loading) {
    return (<LoadingOverlay/>)
  } else {
    return (
      <>
        <ResizedImage
          image={originalfile}
          deffaultLink={deffaultLink}
          figureClass={"dev-team_show_image-area dev-team_show_image-area_upload"}
          deleteButtonClass={'absolute-rt red_batu'}
          imgClass={""}
          onDelete={handleDelete}
          hideDelete={false}
        />
        {!originalfile && (
          <div id='file_upload_area' className={className}>
            <label htmlFor={'file_selection'}>
              <div className={buttonClassName}>
                <Icon name="cloud_upload"/>
                <span>{placeholder || 'ファイルを選択してください'}</span>
                <input
                  type="file"
                  id='file_selection'
                  multiple
                  onChange={onChangeHandler}
                  accept={valid_extensions}
                  style={uploadFormStyle}
                />
              </div>
            </label>
            {(errMessage != "") && <span style={{color: "red"}}>{errMessage}</span>}
          </div>
        )}
      </>
    )
  }
}
export default ImageUploadButton
