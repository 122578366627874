import React, {memo} from 'react';
import Space from '../../atoms/Space';
import FlexBox from '../../atoms/FlexBox';
import Span from '../../atoms/Span';
import Container from '../../atoms/Container';
import Width from '../../atoms/Width';

const RadioButton = memo(({ label, required, options, selectedValue, nameKey, onChange, errorMessage, disabled }) => {
  return (
    <Space padding="1rem">
      <Space margin="0 0 1rem 0">
        <FlexBox>
          <Container>
            <Span fontSize={"12px"} fontWeight={"bold"}>{label}</Span>
          </Container>
          <Space margin="0 0 0 1rem">
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </Space>
        </FlexBox>
      </Space>
      <Width>
        <FlexBox>
          {
            options.map((option, index) => (
              <Space margin="0 1rem 0 0">
                <FlexBox>
                  <span key={`${nameKey}-${index}-${index}`}>
                    <input
                      type={"radio"}
                      value={option.id}
                      checked={selectedValue === option.id}
                      id={`${nameKey}-${index}`}
                      name={nameKey}
                      onChange={onChange}
                      disabled={disabled}
                    />
                  </span>
                  <label htmlFor={`${nameKey}-${index}`}>{option.label}</label>
                </FlexBox>
              </Space>
            ))
          }
        </FlexBox>
      </Width>
      {
        errorMessage &&
        (
          <tr className={'error_message'}>
            <span>{errorMessage}</span>
          </tr>
        )
      }
    </Space>
  );
});

export default RadioButton;
