import useSWR, { useSWRConfig } from 'swr';
import {
  getCourses,
} from '../apis';
import {useEffect} from 'react'

const globalConfig = {
  dedupingInterval: 2000,
  revalidateOnFocus: false,
}

export function useCourses(params) {
  const { data, error, mutate } = useSWR(
    [`/tech/user/courses/list`, params],
    getCourses,
    {...globalConfig, dedupingInterval: 10000}
  );
  const { mutate: globalMutate } = useSWRConfig()
  
  useEffect(() => {
    //globalMutate(
    //  [`/tech/user/courses/list`, {...params, page: params.page + 1}],
    //  () => getCourses(null, {...params, page: params.page + 1}),
    //)
    //globalMutate(
    //  [`/tech/user/courses/list`, {...params, page: params.page + 2}],
    //  () => getCourses(null, {...params, page: params.page + 2}),
    //)
  }, [params])

  return {
    courses: data?.data?.courses,
    pageSize: data?.data?.page_size,
    error,
    isLoading: !error && !data?.data,
    coursesMutate: mutate,
  };
}
