import React, {useState, useEffect} from 'react'
import Axios from 'lib/axios';
import LoadingOverlay from './LoadingOverlay'

const FileUploader = ({
  label,
  name,
  required,
  deffalt,
  valid_extensions,
  hidePreview,
  placeholder,
  maxFileSize = 10,
  onChange,
  errorMessage,
}) => {
  const [files, setFiles] = useState([]);
  const [file_id, setFileId] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (typeof deffalt === "string" && deffalt.indexOf('reset') >= 0 ){
      setFiles([]);
      setFileId("");
    } else if ((typeof deffalt !== "undefined") && ( deffalt !== null) && (deffalt !== "") ){
      Axios.get("/upload_files/" + deffalt).then(({data}) => {
        setFiles(data.files);
        setFileId(data.upload_file_id);
        if (typeof onChange !== 'undefined') {
          onChange(data.upload_file_id);
        }
      }).catch((err) => {
        setErrMessage(err.response.data.message);
      });
    }
  }, [deffalt]);

  const onChangeHandler = (e) => {
    // ファイル選択がキャンセルされた時は undefined
    const file = e.target.files;
    const formData = new FormData();
    for (let i=0; i<file.length; i++) {
      formData.append('files[]', file[i]);
      if (file[i].size > (maxFileSize * 1024 * 1024)) {
        alert(maxFileSize + "MB以上");
        e.target.files.value = '';
        return;
      }
    }

    const uploadUri = '/upload_files';
    setLoading(true);

    Axios.post(uploadUri,formData).then(({data}) => {
      // アップロード成功次の処理
      setFiles(data.files);
      setFileId(data.upload_file_id);
      if (typeof onChange !== 'undefined') {
        onChange(data.upload_file_id);
      }
      setLoading(false);
    }).catch((err) => {
      setErrMessage(err.response.data.message);
      // アップロード失敗時の処理
      setLoading(false);
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setFileId("")
    
    if (typeof onChange !== 'undefined') {
      onChange("");
    }
    setFiles([])
  }

  const uploadFormDivStyle = {
    border: '3px solid',
    display: 'inline-block',
    padding: '2px 1em',
    position: 'relative'
  }

  const uploadFormStyle = {
    height: '100%',
    left: '0',
    opacity: '0',
    position: 'absolute',
    top: '0',
    width: '100%',
    cursor : 'pointer'
  }

  if (loading) {
    return (<LoadingOverlay/>)
  } else {
    return (
      <React.StrictMode>
      <>
        <tr>
          <th>
            <label>
              {label}
              {required && <span className={"badge_status badge_status_important"}>必須</span>}
            </label>
          </th>
        </tr>
        <tr>

          <td>
            <div id='file_upload_area' style={uploadFormDivStyle}>
              {placeholder || 'ファイルを選択してください'}
              <input type="file" id='file_selection' multiple onChange={onChangeHandler}  accept={valid_extensions} style={uploadFormStyle}/>
              {(errMessage != "") && <span style={{color: "red"}}>{errMessage}</span>}
            </div>
            <input type="hidden" name={name} value={file_id}/>
            {!hidePreview && <div className={"row mb-2"}>
              <div className={"col-11"}>
                <div className={"form-row mb-1"}>
                  {files && (
                    files.map((file, index) => (
                      <div className={"col-sm-4"} key={index} >
                        {['jpg', 'png', 'jpeg'].includes(file.name.split('.').pop()) &&
                          <img src={file.link} alt='preview' className={"img-rounded img-responsive"} style={{width: "100%", marginTop: '2rem'}}/>
                        }
                        <span style={{color: "black"}}>{file.name}</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>}
            {files.length > 0 &&
              <div onClick={(e) => handleDelete(e)}>
                <span style={{cursor: 'pointer'}}>x削除</span>
              </div>
            }
            { errorMessage && <span className={'error_message'}>{errorMessage}</span> }
          </td>
        </tr>
      </>
      </React.StrictMode>
    )
  }
}
export default FileUploader
