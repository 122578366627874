import React from 'react';
import Card from '../../../../atoms/Card';
import NoticeMessage from '../../shared/NoticeMessage';
import CardSection from '../../../../atoms/CardSection';
import { textUrlToLink, convertBreakToBr } from '../../../../../shared/text';
import { formatDateSlash, formatTimeSlash } from '../../../../../shared/date';
import FlexBox from '../../../../atoms/FlexBox';
import Space from '../../../../atoms/Space';
import DateDecorator from '../../../../DateDecorator';
import Span from '../../../../atoms/Span';
import { timeZoneLabel } from '../../../../../shared/date';
import movie_02 from '../../../movie_02.jpg';
function CourseDescription({ course, brand_selection }) {
  const onClickHandler = (documentId) => {
    window.open(`${course.id}/video?document_id=${documentId}`, 'subwin', 'width=500,height=500');
  };
  console.log(brand_selection);
  return (
    <Card>
      {!course.is_recruiting_status_active && <NoticeMessage gray>このコースは現在募集を停止しています</NoticeMessage>}
      <CardSection>
        <div className="card_in_card">
          <div className="card">
            <div className="card_content">
              <div className="card_content_section">
                <h2 className="tech_user_course_show_subtitle">コース概要</h2>
                <div className="card_content_section_article">
                  {course.course_template.course_overview.upload_file_link_1 && (
                    <div>
                      <img src={course.course_template.course_overview.upload_file_link_1} alt="" />
                    </div>
                  )}
                </div>
                <div className="card_content_section_article">
                  <div
                    className="break_word"
                    dangerouslySetInnerHTML={{
                      __html: textUrlToLink(convertBreakToBr(course.course_template.course_overview.content_1)),
                    }}
                  />
                </div>
                {course.course_template.tech_course_introduction_id !== null && (
                  <div className="card_content_section_article mb-3">
                    <div className="mt-3">
                      <div
                        className="tech_user_course_lesson_video_row"
                        onClick={() => onClickHandler(course.course_template.tech_course_introduction_id)}
                      >
                        <div className="tech_user_course_lesson_video_row_content">
                          <div className="tech_user_course_lesson_video_row_img_div">
                            <img src={movie_02} className="tech_user_course_lesson_video_row_img" />
                          </div>
                          <div className="tech_user_course_lesson_video_row_title">
                            <span>コース紹介動画</span>
                          </div>
                        </div>
                        <div>
                          <span class="material-icons">open_in_new</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!course.is_less_yoake_course && brand_selection !== "yoake" && (
                  <div className="mt-3" style={{ overflow: 'hidden' }}>
                    <h3 className="tech_user_course_show_subtitle_2">メイントレーナーの略歴</h3>
                    <div className="tech_user_course_trainer">
                      <img src={course.main_trainer_photo_link} className="tech_user_course_trainer_img" alt="" />
                      <div className="tech_user_course_trainer_txt">
                        <span>{course.main_trainer_name}</span>
                        <div className="card_content_section_article">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: textUrlToLink(convertBreakToBr(course.main_trainer_description)),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(course.course_template.course_overview.upload_file_link_2 ||
                course.course_template.course_overview.content_2) && (
                <>
                  <h2 className="tech_user_course_show_subtitle">コースに関する補足</h2>
                  <div className="card_content_section_article">
                    {course.course_template.course_overview.upload_file_link_2 && (
                      <div>
                        <img src={course.course_template.course_overview.upload_file_link_2} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="card_content_section_article">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textUrlToLink(convertBreakToBr(course.course_template.course_overview.content_2 || '')),
                      }}
                    />
                  </div>
                </>
              )}
              <div className="card_content_section">
                <h2 className="tech_user_course_show_subtitle">参加者の要件</h2>
                <div className="card_content_section_article">
                  <div className="mt-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textUrlToLink(convertBreakToBr(course.course_template.course_requirement.content)),
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card_content_section">
                <h2 className="tech_user_course_show_subtitle">アジェンダ</h2>
                <div className="card_content_section_article">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: textUrlToLink(convertBreakToBr(course.course_template.agenda.content)),
                    }}
                  />
                  {course.lessons.length > 1 && (
                    <div className="mt-3">
                      <h3 className="tech_user_course_show_subtitle_2">日程</h3>
                      <div>
                        <table className="table">
                          <tbody>
                            {course.sorted_course_lessons.map((courseLesson, idx) => (
                              <tr>
                                <th className="va_middle ws_nowrap p-0">
                                  <FlexBox content="center" height="4rem">
                                    <span className="fw_bold">{`第${idx + 1}回`}</span>
                                  </FlexBox>
                                </th>
                                <td className="va_middle ws_nowrap p-0">
                                  <FlexBox content="center">
                                    <div>
                                      <span>
                                        {new DateDecorator(new Date(courseLesson.start_at)).dateTimeSlashLabel()}
                                        {timeZoneLabel(new Date(courseLesson.start_at))}
                                      </span>
                                      <FlexBox content="center">
                                        <div>〜</div>
                                      </FlexBox>
                                      <span>
                                        {new DateDecorator(new Date(courseLesson.finish_at)).dateTimeSlashLabel()}
                                        {timeZoneLabel(new Date(courseLesson.finish_at))}
                                      </span>
                                    </div>
                                  </FlexBox>
                                </td>
                                <td className="va_middle p-0">
                                  <div className="col-area col-area_xlarge text-center">
                                    <div className="fw_bold">{courseLesson.lesson.name}</div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {course.lessons.length === 1 && (
                    <div className="mt-3">
                      <h3 className="tech_user_course_show_subtitle_2">実施日時</h3>
                      <div>
                        {`${formatDateSlash(course.first_course_lesson.start_at)} ~ ${formatDateSlash(
                          course.first_course_lesson.finish_at
                        )}`}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card_content_section">
                <h2 className="tech_user_course_show_subtitle">開催場所</h2>
                <div>
                  <Span fontSize="14px" fontWeight="bold">
                    {course.venue.label}
                  </Span>
                </div>
                {course.venue.label !== 'オンライン' && (
                  <>
                    <div className="mb-2">
                      <span>{`${course.venue.address}`}</span>
                    </div>
                    <div className="mb-2">
                      (
                      <a href={course.venue.google_map_url} target="_blank">
                        Google Map<span className="material-icons awesome_text_material_icon">open_in_new</span>
                      </a>
                      )
                    </div>
                  </>
                )}
              </div>
              <div className="card_content_section">
                <h2 className="tech_user_course_show_subtitle">注意事項</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: textUrlToLink(convertBreakToBr(course.course_template.caution.content)),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </CardSection>
    </Card>
  );
}

export default CourseDescription;
