import React, {useEffect, memo} from 'react';
import FlexBox from '../../atoms/FlexBox';
import Span from '../../atoms/Span';
import Space from '../../atoms/Space';
import Container from '../../atoms/Container';
import Width from '../../atoms/Width';

const DatePeriodForm = memo(({ label, required, fromKey, toKey, from, to, onChange, fromErrorMessage, toErrorMessage, disabled }) => {
  useEffect(() => {
    $(`input[name='${fromKey}']`).bootstrapMaterialDatePicker({
      weekStart: 0,
      lang: "ja",
      attend_date: false,
      time: true,
      format: "HH:mm",
    }).on('change', function (e, date) {
      onChange(e)
    });

    $(`input[name='${toKey}']`).bootstrapMaterialDatePicker({
      weekStart: 0,
      lang: "ja",
      attend_date: false,
      time: true,
      format: "HH:mm",
    }).on('change', function (e, date) {
      onChange(e)
    });
  })

  return (
    <Space padding="1rem">
      <Space margin="0 0 1rem 0">
        <FlexBox>
          <Container>
            <Span fontSize={"12px"} fontWeight={"bold"}>{label}</Span>
          </Container>
          <Space margin="0 0 0 1rem">
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </Space>
        </FlexBox>
      </Space>
      <Width>
        <td className={"form-text"}>
          <FlexBox>
            <input
              type={"text"}
              id={`id-${fromKey}`}
              name={fromKey}
              defaultValue={from}
              placeholder={"開始時間を選択してください"}
              disabled={disabled}
            />
            <Container>
              <span className={"row-area_liquid_connect"}>
                  〜
              </span>
            </Container>
            <input
              type={"text"}
              id={`id-${toKey}`}
              name={toKey}
              defaultValue={to}
              placeholder={"終了時間を選択してください"}
              disabled={disabled}
            />
          </FlexBox>
        </td>
      </Width>
      {
        (fromErrorMessage || toErrorMessage) &&
        (
          <tr className={'error_message'}>
            {fromErrorMessage && <span>{fromErrorMessage}</span>}
            {toErrorMessage && (
              <>
                <br/>
                <span>{toErrorMessage}</span>
              </>
            )}
          </tr>
        )
      }
    </Space>
  );
});

export default DatePeriodForm;
