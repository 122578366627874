import React from 'react';
import DateDecorator from './DateDecorator'
import MonthPickerWithPrevNextButton from './MonthPickerWithPrevNextButton'

const DailyReportMonthPicker = (props) => {
  const handlePrevMonth = (targetDate) => {
    location.replace(props.path + `?target_date=${new DateDecorator(new Date(targetDate.setMonth(targetDate.getMonth() - 1))).param()}`);
  };

  const handleNextMonth = (targetDate) => {
    console.log(targetDate)
    location.replace(props.path + `?target_date=${new DateDecorator(new Date(targetDate.setMonth(targetDate.getMonth() + 1))).param()}`);
  };

  return (
    <React.StrictMode>
    <div className="heading-area">
      <div className="title-area">
        <div className="title-area_pagination">
          <MonthPickerWithPrevNextButton handlePrevMonth={handlePrevMonth} handleNextMonth={handleNextMonth} targetDate= {props.targetDate}/>
        </div>
      </div>
    </div>
    </React.StrictMode>
  )
}

export default DailyReportMonthPicker
