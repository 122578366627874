import React from 'react';

function PaymentTypeRadio({ paymentType, onChange, onChangeFormType }) {
  return (
    <div className="card_in_card">
      <div className="card">
        <div className="card_content">
          <div className="card_content_section">
            <h2 className="tech_user_course_show_subtitle">お支払い方法</h2>
            <div className="user_course_show_radio_form">
              <span className="user_course_show_radio_form_option" onClick={(e) => onChange('銀行振込')}>
                <input type={'radio'} checked={paymentType === '銀行振込'} />
                <label>銀行振込</label>
              </span>
              <span
                className="user_course_show_radio_form_option"
                onClick={(e) => {
                  onChange('クレジットカード');
                  onChangeFormType('参加者本人が申し込む');
                }}
              >
                <input type={'radio'} checked={paymentType === 'クレジットカード'} />
                <label>クレジットカード</label>
              </span>
            </div>
            <div>※ 銀行振込の場合、別途振込手数料をご負担いただきます</div>
            <div>※ クレジットカード支払いの場合は、参加者ご本人の申し込みしかできません</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentTypeRadio;
