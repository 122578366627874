import React, {useState} from 'react';
import SubjectGradeIndex from './evide/grade/SubjectGradeIndex';

const ReactRailsBaseComponent = (props) => {
    const childComponents = {
        'SubjectGradeIndex' : SubjectGradeIndex,
    }
    const Component = childComponents[props.childComponentName]
    return <Component {...props}/>
}

export default ReactRailsBaseComponent
