import React, {useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import DateDecorator from "./DateDecorator";
registerLocale('ja', ja);


const MonthPicker = (props) => {
  let date;
  if (typeof props.date == "string") {
    date = new Date(Date.parse(props.date))
  } else {
    date = props.date
  }
  const [startDate, setStartDate] = useState(date || new Date());
  const decorator = new DateDecorator(startDate);
  return (
    <span className="form-text datetime">
      <DatePicker
        locale="ja"
        selected={startDate}
        onChange={date => {setStartDate(date); props.onClick ? props.onClick(date) : null;}}
        dateFormat={props.format || "yyyy-MM"}
        id='month-select'
        showMonthYearPicker
      />
      <input name={"month"} type={"hidden"} value={decorator.monthLabel()}></input>
    </span>
  )
}
export default MonthPicker