export default class DateDecorator {
  constructor(date) {
    this.date = date;
    this.year = date.getFullYear();
    this.month_index = date.getMonth();
    this.lastDateOfMonth = new Date(this.year, this.month_index + 1, 0).getDate();
    this.weekLabels = [ "日", "月", "火", "水", "木", "金", "土" ];
    this.days = [...Array(this.lastDateOfMonth)].map((_, i) => `${i + 1}(${this.weekLabel(i + 1)})`);
  }

  isPast() {
    return this.date < new Date()
  }

  timeLabel() {
    return `${this.zeroFill(this.date.getHours())}:${this.zeroFill(this.date.getMinutes())}`
  }

  fristDayOfMonth(){
    return this.date.getDay();
  }

  weekLabel(day) {
    return this.weekLabels[new Date(this.year, this.month_index, day).getDay() % 7];
  }

  label() {
    return `　${this.year}年${this.currentMonthLabel()}月　`
  }

  fullLabel() {
    return `　${this.year}年${this.currentMonthLabel()}月${this.currentDateLabel()}日`
  }

  dateTimeSlashLabel(){
    return `${this.year}/${this.currentMonthLabel()}/${this.currentDateLabel()} ${this.zeroFill(this.date.getHours())}:${this.zeroFill(this.date.getMinutes())}`
  }

  monthLabel() {
    return `　${this.year}年${this.currentMonthLabel()}月`
  }

  zeroFill(value) {
    return ('0' + (value)).slice(-2)
  }

  currentMonthLabel() {
    return `${this.zeroFill(this.month_index + 1)}`
  }

  currentDateLabel() {
    return `${this.zeroFill(this.dat())}`
  }

  currentTimeLabel() {
    return `${this.zeroFill(this.hour())}:${this.zeroFill(this.min())}`
  }

  year() {
    return `${this.year + 1}`
  }

  month() {
    return `${this.month_index + 1}`
  }

  dat() {
    return `${this.date.getDate()}`
  }

  hour() {
    return `${this.date.getHours()}`
  }

  min() {
    return `${this.date.getMinutes()}`
  }

  param() {
    return `${this.year}-${this.currentMonthLabel()}-${this.date.getDate()}`
  }

  timeParam() {
    return `${this.year}-${this.currentMonthLabel()}-${this.date.getDate()} ${this.date.getHours()}:${this.date.getMinutes()}`
  }

  makePrev(){
    return new DateDecorator(new Date(this.year, this.month_index - 1, 1));
  }

  makeNext(){
    return new DateDecorator(new Date(this.year, this.month_index + 1, 1));
  }

  makeByYear(year){
    return new DateDecorator(new Date(year, this.month_index, 1));
  }

  makeByMonth(month_index){
    return new DateDecorator(new Date(this.year, month_index, 1));
  }

  setYear(year){
    return new DateDecorator(new Date(year, this.month_index, this.date.getDate()));
  }

  setMonth(month){
    return new DateDecorator(new Date(this.year, month, this.date.getDate()));
  }

  setDate(date){
    return new DateDecorator(new Date(this.year, this.month_index, date, this.date.getHours()));
  }

  setHour(hour){
    return new DateDecorator(new Date(this.year, this.month_index, this.date.getDate(), hour, this.date.getHours()));
  }

  year_month() {
    return `${this.year}-${this.currentMonthLabel()}`
  }

  prevDay(){
    return new DateDecorator(new Date(this.year, this.month_index, this.date.getDate() - 1));
  }

  nextDay(){
    return new DateDecorator(new Date(this.year, this.month_index, this.date.getDate() + 1));
  }

  prevMonth(){
    return new DateDecorator(new Date(this.year, this.month_index - 1, this.date.getDate()));
  }

  nextMonth(){
    return new DateDecorator(new Date(this.year, this.month_index + 1, this.date.getDate()));
  }
  isoString(){
    return `${this.year}-${this.currentMonthLabel()}-${this.currentDateLabel()}T${this.timeLabel()}`
  }
}