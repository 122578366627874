import React from 'react';
import {formatDateSlash, formatTimePeriod} from '../../shared/date'
import ModalButton from '../../components/ModalButton';
import DateTime from '../form/DateTime';
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker';
import DateDecorator from "../DateDecorator";

const ScheduleTable = (({
  lessons,
  from,
  to,
  onChange,
  disabled,
  customClass,
  errors
}) => {
  return (
    <div className={customClass}>
      <table className={"table"}>
        <thead className={'center'}>
          <tr>
            <th>授業名</th>
            <th>開始日時</th>
            <th>終了日時</th>
          </tr>
        </thead>
        <tbody className={'center'} key={'preview'}>
        {lessons.map((l, i) => (
          <tr key={i}>
            <td>{l.name || l.lesson_name}</td>
            <td>
              <span className="form-text tech_course_datepicker_popper">
                <DatePicker
                  selected={!!l.start_at ? new Date(l.start_at) : ''}
                  onChange={(selected, e) => {
                    selected ? onChange(new DateDecorator(selected).isoString(), i, 'start_at') : onChange('', i, 'start_at')
                  }}
                  dateFormat="yyyy-MM-dd HH:mm"
                  timeFormat="HH:mm"
                  id='month-select'
                  showTimeSelect
                  disabled={disabled}
                />
              </span>
            </td>
            <td>
              <span className="form-text tech_course_datepicker_popper">
                <DatePicker
                  selected={!!l.finish_at ? new Date(l.finish_at) : ''}
                  onChange={(selected, e) => {
                    selected ? onChange(new DateDecorator(selected).isoString(), i, 'finish_at') : onChange('', i, 'finish_at')
                  }}
                  dateFormat="yyyy-MM-dd HH:mm"
                  timeFormat="HH:mm"
                  id='month-select'
                  showTimeSelect
                  disabled={disabled}
                />
              </span>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {errors && (
        <tr className={'error_message'}>
          <td>
            <span>{errors}</span>
          </td>
        </tr>
      )}
    </div>
  );
})

export default ScheduleTable;
