import Resizer from 'react-image-file-resizer';

export const resizeFile = ( file, rowSize=400, colSize=400, onResized={}, resizedFormat="base64" ) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      rowSize,
      colSize,
      "JPEG",
      100,
      0,
      (uri) => onResized(uri),
      resizedFormat
    );
  });
