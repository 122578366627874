import React from 'react';

const SubjectScore = ({ id, scores, label }) => {
  return (
    <tr key={id}>
      <td></td>
      <td className="va_middle"></td>
      <td className="va_middle">{label}</td>
      {scores.map((score, index) => {
        return (
          <React.Fragment key={id + index}>
            <td className="va_middle">{score}</td>
            <td className="va_middle"></td>
          </React.Fragment>
        )
      })}
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  )
};

export default SubjectScore;