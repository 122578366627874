import React, {useEffect, useState} from 'react';

const overlayStyle = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  background: 'rgba(0,0,0,0.8)',
  zIndex: 2147483647,
}

const messageStyle = {
  textAlign: "center",
  color: "#fff",
  fontWeight: 600
}

const lastSpanStyle = {
  textAlign: "center",
  color: "#fff",
  fontWeight: 600,
  marginTop: "2rem"
}

const imgStyle = {
  width: "30%"
}

// const LoadingOverlay = ({src}) => {
//   console.log(src);
//   return (
//     <div style={overlayStyle}>
//       <span style={messageStyle}>処理中</span>
//       <span style={lastSpanStyle}>
//         <img src={src} style={imgStyle}/>
//       </span>
//     </div>
//   )
// }

const LoadingOverlay = () => {
  const[message, setMessage] = useState('処理中')
  const intervalId = setInterval(() => updateMessage(), 1000);

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    }
  })

  const updateMessage = () => {
    if (message === '処理中...') {
      setMessage('処理中.')
    } else {
      setMessage(message + '.')
    }
  }

  return (
    <div style={overlayStyle}>
      <span style={messageStyle}>{message}</span>
    </div>
  )
}

export default LoadingOverlay
