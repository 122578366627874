import React, {useState, useRef} from 'react';
import {Formik, Form, Field, FieldArray, FastField} from 'formik';
import Axios from '../Axios';
import Alert from '../Alert';
import FormColumn from '../FormColumn';

const defaultValues = {
  position: 1,
  title: '',
  is_other_allowed: false,
  evide_question_form_enum_id: 1,
  choices: Array(1).fill(''),
}

const validate = values => {
  let errors = {}
  if (!values.position) {
    errors.position = '入力してください'
  } else if (Number(values.position) > 9999999) {
    errors.position = '7桁以内で入力してください'
  } else if (Number(values.position) < 0) {
    errors.position = '0以上を入力してください'
  }
  if (!values.title) {
    errors.title = '入力してください'
  } else if (values.title.length > 255) {
    errors.title = '２５５文字以下で入力してください'
  }
  return errors;
}

const QuestionForm= ({ questionnaire, question, isPattern= false }) => {
  const [message, setMessage] = useState();
  const [shouldShowOptions, toggleShowOptions] = useState(question ? question.evide_question_form_enum_id != 1 : false);

  const baseUrl = isPattern ? '/evide/questionnaire_patterns' : '/evide/questionnaires'
  const paramsKey = isPattern ? 'evide_question_pattern' : 'question'

  const formValues = () => {
    return question ?
      { ...question, choices: question.question_choices.length > 0 ? question.question_choices.map(c => c.name) : defaultValues.choices } :
      { ...defaultValues, position: questionnaire.questions.length + 1 }
  }
  const processing = useRef(false);

  const handleSubmit = values => {
    if (processing.current == true ) return;
    if (question) {
      processing.current = true;
      Axios.put(`${baseUrl}/${questionnaire.id}/questions/${question.id}`, {[paramsKey]: values})
        .then(({data}) => {
          setMessage({text: `更新しました`, type: 'notice'});
          window.location.href = `${baseUrl}/${questionnaire.id}`
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    } else {
      processing.current = true;
      Axios.post(`${baseUrl}/${questionnaire.id}/questions`, { [paramsKey]: values})
        .then(({data}) => {
          setMessage({text: `登録しました`, type: 'notice'});
          window.location.href = `${baseUrl}/${questionnaire.id}`
        }).catch(err => {
        setMessage({text: `システムエラーが発生しました。原因：${err}`, type: 'alert'});
      });
    }
  }

  const handleFormChange = (e, setFieldValue) => {
    toggleShowOptions(e.target.value !== "1");
    setFieldValue("evide_question_form_enum_id", e.target.value);
  }

  const choiceValidation = (value) => {
    if (!value.trim()) {
      return '入力してください'
    }
    return value.length > 255 ? "２５５文字以下で入力してください" : undefined
  }

  return (
    <React.StrictMode>
    <div>
      {message && (
        <Alert
          message={message}
          onClose={() => setMessage('')}
          timeout={3000}
        />
      )}
      <h3>{questionnaire.name}</h3>
      <div className='section-area'>
        <Formik
          enableReinitialize
          initialValues={formValues()}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({values, errors, setFieldValue}) => (
            <Form>
              <div className='main-side-area'>
                <div className='main-side-area_main'>
                  <div className='card'>
                    <div className='card_section'>
                      <table className='table_form'>
                        <tbody>
                          <FormColumn label='No.' required>
                            <td className='form-text'>
                              <Field type="number" name="position" id="position" placeholder="例）1"/>
                              <span className="red">{errors.position}</span>
                            </td>
                          </FormColumn>
                          <FormColumn label='質問' required>
                            <td className='form-text'>
                              <Field name="title" id="title" placeholder="例）オープンキャンパスを知ったきっかけは?"/>
                              <span className="red">{errors.title}</span>
                            </td>
                          </FormColumn>
                          <FormColumn label='回答方式' required>
                            <td className='form-text'>
                              <Field as='select' id="evide_question_form_enum_id" name='evide_question_form_enum_id' onChange={e => handleFormChange(e, setFieldValue)} disabled={ isPattern ? false : !questionnaire.deletable}>
                                <option value='1'>記述式</option>
                                <option value='2'>単一選択式</option>
                                <option value='3'>複数選択式</option>
                              </Field>
                            </td>
                          </FormColumn>
                          {shouldShowOptions &&
                            <FormColumn>
                              <td className='form-checkbox'>
                                <div className="row-area">
                                  <span><Field type="checkbox" name="is_other_allowed" id="is_other_allowed" style={{width: "auto"}}/></span>
                                  <span><label>その他を許容する</label></span>
                                </div>
                              </td>
                            </FormColumn>
                          }
                          {shouldShowOptions &&
                            <FormColumn label='選択肢' required>
                              <td className="form-text">
                                <FieldArray
                                  name="choices"
                                  render={(arrayHelpers) => {
                                    return values.choices.map((c, i) => {
                                      return (
                                        <FastField
                                          key={i}
                                          name={`choices.${i}`}
                                          validate={(value) => choiceValidation(value)}
                                        >
                                          {({field, form, meta}) => (
                                            <div style={{marginBottom: "1rem"}}>
                                              <div style={{display: "flex"}}>
                                                <Field key={i} name={`choices.${i}`} placeholder="入力してください"/>
                                                { (isPattern || questionnaire.deletable) && (
                                                  <>
                                                    {i > 0 &&
                                                      <button type="button" id={`remove${i}`} style={{marginLeft: "1rem"}}
                                                              onClick={() => arrayHelpers.remove(i)}>
                                                        ×
                                                      </button>
                                                    }
                                                    <button type="button" id={`add${i}`} style={{marginLeft: "1rem"}}
                                                    onClick={() => arrayHelpers.insert(i + 1, '')} // insert an empty string at a position
                                                    >
                                                    +
                                                    </button>
                                                  </>
                                                  )}
                                                </div>
                                              <span className={'red'}>{meta.error} </span>
                                            </div>
                                            )}
                                        </FastField>
                                      );
                                    })
                                  }}
                                />
                              </td>
                            </FormColumn>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='main-side-area_side'>
                  <div className='card sticky_top'>
                    <div className='card_section'>
                      <div className='card_section_article'>
                        <h1>ページの操作</h1>
                      </div>
                    </div>
                    <div className='card_section'>
                      <div className='card_section_article'>
                        <div className='col-area'>
                          <span>
                            <input type='submit' value={question ? "入力した内容を保存" : "入力した内容で登録"} className="button"/>
                          </span>
                          <span>
                            <a href={`${baseUrl}/${questionnaire.id}`} className="button button_cancel">入力した内容を破棄</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
    </React.StrictMode>
  );
}

export default QuestionForm;
