import React, {useState} from 'react'
import TextField from './form/TextField';
import Space from './atoms/Space';

function Form({category, errors}) {
  const [name, setName]= useState(category.name || '')
  return (
    <>
      <Space margin="1rem 0 2rem 0">
        <h1>FAQカテゴリの作成フォーム</h1>
      </Space>
      <table className="table_form">
        <tbody>
          <TextField
            label="カテゴリ名"
            required
            value={name}
            placeholder="例）会員登録について"
            nameKey="faq_category[name]"
            onChange={(e) => setName(e.target.value)}
            errorMessage={errors?.name ? `カテゴリ名${errors.name}` : ''}
            type="text"
          />
        </tbody>
      </table>
    </>
  )
}

export default Form