import React from 'react'

function MainSideArea({children}) {
  return (
    <div className="main-side-area main-side-area_right-side-bar">
      {children}
    </div>
  )
}

export default MainSideArea