import React from 'react'
import DatePicker, {registerLocale} from "react-datepicker";
import ja from 'date-fns/locale/ja';
import DateDecorator from "../../DateDecorator";
registerLocale('ja', ja);
import Card from '../../atoms/Card';
import Span from '../../atoms/Span';
import Space from '../../atoms/Space';
import Container from '../../atoms/Container';
import CustomDatePickerHeader from './CustomDatePickerHeader'
import CustomDayContent from './CustomDayContent'

export default function CalendarDatePicker({date, highlightDates, onChangeDate}) {
  return (
    <Card>
      <Container margin={"0 0 1.5rem 0"}>
        <CustomDatePickerHeader date={date} onChangeDate={onChangeDate}/>
      </Container>
      <Container>
        <DatePicker
          selected={date}
          onChange={(selected) => onChangeDate(new Date(selected))}
          dateFormat="yyyy-MM-dd"
          locale="ja"
          inline
          highlightDates={highlightDates}
          renderCustomHeader={() => <></>}
          renderDayContents={CustomDayContent}
        />
      </Container>
    </Card>
  )
}
