import React from 'react'

function MainSideAreaMain({children}) {
  return (
    <div className="main-side-area_main">
      {children}
    </div>
  )
}

export default MainSideAreaMain