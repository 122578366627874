import React from "react";

export const TocAccountImage = ({accountName}: { accountName: string }) => {
  const accountNameMap = new Map<string, string>([
    ['鈴木 彩香', 'ayaka_suzuki'],
    ['渡部 祐希', 'yuki_watanabe'],
    ['向井 由美', 'yumi_mukai'],
    ['田中 太一', 'taichi_tanaka'],
  ]);
  if (accountNameMap.has(accountName)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    return <img
      src={require(`../../../assets/images/test_image/memori/toc_account/${accountNameMap.get(accountName)}.jpg`)}/>
  } else {
    return <img
      src={require(`../../../assets/images/test_image/memori/toc_account/other.jpg`)}/>
  }
};