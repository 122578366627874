import React, { useRef, useState, useEffect } from "react";
import { formatDateTimeSlash } from "../shared/date.ts";
import Axios from "./Axios";

const NotificationToast = ({ notifications }) => {
  const toastRef = useRef();
  const [show, setShow] = useState(false);
  const unReadCount = notifications.filter(n => !n.is_read).length;
  const [parentNode, setParentNode] = useState(null);
  useEffect(() => {
    // リンクはコンポーネント外で生成されているので、その要素についてはjqueryで取得する
    document.addEventListener('click', documentClickHandler);
    setParentNode($(".notification_div").get()[0])
    return () => {
      document.removeEventListener('click', documentClickHandler);
    };
  });

  const documentClickHandler = (e) => {
    if (toastRef.current && !toastRef.current.contains(e.target) && !parentNode.contains(e.target)) {
      if(show) {
        setShow(false);
      }
    } else {
      setShow(!show);
    }
  };

  const notify = () => {
    if (show) {
      documentClickHandler();
      return;
    }
    console.log('event listener added')
    document.addEventListener("click", () => documentClickHandler);
    setShow(true);
  };

  const pushToUrl = (notification) => {
    // Urlに直接遷移する場合には修正する
    if(notification.url != null) {
      Axios
        .post(`/notifications/${notification.id}/read`)
        .then(_ => window.location.href = notification.url)
        .catch(_ => {
          $('#flash-notice').text();
          let elem = document.getElementById("flash-alert");
          elem.style.display ="block";
          elem.firstChild.className = "toast_content toast_content_alert visible"
          elem.firstChild.innerHTML = "システムエラーが発生しました"
          window.setTimeout(function(){
            elem.style.display ="none";
            elem.firstChild.className = "toast_content toast_content_alert invisible"
          }, 3000);
        });
    } else {
      window.location.href = `/notifications/${notification.id}`;
    }
  }

  return(
    <React.StrictMode>
      <div className="notification_div">
        <span className="material-icons">notifications</span>
        <span className="notification-label">お知らせ</span>
        {
          unReadCount > 0 && (
            <span className="badge badge-notify rounded-circle">
              {unReadCount}
            </span>
          )
        }
      </div>
      <div className="toast-container position-absolute top-7">
        <div
          className={`${show ? "show" : ""} toast hh-50 ww-50 overflow-auto`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          ref={toastRef}
        >
          <div className="toast-header">
            {/* <img src="..." className="rounded me-2" alt="..." /> */}
            <strong>お知らせ</strong>
          </div>
          <div className="toast-body h-100">
            <div className="row justify-content-center h-100">
              {notifications.length === 0 &&
                <div className="my-auto">
                  <span>現在新しいお知らせはありません</span>
                </div>
              }
              {notifications.length > 0 &&
                <div className="col-lg-12 col-xl-12">
                  <div className="list-group mb-5 shadow">
                    {notifications.map((notification) => (
                      <div className="list-group-item notification-clickable" key={notification.id} onClick={() => pushToUrl(notification)}>
                        <div className="row align-items-center">
                          <div className="col">
                            <div className="mb-0 mt-2 flex justify-content-between">
                              <div className="mb-0 w-75">
                                <strong className="mb-0">
                                  <p className="mb-0 text-truncate">
                                    {notification.notification_message.title}
                                  </p>
                                </strong>
                              </div>
                              {notification.is_read ? (
                                <div className="mb-0">
                                  {notification.notification_message.company_id === null && <span className={"badge_status badge_status_important"}>運営</span>}
                                </div>
                              ) : (
                                <div className={"flex"}>
                                  {notification.notification_message.company_id === null && <span className={"badge_status badge_status_important"}>運営</span>}
                                  <div className="mb-0 notification-circle bg-primary"/>
                                </div>
                              )}
                            </div>
                            <div className="mb-0 mt-2">
                              <p className="mb-0 text-muted text-truncate">
                                {notification.notification_message.message}
                              </p>
                            </div>
                            <div className="mb-0">
                              <p className="mb-0 text-muted text-right">
                                {formatDateTimeSlash(notification.notification_message.published_at)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default NotificationToast;
