import styled from "styled-components"

const Badge = styled.div`
  white-space: nowrap;
  background-color: ${props => props.backColor || "#fff"};
  padding: 0.2rem 0.5rem;
  color: ${props => props.color || "#777"};
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1;
  border: ${props => props.border || "1px solid #777"};
  border-radius 2px;
  cursor: ${props => props.cursor || "normal"};
  &:hover {
    background-color: ${props => props.cursor == "pointer" ? props.hoverBackColor : props.backColor || "#fff" };
  }
`
export default Badge