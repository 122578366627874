import React, {useEffect} from 'react'
import {randomColorSet} from '../../../../shared/color'

function ChartA({data}) {
  useEffect(() => {
    const colorSet = randomColorSet(6)
    var ctx = document.getElementById('myChart').getContext('2d');

    var myChart = new Chart(ctx, {
      type: 'bar',
      data: {
          labels: data.labels(),
          datasets: [{
            data: data.dataset(),
            backgroundColor: colorSet,
            borderColor: colorSet,
            borderWidth: 1,
          },
        ],
      },
      options: {
        legend: {
          display: false
        },
        title: {
            display: true,
            text: '最高得点分布'
        },
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            title: () => 'ユーザー名（取得した試行回）',
            label: (context) => data.tooltipLabels(context.label),
          },
          displayColors: false
        },
      }
    });
  }, [data])

  return (
    <canvas id="myChart"/>
  )
}

export default ChartA