import React, {useEffect, useState} from 'react';
import DeleteBatu from "./DeleteBatu";
import { resizeFile } from "../shared/resizeFile";

export const ResizedImage = ({ image, deffaultLink, figureClass, deleteButtonClass, imgClass, onDelete, hideDelete = false }) => {
  const [resizedImage, setResizedImage] = useState('');

  useEffect(() => {
    if(image) {
      resizeFile(image, 600, 800, setResizedImage, "base64");
    } else if (!deffaultLink){
      setResizedImage('')
    }
  }, [image])

  useEffect(() => {
    if(deffaultLink) {
      setResizedImage(deffaultLink)
    }
  }, [deffaultLink])

  if(!resizedImage) {
    return null;
  }

  const handleDelete = (e) => {
    setResizedImage('');
    onDelete(e);
  }

  return (
    <figure className={figureClass}>
      <div style={{display: 'inline-block'}}>
        {!hideDelete && (<DeleteBatu onClick={handleDelete} className={deleteButtonClass}/>)}
        <img
          id={`preview-image`}
          src={resizedImage}
          className={imgClass}
        />
      </div>
    </figure>
  );
}
