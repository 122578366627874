import React from 'react'
import Th from './Th';
import Td from './Td';
import Container from '../../atoms/Container';
import SpotView from './SpotView';

export default function FacilityShiftTable({
  facilityData,
  deleteShift,
  modalOpen
}) {
  return (
    <>
      <span>{facilityData.facility_name}</span>
      {facilityData.halls.map(hallData => (
        <table>
          <thead>
            <tr>
              <Th colSpan="2" backColor="#ffffe0">{hallData.hall_name}</Th>
            </tr>
          </thead>
          <tbody>
            {hallData.spots.map(spotData => (
              <tr key={spotData.spot_id}>
                <Td backColor="#ffffe0">
                  <Container>{spotData.spot_name}</Container>
                </Td>
                <SpotView
                  facilityData={facilityData}
                  hallData={hallData}
                  spotData={spotData}
                  deleteShift={deleteShift}
                  modalOpen={modalOpen}
                />
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </>
  )
}
