import React, {useState, useRef, useEffect} from 'react'
import LessonForm from './Form'
import DraggableModal from '../../../DraggableModal';
import useWindowSize from '../../../hooks/useWindowSize';
import Space from '../../../atoms/Space';

function FormModalLink({
  id,
  callBack,
  positionX,
  positionY,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const btnRef = useRef(null);
  const [width, height] = useWindowSize();
  const [position, setPosition] = useState({
    width: 0,
    height: 0,
    defaultX: 0,
    defaultY: 0,
  })

  useEffect(() => {
    const refInfo = btnRef.current.getBoundingClientRect()
    setPosition({
      ...position,
      width: Math.min(width * 0.7, 800),
      height: Math.min(height * 0.7, 800),
      // react_component のレンダーの位置を(0, 0)とした時の相対位置
      defaultX: positionX, 
      defaultY: positionY, 
    })
  }, [width, height]);

  const onSuccess = () => {
    location.reload()
  }

  return (
    <>
      <a className="modal-linktext" href onClick={() => setModalVisible(true)} ref={btnRef}>
        <span>授業の作成はこちら</span>
      </a>
      {modalVisible && (
        <DraggableModal
          onClose={() => setModalVisible(false)}
          zIndex={110}
          {...position}
        >
          <>
            <Space margin="3rem"/>
            <LessonForm
              id={id}
              onClose={() => setModalVisible(false)}
              onSuccess={onSuccess}
            />
            <Space margin="1rem"/>
          </>
        </DraggableModal>
      )}
    </>
  )
}

export default FormModalLink