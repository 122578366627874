import React, {useEffect, useState} from 'react';
import {TocAccountImage} from '../atoms/DummyTocAccountImage';
import Axios from "../Axios";
import Alert from "../Alert";
import sanitizeHtml from 'sanitize-html';

interface Thread {
  token: string
  comment: string
  created_at: string
  partner: string
  partner_kana: string
  reporter_type: string
}

interface Threads {
  data: Array<Thread>
}

interface Error {
  text: string
  type: string
}

const Index = (values: Threads) => {
  const [message, setMessage] = useState<Error>({text: null, type: null});
  const [threads, setThreads] = useState<Array<Thread>>(values.data);
  const [searchReplyStatus, setSearchReplyStatus] = useState<string>('all');
  useEffect(() => {
    const intervalId = setInterval(() => {
      //TODO: state使えないか。。？
      const checkedSearch = document.querySelector("input[type=radio]:checked").attributes[3].value;
      Axios.get(`chats.json?reply_status=${checkedSearch}`)
        .then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          setThreads(response.data);
        }).catch(() => {
        setMessage({text: `システムエラーが発生しました`, type: 'alert'});
      });
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleSelect = () => {
    Axios.get(`chats.json?reply_status=${searchReplyStatus}`)
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setThreads(response.data);
      }).catch(() => {
      return;
    });
  }

  return (
    <React.StrictMode>
      <>
        {message.text != null && (
          <Alert
            message={message}
            onClose={() => setMessage({text: null, type: null})}
            timeout={3000}
          />
        )}
        <div className="main-side-area main-side-area_right-side-bar">
          <div className="main-side-area_main">
            <div className="card">
              <div className="heading-area">
                <div className="title-area">
                  <h1>チャット</h1>
                </div>
              </div>
              <div className="card_in-card card_section">
                {threads && threads.length > 0 && threads.map((thread, i) => {
                  return (
                    <a href={sanitizeHtml(`/chats/thread/${thread.token}`)} key={i}>
                      <div className="card">
                        <div
                          className="card_content row-area row-area_xlarge row-area_jc-space-between row-area_ai-start">
                          <div className="col-area">
                            <div className="row-area">
                            <span className="thumbnail-area thumbnail-area_circle">
                              <TocAccountImage accountName={thread.partner}/>
                            </span>
                              <div className="col-area">
                                {thread.reporter_type == "TocAccount" &&
                                  <span className={"badge badge_status badge_status_pending"}
                                        style={{width: "fit-content"}}>未返信</span>
                                }
                                <div className="row-area">
                                  <span className="fw_bold">{thread.partner}</span>
                                  {thread.partner_kana != "" &&
                                    <span>({thread.partner_kana})</span>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-area">
                            <span style={{textAlign: "right"}}>{thread.created_at}</span>
                            <span>{thread.comment}</span>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="main-side-area_side">
            <div className="section-area">
              <div className="card">
                <div className="card_content">
                  <div className="heading-area heading-area_nocard">
                    <div className="title-area">
                      <h1>検索</h1>
                    </div>
                  </div>
                  <div className="card_content_section">
                    <div className="card_content_section_article">
                      <div className="col-area">
                      <span className="form-area">
                        <label className="row-area row-area_small">
                          <span>返信状況</span>
                        </label>
                      </span>
                        <span className="radio">
                          <div className={'row-area'}>
                            <input type="radio" name="reply_status" checked={searchReplyStatus == "all"} value="all" id={'all'}
                                   onChange={(e) => setSearchReplyStatus(e.target.value)}/>
                            <label htmlFor={'all'}>全て</label>
                          </div>
                          <div className={'row-area'}>
                            <input type="radio" name="reply_status" checked={searchReplyStatus == "todo"} value="todo" id={'todo'}
                                   onChange={(e) => setSearchReplyStatus(e.target.value)}/>
                            <label htmlFor={'todo'}>未返信</label>
                          </div>
                          <div className={'row-area'}>
                            <input type="radio" name="reply_status" checked={searchReplyStatus == "done"} value="done" id={'done'}
                                   onChange={(e) => setSearchReplyStatus(e.target.value)}/>
                            <label htmlFor={'done'}>返信済</label>
                          </div>
                          <div className={'row-area'}>
                            <span className={'button'} onClick={handleSelect}>入力した条件で検索</span>
                          </div>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </React.StrictMode>
  )
}

export default Index;
