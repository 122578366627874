import React, { useState } from "react";
import Axios from "./Axios";

const ModalView = ({
  setModal,
  title,
  body,
  children,
  link,
  linkMethod,
  params,
  confirmMessage,
  cancelMessage,
  confirmButtonStyle,
  handleSubmit,
  isWarning,
  noAutoClose,
  setSubmitting
}) => {
  const [submitButtonProcessing, setSubmitButtonProcessing] = useState(false);
  const submitHandler = () => {
    if (submitButtonProcessing) {
      return;
    }
    if (setSubmitting !== null) {
      setSubmitting(true)
    }
    if (handleSubmit) {
      handleSubmit();
      if(!noAutoClose) {
        setModal();
      }
      setSubmitting(false)
      setSubmitButtonProcessing(false);
    } else if (linkMethod === 'delete') {
      Axios.delete(link, params)
        .then((response) => {
          window.location.href = !!response.data.path
            ? response.data.path
            : response.request.responseURL;
          setModal();
          if (setSubmitting !== null) {
            setSubmitting(false)
          }
        })
        .catch((error) => {
          setSubmitButtonProcessing(false);
          if (setSubmitting !== null) {
            setSubmitting(false)
          }
          setModal();
        });
    } else {
      Axios.post(link, params)
        .then((response) => {
          window.location.href = !!response.data.path
            ? response.data.path
            : response.request.responseURL;
          setModal();
          if (setSubmitting !== null) {
            setSubmitting(false)
          }
        })
        .catch((error) => {
          setSubmitButtonProcessing(false);
          setModal();
          if (setSubmitting !== null) {
            setSubmitting(false)
          }
        });
    }
  };

  return (
    <div id="modal-view">
      <div style={overlayStyle}>
        <div style={modalContentStyle}>
          <div style={closeModalButton}>
            <button type="button" onClick={() => !submitButtonProcessing && setModal()}>
              ✕
            </button>
          </div>
          {title && (
            <div style={modalTitle}>
              <h1>
                <>
                  {isWarning ?
                    <span style={warningTitle}>
                      <span className='material-icons'>warning</span>
                      <span>{title}</span>
                    </span> :
                    <span>{title}</span>
                  }
                 </>
              </h1>
            </div>
          )}
          {body && <div style={modalContent}>{body}</div>}
          {children && <div style={modalContent}>{children}</div>}
          {link && (
            <>
              <hr />
              <div style={modalSubmit}>
                <div className={"button-area"}>
                  <button
                    style={closeButton}
                    className="button row-area row-area_small"
                    onClick={() => !submitButtonProcessing && setModal()}
                  >
                    <span>{cancelMessage || "閉じる"}</span>
                  </button>
                  <button
                    className={
                      confirmButtonStyle || "button row-area row-area_small"
                    }
                    onClick={() => {
                      setSubmitButtonProcessing(true);
                      submitHandler();
                    }}
                    disabled={submitButtonProcessing}
                  >
                    <span>
                      {submitButtonProcessing ? "送信中..." : confirmMessage}
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const overlayStyle = {
  /*　画面全体を覆う設定　*/
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: 1000,
  /*　画面の中央に要素を表示させる設定　*/
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const modalContentStyle = {
  zIndex: 1001,
  overflowX: "auto",
  overflowX: "scroll",
  maxHeight: "80%",
  width: "60%",
  padding: "1.5rem",
  background: "#fff",
  border: "1px solid rgba(0,0,0,0.5)",
  borderRadius: "10px",
};

const closeModalButton = {
  position: "sticky",
  top: 0,
  height: 0,
  textAlign: "right",
};

const warningTitle = {
  display: 'flex',
  alignItems: 'center',
  color: 'red'
}

const modalTitle = {
  padding: "1rem",
  marginBottom: "1rem",
  background: "#fff",
  textAlign: "center",
};

const modalContent = {
  padding: "1rem",
  marginBottom: "1rem",
  textAlign: "center",
  display: "flex",
};

const modalSubmit = {
  padding: "1rem",
  marginBottom: "0.5rem",
  background: "#fff",
  textAlign: "right",
};

const closeButton = {
  marginRight: "1rem",
  color: "#555",
  background: "#fff",
  border: "1px solid #555",
};
export default ModalView;
