import React, {memo} from 'react';
const RadioButton = memo(({ label, required, options, selectedValue, nameKey, onChange, errorMessage, disabled }) => {
  return (
    <>
      <tr className={errorMessage && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      <tr>
        <td className={"form-radio"}>
          {
            options.map((option, index) => (
              <span key={`${nameKey}-${index}-${index}`}>
                <input
                  type={"radio"}
                  value={option.value}
                  checked={selectedValue === option.value}
                  id={`${nameKey}-${index}`}
                  name={nameKey}
                  onChange={onChange}
                  disabled={disabled}
                />
                <label htmlFor={`${nameKey}-${index}`}>{option.name}</label>
              </span>
            ))
          }
          <div className="w-100"></div>
          {errorMessage && <span className={'error_message'}>{errorMessage}</span>}
        </td>
      </tr>
    </>
  );
});

export default RadioButton;
