import Axios from "../../../../lib/axios";

export function getCourseKindOptions() {
  return Axios.get(`/tech/course_kinds/options`)
    .then(response => response)
    .catch(error => Promise.reject(error.response.data));
}

export function getLessonVideoOptions() {
  return Axios.get(`/tech/documents/video_options`)
    .then(response => response)
    .catch(error => Promise.reject(error.response.data));
}

export function getLesson(id) {
  return Axios.get(`/tech/lessons/lesson?id=${id}`)
    .then(response => response)
    .catch(error => Promise.reject(error.response.data));
}

export function getLessons() {
  return Axios.get(`/tech/lessons/options`)
    .then(response => response)
    .catch(error => Promise.reject(error.response.data));
}

export function getExercises() {
  return Axios.get(`/tech/exercises/options`)
    .then(response => response)
    .catch(error => Promise.reject(error.response.data));
}