import "bootstrap";
import Rails from "@rails/ujs";
//import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import $ from "jquery";
import "jquery-ui";
import "select2";
import "select2/dist/css/select2.css";
import "jquery-jpostal-ja";
import "chart.js";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "../../assets/stylesheets/toastify.scss";
import "chartkick";

Rails.start();
//Turbolinks.start();
ActiveStorage.start();
window.$ = $; //override with jquery-ui

// Support component names relative to this directory:
let componentRequireContext = require.context("components", true);
let ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require.context("../../assets/images", true);

$(function () {
  history.pushState(null, null, null);
  $('.button').on('click', function() {
    $(this).addClass('button_disable_pointer_none')
    setTimeout(() => {
      $(this).removeClass('button_disable_pointer_none')
    }, $(this).hasClass('button_delete') ? 2000 : 1000)
  })
});
