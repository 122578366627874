import React from 'react';

const StudentTable = ({
                        isNumericEvaluate,
                        students,
                        grades,
                        handleScore,
                        handleGrade,
                        handleEvaluationComment,
                        isEmptyRow = false
                      }) => {
  if (isNumericEvaluate) {
    return (
      <React.StrictMode>
        <table className="table">
          <thead>
          <tr>
            <th>受験番号</th>
            <th>名前</th>
            <th>点数</th>
          </tr>
          </thead>
          <tbody className="ta_center">
          {students &&
          students.map((s) => (
            <tr key={s.id}>
              <td className="va_middle">{s.examineeNumber}</td>
              <td className="va_middle">{s.label}</td>
              <td>
                <div className="form-text">
                  <input
                    type="number"
                    placeholder="点数を入力"
                    defaultValue={s.score}
                    max={9999}
                    onChange={(e) => handleScore(e, s.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
          {isEmptyRow && (
            <tr style={{lineHeight: '44px'}}>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
          </tbody>
        </table>
      </React.StrictMode>
    );
  } else {
    return (
      <React.StrictMode>
        <table className="table">
          <thead>
          <tr>
            <th>受験番号</th>
            <th>名前</th>
            <th>評価</th>
            <th>評価コメント</th>
          </tr>
          </thead>
          <tbody className="ta_center">
          {students &&
          students.map((s) => (
            <tr key={s.id}>
              <td className="va_middle">{s.examineeNumber}</td>
              <td className="va_middle">{s.label}</td>
              <td>
                <div className="form-text">
                  <select
                    defaultValue={s.grade}
                    onChange={(e) => handleGrade(e, s.id)}
                  >
                    <option value=''>評価を選択</option>
                    {grades &&
                    grades.map((g) =>
                      <option value={g.id}>{g.name}</option>
                    )}
                  </select>
                </div>
              </td>
              <td>
                <div className="form-text">
        <textarea style={{height: '44px'}}
                  placeholder="評価コメントを入力"
                  defaultValue={s.evaluationComment}
                  onChange={(e) => handleEvaluationComment(e, s.id)}
        />
                </div>
              </td>
            </tr>
          ))}
          {isEmptyRow && (
            <tr style={{lineHeight: '49px'}}>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
          )}
          </tbody>
        </table>
      </React.StrictMode>
    )
  }
}

export default StudentTable;
