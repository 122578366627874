import styled from "styled-components"

const FlexBox = styled.div`
  display: flex;
  justify-content: ${props => props.content || "start"};
  align-items: ${props => props.align || "center"};
  border-top: ${props => props.borderTop || "none"};
  border-bottom: ${props => props.borderBottom || "none"};
  border: ${props => props.border || "none"};
  width: ${props => props.width || "auto"};
  height: ${props => props.height || "auto"};
  border-radius: ${props => props.borderRadius || 0 };
  background-color: ${props => props.backColor || "auto"};
`
export default FlexBox