import React from 'react'
import Space from '../../atoms/Space';
import FlexBox from '../../atoms/FlexBox';
import Badge from '../../atoms/Badge';
import Container from '../../atoms/Container';
import PrevEventButton from './PrevEventButton';
import NextEventButton from './NextEventButton';
import DateDecorator from '../../DateDecorator';
import Link from '../../atoms/Link';

export default function ActionArea({
  event,
  date,
  isExistPrevDay,
  isExistNextDay,
}) {
  return (
    <Space padding={"2rem 1rem 2rem 1rem"}>
      <FlexBox content="space-between">
        <FlexBox>
          <Badge><Container>日付</Container></Badge>
          <Space margin="0 0 0 1rem">
            <Container>{new DateDecorator(new Date(date)).fullLabel()}</Container>
          </Space>
        </FlexBox>
        <FlexBox>
          <Link
            disabled={!isExistPrevDay}
            onClick={() => {if(isExistPrevDay){location.href=`/shifts/edit_page?event_id=${event.id}&date=${date}&prev_day=true`}}}
          >
            <FlexBox>
              &lt;
              <Space margin="0 0 0 1rem">
                <Container>前の日へ</Container>
              </Space>
            </FlexBox>
          </Link>
          <Space margin="0 0 0 2rem"/>
          <Link
            disabled={!isExistNextDay}
            onClick={() => {if(isExistNextDay){location.href=`/shifts/edit_page?event_id=${event.id}&date=${date}&next_day=true`}}}
          >
            <FlexBox>
              <Space margin="0 1rem 0 0">
                <Container>次の日へ</Container>
              </Space>
              &gt;
            </FlexBox>
          </Link>
        </FlexBox>
      </FlexBox>
    </Space>
  )
}
