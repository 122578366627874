import React, { useState, useEffect } from "react";
import ModalView from "../../../../ModalView";
import Card from "../../../../atoms/Card";
import Width from "../../../../atoms/Width";
import Space from "../../../../atoms/Space";
import Span from "../../../../atoms/Span";
import FlexBox from "../../../../atoms/FlexBox";
import Badge from "../../../../atoms/Badge";
import ApplicantInformationCard from "./ApplicantInformationCard";
import DateDecorator from "../../../../DateDecorator";
import Snackbar from "../../../../shared/Snackbar";
import { timeZoneLabel } from "../../../../../shared/date";
export default function ApplicantsForm ({applicants, change_dead_line, params}) {
  const [modalVisible, setModalVisible] = useState(false);
  const date = new DateDecorator(new Date(change_dead_line))
  const deadLine = `${date.fullLabel()} ${date.timeLabel()}${timeZoneLabel()}`;
  const [snack, setSnack] = useState({
    type: 'success',
    message: '',
    autoHideDuration: 2000,
    anchorOrigin: {vertical: 'top', horizontal: 'center'},
  });

  useEffect(() => {
    setSnack({...snack, message: params.snack || ''})
  }, [])

  return (
    <>
      <Snackbar
        open={snack.message !== ""}
        message={snack.message}
        anchorOrigin={snack.anchorOrigin}
        autoHideDuration={snack.autoHideDuration}
        type={snack.type}
        onClose={() => setSnack({...snack, message: ''})}
      />
      <a className="button" onClick={() => setModalVisible(true)}>
        <span>参加者を変更</span>
      </a>
      {modalVisible && (
        <ModalView setModal={() => {setModalVisible(false)}}>
          <Width>
            <Space margin="1rem">
              <FlexBox content="center">
                <Span fontSize="16px">{`参加者のメールアドレス（${applicants.length}人分）を登録してください`}</Span>
              </FlexBox>
              <FlexBox content="center">
                <Span fontSize="16px">{`${deadLine}までは登録・変更が可能です`}</Span>
              </FlexBox>
            </Space>
            <Space margin="1rem">
              {
                applicants.map((a) => (
                  <ApplicantInformationCard applicant={a} />
                ))
              }
            </Space>
          </Width>
        </ModalView>
      )}
    </>
  );
};
