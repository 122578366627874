import React, { useRef } from 'react';

const VideoThumbnail = ({src, width, height}) => {
  return (
    <div>
      <video
       controls
       src={src}
       width={width || "480px"}
       height={height || "270px"}
      />
    </div>
  );
};

export default VideoThumbnail;
