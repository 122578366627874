import React from 'react'
import {genderLabel, accountTypeLabel} from './func';
import ApplierInformation from './ApplierInformation';
import Space from '../../shared/Space';

function ProxyApplicationDescription({course, tech_student, postData}) {
  return (
    <>
      <ApplierInformation course={course} tech_student={tech_student}/>
      <Space margin="1rem 0"/>
      <h2 className="tech_user_course_show_subtitle">参加者</h2>
      <div className="card_in_card">
        <div className="card">
          <div className="card_content">
            <div className="card_content_section">
              {postData.proxy_mail_addresses.map((address) => (
                <div className="card_section">
                  <div className="card_section_article">
                    <span>{address}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProxyApplicationDescription