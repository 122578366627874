import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import DateDecorator from './DateDecorator';
import AttendanceConditionDialog from './AttendanceConditionDialog';

const AttendanceConditionCalendar = (props) => {
  var initialDate = new DateDecorator(new Date());
  if (props.year) {
    initialDate = new DateDecorator(new Date(props.year, props.month - 1, 1));
  }

  const [recordID, setRecordID] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [attendanceCondition, setAttendanceCondition] = useState({});

  const weekLine  = [0, 1, 2, 3, 4, 5];
  let dayCount = 1;
  let attendanceCount = 1;

  const getAttendanceCondition = (id) => {
    if (id === 0) {
      return;
    }

    axios
      .get(`/api/v1/toucheez/attendance_conditions/${id}`)
      .then(({data}) => {
        let start = new Date(data.started_at);
        let startedAt = ('0' + start.getHours()).slice(-2) + ':' + ('0' + start.getMinutes()).slice(-2);
        let finish = new Date(data.finished_at);
        let finishedAt = ('0' + finish.getHours()).slice(-2) + ':' + ('0' + finish.getMinutes()).slice(-2);
        let attend = new Date(data.attend_date);
        let attendDate = attend.getFullYear() + '/' + ('0' + attend.getMonth()).slice(-2) + '/' + ('0' + attend.getDate()).slice(-2);
        setAttendanceCondition({
          attend_date: attendDate,
          facility_name: data.facility_name,
          started_at: startedAt,
          finished_at: finishedAt,
          role_name: data.role_name
        });
      })
      .catch(error => {
        consolt.log('attendance record dialog init error');
      });
  }

  const displayDate = (weekLine, dayIndex) => {
    // TODO: 1日より前の空欄用分岐。名前つけたい
    if (weekLine == 0 && dayIndex < props.date.fristDayOfMonth()) {
      return '';
    }

    // TODO: 31日より後ろの空欄用分岐。名前つけたい
    if (dayCount > props.date.lastDateOfMonth) {
      return '';
    }

    let day =  dayCount + '日(' + props.date.weekLabels[dayIndex] + ')';
    dayCount++;

    return day;
  }

  const attendanceClass = (record) => {
    if (record.is_admin_updated === true) {
      return "calendar_modify_contents";
    }
    return "calendar_contents";
  }

  // 勤怠情報出力
  const displayAttendance = (weekLine, dayIndex) => {
    if (weekLine == 0 && dayIndex < props.date.fristDayOfMonth()) {
      return ('');
    }

    // TODO: undefinedがどういうときに起こるのか明確にしたい
    if (props.records[attendanceCount] == undefined) {
      attendanceCount++;
      return ('');
    }

    let today = new Date()
    let record = props.records[attendanceCount];
    var startDate = new DateDecorator(new Date(record.started_at));
    var finishDate = new DateDecorator(new Date(record.finished_at));
    attendanceCount++;

    if (props.condition.target === 'account') {
      let recordDay = new Date(record.attend_date);
      if (today > recordDay) {
        if (today.getFullYear() === recordDay.getFullYear() && today.getMonth() === recordDay.getMonth()) {
          return(
            <div className={attendanceClass(record)} id={`${recordDay.getMonth() + 1}-${recordDay.getDate()}`} onClick={() => handleEditModal(record.id)}>
              <span>{record.facility_name}</span>
              <span>{startDate.timeLabel()}-{finishDate.timeLabel()}</span>
            </div>
          );
        } else {
          return(
            <div className={attendanceClass(record)} id={`${recordDay.getMonth() + 1}-${recordDay.getDate()}`} onClick={() => handleEditModal(record.id)}>
              <span>{record.facility_name}</span>
              <span>{startDate.timeLabel()}-{finishDate.timeLabel()}</span>
            </div>
          )
        }
      } else {
        return(
          <div className="calendar_shift_contents" id={`${recordDay.getMonth() + 1}-${recordDay.getDate()}`} >
            <span>{record.facility_name}</span>
            <span>{startDate.timeLabel()}-{finishDate.timeLabel()}</span>
          </div>
        );
      }
    } else {
      if (today.getDate() > attendanceCount) {
        return(
          <div className="calendar_contents" id={`${recordDay.getMonth() + 1}-${recordDay.getDate()}`} onClick={() => handleEditModal(record.id)}>
            <span>{record.account_name}</span>
          </div>
        )
      } else {
        return(
          <div className="calendar_shift_contents" id={`${recordDay.getMonth() + 1}-${recordDay.getDate()}`} >
            <span>{record.account_name}</span>
          </div>
        )
      }
    }
  }

  const handleEditModal = (id) => {
    console.log(id);
    getAttendanceCondition(id);
    setRecordID(id);
    setIsDialogOpen(true);
  };

  const displayCheckbox = () => {
    if(props.records[dayCount] == undefined) {
      return (
        <input type="checkbox" style={{display: 'block'}} id={"check-" + dayCount} />
      );
    }

    return (
      <input type="checkbox" style={{display: 'block'}} id={"check-" + dayCount} value={props.records[dayCount].id} onChange={e => handleCheckbox(e)} checked={displayChecked(dayCount)} />
    );
  };

  const displayAttendanceConditionDialog = () => {
    if (recordID === 0) {
      return '';
    }
    return (
      <AttendanceConditionDialog
        recordID={recordID}
        isOpen={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        getAttendanceConditions={() => props.getAttendanceConditions(props.condition)}
        attendanceCondition={attendanceCondition}
        accounts={props.accounts}
        facilities={props.facilities}
        roles={props.roles}
      />
    );
  }

  const handleCheckbox = (e) => {
    return axios
      .put(`/api/v1/toucheez/attendance_conditions/${e.target.value}`, {toucheez_attendance_condition: {is_checked: e.target.checked}})
      .then(() => {
        props.getAttendanceConditions(props.condition);
      })
      .catch(error => {
        console.log(error);
    });
  };

  const displayChecked = (day) => {
    if (props.records[dayCount] == undefined) {
      return '';
    }

    if (props.records[dayCount].is_checked !== true) {
      return '';
    }

    return "checked";
  }

  const batchArea = () => {
    if (props.condition.target == 'facility') {
      return (
        <div className="badge-area">
          <div>
            <span className="badge">勤務シフト</span>
            <span>Facility1（デモ）</span>
          </div>
          <div>
            <span className="badge">出勤</span>
            <span>9:00（デモ）</span>
          </div>
          <div>
            <span className="badge">退勤</span>
            <span>18:00（デモ）</span>
          </div>
          <div>
            <span className="badge">休憩</span>
            <span>1:00（デモ）</span>
          </div>
          <div>
            <span className="badge">時間数</span>
            <span>8:00（デモ）</span>
          </div>
        </div>
      )
    }

    return (
      <div className="badge-area">
        <div>
          <span className="badge">社員番号</span>
          <span>（デモ）</span>
        </div>
        <div>
          <span className="badge">部署</span>
          <span>（デモ）</span>
        </div>
        <div>
          <span className="badge">出勤日数</span>
          <span>（デモ）</span>
        </div>
        <div>
          <span className="badge">休出日数</span>
          <span>（デモ）</span>
        </div>
        <div>
          <span className="badge">有給残</span>
          <span>（デモ）</span>
        </div>
        <div>
          <span className="badge">欠席日数</span>
          <span>（デモ）</span>
        </div>
        <div>
          <span className="badge">資格</span>
          <span>（デモ）</span>
        </div>
      </div>
    )
  }


  return (
    <>
      <div className="card_section">
        <div className="card_article">
          {batchArea()}
        </div>
        <div className="card_article">
          <table className="table_calendar">
            {weekLine.map((wlValue, wlIndex) => (
              <>
                <thead>
                  <tr key={`head_${wlValue}`}>
                    {props.date.weekLabels.map((wValue, wIndex) => (
                      <th key={`th_${wValue}_${wIndex}`}>
                        <div>
                          <span>{displayCheckbox()}</span>
                          <span>{displayDate(wlValue, wIndex)}</span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr key={`body+${wlValue}`}>
                    {props.date.weekLabels.map((wValue, wIndex) => (
                      <td key={`td_${wValue}_${wIndex}`}>{displayAttendance(wlValue, wIndex)}</td>
                    ))}
                  </tr>
                </tbody>
              </>
            ))}
          </table>
          {displayAttendanceConditionDialog()}
        </div>
      </div>
    </>
  );
}

export default AttendanceConditionCalendar;
