import React from 'react'

function VideoViewer({videoUrl}) {
  return (
    <>
      <video
        controls
        src={videoUrl}
        width={"100%"}
        height={"100%"}
        controlsList="nodownload"
        disablePictureInPicture
        onContextMenu={(e)=> e.preventDefault()}
      />
    </>
  )
}

export default VideoViewer