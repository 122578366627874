import React, { useState, memo } from "react";
import ModalView from "./ModalView";
const ModalLink = memo(({
  message,
  title,
  body,
  icon,
  link,
  linkMethod,
  confirmMessage,
  cancelMessage,
  params,
  submitButtonStyle,
  confirmButtonStyle,
  handleSubmit,
  children,
  noAutoClose,
  ref
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [submitButtonProcessing, setSubmitButtonProcessing] = useState(false);

  return (
    <>
      <a
        className="modal-linktext"
        href
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {icon && <span className="material-icons">{icon}</span>}
        <span>{message}</span>
      </a>
      {modalVisible && (
        <ModalView
          setModal={() => setModalVisible(false)}
          title={title}
          body={body}
          link={link}
          linkMethod={linkMethod}
          confirmMessage={confirmMessage}
          cancelMessage={cancelMessage}
          confirmButtonStyle={confirmButtonStyle}
          params={params}
          handleSubmit={handleSubmit}
          setSubmitting={setSubmitButtonProcessing}
          noAutoClose={noAutoClose}
        >
          {children}
        </ModalView>
      )}
    </>
  );
});

export default ModalLink;
