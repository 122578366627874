import useSWR from 'swr';
import {
  getCourseKindOptions,
  getLessonVideoOptions,
  getLesson,
  getLessons,
  getExercises,
} from '../apis';

const globalConfig = {
  focusThrottleInterval: 2000,
  dedupingInterval: 2000,
  revalidateOnFocus: false,
}

export function useCourseKinds() {
  const { data, error, mutate } = useSWR(
    `/tech/course_kinds/options`,
    getCourseKindOptions,
    globalConfig
  );
  return {
    courseKindOptions: data?.data,
    error,
    isLoading: !error && !data,
    courseKindOptionsMutate: mutate,
  };
}

export function useLessonVideos() {
  const { data, error, mutate } = useSWR(
    `/tech/documents/video_options`,
    getLessonVideoOptions,
    globalConfig
  );
  return {
    lessonVideoOptions: data?.data,
    error,
    isLoading: !error && !data,
    lessonVideoOptionsMutate: mutate,
  };
}

export function useLesson(id) {
  const { data, error, mutate } = useSWR(
    `/tech/lessons/lesson?id=${id}`,
    () => getLesson(id),
    {...globalConfig, shouldRetryOnError: false}
  );

  return {
    lesson: data?.data,
    error,
    isLoading: !error && !data,
    lessonMutate: mutate,
  };
}

export function useLessons() {
  const { data, error, mutate } = useSWR(
    `/tech/lessons/options`,
    getLessons,
    {...globalConfig, shouldRetryOnError: false}
  );

  return {
    lessons: data?.data,
    error,
    isLoading: !error && !data,
    lessonsMutate: mutate,
  };
}

export function useExercises() {
  const { data, error, mutate } = useSWR(
    `/tech/exercises/options`,
    getExercises,
    {...globalConfig, shouldRetryOnError: false}
  );

  return {
    exercises: data?.data,
    error,
    isLoading: !error && !data,
    exercisesMutate: mutate,
  };
}
