import React, {useEffect, useState} from 'react';

const moment = require('moment');

const DateTime = ({label, required, nameKey, value, onChange, errorMessage, disabled, editablePastDate}) => {
  useEffect(() => {
    $(`input[name='${nameKey}']`).bootstrapMaterialDatePicker({
      weekStart: 0,
      lang: "ja",
      time: false,
      format: "YYYY-MM-DD",
      minDate: editablePastDate ? null : moment(),
    }).on('change', handleDateTime('date'))
  });

  const hoursOptions = (hour) => {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(<option key={i} value={i}>{i}</option>)
    }
    return hours
  }

  const miniutesOptions = (minute) => {
    let minutes = [];
    for (let i = 0; i < 60; i++) {
      minutes.push(<option key={i} value={i}>{i}</option>)
    }
    return minutes
  }

  const handleDateTime = (type) => {
    return (e) => {
      let date = type === 'date' ? moment(e.target.value) : moment(value);
      date.hours(type === 'hour' ? e.target.value : moment(value).format('H'));
      date.minutes(type === 'minute' ? e.target.value : moment(value).format('m'));
      onChange(date);
    }
  }

  return (
    <>
      <tr className={(errorMessage) && 'with_error_message'}>
        <th>
          <label>
            {label}
            {required && <span className={"badge_status badge_status_important"}>必須</span>}
          </label>
        </th>
      </tr>
      <tr>
        <td className={"form-text"}>
          <div className={`row-area row-area_liquid`}>
            <span className={errorMessage && "error_border"}>
              <input
                type={"text"}
                id={nameKey}
                name={nameKey}
                defaultValue={moment(value).format('YYYY-MM-DD')}
                placeholder={"カレンダーより選択してください"}
                disabled={disabled}
              />
            </span>
            <span className="row-area_liquid_connect"></span>
            <span>
              <select name={`${nameKey}_hour`} id={`${nameKey}_hour`} onChange={handleDateTime('hour')}
                      defaultValue={moment(value).format('H')}>
                {!required && <option value="">時刻を選択</option>}
                {hoursOptions()}
              </select>
            </span>
            <span className="row-area_liquid_connect"></span>
            <span>
              <select name={`${nameKey}_minute`} id={`${nameKey}_minute`} onChange={handleDateTime('minute')}
                      defaultValue={moment(value).format('m')}>
                {!required && <option value="">時刻を選択</option>}
                {miniutesOptions()}
              </select>
            </span>
          </div>
        </td>
      </tr>
      {errorMessage &&
      (
        <tr className={'error_message'}>
          <td>
            <span>{error}</span>
          </td>
        </tr>
      )
      }
    </>
  );
};

export default DateTime;
