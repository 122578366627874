import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import DraggableArea from './DraggableArea';
import DraggableCloseButton from './DraggableCloseButton';
import DraggableContent from './DraggableContent';

export default function DraggableModal({
  children,
  onClose,
  disableButtons = false,
  width = 500,
  height = 400,
  defaultX = 0,
  defaultY = 0,
  zIndex = 100,
}){
  const [state, setState] = useState({
    width: width,
    height: height,
    x: defaultX,
    y: defaultY,
  });
  return (
    <Rnd
      size={{ width: state.width, height: state.height }}
      position={{ x: state.x, y: state.y }}
      onDragStop={(_, d) => {
        setState({ ...state, ...{ x: d.x, y: d.y } });
      }}
      onResize={(_, __, ref, ___, position) => {
        setState({
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        });
      }}
      className="draggable-modal"
      dragHandleClassName="draggable-area"
      style={{zIndex: zIndex}}
    >
      <DraggableArea className="draggable-area">
        <span className="material-icons">drag_handle</span>
      </DraggableArea>
      <DraggableCloseButton onClick={onClose}>
        <span className="material-icons">close</span>
      </DraggableCloseButton>
      <DraggableContent>{children && children}</DraggableContent>
    </Rnd>
  );
};
