import React, {useState} from 'react'
import DragDropContext, {Draggable} from '../../../../DragDropContext'
import { convertBreakToBr } from '../../../../../shared/text'
import FlexBox from '../../../../atoms/FlexBox'
import Width from '../../../../atoms/Width'
import Container from '../../../../atoms/Container'
import Axios from '../../../../../lib/axios'
import TwoWayRow from '../../../../atoms/TwoWayRow'
import Span from '../../../../atoms/Span'
import Space from '../../../../atoms/Space'
import ChoicesIndex from './ChoicesIndex'
import Card from '../../../../atoms/Card'
import MathJaxTextView from '../../../../MathJaxTextView'

function QuestionIndex({questions}) {
  const [viewQuestions, setViewQuestions] = useState(questions)
  const [selectedQuestion, setSelectedQuestion] = useState(questions[0] || {})
  const [updateFlg, setUpdateFlg] = useState(false)
  const handleChangeOrder = (_questions) => {
    const copy = [...viewQuestions]
    setViewQuestions(_questions.map((c, idx) => ({...c, number: idx + 1})))
    Axios.put(
      `/tech/exercises/${questions[0].tech_exercise_id}/exercise_questions/update_order`,
      {questions: _questions}
    )
      .catch((err) => {
        setViewQuestions(copy)
      })
  }

  const handleChangeChoices = (questionId, choices) => {
    setViewQuestions(viewQuestions.map((vq) => vq.id === questionId ? {...vq, choices: choices} : vq))
    setSelectedQuestion({...selectedQuestion, choices: choices})
  }

  const ExerciseTypeIcon = ({typeEnum}) => {
    if (typeEnum.label === '記述式') {
      return <span className="material-icons">draw</span>
    } else if (typeEnum.label === '単一選択式') {
      return <span className="material-icons">radio_button_checked</span>
    } else if (typeEnum.label === '複数選択式') {
      return <span className="material-icons">check_box</span>
    } else {
      return <></>
    }
  }

  return (
    <FlexBox align="start">
      <Width size="40%">
        <Card>
          <FlexBox>
            <Container width="10%" margin="1rem"></Container>
            <Container width="10%" margin="1rem">配点</Container>
            <Container width="10%" margin="1rem">問題形式</Container>
            <Container width="60%" margin="1rem">問題文</Container>
            <Container width="10%" margin="1rem"></Container>
          </FlexBox>
          <DragDropContext
            handleDragEnd={handleChangeOrder}
            targets={viewQuestions}
            id="question_list"
          >
            {viewQuestions.map((q, index) => (
              <Draggable target={q} index={index} key={q.id}>
                <FlexBox borderTop="1px solid" onClick={() => setSelectedQuestion(q)}>
                  <Container width="10%" margin="1rem">
                    <span className="material-icons">unfold_more</span>
                  </Container>
                  <Container width="10%" margin="1rem">{q.score}</Container>
                  <Container width="10%" margin="1rem">
                    <ExerciseTypeIcon typeEnum={q.tech_exercise_form_type_enum}/>
                  </Container>
                  <Container width="60%" margin="1rem">{q.title.slice( 0, 19 )}</Container>
                  <Container width="10%" margin="1rem"></Container>
                </FlexBox>
              </Draggable>
            ))}
          </DragDropContext>
        </Card>
      </Width>
      <Width size="60%">
        <Space padding="0 0 0 2rem">
          <Width size="100%">
            <Space margin="1rem">
              <Space margin="0 0 1rem 0">
                <FlexBox>
                  <Width size="80%"><Span fontSize="14px" fontWeight="bold">問題文</Span></Width>
                  <Width size="20%">
                    <div className='button-area'>
                      <Span color="gray">
                        <a href={`/tech/exercises/${selectedQuestion.tech_exercise_id}/exercise_questions/${selectedQuestion.id}/edit`}>
                          <Span
                            className="material-icons"
                            color="gray"
                            fontSize="3rem"
                          >edit</Span>
                        </a>
                      </Span>
                      <span>
                        <a
                          data-confirm="本当に削除しますか"
                          rel="nofollow"
                          data-method="delete"
                          href={`/tech/exercises/${selectedQuestion.tech_exercise_id}/exercise_questions/${selectedQuestion.id}`}
                        >
                          <Span
                            className="material-icons"
                            color="#800000"
                            fontSize="3rem"
                          >delete</Span>
                        </a>
                      </span>
                    </div>
                  </Width>
                  
                </FlexBox>
              </Space>
              <MathJaxTextView target={selectedQuestion.title}/>
            </Space>
          </Width>

          <Card height="100%">
            <FlexBox content="center" border="0.2rem solid" backColor="#f5f5f5">
              <Width size="90%">
                {
                  selectedQuestion.tech_exercise_form_type_enum.label === "記述式" && (
                    <FlexBox width="100%" height="10rem" content="center">
                      <span>この問題は記述式です</span>
                    </FlexBox>
                  )
                }
                {
                  selectedQuestion.tech_exercise_form_type_enum.label !== "記述式" && (
                    <ChoicesIndex
                      choices={selectedQuestion.choices}
                      exerciseId={selectedQuestion.tech_exercise_id}
                      handleChangeChoices={handleChangeChoices}
                    />
                  )
                }
              </Width>
            </FlexBox>
          </Card>
        </Space>
      </Width>
    </FlexBox>
  )
}

export default QuestionIndex