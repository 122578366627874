import React from 'react'
import {genderLabel, accountTypeLabel} from './func';

function ApplierInformation({course, tech_student}) {
  return (
    <>
      <h2 className="tech_user_course_show_subtitle">申込者</h2>
      <div className="card_in_card">
        <div className="card">
          <div className="card_content">
            <div className="card_content_section">
              <div className="card_child_two_row">
                <div className="card_child_two_row_div">
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>お名前</h2>
                      <div>{`${tech_student.family_name} ${tech_student.given_name}`}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>性別</h2>
                      <div>{genderLabel(tech_student)}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>メールアドレス</h2>
                      <div>{tech_student.email}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>会社名もしくは学校名</h2>
                      <div>{tech_student.organization_name}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>電話番号</h2>
                      <div>{`${tech_student.first_tel}-${tech_student.second_tel}-${tech_student.third_tel}`}</div>
                    </div>
                  </div>
                </div>
                <div className="card_child_two_row_div">
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>お名前（ローマ字）</h2>
                      <span>{`${tech_student.family_name_romaji} ${tech_student.given_name_romaji}`}</span>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>年齢</h2>
                      <div>{tech_student.age}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>会員種別</h2>
                      <div>{accountTypeLabel(tech_student)}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>会社名もしくは学校名（カナ）
                      </h2>
                      <div>{tech_student.organization_name_kana}</div>
                    </div>
                  </div>
                  <div className="card_section">
                    <div className="card_section_article">
                      <h2>メール等の配信希望</h2>
                      <div>{tech_student.is_send_mail_magazine === 'true' ? '希望する' : '希望しない'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplierInformation