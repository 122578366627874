import React from "react";
import styled from "styled-components"
import DateDecorator from "../../DateDecorator";
import Width from "../../atoms/Width";
import FlexBox from "../../atoms/FlexBox";
import Space from "../../atoms/Space";

const Header = styled.div`
  margin: 0;
  display: flex,
  justifyContent: center,
`
const Button = styled.button`
  justify-content: center;
  height: 39px;
  width: 39px;
  background-color: #134485;
  padding: 1rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 0.4rem;
  cursor: pointer;
  padding: 0 13px;
  margin: 0;
`

const Span = styled.span`
  font-weight: bold;
  font-size: 14px;
`

const CustomDatePickerHeader = ({date, onChangeDate}) => {
  const onChangeYear = (e) => {
    let d = new DateDecorator(date)
    d = d.setYear(e.target.value)
    onChangeDate(d.date)
  }

  const onChangeMonth = (e) => {
    let d = new DateDecorator(date)
    d = d.setMonth(e.target.value - 1)
    onChangeDate(d.date)
  }

  return (
    <Width size="100%">
      <Header>
        <FlexBox>
          <Width size="20%">
            <Button onClick={() => onChangeMonth({target: {value: date.getMonth()}})}>
              {"<"}
            </Button>
          </Width>
          <Width size="30%">
            <select onChange={onChangeYear} value={date.getFullYear()}>
              {
                [...Array(4)].map((_, i) => i + date.getFullYear() - 2).map((option, index) => (
                  <option value={option} key={`month-key-${index}`}>{option}</option>
                ))
              }
            </select>
          </Width>
          <Width size="30%">
            <select onChange={onChangeMonth} value={date.getMonth() + 1}>
              {
                [1,2,3,4,5,6,7,8,9,10,11,12].map((option, index) => (
                  <option value={option} key={`month-key-${index}`}>{option}</option>
                ))
              }
            </select>
          </Width>
          <Width size="20%">
            <Button onClick={() => onChangeMonth({target: {value: date.getMonth() + 2}})}>
              {">"}
            </Button>
          </Width>
        </FlexBox>
      </Header>
    </Width>

  )
}

export default CustomDatePickerHeader