import React from 'react'
import FlexBox from '../../atoms/FlexBox';
import Space from '../../atoms/Space';
import Container from '../../atoms/Container';
import PrevEventButton from './PrevEventButton';
import NextEventButton from './NextEventButton';

export default function TitleArea({
  event,
  isExistPrevEvent,
  isExistNextEvent
}) {
  return (
    <FlexBox>
      <PrevEventButton event={event} isExistPrevEvent={isExistPrevEvent}/>
      <NextEventButton event={event} isExistNextEvent={isExistNextEvent}/>
      <Space margin="0 0 0 2rem">
        <Container width="100%" height="100%"><h1>{event.event_name}</h1></Container>
      </Space>
    </FlexBox>
  )
}
